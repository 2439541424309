/* eslint-disable no-underscore-dangle */
import { Tabs, TabsRef, Select, Button, TextInput } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import { ICredit, getCredits } from 'services/accountManager/credits';
import toast from 'react-hot-toast';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment-timezone';
import DataTable from 'components/tables/tables';
import { getCurrentUser } from 'utils/helpers/auth';
import { AiOutlineSearch } from 'react-icons/ai';
import { CiExport } from 'react-icons/ci';
import { adsColumns, cogsColumns, handleExportCredit, statusDisplay } from './creditElements';
import { ICountCreditStats, Ifilter } from './credits.types';
import CreditStatistic from './creditsStatistic';
import CreateCredit from './createCredit';

interface Ipagination {
    page: number;
    limit: number;
}

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}
const Credits = () => {
    const tabsRef = useRef<TabsRef | null>(null);
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [filter, setFilter] = useState<Ifilter>({
        creditType: 'Cogs',
    });
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [creditList, setCreditList] = useState<ICredit[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [creditStats, setCreditStats] = useState<ICountCreditStats | null>(null);
    const [loadingStats, setLoadingStats] = useState<boolean>(false);
    const [openCreateCredit, setOpenCreateCredit] = useState<boolean>(false);
    const [selectedCredits, setSelectedCredits] = useState<ICredit[]>([]);
    const user = getCurrentUser();

    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setFilter({ ...filter, ...{ dateRange: newRange } });
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    };

    function handleChange(event: any) {
        setIsLoading(true);
        if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined } });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    }

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const getTabValue = (tab: number) => {
        switch (tab) {
            case 0:
                return 'Cogs';
            case 1:
                return 'Ads';
            default:
                return 'Cogs';
        }
    };

    const handleCreateCredit = () => {
        setOpenCreateCredit(true);
    };

    useEffect(() => {
        setIsLoading(true);
        const queryOptions = { ...pagination, ...filter };
        getCredits(queryOptions)
            .then((result: any) => {
                setCreditList(result?.data?.docs);
                setMeta({
                    hasNextPage: result.data?.hasNextPage,
                    hasPrevPage: result.data?.hasPrevPage,
                    limit: result.data?.limit,
                    nextPage: result.data?.nextPage,
                    page: result.data?.page,
                    pagingCounter: result.data?.pagingCounter,
                    prevPage: result.data?.prevPage,
                    totalDocs: result.data?.totalDocs,
                    totalPages: result.data?.totalPages,
                });

                if (!result.data.docs?.length) {
                    toast.success('No results found for your search criteria');
                }

                setIsLoading(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message ?? 'Sorry something went wrong');
            });
    }, [filter, pagination]);

    return (
        <>
            <div className="bg-white dark:bg-gray-900 p-2">
                <section className="dark:bg-gray-900  items-center my-1">
                    <div className="flex  my-4">
                        <div className="flex ">
                            <span className="font-medium text-2xl">Credits</span>
                        </div>
                    </div>

                    <CreditStatistic
                        creditStats={creditStats}
                        loadingStats={loadingStats}
                        filter={filter}
                        setCreditStats={setCreditStats}
                        setLoadingStats={setLoadingStats}
                    />

                    <div className=" relative flex w-full items-center justify-between">
                        <div className="flex items-center gap-4">
                            <div>
                                <Datepicker
                                    inputId="deliveryDate"
                                    value={filter.dateRange || null}
                                    containerClassName="relative z-100"
                                    useRange
                                    onChange={handleRangeFilterChange}
                                    showShortcuts
                                />
                            </div>
                            <div>
                                <Select id="status" required name="status" onChange={(e) => handleChange(e)}>
                                    <option value="" key="0">
                                        All Status
                                    </option>
                                    <option value="1" key="1">
                                        Pending
                                    </option>
                                    <option value="2" key="2">
                                        Confirmed
                                    </option>
                                    <option value="3" key="3">
                                        Paid
                                    </option>
                                    <option value="4" key="4">
                                        Rejected
                                    </option>
                                </Select>
                            </div>
                            <div className="w-48">
                                <TextInput
                                    icon={AiOutlineSearch}
                                    style={{ width: '320px' }}
                                    id="skuSearch"
                                    placeholder={
                                        filter?.creditType === 'Cogs'
                                            ? 'Search by SKU, product name or seller'
                                            : 'Search by seller'
                                    }
                                    name="sku"
                                    onChange={(e) => {
                                        setFilter({
                                            ...filter,
                                            ...{ skuOrCustomer: e.target.value || '' },
                                        });

                                        setPagination({ ...pagination, page: 1 });
                                        setMeta({ ...meta, page: -1 });
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex gap-2">
                            <Button
                                type="button"
                                onClick={() => handleExportCredit(selectedCredits, filter.creditType)}
                                disabled={isLoading || !selectedCredits?.length}
                                color="light"
                            >
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <CiExport className="w-4 h-4 dark:text-gray-400 text-gray-700" />
                                    </div>
                                    <div className="dark:text-gray-400 text-gray-700">Export</div>
                                </div>
                                {selectedCredits?.length > 0 ? (
                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-600 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                                        {selectedCredits.length}
                                    </div>
                                ) : null}
                            </Button>

                            <Button color="blue" onClick={handleCreateCredit}>
                                Create a credit
                            </Button>
                        </div>
                    </div>
                </section>

                <Tabs
                    aria-label="Tabs with icons"
                    // eslint-disable-next-line react/style-prop-object
                    theme={{
                        tablist: {
                            tabitem: {
                                base: 'flex items-center text-blue-500 justify-center px-4 h-14 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                styles: {
                                    default: {
                                        base: 'flex items-center text-blue-500 justify-center px-4 h-14  text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                        active: {
                                            on: 'text-main border-main border-b-2',
                                            off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                                        },
                                    },
                                },
                            },
                        },
                    }}
                    ref={tabsRef}
                    onActiveTabChange={(tab) => {
                        if (getTabValue(tab) !== filter.creditType) {
                            setFilter({ ...filter, ...{ creditType: getTabValue(tab) } });
                            setPagination({ ...pagination, page: 1 });
                            setMeta({ ...meta, page: -1 });
                        }
                    }}
                >
                    {[
                        { label: 'Cogs', value: 'Cogs' },
                        { label: 'Ads', value: 'Ads' },
                    ].map((item: any) => {
                        const displayedColumns = item?.value === 'Ads' ? adsColumns : cogsColumns;
                        return (
                            <Tabs.Item
                                title={<div className="h-full py-4">{item.label}</div>}
                                key={item.value}
                                style={{ height: '64px !important', padding: '0px 24px' }}
                                className="!py-0 xs:!h-14"
                            >
                                <DataTable
                                    selectable
                                    setRowsSelected={setSelectedCredits}
                                    rows={creditList}
                                    columns={displayedColumns.filter((elt) =>
                                        user.role === 'admin' ? elt : elt.field !== 'managedBy',
                                    )}
                                    icons={statusDisplay}
                                    setRows={setCreditList}
                                    pageState={{
                                        isLoading,
                                        total: meta.totalDocs,
                                        pageSize: pagination.limit,
                                        page: pagination.page,
                                        count: meta.totalPages,
                                    }}
                                    setPageState={handlePaginationChange}
                                    filterState={filter}
                                    setStats={setCreditStats}
                                />
                            </Tabs.Item>
                        );
                    })}
                </Tabs>
            </div>
            {openCreateCredit && (
                <CreateCredit
                    creditType={filter.creditType}
                    openCreateCredit={openCreateCredit}
                    setOpenCreateCredit={setOpenCreateCredit}
                    setCreditList={setCreditList}
                    setCreditStats={setCreditStats}
                />
            )}
        </>
    );
};
export default Credits;
