/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Button, Modal } from 'flowbite-react';
import React, { useRef, useState } from 'react';
import { IoMdRemoveCircle } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { openPdfInNewTab } from 'utils/helpers/productFilesDownloader';

import moment from 'moment';
import toast from 'react-hot-toast';
import { AiOutlineUpload } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { deleteMedia } from 'services/admin/resellerProduct';
import FileOther from '../../../../assets/file.png';
import FilePdf from '../../../../assets/pdf.png';
import { IMediaUploaderProps, MediaData } from './types';

const MediaUploader: React.FC<IMediaUploaderProps> = (props) => {
    const location: any = useLocation();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { setAllImages, allImages, setResellerVideos, allVideos, setAllVideos } = props;
    const [selectedImgForDelete, setSelectedImgForDelete] = useState('');
    const [selectedVidForDelete, setSelectedVidForDelete] = useState('');
    const [openDeleteVideoModal, setOpenDeleteVideoModal] = useState(false);
    const [openDeleteImgModal, setOpenDeleteImgModal] = useState(false);
    const acceptedFiles = '.jpg,.jpeg,.png,.webp,.svg,.svg%2Bxml,.mp4,.ogg,.pdf';
    const handleOpenDeleteImgModal = (state: boolean) => {
        setOpenDeleteImgModal(state);
    };
    const handleOpenDeleteVideoModal = (state: boolean) => {
        setOpenDeleteVideoModal(state);
    };

    const handleDeleteVideo = () => {
        // Pending UI logic

        // setIsLoading(true);
        // toast.loading('Deleting image in progress...');

        /**
         * 🏷️ Check if the image to delete is already updloaded or not
         *
         * Not uploaded images have a field called `file` containing the
         * image blob so already uploaded images does not have this field
         *  */
        const isNewVideo = allVideos.some((video) => {
            return video.url === selectedVidForDelete && !!video.file;
        });

        /**
         * 🏷️ Delete action
         *
         * If the video is not uploaded yet (`isNewVideo === true`):
         *      we remove its data from the local states
         *      &
         *      we release its objectURL from the browser (performance & memory usage optimisation)
         * Else (`isNewVideo === false`):
         *      we send a delete request to the backend.
         */
        if (isNewVideo) {
            const updatedData: typeof allVideos = [];
            allVideos.forEach((video) => {
                if (video.url !== selectedVidForDelete) {
                    updatedData.push(video);
                } else {
                    URL.revokeObjectURL(video.url);
                }
            });
            setAllVideos([...updatedData]);
            setResellerVideos(() => [...updatedData.map((video) => video.url)]);
            // Action feedback

            // setIsLoading(false);
            toast.success('Video deleted successfully');
        } else {
            deleteMedia({ productId: location.state.productId, link: selectedVidForDelete, type: 'videos' })
                .then((res) => {
                    setAllVideos([
                        ...res.data.map((url: string) => ({
                            name: url.substring(url.indexOf('.com/') + 5), // selects 'imgName' from 'https://domain.com/imgName'
                            url,
                        })),
                    ]);
                    setResellerVideos([...res.data.map((url: string) => url)]);
                    toast.success('Video deleted successfully');
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong! Please try again.');
                })
                .finally(() => {
                    // setIsLoading(false);
                });
        }
        setOpenDeleteVideoModal(false);
    };
    const handleDeleteImage = () => {
        const isNewImage = allImages.some((image) => {
            return image.url === selectedImgForDelete && !!image.file;
        });

        if (isNewImage) {
            const updatedData: typeof allImages = [];
            allImages.forEach((img) => {
                if (img.url !== selectedImgForDelete) {
                    updatedData.push(img);
                } else {
                    URL.revokeObjectURL(img.url);
                }
            });
            setAllImages([...updatedData]);
            toast.success('Image deleted successfully');
        } else {
            deleteMedia({ productId: location.state.productId, link: selectedImgForDelete, type: 'images' })
                .then((res) => {
                    setAllImages([
                        ...res.data.map((url: string) => ({
                            name: url.substring(url.indexOf('.com/') + 5), // selects 'imgName' from 'https://domain.com/imgName'
                            url,
                        })),
                    ]);
                    toast.success('Image deleted successfully');
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong! Please try again.');
                })
                .finally(() => {
                    // setIsLoading(false);
                });
        }

        setOpenDeleteImgModal(false);
    };
    const handleDrop = (e: any) => {
        e.preventDefault();
        const files = Array.from(e.target.files || e.dataTransfer.files);
        if (files) {
            files.forEach((file: any) => {
                const extension = file?.type ? file.type.split('/')[1] : '';
                if (file && acceptedFiles.includes(extension)) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const newFile: MediaData = {
                            name: file.name,
                            url: URL.createObjectURL(file),
                            file,
                            base64: reader.result,
                            date: new Date(),
                            type: file.type,
                        };
                        if (file.type.startsWith('video/')) {
                            setAllVideos((vids) => [...vids, newFile]);
                            setResellerVideos((urls) => [...urls, newFile.url]);
                        } else {
                            setAllImages((imgs) => {
                                return [...imgs, newFile];
                            });
                        }
                    };
                }
            });
        }
    };
    return (
        <div>
            <Modal show={openDeleteImgModal}>
                <Modal.Body>
                    <div
                        style={{
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '500px',
                        }}
                    >
                        <p style={{ fontSize: 17, padding: '10px 20px' }}>Delete image ? </p>
                        <p>Deleted images can&apos;t be restored!</p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                margin: '20px',
                            }}
                        >
                            <Button color="failure" onClick={handleDeleteImage} pill>
                                Delete
                            </Button>
                            <Button
                                color="gray"
                                pill
                                onClick={() => {
                                    handleOpenDeleteImgModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openDeleteVideoModal}>
                <Modal.Body>
                    <div
                        style={{
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '500px',
                        }}
                    >
                        <p style={{ fontSize: 17, padding: '10px 20px' }}>Delete video?</p>
                        <p>Deleted videos can&apos;t be restored!</p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                margin: '20px',
                            }}
                        >
                            <Button color="failure" onClick={handleDeleteVideo} pill>
                                Delete
                            </Button>
                            <Button
                                color="gray"
                                pill
                                onClick={() => {
                                    handleOpenDeleteVideoModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div
                className="rounded-md outline-2	 outline-dashed outline-gray-300 p-5 min-h-58 bloc"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
            >
                <input
                    type="file"
                    multiple
                    accept={acceptedFiles}
                    onChange={handleDrop}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                />
                <div className="flex justify-center gap-8">
                    {[...allImages, ...allVideos]
                        .sort((a, b) => {
                            const dateA = a.date ? moment(a.date).toDate() : new Date(0);
                            const dateB = b.date ? moment(b.date).toDate() : new Date(0);
                            return dateA.getTime() - dateB.getTime();
                        })
                        .map((file: any) => {
                            if (file?.type?.startsWith('video/')) {
                                let videoSrc = file.url;
                                if (
                                    videoSrc &&
                                    !videoSrc.toLowerCase().startsWith('https://') &&
                                    !videoSrc.toLowerCase().startsWith('http://') &&
                                    !videoSrc.toLowerCase().startsWith('blob')
                                ) {
                                    videoSrc = `https://${file.url}`;
                                }

                                return (
                                    <div className="relative w-64" key={file.url}>
                                        <IoMdRemoveCircle
                                            className="h-6 w-6 absolute right-2 z-10"
                                            color="red"
                                            role="button"
                                            onClick={() => {
                                                setSelectedVidForDelete(file.url);
                                                handleOpenDeleteVideoModal(true);
                                            }}
                                        />

                                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                        <video
                                            src={videoSrc}
                                            controls
                                            className="cursor-pointer object-cover rounded-lg overflow-hidden w-64 h-40 object-cover "
                                        />
                                    </div>
                                );
                            }
                            return (
                                <div style={{ position: 'relative' }} key={file.url}>
                                    {file.name.endsWith('.jpg') ||
                                    file.name.endsWith('.png') ||
                                    file.name.endsWith('.jpeg') ||
                                    file.name.endsWith('.webp') ||
                                    file.name.endsWith('.svg') ||
                                    file.name.endsWith('.svg%2Bxml') ? (
                                        <>
                                            <IoMdRemoveCircle
                                                className="h-6 w-6 absolute right-2"
                                                color="red"
                                                role="button"
                                                onClick={() => {
                                                    setSelectedImgForDelete(file.url);
                                                    handleOpenDeleteImgModal(true);
                                                }}
                                            />

                                            <img
                                                src={(file?.base64 as string) || file.url}
                                                alt="quotation"
                                                title="Click to view PDF"
                                                className="cursor-pointer "
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: 8,
                                                    overflow: 'hidden',
                                                    width: 100,
                                                    height: 100,
                                                }}
                                                onClick={() => openPdfInNewTab(file.url, 'Image preview')}
                                            />
                                        </>
                                    ) : file.name.endsWith('.pdf') ? (
                                        <div>
                                            <IoMdRemoveCircle
                                                className="h-6 w-6 absolute right-2"
                                                color="red"
                                                role="button"
                                                onClick={() => {
                                                    setSelectedImgForDelete(file.url);
                                                    handleOpenDeleteImgModal(true);
                                                }}
                                            />

                                            <div className="relative">
                                                <img
                                                    src={FilePdf}
                                                    alt="quotation"
                                                    title="Click to view PDF"
                                                    className="cursor-pointer "
                                                    style={{
                                                        objectFit: 'cover',
                                                        borderRadius: 8,
                                                        overflow: 'hidden',
                                                        width: 100,
                                                        height: 100,
                                                    }}
                                                    onClick={() => openPdfInNewTab(file.url, 'Image preview')}
                                                />
                                                <IoSearch
                                                    onClick={() => openPdfInNewTab(file.url, 'Image preview')}
                                                    title="Click to view PDF"
                                                    className="absolute top-1 cursor-pointer left-4 h-5 w-5 text-red-500 hover:bg-red-200 hover:ring-2 ring-red-200 "
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <IoMdRemoveCircle
                                                className="h-6 w-6 absolute right-2"
                                                color="red"
                                                role="button"
                                                onClick={() => {
                                                    setSelectedImgForDelete(file.url);
                                                    handleOpenDeleteImgModal(true);
                                                }}
                                            />

                                            <img
                                                src={FileOther}
                                                alt="reseller product"
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: 8,
                                                    overflow: 'hidden',
                                                    width: 100,
                                                    height: 100,
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
                <div
                    className="w-full grid text-gray-400 justify-center items-center h-[100px]"
                    onClick={() => fileInputRef.current?.click()}
                >
                    {![...allImages, ...allVideos].length && <AiOutlineUpload className="w-16 h-16  my-0 mx-auto" />}
                    <p className="cursor-pointer">Drag one or more files</p>
                </div>
            </div>
        </div>
    );
};
export default MediaUploader;
