/* eslint-disable no-underscore-dangle */
import { ICustomer } from 'pages/customers/customer.types';
import { INVOICE_TYPES, Iinvoice } from 'pages/invoices/invoice.types';
import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';
import { utils, write, writeFile } from 'xlsx';

export interface IxlsxInvoice {
    Name: string;
    recipientEmail: string;
    paymentReference: string;
    receiverType: string;
    amountCurrency: string;
    amount: number;
    sourceCurrency: string;
    targetCurrency: string;
    type: string;
    abartn: string;
    accountNumber: string;
    accountType: string;
    addressCountryCode?: string;
    addressCity?: string;
    addressFirstLine: string;
    addressState: string;
    addressPostCode?: string;
    BIC?: string;
}
export interface IxlsxInvoicePaypalPayment {
    'Recipient identifier': string;
    'Payment amount': string;
    Currency: string;
    'Customer ID': string;
    'Note to Recipient': string;
    'Recipient Wallet': string;
}

export interface ICountInvoicesStats {
    totalAmountToPay?: number;
    totalUnpaidBalance?: number;
    callCenterIncome?: number;
    ordersIncome?: number;
    totalShippingFees?: number;
    totalHandleFees?: number;
    totalResellerFees?: number;
}

export const getInvoiceList = async (data: any) => {
    const invoices = await axios.post(`/invoices/filter`, data, getBearerToken());
    return invoices;
};

export const getInvoice = async (id: any) => {
    const invoice = await axios.get(`/invoices/${id}`, getBearerToken());
    return invoice;
};

export const updateInvoice = async (id: any, data: any) => {
    const invoiceUpdate = await axios.put(`/invoices/update-one/${id}`, data, getBearerToken());
    return invoiceUpdate;
};
export const updateManyInvoices = async (data: any) => {
    const invoiceUpdate = await axios.put(`/invoices/update-many`, data, getBearerToken());
    return invoiceUpdate;
};
// Downloads xlsx file containing 1 order of multiple items with dummy data (this exemple contains merged rows)
export const downloadBatchPayment = (invoicesList: Iinvoice[]) => {
    const wisePayments: IxlsxInvoice[] = invoicesList
        .filter(
            (invoice) =>
                (invoice.customer as ICustomer).bankAccountInformation?.bankingType === 'BankAccount' ||
                !(invoice.customer as ICustomer).bankAccountInformation?.bankingType,
        )
        .map((invoice) => ({
            Name: `${(invoice?.customer as ICustomer)?.firstName} ${
                (invoice?.customer as ICustomer)?.lastName || '--'
            }`,
            recipientEmail: `${(invoice?.customer as ICustomer)?.email || '--'}`,
            paymentReference: `${invoice?.reference || '--'}`,
            receiverType: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.receiverType || '--'}`,
            amountCurrency: `source`,
            amount: invoice?.totalAmountToPay,
            sourceCurrency: `USD`,
            targetCurrency: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.targetCurrency || '--'}`,
            type: '', //
            abartn: `OPTIONAL`,

            accountNumber: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.accountNumber || '--'}`,
            accountType: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.accountType || '--'}`,
            addressCountryCode: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.country || '--'}`,
            addressCity: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.city || '--'}`,
            addressFirstLine: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.recepientAdress || '--'}`,
            addressState: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.city || '--'}`,
            addressPostCode: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.postCode || '--'}`,
            BIC: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.swiftBicCode || '--'}`,
            IBAN: `${(invoice?.customer as ICustomer)?.bankAccountInformation?.accountNumber || '--'}`,
        }));
    const paypalPayments: IxlsxInvoicePaypalPayment[] = invoicesList
        .filter((invoice) => (invoice.customer as ICustomer).bankAccountInformation?.bankingType === 'FinancialService')
        .map((invoice) => ({
            'Recipient identifier': `${(invoice?.customer as ICustomer)?.email}`,
            'Payment amount': `${invoice?.totalAmountToPay || '0'}`,
            Currency: `USD`, // Source currency
            'Customer ID': `${(invoice?.customer as ICustomer)?.bankAccountInformation?.receiverType || '--'}`,
            'Note to Recipient': `Invoice payment ${invoice?.reference || '--'}`,
            'Recipient Wallet': 'PAYPAL', //  || VENMO
        }));
    let worksheet = utils.json_to_sheet(wisePayments);

    let workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Logistio-Batch-Payment');
    write(workbook, { bookType: 'xlsx', type: 'binary' });
    writeFile(workbook, 'Logistio-batch-payment-wise.xlsx');

    utils.json_to_sheet(paypalPayments);
    worksheet = utils.json_to_sheet(paypalPayments);
    workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Logistio-Batch-Payment');
    write(workbook, { bookType: 'csv', type: 'binary' });
    writeFile(workbook, 'Logistio-batch-payment-Paypal.csv');
};

export const getInvoicePdf = async (data: { invoiceId: string; timezone: string; invoiceType: INVOICE_TYPES }) => {
    const response = await axios.post<Buffer>(`/invoices/getPdfInvoice`, data, {
        responseType: 'arraybuffer',
        ...getBearerToken(),
    });
    return response;
};

export const getPendingInvoicesCount = async () => {
    const res = await axios.get<number>('/invoices/countPendingInvoices', getBearerToken());
    return res;
};

export const countInvoicesStats = async (filter: any) => {
    const res = await axios.post<ICountInvoicesStats>('/invoices/countInvoicesStats', filter, getBearerToken());
    return res;
};
