import { TableColDef } from 'components/tables/tables.types';
import { IResellerStock } from 'services/admin/resellerStock';
import React from 'react';
import { Avatar, Button } from 'flowbite-react';
import { handleCopyTC } from 'utils/helpers/copyTC';
import { FaRegCopy } from 'react-icons/fa';
import ShipmentDetails from 'pages/stock/shipmentDetails/shipmentDetails';
import { ResellerProduct } from '../reseller.types';

export const columns: TableColDef[] = [
    {
        field: 'country',
        headerName: 'Country',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'resellerProduct.name',
        headerName: 'product Name',
        fieldType: 'render',
        renderCell: (params: IResellerStock) => {
            const isThere = (
                (params.resellerProduct as ResellerProduct)?.images?.length
                    ? (params.resellerProduct as ResellerProduct).images
                    : []
            )?.filter(
                (prodImg: any) =>
                    prodImg.endsWith('.jpg') ||
                    prodImg.endsWith('.png') ||
                    prodImg.endsWith('.jpeg') ||
                    prodImg.endsWith('.webp'),
            );
            return (
                <>
                    <td className="px-4 py-4 flex gap-2">
                        <Avatar img={isThere[0]} />
                        <span className="capitalize my-auto">{(params.resellerProduct as ResellerProduct).name}</span>
                    </td>{' '}
                </>
            );
        },
    },
    {
        field: 'resellerProduct',
        headerName: 'Variant/SKU',
        fieldType: 'render',
        renderCell: (params: any) => {
            return (
                <>
                    <td className="py-4 block gap-2">
                        <div className="capitalize text-black dark:text-white">{params?.resellerProduct?.variant}</div>
                        <div className="flex items-center gap-2">
                            <p className="font-normal ">{params?.resellerProduct?.sku}</p>
                            <Button
                                size="l"
                                color="white"
                                title="Copy tracking code to clipboard"
                                onClick={() => {
                                    handleCopyTC(params?.resellerProduct?.sku || '', 'SKU');
                                }}
                            >
                                <FaRegCopy className="w-4 h-4" />
                            </Button>
                        </div>
                    </td>{' '}
                </>
            );
        },
    },

    {
        field: 'quantity',
        headerName: 'Total Qty',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'incomingQuantity',
        headerName: 'Incoming Quantity',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white ',
        fieldType: 'render',
        renderCell: (params: any) => params.incomingQuantity || 0,
    },
    {
        field: 'availableQuantity',
        headerName: 'Available Quantity',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white ',
    },
    {
        field: 'Action',
        headerName: 'Outside Quantity',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white ',
        actionType: 'OrdersResellerStock',
    },
    {
        field: 'deliveredQuantity',
        headerName: 'Delivered Quantity',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white ',
    },
    {
        field: 'packagedQuantity',
        headerName: 'Packaged Quantity',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white ',
        fieldType: 'render',
        renderCell: (params: any) => params.packagedQuantity || 0,
    },
    {
        field: 'Action',
        headerName: '',
        actionType: 'render',
        renderCell: (params) => {
            return (
                <>
                    <ShipmentDetails data={params} />
                </>
            );
        },
    },
];
