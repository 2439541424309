import { Modal } from 'flowbite-react';
import React, { useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { IPayments } from 'services/admin/payments';
import BankPayment from '../bankPayment';
import { IPaymentActions } from '../payments.types';

const PaymentActions = (props: IPaymentActions) => {
    const [ModalDetails, setModalDetails] = useState(false);
    const DetailsModalOpen = () => {
        setModalDetails(true);
    };
    const DetailsModalClose = () => {
        setModalDetails(false);
    };
    const { payment, setRows, currentPage, currentFilter } = props;

    return (
        <>
            {ModalDetails && (
                <Modal dismissible show={ModalDetails} popup onClose={DetailsModalClose}>
                    <Modal.Header>Payment Details</Modal.Header>
                    <Modal.Body>
                        <BankPayment
                            paymentData={payment as IPayments}
                            setRows={setRows}
                            currentPage={currentPage}
                            currentFilter={currentFilter}
                        />
                    </Modal.Body>
                </Modal>
            )}
            <IoEyeOutline
                onClick={DetailsModalOpen}
                className="w-5 h-5 hover:cursor-pointer dark:text-gray-400  text-gray-700"
            />
        </>
    );
};
export default PaymentActions;
