import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import { Modal, Label, Select, TextInput } from 'flowbite-react';
import { Formik, Form } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import { ICurrencyRate, addCountryConfiguration, getConfiguration } from 'services/admin/configuration';
import { countries } from 'utils/countryList';
import { AddPricingCurrencyRate } from 'utils/validations/pricingConfiguration';
import { AddConfig } from './configuration.types';

const AddConfiguration: React.FC<AddConfig> = ({ open, configPricing, setOpen, setConfigPricing }) => {
    const AddCountryConfig = (values: ICurrencyRate) => {
        const toastLoader = toast.loading('Loading...');
        let newValues;
        const newPricing = configPricing;
        if (configPricing.currencyRate) {
            newValues = [...configPricing.currencyRate];
            newValues.push(values);
        } else {
            newValues = [values];
        }
        newPricing.currencyRate = newValues;
        addCountryConfiguration({ pricing: newPricing })
            .then((res) => {
                toast.success(res.data.message, { id: toastLoader });
                getConfiguration().then((result) => {
                    setConfigPricing(result.data.pricing);
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message, { id: toastLoader });
            });
    };
    const checkCountryPricing = (country: string) => {
        return configPricing.currencyRate && configPricing.currencyRate.find((el: any) => el.country === country);
    };

    return (
        <div>
            <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                <Modal.Header>New Pricing</Modal.Header>
                <Modal.Body className=" !p-0">
                    <Formik
                        initialValues={{
                            country: '',
                            currencyValue: '',
                        }}
                        onSubmit={(values, onSubmitProps) => {
                            AddCountryConfig({
                                country: values.country,
                                currencyValue: Number(values.currencyValue),
                            });
                            setOpen(false);
                            onSubmitProps.setSubmitting(false);
                            onSubmitProps.resetForm();
                        }}
                        validationSchema={AddPricingCurrencyRate}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <div className="py-2 px-6 flex flex-col gap-2 overflow-auto max-h-[37rem]">
                                    <div>
                                        <div className="mb-2 block" key="zer">
                                            <Label htmlFor="country" value="Select country" />
                                        </div>
                                        <Select
                                            id="countries"
                                            required
                                            {...formik.getFieldProps('country')}
                                            name="country"
                                            helperText={
                                                <>
                                                    <CustomError name="country" component="div" />
                                                </>
                                            }
                                        >
                                            <option key="chooseCountryKey" value="" selected>
                                                Select Country
                                            </option>
                                            {countries.map((country) => {
                                                if (!checkCountryPricing(country.label)) {
                                                    return (
                                                        <option
                                                            key={`${country.code}addCurrency`}
                                                            value={country.label}
                                                        >
                                                            {country.label}
                                                        </option>
                                                    );
                                                }
                                                return (
                                                    <option key={country.code} value={country.label} disabled>
                                                        {country.label}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                    <div className="flex gap-2 justify-between py-2 px-2 items-top rounded-b-lg  bg-background-secondary">
                                        <div>
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="currencyValUDS"
                                                    className="dark:text-white"
                                                    value="USD value"
                                                />
                                            </div>
                                            <TextInput
                                                value={1}
                                                name="currencyValUDS"
                                                // placeholder="jhondoe@email.com"
                                                disabled
                                                type="text"
                                                helperText={
                                                    <>
                                                        <CustomError name="currencyValUDS" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>
                                        <div>
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="currencyValue"
                                                    className="dark:text-white"
                                                    color={
                                                        formik.touched.currencyValue && formik.errors.currencyValue
                                                            ? 'failure'
                                                            : ''
                                                    }
                                                    value={`${
                                                        countries.find(
                                                            (element) => element.label === formik.values.country,
                                                        )?.currency || '******'
                                                    } value`}
                                                />
                                            </div>
                                            <TextInput
                                                {...formik.getFieldProps('currencyValue')}
                                                name="currencyValue"
                                                color={
                                                    formik.touched.currencyValue && formik.errors.currencyValue
                                                        ? 'failure'
                                                        : 'gray'
                                                }
                                                type="text"
                                                helperText={
                                                    <>
                                                        <CustomError name="currencyValue" component="div" />
                                                    </>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                                    <CustomBtn
                                        variant="failure"
                                        outline
                                        pill
                                        type="reset"
                                        onClick={() => {
                                            setOpen(false);
                                            formik.resetForm();
                                        }}
                                    >
                                        Cancel
                                    </CustomBtn>
                                    <CustomBtn
                                        pill
                                        variant="primary"
                                        type="submit"
                                        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                    >
                                        Add
                                    </CustomBtn>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default AddConfiguration;
