/* eslint-disable no-underscore-dangle */
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import React, { useEffect, useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';

import { Box } from '@mui/material';
import AutoComplete from 'components/autocomplete/autoComplete';
import { ICustomer } from 'pages/customers/customer.types';

import { Button } from 'flowbite-react';
import { IProduct } from 'utils/Interfaces/products.interface';
import { getProducts } from 'services/admin/product';
import InputField from 'components/inputField/InputField';
import toast from 'react-hot-toast';
import { addCredit, getCredits, getCreditStats, ICredit, updatecredit } from 'services/accountManager/credits';
import { getCustomers } from 'services/admin/customer';
import { CREDIT_TYPES, IaddCreditValues } from './credits.types';

const CreateCredit: React.FC<{
    creditType: CREDIT_TYPES;
    openCreateCredit: boolean;
    setOpenCreateCredit: any;
    setCreditList: any;
    setCreditStats: any;
    edit?: boolean;
    creditData?: ICredit;
}> = ({
    creditType,
    openCreateCredit,
    setOpenCreateCredit,
    setCreditList,
    setCreditStats,
    edit = false,
    creditData,
}) => {
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [addCreditValues, setAddCreditValues] = useState<IaddCreditValues>({
        customer: '',
        creditType,
        status: 1,
    });

    useEffect(() => {
        getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    }, []);

    useEffect(() => {
        if (edit && creditData) {
            getProducts({ customer: creditData.customer?._id, isAvailableForCredit: true }).then((res) => {
                setProducts(res.data.docs?.filter((elt: IProduct) => elt.itemKind === 'product'));
            });
            setAddCreditValues((prevValues) => ({
                ...prevValues,
                customer: creditData.customer?._id,
                product: creditData?.product?._id,
                productQty: creditData?.product?.quantity,
                adsPercent: creditData?.adsPercent,
                cogsUnitPrice: creditData?.cogsUnitPrice,
                creditAmount: creditData?.creditAmount,
            }));
        }
    }, [edit, creditData]);

    const getProductsByCustomer = (customerId: string) => {
        if (!customerId) {
            setProducts([]);
        } else {
            getProducts({ customer: customerId, isAvailableForCredit: true }).then((res) => {
                setProducts(res.data.docs?.filter((elt: IProduct) => elt.itemKind === 'product'));
            });
        }
    };

    const handleResetAddCreditValue = () => {
        setProducts([]);
        setAddCreditValues((prevValues) => ({
            ...prevValues,
            adsPercent: 0,
            cogsUnitPrice: 0,
            creditAmount: 0,
            product: '',
            productQty: 0,
        }));
    };

    const handleUpdateAddCreditValues = (newValue: number | string | CREDIT_TYPES, field: string) => {
        setAddCreditValues((prevValues) => ({
            ...prevValues,
            [field]: newValue,
        }));
    };

    const getHeaders = (type: CREDIT_TYPES) => {
        if (type === 'Cogs') {
            return (
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Product
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Total Quantity
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Credit amount ($)
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Cogs unit price ($)
                    </th>
                </tr>
            );
        }
        return (
            <tr>
                <th scope="col" className="px-6 py-3">
                    Credit amount ($)
                </th>
                <th scope="col" className="px-6 py-3">
                    Ads Percent (%)
                </th>
            </tr>
        );
    };

    const getColumns = (type: CREDIT_TYPES) => {
        if (type === 'Cogs') {
            return (
                <tr className="border-b bg-white dark:bg-gray-800 dark:border-gray-700">
                    <td className="px-6 py-4">
                        <AutoComplete
                            key="productsList"
                            placeholder="Products"
                            options={products as []}
                            defaultValue={
                                creditData && creditData?.product?._id === addCreditValues.product
                                    ? creditData?.product?.name
                                    : ''
                            }
                            getOptionLabel={(option: any) => option.name}
                            onChange={(option) => {
                                handleUpdateAddCreditValues(option._id, 'product');
                                handleUpdateAddCreditValues(
                                    option?.quantity ||
                                        option?.variants?.reduce(
                                            (acc: any, elt: IProduct) => acc + elt?.quantity || 0,
                                            0,
                                        ),
                                    'productQty',
                                );
                            }}
                        />
                    </td>

                    <td className="px-6 py-4">
                        <InputField
                            value={addCreditValues?.productQty || 0}
                            name="productQty"
                            placeholder="product quantity"
                            disabled
                        />
                    </td>

                    <td className="px-6 py-4 ">
                        <InputField
                            name="creditAmount"
                            placeholder="Credit amount"
                            value={addCreditValues.creditAmount}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleUpdateAddCreditValues(e.target.value, 'creditAmount');
                            }}
                        />
                    </td>

                    <td className="px-6 py-4">
                        <p className="w-fit">
                            <InputField
                                name="cogsUnitPrice"
                                placeholder="Cogs unit price"
                                value={addCreditValues.cogsUnitPrice}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleUpdateAddCreditValues(e.target.value, 'cogsUnitPrice');
                                }}
                            />
                        </p>
                    </td>
                </tr>
            );
        }
        return (
            <tr className="border-b bg-white dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 ">
                    <InputField
                        name="creditAmount"
                        placeholder="Credit amount"
                        value={addCreditValues.creditAmount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleUpdateAddCreditValues(e.target.value, 'creditAmount');
                        }}
                    />
                </td>

                <td className="px-6 py-4 ">
                    <InputField
                        name="adsPercent"
                        placeholder="Ads percent"
                        value={addCreditValues.adsPercent}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleUpdateAddCreditValues(e.target.value, 'adsPercent');
                        }}
                    />
                </td>
            </tr>
        );
    };

    const refetchData = () => {
        getCredits({ creditType: addCreditValues?.creditType })
            .then((res) => setCreditList(res.data.docs))
            .catch(() => {
                toast.error(`Something went wrong, please try again`);
            });
        getCreditStats({ creditType: addCreditValues?.creditType })
            .then((res) => {
                setCreditStats(res.data);
            })
            .catch((err) => {
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {});
            });
    };

    const handleCreateCredit = () => {
        if (!addCreditValues?.creditType) {
            toast.error('Please select a credit type');
            return;
        }

        if (!addCreditValues?.customer) {
            toast.error('Please select a customer');
            return;
        }
        if (addCreditValues?.creditType === 'Cogs') {
            if (!addCreditValues.product) {
                toast.error('Please provide a product.');
                return;
            }
            if (!addCreditValues.creditAmount || Number(addCreditValues.creditAmount) <= 0) {
                toast.error('Please enter a valid credit amount greater than 0.');
                return;
            }
            if (!addCreditValues.cogsUnitPrice || Number(addCreditValues.cogsUnitPrice) <= 0) {
                toast.error('Please enter a valid COGS unit price greater than 0.');
                return;
            }

            if (Number(addCreditValues?.cogsUnitPrice) > Number(addCreditValues?.creditAmount)) {
                toast.error('The unit price of COGS must be less than the credit amount.');
                return;
            }
        }

        if (addCreditValues?.creditType === 'Ads') {
            if (!addCreditValues.creditAmount || Number(addCreditValues.creditAmount) <= 0) {
                toast.error('Please enter a valid credit amount greater than 0.');
                return;
            }
            if (!addCreditValues.adsPercent || Number(addCreditValues.adsPercent) <= 0) {
                toast.error('Please enter a valid Ads percent greater than 0.');
                return;
            }
            if (Number(addCreditValues.adsPercent) > 100) {
                toast.error('Ads percent cannot be greater than 100.');
                return;
            }
        }

        delete addCreditValues.productQty;
        if (addCreditValues.creditType === 'Ads') {
            delete addCreditValues.product;
        }

        if (edit && creditData) {
            const updateData =
                creditData?.creditType === 'Cogs'
                    ? {
                          product: addCreditValues.product,
                          cogsUnitPrice: addCreditValues.cogsUnitPrice,
                          creditAmount: addCreditValues.creditAmount,
                      }
                    : {
                          creditAmount: addCreditValues.creditAmount,
                          adsPercent: addCreditValues.adsPercent,
                      };

            updatecredit(creditData._id, {
                status: creditData.status,
                ...updateData,
            })
                .then(() => {
                    toast.success('Credit updated successfully');
                    setOpenCreateCredit(false);
                })
                .then(() => {
                    refetchData();
                })
                .catch((err) => {
                    toast.error(err.response.data.errors.message);
                    setOpenCreateCredit(false);
                });
        } else {
            addCredit(addCreditValues)
                .then(() => {
                    toast.success('Credit added successfully');
                    setOpenCreateCredit(false);
                })
                .then(() => {
                    refetchData();
                })
                .catch((err) => {
                    toast.error(err.response.data.errors.message);
                    setOpenCreateCredit(false);
                });
        }
    };
    return (
        <>
            <GeneralDrawer
                anchor="right"
                open={openCreateCredit}
                onClose={setOpenCreateCredit}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <div className="flex justify-between items-center pb-2">
                        <div>
                            <p className="text-xl font-bold">Create a credit</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpenCreateCredit(false)}
                            className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                        >
                            <IoMdCloseCircle className="w-8 h-8" color="grey" />
                        </button>
                    </div>

                    <Box
                        sx={{
                            padding: '20px',
                            border: '1px solid',
                            margin: '10px 0px 20px 0px',
                            borderRadius: '10px',
                            borderColor: 'borderColor',
                        }}
                    >
                        <div className="flex flex-col gap-2">
                            <div>
                                <p>Credit type</p>
                            </div>

                            <div className="pb-4">
                                <AutoComplete
                                    disabled={edit}
                                    key="creditType"
                                    defaultValue={creditType}
                                    placeholder="List of sellers"
                                    options={[
                                        { label: 'Cogs', value: 'Cogs' },
                                        { label: 'Ads', value: 'Ads' },
                                    ]}
                                    getOptionLabel={(option: any) => option?.label}
                                    onChange={(option) => {
                                        handleResetAddCreditValue();
                                        handleUpdateAddCreditValues(option.value, 'creditType');
                                    }}
                                />
                            </div>
                            <div>
                                <p>Select the seller</p>
                            </div>
                            <div>
                                <AutoComplete
                                    disabled={edit}
                                    key="sellersList"
                                    placeholder="List of sellers"
                                    options={customers as []}
                                    defaultValue={
                                        creditData
                                            ? `${creditData?.customer.firstName} ${creditData?.customer.lastName}`
                                            : ''
                                    }
                                    getOptionLabel={(option: any) =>
                                        option?.lastName && option?.firstName
                                            ? `${option.firstName} ${option.lastName}`
                                            : option?.email
                                    }
                                    onChange={(option) => {
                                        handleResetAddCreditValue();
                                        handleUpdateAddCreditValues(option._id, 'customer');
                                        getProductsByCustomer(option._id);
                                    }}
                                />
                            </div>
                        </div>
                    </Box>

                    <div className="my-2 shadow-sm sm:rounded-lg border dark:border-gray-700">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                                {getHeaders(addCreditValues.creditType)}
                            </thead>

                            <tbody> {getColumns(addCreditValues.creditType)}</tbody>
                        </table>
                    </div>

                    <div className="flex items-center justify-end gap-2 pt-2">
                        <Button onClick={() => setOpenCreateCredit(false)} color="light">
                            Cancel
                        </Button>
                        <Button onClick={handleCreateCredit} color="blue">
                            Save
                        </Button>
                    </div>
                </div>
            </GeneralDrawer>
        </>
    );
};
export default CreateCredit;
