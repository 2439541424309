/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { Badge, Button, Card } from 'flowbite-react';
import React, { useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import { BsPencilSquare } from 'react-icons/bs';
import { FcCancel } from 'react-icons/fc';
import { IItems, IOrder } from 'services/admin/order';
import { IProduct } from 'utils/Interfaces/products.interface';
import Package from '../../../../assets/package.png';
import UpdateItem from './updateItem';

const AvailableProducts: React.FC<{
    similarProducts: IProduct[];
    data: IOrder;
    setRows?: React.Dispatch<React.SetStateAction<IOrder[]>>;
    currentPage?: number;
    revalidateData?: () => void;
}> = ({ similarProducts, data, setRows, currentPage, revalidateData }) => {
    const [updateList, setUpdateList] = useState(false);

    const isThereAnImage = (imageArr: string[]) => {
        const isThere = imageArr?.filter(
            (prodImg) =>
                prodImg.endsWith('.jpg') ||
                prodImg.endsWith('.png') ||
                prodImg.endsWith('.jpeg') ||
                prodImg.endsWith('.webp') ||
                prodImg.endsWith('.svg%2Bxml'),
        );
        if (isThere && isThere.length > 0) {
            return (
                <Zoom>
                    <img
                        src={isThere[0]}
                        alt="quotation"
                        style={{
                            objectFit: 'contain',
                            borderRadius: 8,
                            overflow: 'hidden',
                            width: 100,
                            height: 70,
                        }}
                    />
                </Zoom>
            );
        }
        return (
            <Zoom>
                <img
                    src={Package}
                    alt="quotation"
                    style={{
                        objectFit: 'contain',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: 100,
                        height: 70,
                    }}
                />
            </Zoom>
        );
    };

    return (
        <>
            <div className="  p-2 " key="productDetailsHolder">
                <div className="flex justify-end mb-2">
                    <Button
                        onClick={() => {
                            setUpdateList(!updateList);
                        }}
                    >
                        <div className="flex justify-center items-center gap-2">
                            {updateList ? (
                                <FcCancel className="h-6 w-6" />
                            ) : (
                                <BsPencilSquare className="h-6 w-6 text-green-700" />
                            )}
                            {updateList ? (
                                <p className="text-base">Cancel</p>
                            ) : (
                                <p className="text-base"> Update list</p>
                            )}
                        </div>
                    </Button>
                </div>
                <Card
                    theme={{
                        root: {
                            children: 'p-2',
                        },
                    }}
                    className="mb-1"
                >
                    <div className="grid grid-cols-5 text-center ">
                        <div className="">
                            <div className="sr-only">Image</div>
                        </div>
                        <div>
                            <p>Product Name</p>
                        </div>
                        <div>
                            <p>Product Spec</p>
                        </div>
                        <div>
                            <p>SKU</p>
                        </div>
                        <div>
                            <p>Quantity</p>
                        </div>
                    </div>
                </Card>
                <Card
                    theme={{
                        root: {
                            children: 'p-2',
                        },
                    }}
                    className="mb-1"
                >
                    {!updateList ? (
                        <div>
                            {data?.items?.length > 0 &&
                                data.items.map((item: IItems) => {
                                    const itemProduct = item?.product as IProduct;
                                    let productName = '';
                                    let variant = '';
                                    if (itemProduct.itemKind === 'variant') {
                                        productName = itemProduct?.parentProduct?.name;
                                        variant = itemProduct?.name;
                                    } else {
                                        productName = itemProduct?.name;
                                        variant = '-';
                                    }
                                    return (
                                        <div key={itemProduct._id}>
                                            {(item.quantity || 0) > 0 && (
                                                <Card
                                                    key={itemProduct.globalSKU}
                                                    theme={{
                                                        root: {
                                                            children: 'p-2',
                                                        },
                                                    }}
                                                    className="mb-1 relative"
                                                >
                                                    {itemProduct?.isReseller && (
                                                        <Badge
                                                            color="secondary"
                                                            className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                            style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                        >
                                                            RESELLER
                                                        </Badge>
                                                    )}
                                                    <div className="grid grid-cols-5 text-center items-center">
                                                        <div className="flex justify-center">
                                                            {isThereAnImage(itemProduct.design)}
                                                        </div>
                                                        <div>
                                                            <p>{productName}</p>
                                                        </div>
                                                        <div>
                                                            <p>{variant}</p>
                                                        </div>
                                                        <div>
                                                            <p>{itemProduct.globalSKU || '-'}</p>
                                                        </div>
                                                        <div>
                                                            <p>{item.quantity || '-'}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )}
                                            {item.upsellQuantity && (
                                                <Card
                                                    key={itemProduct.globalSKU}
                                                    theme={{
                                                        root: {
                                                            children: 'p-2',
                                                        },
                                                    }}
                                                    className="mb-1 bg-gray-100 relative"
                                                >
                                                    {itemProduct?.isReseller && (
                                                        <Badge
                                                            color="secondary"
                                                            className="inline-block absolute top-1 left-1 transform -rotate-12 z-10"
                                                            style={{ backgroundColor: '#F2EAFA', color: '#7828C8' }}
                                                        >
                                                            RESELLER
                                                        </Badge>
                                                    )}
                                                    <Badge
                                                        color="success"
                                                        className="inline-block absolute top-2 right-1 transform rotate-12"
                                                    >
                                                        UPSELL
                                                    </Badge>
                                                    <div className="grid grid-cols-5 text-center items-center">
                                                        <div className="flex justify-center">
                                                            {isThereAnImage(itemProduct.design)}
                                                        </div>
                                                        <div>
                                                            <p>{productName}</p>
                                                        </div>
                                                        <div>
                                                            <p>{variant}</p>
                                                        </div>
                                                        <div>
                                                            <p>{itemProduct.globalSKU || '-'}</p>
                                                        </div>
                                                        <div>
                                                            <p>{item.upsellQuantity || '-'}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                    ) : (
                        <>
                            <UpdateItem
                                similarProducts={similarProducts as IProduct[]}
                                data={data}
                                setRows={setRows}
                                currentPage={currentPage}
                                setUpdateList={setUpdateList}
                                revalidateData={revalidateData}
                            />
                        </>
                    )}
                </Card>
            </div>
        </>
    );
};

export default AvailableProducts;
