/* eslint-disable no-underscore-dangle */
import React from 'react';

import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import DataTable from 'components/tables/tables';
import { ICustomer } from 'pages/customers/customer.types';
import ChatIcon from '@mui/icons-material/Chat';
import { TableColDef } from 'components/tables/tables.types';
import { Button } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';

const CustomersList: React.FC<{
    customers: ICustomer[];
    open: boolean;
    setOpen: (el: boolean) => void;
}> = ({ customers, open, setOpen }) => {
    const navigate = useNavigate();

    const columns: TableColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: ICustomer) => (
                <p>{`${
                    params?.firstName && params.lastName ? `${params?.firstName} ${params.lastName}` : params.email
                } `}</p>
            ),
        },

        {
            field: 'action',
            headerName: 'Action',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: ICustomer) => (
                <>
                    <Button
                        onClick={() => {
                            const user = getCurrentUser();
                            let navigation = 'admin';
                            if (user.role === 'accountManager') {
                                navigation = 'accountManager';
                            }
                            navigate(`/${navigation}/support-chat`, {
                                state: { customer: params },
                            });
                        }}
                        title="Support chat"
                        color="light"
                        size="s"
                    >
                        <ChatIcon className="h-5 w-5 cursor-pointer" />
                    </Button>
                </>
            ),
        },
    ];
    return (
        <>
            <GeneralDrawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <h4 className="py-2">List of customers</h4>
                    <DataTable
                        rows={customers}
                        columns={columns}
                        setRows={() => {}}
                        pageState={{
                            isLoading: false,
                            total: 0,
                            pageSize: 0,
                            page: 0,
                            count: 0,
                        }}
                        setPageState={() => {}}
                    />
                </div>
            </GeneralDrawer>
        </>
    );
};
export default CustomersList;
