/* eslint-disable react/style-prop-object */
import { Badge } from 'flowbite-react';
import React from 'react';
import { StatusBadge } from 'utils/helpers/statusBadge';
import { IStausInterface } from '../../../components/tables/tables.types';

export const timingDisplay: { [keys: string]: string } = {
    Daily: 'Daily',
    TwiceAWeek: 'Twice a week',
    Weekly: 'Weekly',
};

export const statusDisplay: { [keys: string]: IStausInterface } = {
    '2': {
        statusIcon: <StatusBadge name="Paid" style="greenTag" />,
        statusLabel: 'Paid',
        statusClass: 'greenTag',
    },
    '1': {
        statusIcon: <StatusBadge name="Pending" style="blueTag" />,
        statusLabel: 'Pending',
        statusClass: 'blueTag',
    },

    '3': {
        statusIcon: <StatusBadge name="Postponed" style="redTag" />,
        statusLabel: 'Postponed',
        statusClass: 'redTag',
    },
};

export const paymentStatus: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="success" size="sm">
                Paid
            </Badge>
        ),
        statusLabel: 'Paid',
    },
    2: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Unpaid
            </Badge>
        ),
        statusLabel: 'Unpaid',
    },
};
