/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { getpayments, IPayments, paymentTypes, paymetSources } from 'services/admin/payments';
import { Select, Tabs, TabsRef } from 'flowbite-react';
import Datepicker from 'react-tailwindcss-datepicker';
import AutoComplete from 'components/autocomplete/autoComplete';

import toast from 'react-hot-toast';

import moment from 'moment-timezone';
import { ICustomer } from 'pages/customers/customer.types';
import { getCustomers } from 'services/admin/customer';
import DataTable from '../../components/tables/tables';
import { columns, statusDisplay } from './paymentsTableActions/paymentsElements';

interface Ipagination {
    page: number;
    limit: number;
}

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

export interface IdateRange {
    startDate: string;
    endDate: string;
}
interface Ifilter {
    status?: number;
    createdAt?: object;
    type?: string;
    dateRange?: IdateRange | null;
    source?: string;
    customer?: string;
}

const Payments = () => {
    UseDocumentTitle('Payments');
    const tabsRef = useRef<TabsRef | null>(null);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [paymentsList, setPaymentsList] = useState<Array<IPayments>>([]);

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<Ifilter>({
        source: 'wallet',
    });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);

    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setFilter({ ...filter, ...{ dateRange: newRange } });
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    };

    const handleCustomerFilterChange = (newFilter: Ifilter) => {
        if (newFilter.customer === undefined || newFilter.customer === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.customer;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    };

    useEffect(() => {
        getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    }, []);

    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        setIsLoading(true);
        const queryOptions = { ...pagination, ...filter };
        getpayments(queryOptions)
            .then((res) => {
                toast.dismiss(feedbackToast);
                const data = res.data.response;
                setPaymentsList(data.docs);
                setMeta({
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage,
                    limit: data.limit,
                    nextPage: data.nextPage,
                    page: data.page,
                    pagingCounter: data.pagingCounter,
                    prevPage: data.prevPage,
                    totalDocs: data.totalDocs,
                    totalPages: data.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            });
    }, [filter, pagination]);

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const getTabValue = (tab: number) => {
        switch (tab) {
            case 0:
                return 'wallet';
            case 1:
                return 'bank';
            case 2:
                return 'walletBank';
            default:
                return 'wallet';
        }
    };

    return (
        <>
            <div className="bg-white dark:bg-gray-900 p-2">
                <section className="dark:bg-gray-900  items-center my-1">
                    <div className="flex  my-4">
                        <div className="flex ">
                            <span className="font-medium text-2xl">Payments</span>
                        </div>
                    </div>

                    <div className=" relative flex w-full items-center justify-between">
                        <div className="flex items-center gap-4">
                            <div>
                                <div id="select">
                                    <Select
                                        id="operationType"
                                        required
                                        value={filter.status?.toString()}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                setFilter((prevFilter) => ({
                                                    ...prevFilter,
                                                    status: Number(e.target.value),
                                                }));
                                            } else if (!e.target.value && filter.status) {
                                                const filterDraft = { ...filter };
                                                delete filterDraft.status;
                                                setFilter({ ...filterDraft });
                                            }
                                            setPagination({ ...pagination, page: 1 });
                                            setMeta({ ...meta, page: -1 });
                                        }}
                                    >
                                        <option value="">All Status</option>
                                        <option value="1">Pending</option>
                                        <option value="2">Confirmed</option>
                                        <option value="3">Rejected</option>
                                    </Select>
                                </div>
                            </div>

                            <div className="w-52">
                                <div id="typePayment">
                                    <Select
                                        id="typePayment"
                                        required
                                        value={filter.type?.toString()}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                setFilter((prevFilter) => ({ ...prevFilter, type: e.target.value }));
                                            } else if (!e.target.value && filter.type) {
                                                const filterDraft = { ...filter };
                                                delete filterDraft.type;
                                                setFilter({ ...filterDraft });
                                            }
                                            setPagination({ ...pagination, page: 1 });
                                            setMeta({ ...meta, page: -1 });
                                        }}
                                    >
                                        <option value="">All Types</option>
                                        {paymentTypes.map((type) => {
                                            return <option value={type.value}>{type.label}</option>;
                                        })}
                                    </Select>
                                </div>
                            </div>

                            <div className="w-72">
                                <AutoComplete
                                    key="id"
                                    placeholder="Sellers"
                                    options={customers as []}
                                    getOptionLabel={(option: any) =>
                                        option?.lastName && option?.firstName
                                            ? `${option.firstName} ${option.lastName}`
                                            : option?.email
                                    }
                                    onChange={(option) => {
                                        handleCustomerFilterChange({ customer: option._id as string });
                                    }}
                                />
                            </div>
                        </div>

                        <div>
                            <Datepicker
                                inputId="deliveryDate"
                                value={filter.dateRange || null}
                                containerClassName="relative z-100"
                                useRange
                                onChange={handleRangeFilterChange}
                                showShortcuts
                            />
                        </div>
                    </div>
                </section>

                <Tabs
                    aria-label="Tabs with icons"
                    // eslint-disable-next-line react/style-prop-object
                    theme={{
                        tablist: {
                            tabitem: {
                                base: 'flex items-center text-blue-500 justify-center px-4 h-14 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                styles: {
                                    default: {
                                        base: 'flex items-center text-blue-500 justify-center px-4 h-14  text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                        active: {
                                            on: 'text-main border-main border-b-2',
                                            off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                                        },
                                    },
                                },
                            },
                        },
                    }}
                    ref={tabsRef}
                    onActiveTabChange={(tab) => {
                        if (getTabValue(tab) !== filter.source) {
                            setFilter({ ...filter, ...{ source: getTabValue(tab) } });
                            setPagination({ ...pagination, page: 1 });
                            setMeta({ ...meta, page: -1 });
                        }
                    }}
                >
                    {paymetSources.map((item: any) => {
                        return (
                            <Tabs.Item
                                title={<div className="h-full py-4">{item.label}</div>}
                                key={item.value}
                                style={{ height: '64px !important', padding: '0px 24px' }}
                                className="!py-0 xs:!h-14"
                            >
                                <DataTable
                                    rows={paymentsList}
                                    columns={columns}
                                    icons={statusDisplay}
                                    setRows={setPaymentsList}
                                    pageState={{
                                        isLoading,
                                        total: meta.totalDocs,
                                        pageSize: pagination.limit,
                                        page: pagination.page,
                                        count: meta.totalPages,
                                    }}
                                    setPageState={handlePaginationChange}
                                    filterState={filter}
                                />
                            </Tabs.Item>
                        );
                    })}
                </Tabs>
            </div>
        </>
    );
};

export default Payments;
