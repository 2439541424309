import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';
import moment from 'moment';
import { orderStatus } from 'pages/orders/orders.styles';
import React from 'react';
import { IOrder } from 'services/admin/order';
import { useActiveCallOrders } from 'utils/zustand/ziwo';
import OrderClientInfo from './ordersDetailsGroup/orderClientInfo';
import OrderProductInfo from './ordersDetailsGroup/orderProductInfo';

export const columns: TableColDef[] = [
    {
        field: 'orderRef',
        headerName: 'Order Ref',
        fieldType: 'render',
        renderCell: (params) => <p>{`${params.orderRef ? params.orderRef : '-'}`}</p>,
    },

    {
        field: 'ClientInfo',
        headerName: 'Client Info',
        fieldType: 'render',
        renderCell: (params: IOrder) => (
            <>
                <OrderClientInfo data={params} />
            </>
        ),
    },

    {
        field: 'ProductInfo',
        headerName: 'Product Info',
        fieldType: 'render',
        renderCell: (params: IOrder) => (
            <>
                <OrderProductInfo data={params} />
            </>
        ),
    },

    {
        field: 'country',
        headerName: 'Country',
    },
    {
        field: 'callCenter',
        headerName: 'Call Center',
        fieldType: 'render',
        renderCell: (params) => <p>{params.callCenter ? params.callCenter.name : '-'}</p>,
    },

    {
        field: 'totalPrice',
        headerName: 'Total Price',
        fieldType: 'render',
        renderCell: (params: IOrder) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                {params.currency ? params.currency : ''} {params.totalPrice}
            </div>
        ),
    },

    {
        field: 'callAgent',
        headerName: 'Call agent',
        fieldType: 'render',
        renderCell: (params) => (
            <p
                title={
                    params?.callAgent && (params?.callAgent?.firstName || params?.callAgent?.lastName)
                        ? `${params?.callAgent?.firstName || '...'} ${params?.callAgent?.lastName || '...'}`
                        : 'No call agent assigned yet!'
                }
            >{`${
                params?.callAgent && (params?.callAgent?.firstName || params?.callAgent?.lastName)
                    ? `${params?.callAgent?.firstName || '...'} ${params?.callAgent?.lastName || '...'}`
                    : '-'
            }`}</p>
        ),
    },

    {
        field: 'callCenterAttempt',
        headerName: 'Calls Attempts',
        fieldType: 'render',
        renderCell: (params) => <p>{`${params.callCenterAttempt ? params.callCenterAttempt : '0'}`}</p>,
    },

    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'render',
        renderCell: (params) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{ width: 'fit-content' }}>
                    <div className="flex gap-2 items-center">
                        <Badge color={orderStatus(params.status).color} className="w-max max-w-[150px]">
                            {orderStatus(params.status).title}
                        </Badge>
                    </div>
                </div>
                {params.statusDescription && (
                    <p>
                        {params.statusDescription.substring(0, 20) +
                            (params.statusDescription.substring(20).length > 0 ? '...' : '')}
                    </p>
                )}
            </div>
        ),
    },

    {
        field: 'date',
        headerName: 'DATE',
        // fieldType: 'date',
        fieldType: 'render',
        renderCell: (params) => {
            return (
                <div className="flex-col gap-1 text-xs w-max">
                    <p>
                        <span className="opacity-70">created at: </span>
                        {moment(
                            new Date(params.createdAt).toLocaleString('en-US', {
                                timeZone: 'Asia/Shanghai',
                            }),
                        ).format('MMMM Do YYYY, HH:mm')}
                    </p>
                    <p>
                        <span className="opacity-70">updated at: </span>
                        {moment(
                            new Date(params.updatedAt).toLocaleString('en-US', {
                                timeZone: 'Asia/Shanghai',
                            }),
                        ).format('MMMM Do YYYY, HH:mm')}
                    </p>
                </div>
            );
        },
    },
    {
        field: 'call status',
        headerName: 'Call Status',
        fieldType: 'render',
        renderCell: (params) => {
            const activeCallsOrders = useActiveCallOrders();
            return (
                <div className="flex gap-2 items-center">
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    {activeCallsOrders.has(params._id) ? (
                        <Badge color="failure" className="w-max">
                            <span className="flex items-center gap-1">
                                <div className="w-1 h-1 rounded-full bg-red-700" />
                                In call
                            </span>
                        </Badge>
                    ) : null}
                </div>
            );
        },
    },

    {
        field: 'Action',
        headerName: 'Actions',
        actionType: 'OrdersForCallAdmin',
    },
];

export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="info" size="sm">
                New
            </Badge>
        ),
        statusLabel: 'New',
    },
    2: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Canceled
            </Badge>
        ),
        statusLabel: 'Canceled',
    },
    21: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Wrong number
            </Badge>
        ),
        statusLabel: 'Wrong number',
    },
    3: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Unreachable
            </Badge>
        ),
        statusLabel: 'Unreachable',
    },
    4: {
        statusIcon: (
            <Badge color="success" size="sm">
                Confirmed
            </Badge>
        ),
        statusLabel: 'Confirmed',
    },
    41: {
        statusIcon: (
            <Badge color="info" size="sm">
                Pending answer
            </Badge>
        ),
        statusLabel: 'Pending answer',
    },
    42: {
        statusIcon: (
            <Badge color="success" size="sm">
                Question answered
            </Badge>
        ),
        statusLabel: 'Question answered',
    },
    43: {
        statusIcon: (
            <Badge color="info" size="sm">
                Scheduled call
            </Badge>
        ),
        statusLabel: 'Scheduled call',
    },
    5: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Waiting for shipment
            </Badge>
        ),
        statusLabel: 'Waiting for shipment',
    },
    6: {
        statusIcon: (
            <Badge color="info" size="sm">
                Out for shipment
            </Badge>
        ),
        statusLabel: 'Out for shipment',
    },
    7: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Shipped
            </Badge>
        ),
        statusLabel: 'Shipped',
    },
    8: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pick and pack
            </Badge>
        ),
        statusLabel: 'Pick and pack',
    },
    81: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order received by carrier
            </Badge>
        ),
        statusLabel: 'Order received by carrier',
    },
    82: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Arrive to headquarters warehouse
            </Badge>
        ),
        statusLabel: 'Arrive to headquarters warehouse',
    },
    83: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order transferred
            </Badge>
        ),
        statusLabel: 'Order transferred',
    },
    84: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Arrival at Hub
            </Badge>
        ),
        statusLabel: 'Arrival at Hub',
    },
    9: {
        statusIcon: (
            <Badge color="info" size="sm">
                Out for delivery
            </Badge>
        ),
        statusLabel: 'Out for delivery',
    },
    10: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Delivery attempted
            </Badge>
        ),
        statusLabel: 'Delivery attempted',
    },
    101: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order returned to Hub
            </Badge>
        ),
        statusLabel: 'Order returned to Hub',
    },
    102: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order returned to Warehouse
            </Badge>
        ),
        statusLabel: 'Order returned to Warehouse',
    },
    103: {
        statusIcon: (
            <Badge color="warning" size="sm">
                New delivery request
            </Badge>
        ),
        statusLabel: 'New delivery request',
    },
    104: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Wait to WMS
            </Badge>
        ),
        statusLabel: 'Wait to WMS',
    },
    105: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Delivery scheduled
            </Badge>
        ),
        statusLabel: 'Wait to WMS',
    },
    11: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Delivery failed
            </Badge>
        ),
        statusLabel: 'Delivery failed',
    },
    111: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Return to origin - Out
            </Badge>
        ),
        statusLabel: 'Return to origin - Out',
    },
    112: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Return to origin - In
            </Badge>
        ),
        statusLabel: 'Return to origin - In',
    },
    113: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Transport management system to Warehouse
            </Badge>
        ),
        statusLabel: 'Transport management system to Warehouse',
    },
    114: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Back to stock
            </Badge>
        ),
        statusLabel: 'Back to stock',
    },
    12: {
        statusIcon: (
            <Badge color="success" size="sm">
                Delivered
            </Badge>
        ),
        statusLabel: 'Delivered',
    },
    13: {
        statusIcon: (
            <Badge color="pink" size="sm">
                Expired
            </Badge>
        ),
        statusLabel: 'Expired',
    },
    14: {
        statusIcon: (
            <Badge color="red" size="sm">
                Invalid
            </Badge>
        ),
        statusLabel: 'Expired',
    },
    15: {
        statusIcon: (
            <Badge color="red" size="sm">
                Pending payment
            </Badge>
        ),
        statusLabel: 'Expired',
    },
    99: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Check required
            </Badge>
        ),
        statusLabel: 'Check required',
    },
    100: {
        statusIcon: (
            <Badge color="red" size="sm">
                Hold - No stock available
            </Badge>
        ),
        statusLabel: 'Hold - No stock available',
    },
    115: {
        statusIcon: (
            <Badge color="red" size="sm">
                Duplicated
            </Badge>
        ),
        statusLabel: 'Duplicated',
    },
};

export interface IOrderStatus {
    id: number;
    label: string;
    type: string;
}

export const orderStatusListForAdminCC: IOrderStatus[] = [
    { id: 1, label: 'New', type: 'Processing' },
    { id: 2, label: 'Canceled', type: 'Closed' },
    { id: 21, label: 'Wrong number', type: 'Closed' },
    { id: 3, label: 'Unreachable', type: 'Processing' },
    { id: 43, label: 'Scheduled call', type: 'Processing' },
    { id: 41, label: 'Pending answer', type: 'Processing' },
    { id: 42, label: 'Question answered', type: 'Processing' },
    { id: 13, label: 'Expired', type: 'Closed' },
    { id: 8, label: 'Pick and pack', type: 'Shipped' },
    { id: 81, label: 'Order received by carrier', type: 'Shipped' },
    { id: 82, label: 'Arrive to headquarters warehouse', type: 'Shipped' },
    { id: 83, label: 'Order transferred', type: 'Shipped' },
    { id: 84, label: 'Arrival at Hub', type: 'Shipped' },
    { id: 9, label: 'Out for delivery', type: 'Shipped' },
    { id: 10, label: 'Delivery attempted', type: 'Shipped' },
    { id: 105, label: 'Delivery scheduled', type: 'Shipped' },
    { id: 103, label: 'New delivery request', type: 'Shipped' },
    { id: 11, label: 'Delivery failed', type: 'Shipped' },
    { id: 101, label: 'Order returned to Hub', type: 'Shipped' },
    { id: 102, label: 'Order returned to Warehouse', type: 'Shipped' },
    { id: 104, label: 'Wait to WMS', type: 'Shipped' },
    { id: 111, label: 'Return to origin - Out', type: 'Shipped' },
    { id: 112, label: 'Return to origin - In', type: 'Shipped' },
    { id: 113, label: 'Transport management system to Warehouse', type: 'Shipped' },
    { id: 114, label: 'Back to stock', type: 'Shipped' },
    { id: 12, label: 'Delivered', type: 'Shipped' },
    { id: 115, label: 'Duplicated', type: 'Processing' },
];

export const ORDER_PHASES = ['Processing', 'Shipped', 'Closed'];
