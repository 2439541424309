/* eslint-disable react/jsx-fragments */
import CustomError from 'components/customError/CustomError';
import { Label, Modal, Select, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { countries } from 'utils/countryList';
import { AddPricingConfig } from 'utils/validations/pricingConfiguration';
import CustomBtn from 'components/customButton/customBtn';
import { ICodPricingItem, updateLogistioBulkFees } from 'services/admin/logistioBulkFees';
import { AddConfigLogstioBulkFee } from '../pricingConfiguration.types';

const AddConfiguration: React.FC<AddConfigLogstioBulkFee> = ({
    open,
    configPricing,
    setOpen,
    configId,
    refetchGetFees,
}) => {
    const [newPricingLoader, setNewPricingLoader] = useState(false);

    const AddCountryConfig = (values: ICodPricingItem) => {
        setNewPricingLoader(true);
        const loadingToast = toast.loading('Loading...');
        let newValues;
        const newPricing = configPricing;
        if (configPricing.codWithCC) {
            newValues = [...configPricing.codWithCC];
            newValues.push(values);
        } else {
            newValues = [values];
        }
        newPricing.codWithCC = newValues;
        updateLogistioBulkFees(configId, { pricing: newPricing })
            .then((res) => {
                toast.success(res.data.message, { id: loadingToast });
                refetchGetFees();
                setNewPricingLoader(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message, { id: loadingToast });
                setNewPricingLoader(false);
            });
    };
    const checkCountryPricing = (country: string) => {
        return configPricing.codWithCC && configPricing.codWithCC.find((el) => el.country === country);
    };
    return (
        <>
            <Modal dismissible show={open} popup className="z-[1400]" onClose={() => setOpen(false)}>
                <Modal.Header>New Pricing</Modal.Header>
                <Modal.Body className=" !p-0">
                    <Formik
                        initialValues={{
                            country: '',
                            newLeadPrice: '',
                            confirmedLeadPrice: '',
                            deliveredLeadPrice: '',
                            shippedLeadPrice: '',
                            percentFromSellPrice: '',
                            deliveredPriceforCC: '',
                            upDownCross: '',
                        }}
                        onSubmit={(values, onSubmitProps) => {
                            AddCountryConfig({
                                country: values.country,
                                newLeadPrice: Number(values.newLeadPrice),
                                confirmedLeadPrice: Number(values.confirmedLeadPrice),
                                deliveredLeadPrice: Number(values.deliveredLeadPrice),
                                shippedLeadPrice: Number(values.shippedLeadPrice),
                                percentFromSellPrice: Number(values.percentFromSellPrice),
                                deliveredPriceforCC: Number(values.deliveredPriceforCC),
                                upDownCross: Number(values.upDownCross),
                            });
                            setOpen(false);
                            onSubmitProps.setSubmitting(false);
                            onSubmitProps.resetForm();
                        }}
                        validationSchema={AddPricingConfig}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <div className="py-1 px-6 flex flex-col gap-2 overflow-auto max-h-[37rem]">
                                    <div>
                                        <div className="mb-2 block" key="zer">
                                            <Label htmlFor="country" value="Select country" />
                                        </div>
                                        <Select
                                            id="countries"
                                            required
                                            {...formik.getFieldProps('country')}
                                            name="country"
                                            helperText={
                                                <React.Fragment>
                                                    <CustomError name="country" component="div" />
                                                </React.Fragment>
                                            }
                                        >
                                            <option key="chooseCountryKey" value="" selected>
                                                Select Country
                                            </option>
                                            {countries.map((country) => {
                                                if (!checkCountryPricing(country.label)) {
                                                    return (
                                                        <option key={`${country.code}add`} value={country.label}>
                                                            {country.label}
                                                        </option>
                                                    );
                                                }
                                                return (
                                                    <option key={country.code} value={country.label} disabled>
                                                        {country.label}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <div>
                                            <h6 className="text-sm font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                Call Center
                                            </h6>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label
                                                        htmlFor="price1"
                                                        className="dark:text-white"
                                                        color={
                                                            formik.touched.newLeadPrice && formik.errors.newLeadPrice
                                                                ? 'failure'
                                                                : ''
                                                        }
                                                        value="Entered"
                                                    />
                                                </div>
                                                <TextInput
                                                    {...formik.getFieldProps('newLeadPrice')}
                                                    name="newLeadPrice"
                                                    // placeholder="jhondoe@email.com"
                                                    color={
                                                        formik.touched.newLeadPrice && formik.errors.newLeadPrice
                                                            ? 'failure'
                                                            : 'gray'
                                                    }
                                                    type="text"
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="newLeadPrice" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label
                                                        htmlFor="price6"
                                                        className="dark:text-white"
                                                        color={
                                                            formik.errors.deliveredPriceforCC &&
                                                            formik.touched.deliveredPriceforCC
                                                                ? 'failure'
                                                                : ''
                                                        }
                                                        value="Delivered"
                                                    />
                                                </div>
                                                <TextInput
                                                    {...formik.getFieldProps('deliveredPriceforCC')}
                                                    name="deliveredPriceforCC"
                                                    type="text"
                                                    color={
                                                        formik.errors.deliveredPriceforCC &&
                                                        formik.touched.deliveredPriceforCC
                                                            ? 'failure'
                                                            : 'gray'
                                                    }
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="deliveredPriceforCC" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label
                                                        htmlFor="price2"
                                                        className="dark:text-white"
                                                        color={
                                                            formik.touched.confirmedLeadPrice &&
                                                            formik.errors.confirmedLeadPrice
                                                                ? 'failure'
                                                                : ''
                                                        }
                                                        value="Confirmed"
                                                    />
                                                </div>
                                                <TextInput
                                                    {...formik.getFieldProps('confirmedLeadPrice')}
                                                    name="confirmedLeadPrice"
                                                    color={
                                                        formik.touched.confirmedLeadPrice &&
                                                        formik.errors.confirmedLeadPrice
                                                            ? 'failure'
                                                            : 'gray'
                                                    }
                                                    type="text"
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="confirmedLeadPrice" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h6 className="text-sm font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                Shipping
                                            </h6>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label
                                                        htmlFor="price3"
                                                        className="dark:text-white"
                                                        color={
                                                            formik.touched.deliveredLeadPrice &&
                                                            formik.errors.deliveredLeadPrice
                                                                ? 'failure'
                                                                : ''
                                                        }
                                                        value="Shipped & Delivered"
                                                    />
                                                </div>
                                                <TextInput
                                                    {...formik.getFieldProps('deliveredLeadPrice')}
                                                    name="deliveredLeadPrice"
                                                    color={
                                                        formik.touched.deliveredLeadPrice &&
                                                        formik.errors.deliveredLeadPrice
                                                            ? 'failure'
                                                            : 'gray'
                                                    }
                                                    type="text"
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="deliveredLeadPrice" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label
                                                        htmlFor="price4"
                                                        className="dark:text-white"
                                                        color={
                                                            formik.errors.shippedLeadPrice &&
                                                            formik.touched.shippedLeadPrice
                                                                ? 'failure'
                                                                : ''
                                                        }
                                                        value="Shipped & Returned"
                                                    />
                                                </div>
                                                <TextInput
                                                    {...formik.getFieldProps('shippedLeadPrice')}
                                                    name="shippedLeadPrice"
                                                    type="text"
                                                    color={
                                                        formik.errors.shippedLeadPrice &&
                                                        formik.touched.shippedLeadPrice
                                                            ? 'failure'
                                                            : 'gray'
                                                    }
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="shippedLeadPrice" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label
                                                        htmlFor="price5"
                                                        className="dark:text-white"
                                                        color={
                                                            formik.errors.shippedLeadPrice &&
                                                            formik.touched.shippedLeadPrice
                                                                ? 'failure'
                                                                : ''
                                                        }
                                                        value="COD %"
                                                    />
                                                </div>
                                                <TextInput
                                                    {...formik.getFieldProps('percentFromSellPrice')}
                                                    name="percentFromSellPrice"
                                                    type="text"
                                                    color={
                                                        formik.errors.shippedLeadPrice &&
                                                        formik.touched.shippedLeadPrice
                                                            ? 'failure'
                                                            : 'gray'
                                                    }
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="percentFromSellPrice" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-2 block">
                                            <Label
                                                htmlFor="upDownCross"
                                                className="dark:text-white"
                                                color={
                                                    formik.touched.upDownCross && formik.errors.upDownCross
                                                        ? 'failure'
                                                        : ''
                                                }
                                                value="Up/Down/Cross sell"
                                            />
                                        </div>
                                        <TextInput
                                            {...formik.getFieldProps('upDownCross')}
                                            name="upDownCross"
                                            color={
                                                formik.touched.upDownCross && formik.errors.upDownCross
                                                    ? 'failure'
                                                    : 'gray'
                                            }
                                            type="text"
                                            helperText={
                                                <React.Fragment>
                                                    <CustomError name="upDownCross" component="div" />
                                                </React.Fragment>
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                                    <CustomBtn
                                        variant="failure"
                                        outline
                                        pill
                                        type="reset"
                                        onClick={() => {
                                            setOpen(false);
                                            formik.resetForm();
                                        }}
                                    >
                                        Cancel
                                    </CustomBtn>
                                    <CustomBtn
                                        pill
                                        variant="primary"
                                        type="submit"
                                        disabled={
                                            !formik.isValid || !formik.dirty || formik.isSubmitting || newPricingLoader
                                        }
                                        isProcessing={newPricingLoader}
                                    >
                                        Add
                                    </CustomBtn>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddConfiguration;
