/* eslint-disable react/style-prop-object */
import React from 'react';
import { IIconsInterface, IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';
import { IPayments } from 'services/admin/payments';
import { StatusBadge } from 'utils/helpers/statusBadge';
import { currencyConverter } from 'utils/currencyConverter';

export const statusDisplay: { [keys: string]: IStausInterface } = {
    '2': {
        statusIcon: <StatusBadge name="Confirmed" style="greenTag" />,
        statusLabel: 'Confirmed',
        statusClass: 'greenTag',
    },
    '1': {
        statusIcon: <StatusBadge name="Pending" style="blueTag" />,
        statusLabel: 'Pending',
        statusClass: 'blueTag',
    },

    '3': {
        statusIcon: <StatusBadge name="Rejected" style="redTag" />,
        statusLabel: 'Rejected',
        statusClass: 'redTag',
    },
};

export const paymentSource: { [keys: string]: IIconsInterface } = {
    wallet: {
        icon: (
            <Badge color="info" size="sm">
                Wallet Operation
            </Badge>
        ),
        label: 'Wallet Operation',
    },
    bank: {
        icon: (
            <Badge color="gray" size="sm">
                Bank Transaction
            </Badge>
        ),
        label: 'Bank Transaction',
    },
    walletBank: {
        icon: (
            <Badge color="purple" size="sm">
                Wallet Operation and Bank Transaction
            </Badge>
        ),
        label: 'Wallet Operation and BANK Transaction',
    },
    other: {
        icon: (
            <Badge color="purple" size="sm">
                Other
            </Badge>
        ),
        label: 'Other',
    },
};
export const paymentType: { [keys: string]: IStausInterface } = {
    order: {
        statusIcon: <StatusBadge name="Order Fees" color="pink" />,
        statusLabel: 'Order Fees',
        statusClass: 'pink',
    },
    product: {
        statusIcon: <StatusBadge name="Product Sourcing" color="purple" />,
        statusLabel: 'Product Sourcing',
        statusClass: 'purple',
    },
    restock: {
        statusIcon: <StatusBadge name="Restock Product" style="greenTag" />,
        statusLabel: 'Restock Product',
        statusClass: 'greenTag',
    },
    invoice: {
        statusIcon: <StatusBadge name="Invoice Payment" color="indigo" />,
        statusLabel: 'Invoice Payment',
        statusClass: 'indigo',
    },
    resellerProduct: {
        statusIcon: <StatusBadge name="Reseller Product Fees" color="indigo" />,
        statusLabel: 'Reseller Product Fees',
        statusClass: 'indigo',
    },
    credit: {
        statusIcon: <StatusBadge name=" Credit" color="yellow" />,
        statusLabel: ' Credit',
        statusClass: 'yellow',
    },
    other: {
        statusIcon: <StatusBadge name="Other" color="purple" />,
        statusLabel: 'Other',
        statusClass: 'purple',
    },
};
export const columns: TableColDef[] = [
    {
        field: 'createdAt',
        headerName: 'Date',
        fieldType: 'date',
    },

    {
        field: 'type',
        headerName: 'Type',
        fieldType: 'render',
        renderCell: (params: IPayments) => (
            <>
                <div className="flex items-center">{paymentType[params.type]?.statusIcon}</div>
            </>
        ),
    },

    {
        field: 'customer',
        headerName: 'Seller',
        fieldType: 'combo',
        child: ['firstName', 'lastName'],
    },

    {
        field: 'amount',
        fieldType: 'render',
        headerName: 'Amount',
        renderCell: (params: IPayments) => (
            <>
                <div className="flex items-center">
                    {params?.bankAmount && ['bank', 'walletBank'].includes(params?.source)
                        ? `${currencyConverter(params.bankAmount || params.amount, params?.operationCurrency || 'USD')}`
                        : `${currencyConverter(params.amount, 'USD')}`}
                </div>
            </>
        ),
    },

    {
        field: 'status',
        headerName: 'status',
        fieldType: 'status',
    },

    {
        field: 'Action',
        actionType: 'Payment',
        headerName: '',
    },
];
