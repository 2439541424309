/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
    getOrders,
    IOrder,
    // updateOrders
} from 'services/admin/order';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
// import { FaUserCheck } from 'react-icons/fa';
// import CustomBtn from 'components/customButton/customBtn';
import {
    // Modal,
    Select,
    TextInput,
} from 'flowbite-react';
import { ICustomer } from 'pages/customers/customer.types';
import AutoComplete from 'components/autocomplete/autoComplete';
// import { Iadmin } from 'pages/adminsList/admin.types';
// import { getAllCallAgents } from 'services/admin/callAgent';
// import { BsClipboardCheckFill } from 'react-icons/bs';
import Datepicker from 'react-tailwindcss-datepicker';
import { getCustomers } from 'services/admin/customer';
import { countries } from 'utils/countryList';
import toast from 'react-hot-toast';
import { IStore, getStores } from 'services/admin/store';
import DataTable from 'components/tables/tables';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { exportOrdersExcel } from 'utils/helpers/order';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { getAllCallCenters } from 'services/admin/callCenter';
import { ICallCenter } from 'pages/callCenterList/callCenter.types';
import CustomSelect from 'components/customSelect/customSelect';
import { columns, statusDisplay } from './tableElements';
import { IdateRange, callCenterOption, orderStatusList, orderTypes } from './orders.types';

interface Ipagination {
    page: number;
    limit: number;
    status: number; // used for tabulation
}

interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

interface Ifilter {
    name?: string;
    zipCode?: number;
    customer?: any;
    country?: string;
    fulfillement?: string;
    goodsCost?: number;
    ShippingCost?: number;
    total?: number;
    status?: (number | string)[];
    trackingCode?: string;
    lgstTrackingCode?: string;
    orderRef?: string;
    dateRange?: { startDate?: string | number; endDate?: string | number };
    store?: any;
    cod?: boolean;
    prePaid?: boolean;
    withCC?: boolean;
    callCenter?: string[];
}

const OrdersListing = () => {
    UseDocumentTitle('Orders');
    // Data states
    const [ordersList, setOrdersList] = useState<IOrder[]>([]);

    // Pagination States
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
        status: 0,
    });
    const [filter, setFilter] = useState<Ifilter>({ status: [] });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [isLoading, setIsLoading] = useState(false);

    // Date Picker states
    const [rangevalue, setRangeValue] = useState<IdateRange | null>(null);
    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            startD.set('hour', 0);
            startD.set('minute', 0);
            startD.set('second', 0);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setRangeValue(newValue);
        setFilter((oldFilter) => ({ ...oldFilter, dateRange: newRange }));
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    };
    // const [openAssignModal, setOpenAssignModal] = useState(false);
    // const [callAgents, setCallAgents] = useState<Iadmin[]>([]);
    const [ordersSelected, setOrdersSelected] = useState<IOrder[]>([]);
    // fetch orders on component mount & pagination or filter change
    // const debouncedPagination = useDebouncedValue(pagination, 500);
    // const debouncedFilter = useDebouncedValue(filter, 500);

    useEffect(() => {
        /**
         * apply pagination & filter if filter options exists
         * else ignore filter options and only use pagination
         */
        // const queryOptions = Object.values(debouncedFilter)[0]
        //     ? { ...debouncedPagination, ...debouncedFilter }
        //     : { ...debouncedPagination };

        const queryOptions = Object.values(filter).length
            ? {
                  ...pagination,
                  ...{ ...filter, status: filter?.status?.filter((elt) => elt !== 'select_all') as number[] },
              }
            : { ...pagination };

        /**
         * check if we are requesting an active page (already fetched and is displayed)
         */
        const fetchedAndActive = meta.page === pagination.page;
        /**
         * ignore request if page is available
         */
        if (!fetchedAndActive) {
            setIsLoading(true);
            getOrders(queryOptions)
                .then(({ data }) => {
                    setOrdersList(data.docs);
                    setMeta({
                        hasNextPage: data.hasNextPage,
                        hasPrevPage: data.hasPrevPage,
                        limit: data.limit,
                        nextPage: data.nextPage,
                        page: data.page,
                        pagingCounter: data.pagingCounter,
                        prevPage: data.prevPage,
                        totalDocs: data.totalDocs,
                        totalPages: data.totalPages,
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(err.response.data.errors.message);
                });
        }
    }, [filter, pagination]);

    // const handleFilterChange = (newFilter: React.SetStateAction<Ifilter>) => {
    //     setFilter({ ...filter, ...newFilter });
    //     setMeta({ ...meta, page: -1 });
    // };

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };
    // const filterOrdersWithCC = (orders: IOrder[]) => {
    //     return orders.filter((order) => order.withCC === true && order.cod === true);
    // };
    // const [callAgent, setCallAgent] = useState<string | null>(null);
    // useEffect(() => {
    //     if (callAgent) getAllCallAgents({ limit: 1000, email: callAgent }).then((res) => setCallAgents(res.data.docs));
    //     else getAllCallAgents({ limit: 1000 }).then((res) => setCallAgents(res.data.docs));
    // }, [callAgent]);

    // const [callAgentAssignLoader, setCallAgentAssignLoader] = useState(false);
    // function assignOrderCallAgent() {
    //     const orders = filterOrdersWithCC(ordersSelected).map((order) => order?._id);
    //     setCallAgentAssignLoader(true);
    //
    //     updateOrders({ orders, callAgent })
    //         .then(() => {
    //             toast.success('Orders updated successfully');
    //             setOpenAssignModal(false);
    //             setCallAgentAssignLoader(false);
    //             setIsLoading(true);
    //             const queryOptions = Object.values(filter)[0] ? { ...pagination, ...filter } : { ...pagination };
    //             getOrders(queryOptions)
    //                 .then(({ data }) => {
    //                     setOrdersList(data.docs);
    //                     setMeta({
    //                         hasNextPage: data.hasNextPage,
    //                         hasPrevPage: data.hasPrevPage,
    //                         limit: data.limit,
    //                         nextPage: data.nextPage,
    //                         page: data.page,
    //                         pagingCounter: data.pagingCounter,
    //                         prevPage: data.prevPage,
    //                         totalDocs: data.totalDocs,
    //                         totalPages: data.totalPages,
    //                     });
    //                     setIsLoading(false);
    //                 })
    //                 .catch((err) => {
    //                     setIsLoading(false);
    //                     toast.error(err.response.data.errors.message);
    //                 });
    //         })
    //         .catch((err) => {
    //             toast.error(err?.response?.data?.errors?.message);
    //             setOpenAssignModal(false);
    //             setCallAgentAssignLoader(false);
    //         });
    // }

    const [costumers, setCustomers] = useState<Array<ICustomer>>([]);
    const [stores, setStores] = useState<Array<IStore>>([]);
    const [customer] = useState('');
    const [callCenters, setCallCenters] = useState<Array<ICallCenter>>([]);
    useEffect(() => {
        if (customer) getCustomers({ limit: 1000, _id: customer }).then((res) => setCustomers(res.data.docs));
        else getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
        getStores({ limit: 1000 }).then((res) => setStores(res.data.docs));
    }, [customer]);

    useEffect(() => {
        getAllCallCenters({ limit: 1000 }).then((res) => {
            setCallCenters(res.data.docs);
        });
    }, []);
    const handleCustomerFilterChange = (newFilter: Ifilter) => {
        if (newFilter.customer === undefined || newFilter.customer === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.customer;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };
    const handleStoreFilterChange = (newFilter: Ifilter) => {
        if (newFilter.store === undefined || newFilter.store === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.store;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };
    const handleStatusFilterChange = (e: any) => {
        if (e?.target.value !== '') {
            if (filter?.status?.includes(Number(e.target.value) || e.target.value)) {
                setFilter({
                    ...filter,
                    status:
                        e.target.value === 'select_all'
                            ? []
                            : filter?.status?.filter(
                                  (elt: any) => elt !== Number(e.target.value) && elt !== 'select_all',
                              ),
                });
            } else {
                setFilter({
                    ...filter,
                    status:
                        e.target.value === 'select_all'
                            ? ['select_all', ...(orderStatusList as any[])?.map((elt: any) => Number(elt?.id))]
                            : [
                                  ...(filter?.status?.filter((elt: any) => elt !== 'select_all') || []),
                                  Number(e.target.value),
                              ],
                });
            }
        } else {
            setFilter({ ...filter, status: [] });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handleCountryFilterChange = (newFilter: Ifilter) => {
        if (newFilter.country === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.country;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handleTrackingCodeFilterChange = (newFilter: Ifilter) => {
        if (newFilter.lgstTrackingCode === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.lgstTrackingCode;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handleOrderRefFilterChange = (newFilter: Ifilter) => {
        if (newFilter.orderRef === '') {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.orderRef;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handleCallCenterChange = (newFilter: Ifilter) => {
        if (newFilter.callCenter?.every((cc) => cc === '')) {
            setFilter((oldFilter) => {
                const temp = oldFilter;
                delete temp.callCenter;
                return { ...temp };
            });
        } else {
            setFilter({ ...filter, ...newFilter });
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    // const disableAssign = filterOrdersWithCC(ordersSelected).length === 0;
    const [orderType, setOrderType] = useState<string>('');
    const [optionSelectedCC, setOptionSelectedCC] = useState<string>('');
    function handleOrderTypeChange(value: string) {
        switch (value) {
            case 'cod':
                setFilter((oldFilter) => {
                    const temp = oldFilter;
                    delete temp.cod;
                    delete temp.prePaid;
                    return { ...temp, cod: true, prePaid: false };
                });
                setOrderType(value);
                break;
            case 'prePaid':
                setFilter((oldFilter) => {
                    const temp = oldFilter;
                    delete temp.cod;
                    delete temp.prePaid;
                    return { ...temp, prePaid: true, cod: false };
                });
                setOrderType(value);
                break;
            default:
                setFilter((oldFilter) => {
                    const temp = oldFilter;
                    delete temp.cod;
                    delete temp.prePaid;
                    return { ...temp };
                });
                setOrderType(value);

                break;
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    }
    function handleCodOptionChange(value: string) {
        switch (value) {
            case 'withCC':
                setFilter((oldFilter) => {
                    const temp = { ...oldFilter };
                    return { ...temp, withCC: true };
                });
                setOptionSelectedCC('withCC');
                break;
            case 'noCC':
                setFilter((oldFilter) => {
                    const temp = { ...oldFilter };
                    return { ...temp, withCC: false };
                });
                setOptionSelectedCC('noCC');
                break;
            default:
                setFilter((oldFilter) => {
                    const temp = { ...oldFilter };
                    delete temp.withCC;
                    setOptionSelectedCC(value);

                    return { ...temp };
                });
                break;
        }
        setMeta({ ...meta, page: -1 });
        setPagination((prev) => ({ ...prev, page: 1 }));
    }

    return (
        <main>
            {/* <Modal */}
            {/*     dismissible */}
            {/*     show={openAssignModal} */}
            {/*     popup */}
            {/*     onClose={() => { */}
            {/*         setOpenAssignModal(false); */}
            {/*         setCallAgent(null); */}
            {/*     }} */}
            {/*     size="xl" */}
            {/* > */}
            {/*     <Modal.Header>Assign Orders to a call agent</Modal.Header> */}
            {/*     <Modal.Body className="!p-2 min-h-[17rem]"> */}
            {/*         {callAgents && ( */}
            {/*             <div className="items-center p-2 "> */}
            {/*                 <div className="p-2 "> */}
            {/*                     <AutoComplete */}
            {/*                         key="cAgent" */}
            {/*                         placeholder="Call Agent" */}
            {/*                         options={callAgents as []} */}
            {/*                         getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`} */}
            {/*                         // eslint-disable-next-line no-underscore-dangle */}
            {/*                         onChange={(option) => setCallAgent(option._id as string)} */}
            {/*                     /> */}
            {/*                 </div> */}
            {/**/}
            {/*                 <div className="w-full p-2 "> */}
            {/*                     <CustomBtn */}
            {/*                         variant="primary" */}
            {/*                         disabled={ */}
            {/*                             disableAssign || */}
            {/*                             callAgentAssignLoader || */}
            {/*                             filter.withCC === false || */}
            {/*                             filter.prePaid */}
            {/*                         } */}
            {/*                         onClick={() => { */}
            {/*                             assignOrderCallAgent(); */}
            {/*                         }} */}
            {/*                         isProcessing={callAgentAssignLoader} */}
            {/*                         className="w-full" */}
            {/*                     > */}
            {/*                         <BsClipboardCheckFill className="mr-2 h-5 w-5" /> */}
            {/*                         Assign */}
            {/*                     </CustomBtn> */}
            {/*                 </div> */}
            {/*                 <div className="flex p-2 "> */}
            {/*                     {disableAssign && ordersSelected.length > 0 && ( */}
            {/*                         <p className="text-red-500"> Some selected orders have disabled call center</p> */}
            {/*                     )} */}
            {/*                     {ordersSelected.length === 0 && <p className="text-red-500">* No Order selected</p>} */}
            {/*                 </div> */}
            {/*             </div> */}
            {/*         )} */}
            {/*     </Modal.Body> */}
            {/* </Modal> */}
            {/* <Typography sx={layoutTitle}> All Orders</Typography> */}
            <section className="flex items-center my-2">
                <div className=" relative w-full">
                    <div className="flex items-center justify-between relative shadow-md sm:rounded-lg">
                        <div className="flex gap-1 ml-1">
                            <Select
                                sizing="md"
                                id="countrySelect"
                                required
                                value={filter.country?.toString()}
                                onChange={(e) => {
                                    handleCountryFilterChange({ country: e.target.value });
                                }}
                            >
                                <option value="">All Countries</option>
                                {countries.map((country) => {
                                    return (
                                        <option key={uuidv4()} value={country.label}>
                                            {country.label}
                                        </option>
                                    );
                                })}
                            </Select>

                            <TextInput
                                placeholder="Tracking Number..."
                                id="base"
                                type="text"
                                sizing="md"
                                style={{ maxWidth: 150 }}
                                onChange={(e) => {
                                    handleTrackingCodeFilterChange({ lgstTrackingCode: e.target.value });
                                }}
                            />

                            <TextInput
                                placeholder="Order ref"
                                id="base"
                                type="text"
                                sizing="md"
                                style={{ maxWidth: 150 }}
                                onChange={(e) => {
                                    handleOrderRefFilterChange({ orderRef: e.target.value });
                                }}
                            />

                            <AutoComplete
                                key="customer"
                                styles={{ height: '42px', maxWidth: 250 }}
                                placeholder="Sellers"
                                options={costumers as []}
                                getOptionLabel={(option: any) =>
                                    option?.lastName && option?.firstName
                                        ? `${option.firstName} ${option.lastName}`
                                        : option?.email
                                }
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    // setCustomer(option.email as string);
                                    handleCustomerFilterChange({ customer: option._id });
                                }}
                            />
                            <AutoComplete
                                key="store"
                                placeholder="Store"
                                options={stores as []}
                                styles={{ height: '42px', maxWidth: 250 }}
                                getOptionLabel={(option: any) => `${option.storeName}`}
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    // setCustomer(option.email as string);
                                    handleStoreFilterChange({ store: option._id });
                                }}
                            />
                            {/* <TextInput
                                placeholder="customer..."
                                id="base"
                                type="search"
                                sizing="md"
                                onChange={(e) => {
                                    handleCustomerFilterChange({ customer: e.target.value });
                                }}
                            /> */}
                            <Datepicker
                                inputId="deliveryDate"
                                value={rangevalue}
                                containerClassName="relative z-100"
                                useRange
                                onChange={handleRangeFilterChange}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-between relative shadow-md sm:rounded-lg">
                        <div className="flex gap-1 ml-1">
                            {/* <Select
                                sizing="md"
                                id="statusType"
                                required
                                value={filter.status?.toString()}
                                onChange={(e) => {
                                    handleStatusFilterChange({ status: Number(e.target.value) });
                                }}
                            >
                                <option value="">All Status</option>
                                {orderStatusList.map((stat) => {
                                    return (
                                        <option key={uuidv4()} value={stat.id}>
                                            {stat.label}
                                        </option>
                                    );
                                })}
                            </Select> */}
                            <CustomSelect
                                optionList={orderStatusList}
                                selectedOptions={filter?.status || []}
                                onChange={handleStatusFilterChange}
                                placeholder="Select status"
                            />
                            <Select
                                sizing="md"
                                id="orderType"
                                required
                                value={orderType}
                                onChange={(e) => {
                                    handleOrderTypeChange(e.target.value);
                                }}
                            >
                                <option value="">All Types</option>
                                {orderTypes.map((type) => {
                                    return (
                                        <option key={uuidv4()} value={type.value}>
                                            {type.label}
                                        </option>
                                    );
                                })}
                            </Select>
                            <Select
                                sizing="md"
                                id="callCenterOption"
                                required
                                value={optionSelectedCC}
                                onChange={(e) => {
                                    handleCodOptionChange(e.target.value);
                                }}
                            >
                                <option value="">All</option>
                                {callCenterOption.map((type) => {
                                    return (
                                        <option key={uuidv4()} value={type.value}>
                                            {type.label}
                                        </option>
                                    );
                                })}
                            </Select>
                            <Select
                                sizing="md"
                                id="callCenters"
                                required
                                onChange={(e) => {
                                    handleCallCenterChange({ callCenter: [e.target.value] });
                                }}
                            >
                                <option value="" selected>
                                    Assigned/Unassigned
                                </option>
                                <option value="NotAssigned">Not Assigned</option>
                                {callCenters.map((callCenter) => {
                                    return (
                                        <option key={callCenter._id} value={callCenter._id}>
                                            {callCenter.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="flex flex-col items-center justify-end p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                            <button
                                type="button"
                                className="relative inline-flex items-center px-5  py-2.5  text-sm font-medium text-center disabled:cursor-not-allowed disabled:bg-blue-400 text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                disabled={ordersSelected.length < 1}
                                onClick={() => {
                                    exportOrdersExcel(ordersSelected);
                                }}
                            >
                                <RiFileExcel2Fill className="mr-2 h-5 w-5" />
                                <span className="sr-only">Export Orders</span>
                                Export orders
                                {ordersSelected.length > 0 ? (
                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-600 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                                        {ordersSelected.length}
                                    </div>
                                ) : null}
                            </button>
                            {/* <button */}
                            {/*     className="relative inline-flex items-center px-5 py-2.5 text-sm font-medium text-center disabled:cursor-not-allowed disabled:bg-blue-400 text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" */}
                            {/*     type="button" */}
                            {/*     disabled={ */}
                            {/*         filter.withCC === false || */}
                            {/*         filter.prePaid || */}
                            {/*         filterOrdersWithCC(ordersSelected).length === 0 */}
                            {/*     } */}
                            {/*     onClick={() => { */}
                            {/*         setOpenAssignModal(true); */}
                            {/*     }} */}
                            {/* > */}
                            {/*     <FaUserCheck className="mr-2 h-5 w-5" /> */}
                            {/*     Assign to Call agent */}
                            {/*     {ordersSelected.length > 0 ? ( */}
                            {/*         <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-600 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900"> */}
                            {/*             {filterOrdersWithCC(ordersSelected).length} */}
                            {/*         </div> */}
                            {/*     ) : null} */}
                            {/* </button> */}
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <DataTable
                    rows={ordersList}
                    columns={columns}
                    icons={statusDisplay}
                    setRows={setOrdersList}
                    pageState={{
                        isLoading,
                        total: meta.totalDocs,
                        pageSize: pagination.limit,
                        page: pagination.page,
                        count: meta.totalPages,
                    }}
                    setPageState={handlePaginationChange}
                    selectable
                    setRowsSelected={setOrdersSelected}
                    filterState={filter}
                />
            </div>
        </main>
    );
};

export default OrdersListing;
