/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { countries } from 'utils/countryList';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';
import {
    useActiveCallOrders,
    useCallWebSocketStatus,
    useSetActiveCallOrders,
    useSetCallWebSocketStatus,
    useSetScheduledCallNotification,
    useSetZiwoStatus,
    useZiwo,
    useZiwoOrder,
    useZiwoVisibility,
    ZiwoStoreActions,
} from 'utils/zustand/ziwo';
import { ICall } from '../../services/call/call';

function removeCountryPrefix(number: string): string {
    if (number[0] === '+') {
        return number.substring(4);
    }
    if (number[0] === '0' && number[1] === '0') {
        return number.substring(5);
    }
    return number;
}

export function addCountryPrefix(number: string, country: string): string {
    if (number[0] === '+' || (number[0] === '0' && number[1] === '0')) {
        return number;
    }
    if (number.length > 10 && number[0] !== '+') {
        return `+${number}`;
    }

    const countryInfo = countries.find((c) => c.label.toLowerCase() === country.toLowerCase());
    if (!countryInfo) {
        return number;
    }

    return `${countryInfo.phone}${number}`;
}

type CallEvent = {
    call: {
        callId: string;
        direction: 'inbound' | 'outbound';
        participants: {
            number: string;
        }[];
    } & Record<string, any>;
    type: 'ringing' | 'requesting' | 'trying' | 'early' | 'attach' | 'recovering' | 'active' | 'hangup';
    cause?: ICall['result'];
};

const socketEvents = {
    startCall: 'start-call',
    endCall: 'end-call',
    joinedRoom: 'joined-room',
    unableToJoinRoom: 'unable-to-join-room',
    scheduledCall: 'scheduled-call-notification',
} as const;

type SocketEvent = (typeof socketEvents)[keyof typeof socketEvents];

function subscribeToCallWS({
    userId,
    setActiveCalls,
    setCallWSConnection,
    setScheduledCallNotification,
}: {
    userId: string;
    setActiveCalls: (
        {
            callID,
            callData,
        }: {
            callID: string;
            callData: ICall;
        },
        type: 'add' | 'remove',
    ) => void;
    setCallWSConnection: (value: boolean) => void;
    setScheduledCallNotification: ZiwoStoreActions['setScheduledCallNotification'];
}): SocketEvent[] {
    socket.emit('joinCallRoom', { user: userId });

    socket.on(socketEvents.startCall, (data: ICall) => {
        if (data.orders) {
            setActiveCalls({ callID: data._id, callData: data }, 'add');
        }
    });

    socket.on(socketEvents.endCall, (data: ICall) => {
        if (data.orders) {
            setActiveCalls({ callID: data._id, callData: data }, 'remove');
        }
    });

    socket.on(socketEvents.scheduledCall, (data: { user: string; order: string; phoneNumber: string }) => {
        setScheduledCallNotification({ show: true, data });
    });

    socket.on(socketEvents.joinedRoom, () => {
        setCallWSConnection(true);
    });
    socket.on(socketEvents.unableToJoinRoom, () => {
        setCallWSConnection(false);
    });

    return ['start-call', 'end-call', 'unable-to-join-room', 'joined-room', 'scheduled-call-notification'];
}

export function sendCallSocketEvent(
    data:
        | {
              eventType: 'startCall';
              payload: {
                  user: string;
                  orderId?: string;
                  phoneNumbers: ICall['participants'];
                  /** Unix timestamp */
                  startedAt: number;
                  direction: ICall['direction'];
                  externalCallId: ICall['externalCallId'];
              };
          }
        | {
              eventType: 'endCall';
              payload: { user: string; externalCallId: ICall['externalCallId']; result: ICall['result'] };
          },
) {
    socket.emit(data.eventType, data.payload);
}

export default function useZiwoEvents() {
    const userId = getCurrentUser()._id;
    // const [activeCalls, setActiveCalls] = useState<Record<CallID, ICall>>({});
    const activeCalls = useActiveCallOrders();
    const setActiveCalls = useSetActiveCallOrders();
    const z = useZiwo();
    const setStatus = useSetZiwoStatus();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setOpen] = useZiwoVisibility();
    const callWSActive = useCallWebSocketStatus();
    const setCallWSConnection = useSetCallWebSocketStatus();
    const setScheduledCallNotification = useSetScheduledCallNotification();
    const order = useZiwoOrder();
    useEffect(() => {
        const subscribedEvents: SocketEvent[] = [];

        if (z && z?.session?.token && z.calls.callEvents$) {
            if (!callWSActive) {
                const events = subscribeToCallWS({
                    userId,
                    setActiveCalls,
                    setCallWSConnection,
                    setScheduledCallNotification,
                });
                events.forEach((e) => {
                    subscribedEvents.push(e);
                });
            }

            z.calls.callEvents$.subscribe((e: CallEvent) => {
                if (['ringing', 'early', 'attach', 'recovering'].includes(e.type)) {
                    sendCallSocketEvent({
                        eventType: 'startCall',
                        payload: {
                            direction: e.call.direction,
                            externalCallId: e.call.callId,
                            user: userId,
                            orderId: order,
                            startedAt: new Date().getTime(),
                            phoneNumbers: e.call.participants.map((p) => removeCountryPrefix(p.number)),
                        },
                    });
                    setOpen(true);
                }

                if (e.type === 'hangup') {
                    setOpen(true);
                    sendCallSocketEvent({
                        eventType: 'endCall',
                        payload: {
                            externalCallId: e.call.callId,
                            user: userId,
                            result: e.cause || 'UNKNOWN',
                        },
                    });
                }
            });
        }
        z?.status?.status$.subscribe((status: string) => setStatus(status));
        z?.connectivity.liveSocketStatus$.subscribe((status: boolean) => {
            if (!status) {
                setStatus('Logged Out');
            }
        });

        return () => {
            subscribedEvents.forEach((e) => {
                socket.off(e);
            });
        };
    }, [z, order]);

    return activeCalls;
}
