/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Badge, Dropdown, Label, Select, Textarea, TextInput } from 'flowbite-react';
import React from 'react';

import FormError from 'components/customError/formError';
import { resellerCategories } from 'services/admin/resellerProduct';

import { ResellerProduct } from 'pages/reseller/reseller.types';
import { AiFillCloseCircle } from 'react-icons/ai';
import { badgeTheme } from 'utils/helpers/themeStyle/badge';
import QuotationDetailsForm from './quotationDetails';
import { GeneralInfoProps, IAddResellerProduct } from './types';
import { intialResellerProduct } from '../productsElements';

const GeneralInformations: React.FC<GeneralInfoProps> = (props) => {
    const { resellerProducts, formik, setSelectedProduct, productId, selectedProduct, error } = props;

    const handleSelectProduct = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const product = resellerProducts?.find((el) => el._id === e.target.value);
        if (!product) {
            formik?.setValues(intialResellerProduct);
            formik.setFieldValue(`resellerQuotation.0.quantityLimit`, '');
            formik.setFieldValue(`resellerQuotation.0.recommendedPrice`, '');
            formik.setFieldValue(`resellerQuotation.0.sellingPrice`, '');
            setSelectedProduct();
            return;
        }
        const {
            name,
            adCopies,
            chineseName,
            variant,
            quantity,
            weight,
            width,
            height,
            length,
            declarationAmount,
            quotation,
            openForSale,
            notificationType,
            categories,
            landingPages,
            description,
            updatedAt,
        } = product;
        const data: IAddResellerProduct = {
            name,
            variant: variant || '',
            adCopies: adCopies || [''],
            chineseName,
            quantity,
            weight,
            width,
            height,
            length,
            declarationAmount,
            openForSale,
            canBeOrdered: product.canBeOrdered,
            notificationType: notificationType || [],
            categories,
            landingPages: landingPages?.length ? landingPages : [''],
            description: description || '',
            resellerQuotation: quotation,
            updatedAt,
        };
        formik.setValues(data);
        setSelectedProduct(product);
    };
    return (
        <div className="m-4 bg-white h-full dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden w-full p-4">
            <div className="flex mb-5">
                <span className="font-medium text-xl">General Informations</span>
            </div>

            <div className="mb-4  grid  grid-cols-2 gap-2">
                <div>
                    <div className="mb-2 mt-1 block flex">
                        <Label
                            htmlFor="productName"
                            value="Product Name"
                            className="block text-sm font-medium text-gray-900 dark:text-white"
                        />
                    </div>
                    {productId ? (
                        <TextInput
                            id="productName"
                            type="text"
                            {...formik.getFieldProps('name')}
                            value={`${formik.getFieldProps('name').value} / ${formik.getFieldProps('variant').value}`}
                            placeholder="Insert the name of the product"
                            name="name"
                            disabled
                        />
                    ) : (
                        <Select
                            id="list"
                            onChange={handleSelectProduct}
                            value={selectedProduct?._id || ''}
                            name="product"
                            sizing="m"
                            aria-placeholder="select product"
                        >
                            <option value="">select product</option>
                            {resellerProducts?.map((el: ResellerProduct) => (
                                <option value={el?._id}>{`${el.name} / ${el.variant || ''}`}</option>
                            ))}
                        </Select>
                    )}
                    <span>
                        <FormError name="name" component="div" />
                    </span>
                </div>
                <div>
                    <div className="mb-2 mt-1 block flex">
                        <Label
                            htmlFor="Category"
                            value="Category"
                            className="block text-sm font-medium text-gray-900 dark:text-white"
                        />
                        <span className="text-red-600 "> *</span>
                    </div>
                    <Dropdown
                        label=""
                        renderTrigger={() => (
                            <div className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                <div className="flex flex-wrap gap-2">
                                    {formik.values.categories.length > 0
                                        ? formik.values.categories.map((cat, index) => (
                                              // eslint-disable-next-line react/no-array-index-key
                                              <Badge color="blue" key={index} theme={badgeTheme}>
                                                  <div className="inline-flex gap-2 text-center items-center ">
                                                      <AiFillCloseCircle
                                                          role="button"
                                                          onClick={() => {
                                                              const newCategories = formik.values.categories;
                                                              newCategories.splice(index, 1);
                                                              formik.setFieldValue('categories', newCategories);
                                                          }}
                                                      />
                                                      {cat}
                                                  </div>
                                              </Badge>
                                          ))
                                        : 'Categories'}
                                </div>
                            </div>
                        )}
                        dismissOnClick={false}
                        aria-checked
                        className="h-48 overflow-x-auto "
                        color="gray"
                    >
                        {resellerCategories.map((cat) => (
                            <Dropdown.Item key={cat}>
                                <div className="flex items-center gap-2">
                                    <div className="flex items-center mb-4">
                                        <input
                                            id={`checkbox${cat}`}
                                            type="checkbox"
                                            {...formik.getFieldProps(`categories`)}
                                            checked={formik.values.categories.indexOf(cat) !== -1}
                                            value={cat}
                                            name="categories"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <Label
                                            htmlFor={`checkbox${cat}`}
                                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            {cat}
                                        </Label>
                                    </div>
                                </div>
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                    <span>
                        <FormError name="categories" component="div" />
                    </span>
                </div>
            </div>
            <div className="mb-4">
                <div className="mb-2 mt-1 block flex">
                    <Label
                        htmlFor="Assign a variant"
                        value="Description"
                        className="block text-sm font-medium text-gray-900 dark:text-white"
                    />
                </div>
                <Textarea
                    placeholder="Enter your text..."
                    rows={4}
                    {...formik.getFieldProps(`description`)}
                    name="description"
                />
            </div>
            <QuotationDetailsForm formik={formik} error={error} />
        </div>
    );
};
export default GeneralInformations;
