import React from 'react';
import { FormikProps } from 'formik';
import { IAddResellerProduct } from './types';

const ErrorMsg = ({
    name,
    error,
    formik,
}: {
    name: string;
    error?: boolean;
    formik: FormikProps<IAddResellerProduct>;
}) => {
    const err = formik.getFieldMeta(name).error;
    return err && error ? <span className="text-xs text-red-600 font-semibold">{err}</span> : <></>;
};

export default ErrorMsg;
