/* eslint-disable react/no-array-index-key */
import { Modal, Table } from 'flowbite-react';
import moment from 'moment';
import { IFees, IInvoiceActions } from 'pages/invoices/invoice.types';
import React, { useState } from 'react';
import { currencyConverter } from 'utils/currencyConverter';
import { IStore } from 'services/admin/store';
import { IoEyeOutline } from 'react-icons/io5';
import { groupCreditProductByStore, groupResellerProductsByStore } from 'utils/groupProductInvoicesByStore';
import { statusDisplay } from './invoiceListElements';

const VisualizeInvoice = (props: IInvoiceActions) => {
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const { invoice } = props;
    const [Currency] = useState('USD');

    const groupedResellerProducts = groupResellerProductsByStore(invoice.fees);
    const groupedCreditProducts = groupCreditProductByStore(invoice.fees);
    return (
        <>
            <Modal
                dismissible
                show={openInvoiceModal}
                popup
                size="5xl"
                onClose={() => setOpenInvoiceModal(false)}
                theme={{
                    root: {
                        show: {
                            on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[9999] h-screen',
                        },
                    },
                }}
            >
                <Modal.Header>
                    <div>Prepaid Invoice</div>
                    <div> {statusDisplay[invoice.status]?.statusIcon}</div>
                </Modal.Header>
                <Modal.Body className="!p-0 overflow-y-hidden ">
                    <div className="flex w-full justify-between pb-2 px-2">
                        <div>
                            <span className="font-medium text-gray-900 dark:text-white">Date</span>{' '}
                            {moment(
                                new Date(invoice?.createdAt!).toLocaleString('en-US', {
                                    timeZone: 'Asia/Shanghai',
                                }),
                            ).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                    <div className="pb-2 px-2">
                        <span className="font-medium text-gray-900 dark:text-white">Reference</span> {invoice.reference}
                    </div>
                    <div className="px-2">
                        <span className="font-medium text-gray-900 dark:text-white">From </span>{' '}
                        {moment(
                            new Date(invoice?.from!).toLocaleString('en-US', {
                                timeZone: 'Asia/Shanghai',
                            }),
                        ).format('DD/MM/YYYY HH:mm')}
                        <span className="font-medium text-gray-900 dark:text-white"> to </span>
                        {moment(
                            new Date(invoice?.to!).toLocaleString('en-US', {
                                timeZone: 'Asia/Shanghai',
                            }),
                        ).format('DD/MM/YYYY HH:mm')}
                    </div>
                    <div className="relative  pb-2 overflow-auto h-[380px]">
                        <Table className="table-auto w-full border-separate border-spacing-0">
                            <Table.Head>
                                <Table.HeadCell className="border-r border-gray-300 bg-gray-200 sticky top-0 left-0 z-20">
                                    ITEM
                                </Table.HeadCell>
                                {invoice.fees.map((str: IFees) => (
                                    <Table.HeadCell
                                        className="text-center border-l border-gray-300 border-r border-gray-300 bg-gray-200 sticky top-0 z-10"
                                        colSpan={2}
                                        key={(str.store as IStore)?.storeName}
                                    >
                                        <div className="font-semibold text-xs">{(str.store as IStore)?.storeName}</div>
                                    </Table.HeadCell>
                                ))}
                            </Table.Head>

                            <Table.Head>
                                <Table.HeadCell className="border-r border-gray-300 bg-gray-200 sticky top-[40px] left-0 z-20" />
                                {invoice.fees.map(() => (
                                    <>
                                        <Table.HeadCell className="text-center border-l border-gray-300 bg-gray-200 sticky top-[40px] z-10">
                                            NUMBER
                                        </Table.HeadCell>
                                        <Table.HeadCell className="text-center border-r border-gray-300 bg-gray-200 sticky top-[40px] z-10">
                                            TOTAL
                                        </Table.HeadCell>
                                    </>
                                ))}
                            </Table.Head>

                            <Table.Body className="divide-y divide-gray-300">
                                {/*  Total handle fees */}
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-xs">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white border-r border-gray-300 sticky left-0 z-10 bg-gray-50 dark:bg-gray-800">
                                        Total handle fees
                                    </Table.Cell>
                                    {invoice.fees.map((str: IFees) => (
                                        <>
                                            <Table.Cell className="text-center border-l border-gray-300">
                                                {str?.ordersNumber ?? '--'}
                                            </Table.Cell>
                                            <Table.Cell className="text-center border-r border-gray-300">
                                                {currencyConverter(str?.handleFees, Currency)}
                                            </Table.Cell>
                                        </>
                                    ))}
                                </Table.Row>

                                {/*     Total shipping fees */}
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-xs">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white border-r border-gray-300 sticky left-0 z-10 bg-gray-50 dark:bg-gray-800">
                                        Total shipping fees
                                    </Table.Cell>
                                    {invoice.fees.map((str: IFees) => (
                                        <>
                                            <Table.Cell className="text-center border-l border-gray-300">
                                                {str?.ordersNumber ?? '--'}
                                            </Table.Cell>
                                            <Table.Cell className="text-center border-r border-gray-300">
                                                {currencyConverter(str?.shippedLeads, Currency)}
                                            </Table.Cell>
                                        </>
                                    ))}
                                </Table.Row>

                                {/* Reseller Products Rows */}
                                {Object.keys(groupedResellerProducts).map((productName) => {
                                    const productData = groupedResellerProducts[productName];

                                    return (
                                        <Table.Row
                                            key={`reseller-${productName}`}
                                            className="bg-white dark:border-gray-700 dark:bg-gray-800 text-xs"
                                        >
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white border-r border-gray-300 sticky left-0 z-10 bg-gray-50 dark:bg-gray-800">
                                                Reseller Product ({productName})
                                            </Table.Cell>

                                            {invoice.fees.map((storeFee: IFees) => {
                                                const storeName = (storeFee.store as IStore)?.storeName as string;

                                                const storeData = productData[storeName];

                                                if (!storeData) {
                                                    return (
                                                        <>
                                                            <Table.Cell className="text-center border-l border-gray-300">
                                                                -
                                                            </Table.Cell>
                                                            <Table.Cell className="text-center border-r border-gray-300">
                                                                -
                                                            </Table.Cell>
                                                        </>
                                                    );
                                                }

                                                return (
                                                    <>
                                                        <Table.Cell className="text-center border-l border-gray-300">
                                                            {storeData.totalItems ?? '-'}
                                                        </Table.Cell>
                                                        <Table.Cell className="text-center border-r border-gray-300">
                                                            {currencyConverter(storeData.totalFees || 0, Currency)}
                                                        </Table.Cell>
                                                    </>
                                                );
                                            })}
                                        </Table.Row>
                                    );
                                })}

                                {/* Total reseller products fees */}
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-xs">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white border-r border-gray-300 sticky left-0 z-10 bg-gray-50 dark:bg-gray-800">
                                        Total reseller products fees
                                    </Table.Cell>
                                    {invoice.fees.map((str: IFees) => {
                                        const resellerDetailsCount = Object.values(
                                            str.resellerFeesDetails || {},
                                        ).reduce((acc, elt) => acc + elt.totalItems, 0);
                                        return (
                                            <>
                                                <Table.Cell className="text-center border-l border-gray-300">
                                                    {(str?.resellerProductsNumber || resellerDetailsCount) ?? '--'}
                                                </Table.Cell>
                                                <Table.Cell className="text-center border-r border-gray-300">
                                                    {currencyConverter(str?.resellerFees, Currency)}
                                                </Table.Cell>
                                            </>
                                        );
                                    })}
                                </Table.Row>

                                {/* Ads credits / loans */}
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-xs">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white border-r border-gray-300 sticky left-0 z-10 bg-gray-50 dark:bg-gray-800">
                                        Ads credits / loans
                                    </Table.Cell>
                                    {invoice.fees.map((str: IFees) => (
                                        <>
                                            <Table.Cell className="text-center border-l border-gray-300">
                                                {str.creditAdsPercent && str?.CreditAdsAmount
                                                    ? `${str.creditAdsPercent}%`
                                                    : ''}
                                            </Table.Cell>
                                            <Table.Cell className="text-center border-r border-gray-300">
                                                {currencyConverter(str?.CreditAdsAmount, Currency)}
                                            </Table.Cell>
                                        </>
                                    ))}
                                </Table.Row>

                                {/* Cogs credits / loans */}
                                {Object.keys(groupedCreditProducts).map((productName) => {
                                    const productData = groupedCreditProducts[productName];

                                    return (
                                        <Table.Row
                                            key={`credit-${productName}`}
                                            className="bg-white dark:border-gray-700 dark:bg-gray-800 text-xs"
                                        >
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white border-r border-gray-300 sticky left-0 z-10 bg-gray-50 dark:bg-gray-800">
                                                Cogs credits / loans ({productName})
                                            </Table.Cell>

                                            {invoice.fees.map((storeFee: IFees) => {
                                                const storeName = (storeFee.store as IStore)?.storeName as string;

                                                const storeData = productData[storeName];

                                                if (!storeData) {
                                                    return (
                                                        <>
                                                            <Table.Cell className="text-center border-l border-gray-300" />
                                                            <Table.Cell className="text-center border-r border-gray-300">
                                                                -
                                                            </Table.Cell>
                                                        </>
                                                    );
                                                }

                                                return (
                                                    <>
                                                        <Table.Cell className="text-center border-l border-gray-300">
                                                            {storeData?.nbrOfSales || ''}
                                                        </Table.Cell>

                                                        <Table.Cell className="text-center border-r border-gray-300">
                                                            {currencyConverter(storeData?.total || 0, Currency)}
                                                        </Table.Cell>
                                                    </>
                                                );
                                            })}
                                        </Table.Row>
                                    );
                                })}

                                {/* Total Cogs credits / loans */}
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-xs">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white border-r border-gray-300 sticky left-0 z-10 bg-gray-50 dark:bg-gray-800">
                                        Total Cogs credits / loans
                                    </Table.Cell>
                                    {invoice.fees.map((str: IFees) => (
                                        <>
                                            <Table.Cell className="text-center border-l border-gray-300">
                                                {str.totalCogsSales || ''}
                                            </Table.Cell>
                                            <Table.Cell className="text-center border-r border-gray-300">
                                                {currencyConverter(str?.totalCreaditCogs, Currency)}
                                            </Table.Cell>
                                        </>
                                    ))}
                                </Table.Row>

                                {/* Total fees */}
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-xs">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white border-r border-gray-300 sticky left-0 z-10 bg-gray-50 dark:bg-gray-800">
                                        Total fees
                                    </Table.Cell>
                                    {invoice.fees.map((str: IFees) => (
                                        <>
                                            <Table.Cell className="text-center border-l border-gray-300" />
                                            <Table.Cell className="text-center font-bold border-r border-gray-300">
                                                {currencyConverter(str?.logistioBulkFees || 0, Currency)}
                                            </Table.Cell>
                                        </>
                                    ))}
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal>

            <IoEyeOutline
                className="w-5 h-5 hover:cursor-pointer dark:text-gray-400  text-gray-700"
                onClick={() => {
                    setOpenInvoiceModal(true);
                }}
            />
        </>
    );
};
export default VisualizeInvoice;
