/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-fragments */
import AutoComplete from 'components/autocomplete/autoComplete';
import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import { Avatar, Badge, Label, Select, TextInput, Textarea, Timeline } from 'flowbite-react';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { IdateRange } from 'pages/orders/orders.types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HiChat } from 'react-icons/hi';
import Datepicker from 'react-tailwindcss-datepicker';
import { IOrder, getOrders } from 'services/admin/order';
import { IStore } from 'services/admin/store';
import { IUser } from 'services/auth';
import { getQuestionHistory, pendingQuestion, updateOrder } from 'services/callAgent/orders';
import { getCurrentUser } from 'utils/helpers/auth';
import { convertDateTimeToUTC } from 'utils/helpers/tz';
import { CallCenterFeedbackSchema } from 'utils/validations/ordersValidations';
import { cities } from 'utils/vnlinCities';
import { IProduct } from 'utils/Interfaces/products.interface';
import { getSimilarProducts } from 'services/admin/product';
import { ICustomer } from 'pages/customers/customer.types';
import { OrderDetail } from './orderCardDetail';
import { OrderCordinate } from './orderCoordinate';
import { OrderInformation } from './orderInformation';
import AvailableProducts from './similarProduct/availableProducts';

const OrderDetails: React.FC<{
    data: IOrder;
    setRows?: React.Dispatch<React.SetStateAction<IOrder[]>>;
    setOpenDetailsModal?: React.Dispatch<React.SetStateAction<boolean>>;
    currentPage?: number;
    currentFilter?: Record<string, any>;
    revalidateData?: () => void;
}> = ({ data, setRows, setOpenDetailsModal, currentPage, currentFilter, revalidateData }) => {
    const [editCordinate, setEditCordinate] = useState(false);
    const [editInfo, setEditInfo] = useState(false);
    const [value, setValue] = useState<IdateRange>({
        startDate: null,
        endDate: null,
    });
    const [similarProducts, setSimilarProducts] = useState<IProduct[]>([]);

    const [cityVerification] = useState<boolean>(true);
    const handleValueChange = (newValue: any) => {
        setValue(newValue);
    };
    // const handleStatusChange = (event: any) => {
    //     if (event.target.id === 'status') {
    //         if (event.target.value === '4' && (Number(data.callCenterAttempt) === 0 || !data.callCenterAttempt)) {
    //             setCityVerfication(false);
    //             toast.error(`Please verify and update City field before this action`);
    //         } else {
    //             setCityVerfication(true);
    //         }
    //     }
    //     // if(Number)
    // };

    const city = cities[data.country as keyof typeof cities].find((el) => el === data.city);

    const isOurCountry = (country: string | undefined) => {
        return !!cities[country as keyof typeof cities];
    };

    const isCityinCountryList = (country: string | undefined, cityName: string | undefined) => {
        return cities[country as keyof typeof cities].some((list) => list === cityName);
    };

    // const handleStatusChangeTrue = (event: any) => {
    //     if (event.target.id === 'status') {
    //         if (event.target.value === '4' && !city) {
    //             setCityVerfication(false);
    //             toast.error(`Please verify and update Country and City field before this action`);
    //         } else {
    //             setCityVerfication(true);
    //         }
    //     }
    // };

    const handleStatusChangeTrue = (event: any) => {
        if (event.target.id === 'status') {
            if (event.target.value === '4' && !isOurCountry(data.country)) {
                toast.error(`Please verify and update Country field before this action`);
            } else if (event.target.value === '4' && !isCityinCountryList(data.country, data.city)) {
                toast.error(`Please verify and update City field before this action`);
            }
        }
    };

    const canceledReason = [
        { value: 1, label: 'Change of mind' },
        { value: 2, label: 'Bought already' },
        { value: 3, label: 'Did not order' },
        { value: 4, label: 'Price not accepted' },
        // { value: 6, label: 'Wrong phone number' },
        { value: 55, label: 'Other' },
    ];
    const unreachableReason = [
        { value: 7, label: 'No answer' },
        { value: 8, label: 'Phone off' },
        { value: 55, label: 'Other' },
    ];
    const scheduledCallReason = [
        { value: 9, label: 'Call me later' },
        { value: 55, label: 'Other' },
    ];
    const options = ['Canceled', 'Unreachable', 'Confirmed', 'Wrong number', 'Scheduled call', 'Pending answer'];
    const statusCode = (status: string) => {
        switch (status) {
            case 'Canceled':
                return 2;
            case 'Unreachable':
                return 3;
            case 'Confirmed':
                return 4;
            case 'Wrong number':
                return 21;
            case 'Scheduled call':
                return 43;
            case 'Pending answer':
                return 41;
            default:
                return 1;
        }
    };
    const reasonLabel = (status: number) => {
        const reason = unreachableReason
            .concat(canceledReason, scheduledCallReason)
            .find((element) => element.value === status);
        return reason?.label;
    };

    const [callCenterFeedbackLoader, setCallcenterFeedBackLoader] = useState(false);
    const updateCallCenterFeedback = (Feedback: any) => {
        const feedbackToast = toast.loading('Updating feedback...');
        setCallcenterFeedBackLoader(true);
        const dataUpdate: Partial<IOrder> = {
            actionReason: Feedback.actionReason,
            status: Feedback?.status,
            callCenterFeedback: data.callCenterFeedback || [],
            callCenterAttempt: data?.callCenterAttempt,
            totalPrice: Feedback?.totalPrice,
            fixedDeliveryDate: Feedback.fixedDeliveryDate,
            updateType: 1,
        };
        if (Feedback.callCenterFeedback.length > 0) {
            dataUpdate.callCenterFeedback = data.callCenterFeedback || [];
            dataUpdate.callCenterFeedback.push(Feedback.callCenterFeedback);
        } else {
            dataUpdate.callCenterFeedback = data.callCenterFeedback || [];
            dataUpdate.callCenterFeedback.push('Feedback not specified');
        }
        if (Feedback.scheduledCallAt) {
            dataUpdate.scheduledCallAt = Feedback.scheduledCallAt;
        }
        dataUpdate.callCenterAttempt = (data.callCenterAttempt || 0) + 1;
        updateOrder({ id: data._id, ...dataUpdate })
            .then((res) => {
                toast.success(res.data.message);
                setCallcenterFeedBackLoader(false);
                // setPrevMsg(Feedback.callCenterFeedback || Feedback.cancelReason);
                if (setOpenDetailsModal) {
                    setOpenDetailsModal(false);
                }
            })
            .then(() => {
                if (!setRows) {
                    return;
                }
                getOrders({ page: currentPage, ...(currentFilter || {}), callAgent: getCurrentUser()._id })
                    .then((orderData) => {
                        toast.success('Feedback updated successfuly', { id: feedbackToast });
                        if (orderData.data.docs.length === 0) {
                            // if the current page does not have items to show, paginate to the 1st pag
                            getOrders({ page: 1, ...(currentFilter || {}) })
                                .then((prevPageData) => {
                                    setRows(prevPageData.data.docs);
                                })
                                .catch(() => {
                                    toast.error(`Something went wrong, please try again`, { id: feedbackToast });
                                });
                        } else {
                            setRows(orderData.data.docs);
                        }
                    })
                    .catch(() => {
                        toast.error(`Something went wrong, please try again`);
                        setCallcenterFeedBackLoader(false);
                    });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message, { id: feedbackToast });
                setCallcenterFeedBackLoader(false);
            });
    };

    const [cordinateLoader, setCordinateLoader] = useState(false);

    const updateOrderInformations = (Feedback: any) => {
        setCordinateLoader(true);
        const feedbackToast = toast.loading('Updating order informations...');
        updateOrder({ id: data._id, ...Feedback })
            .then((res) => {
                toast.success(res.data.message);
                setEditCordinate(false);
                setEditInfo(false);
                setCordinateLoader(false);
                // setPrevMsg(Feedback.callCenterFeedback || Feedback.cancelReason);
            })
            .then(() => {
                getOrders({ page: currentPage, ...(currentFilter || {}), callAgent: getCurrentUser()._id })
                    .then((orderData) => {
                        toast.success('Order updated successfuly', { id: feedbackToast });
                        if (!setRows) {
                            return;
                        }
                        if (orderData.data.docs.length === 0) {
                            // if the current page does not have items to show, paginate to the 1st pag
                            getOrders({ page: 1, ...(currentFilter || {}) })
                                .then((prevPageData) => {
                                    setRows(prevPageData.data.docs);
                                })
                                .catch(() => {
                                    toast.error(`Something went wrong, please try again`, { id: feedbackToast });
                                });
                        } else {
                            setRows(orderData.data.docs);
                        }
                    })
                    .catch(() => {
                        toast.error(`Something went wrong, please try again`);
                        setCordinateLoader(false);
                    });
                if (revalidateData) {
                    revalidateData();
                }
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message, { id: feedbackToast });
                setCordinateLoader(false);
            });
    };

    useEffect(() => {}, [data.callCenterFeedback, data.actionReason]);

    const hours = Array.from({ length: 24 }, (_, i) => (i < 10 ? `0${i}` : `${i}`));
    const minutes = Array.from({ length: 60 }, (_, i) => (i < 10 ? `0${i}` : `${i}`));

    const updatePendingQuestion = (values: { orderId: string; content: string }) => {
        pendingQuestion({ ...values })
            .then(() => {
                toast.success('Question Sent');
                getOrders({ page: currentPage, ...(currentFilter || {}), callAgent: getCurrentUser()._id })
                    .then((orderData) => {
                        if (!setRows) {
                            if (setOpenDetailsModal) {
                                setOpenDetailsModal(false);
                            }
                            return;
                        }
                        if (orderData.data.docs.length === 0) {
                            // if the current page does not have items to show, paginate to the 1st pag
                            getOrders({ page: 1, ...(currentFilter || {}) })
                                .then((prevPageData) => {
                                    setRows(prevPageData.data.docs);
                                })
                                .catch(() => {
                                    toast.error(`Something went wrong, please try again`);
                                });
                            if (setOpenDetailsModal) {
                                setOpenDetailsModal(false);
                            }
                        } else {
                            setRows(orderData.data.docs);
                            if (setOpenDetailsModal) {
                                setOpenDetailsModal(false);
                            }
                        }
                    })
                    .catch(() => {
                        toast.error(`Something went wrong, please try again`);
                        setCordinateLoader(false);
                    });
            })
            .catch((e) => {
                toast.error(e?.response?.data?.errors?.message);
            });
    };

    const [answerHistory, setAnswerHistory] = useState<IOrder['inquiriesConversation']>();

    useEffect(() => {
        getSimilarProducts({
            stocked: true,
            store: (data.store as IStore)._id,
            customer: (data.customer as ICustomer)._id,
            country: data.country,
        })
            .then((res) => {
                setSimilarProducts(res.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong, please try again');
            });
    }, []);

    useEffect(() => {
        getQuestionHistory(data._id)
            .then((result) => {
                setAnswerHistory(result.data);
            })
            .catch((e) => {
                toast.error(e?.response?.data?.errors?.message || 'Something went wrong');
            });
    }, []);

    return (
        <div className="p-2" key={data._id}>
            <div className="flex flex-col gap-2 ">
                <div className="flex gap-2 flex-wrap  ">
                    <Badge color="indigo" size="xs" className="font-medium text-xs">
                        Store:{' '}
                        <span className="font-bold">
                            {(data?.store as IStore)?.storeName ? (data.store as IStore).storeName : '--'}
                        </span>
                    </Badge>
                    <Badge size="xs" color="indigo" className="font-medium text-xs">
                        Order reference: <span className="font-bold">{data?.orderRef ? data.orderRef : '--'}</span>
                    </Badge>
                    <Badge size="xs" color="blue" className="font-medium text-xs">
                        Logistio code: <span className="font-bold">{data?.lgstTrackingCode}</span>
                    </Badge>

                    {(data?.store as IStore)?.storeLink && (
                        <a
                            href={
                                (data?.store as IStore).storeLink.startsWith('http:') ||
                                (data?.store as IStore).storeLink.startsWith('https:')
                                    ? (data?.store as IStore).storeLink
                                    : `//${(data?.store as IStore).storeLink}`
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                            <span className="inline-block overflow-hidden overflow-ellipsis  whitespace-nowrap">
                                Store link
                            </span>
                        </a>
                    )}
                </div>

                <div>
                    {!city && (
                        <Badge color="failure">
                            Please verify the city with the client and update it before any action
                        </Badge>
                    )}
                </div>
            </div>
            <div className="grid grid-cols-3 p-4 mb-2 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <OrderCordinate
                    data={data}
                    editCordinate={editCordinate}
                    setEditCordinate={setEditCordinate}
                    updateOrderInformations={updateOrderInformations}
                    cordinateLoader={cordinateLoader}
                />
                <OrderInformation
                    data={data}
                    editInfo={editInfo}
                    setEditInfo={setEditInfo}
                    updateOrderInformations={updateOrderInformations}
                    cordinateLoader={cordinateLoader}
                />
                <OrderDetail data={data} />
            </div>

            <AvailableProducts
                similarProducts={similarProducts as IProduct[]}
                data={data}
                setRows={setRows}
                currentPage={currentPage}
                revalidateData={revalidateData}
            />

            <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <Formik
                    key={data._id}
                    initialValues={{
                        callCenterFeedback: '',
                        actionReason: '',
                        callMinutes: '',
                        callHours: '',
                        schedualCallDate: '',
                        timeZone: 'Egypt',
                        status: String(data.status),
                        totalPrice: data.totalPrice,
                        updateType: 1,
                        content: '',
                    }}
                    onSubmit={(values, onSubmitProps) => {
                        const updatePayload: Omit<
                            typeof values,
                            'callMinutes' | 'callHours' | 'schedualCallDate' | 'timeZone'
                        > & { scheduledCallAt?: string } = {
                            callCenterFeedback: values.callCenterFeedback,
                            actionReason: values.actionReason,
                            status: values.status,
                            totalPrice: values.totalPrice,
                            updateType: values.updateType,
                            content: values.content,
                        };

                        // if status is `scheduled call` we assign the date time value of the scheduled call in utc to the update payload
                        if (values.status === '43') {
                            const scheduledCallAt = convertDateTimeToUTC({
                                date: values.schedualCallDate as `${number}-${number}-${number}`,
                                hour: values.callHours as `${number}`,
                                minute: values.callMinutes as `${number}`,
                                tz: values.timeZone as `${string}/${string}`,
                            });
                            Object.assign(updatePayload, { scheduledCallAt });
                        } else {
                            Object.assign(updatePayload, {
                                fixedDeliveryDate: value.startDate,
                            });
                        }
                        if (values.status === '41') {
                            updatePendingQuestion({ orderId: data._id, content: values.content });
                        } else {
                            updateCallCenterFeedback({ ...updatePayload });
                        }
                        onSubmitProps.setSubmitting(false);
                        if (revalidateData) {
                            revalidateData();
                        }
                    }}
                    validationSchema={CallCenterFeedbackSchema}
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit} onChange={handleStatusChangeTrue} placeholder="">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <div>
                                        <h5>Previous Feedback :</h5>
                                    </div>
                                    <div>
                                        <Timeline className="p-4">
                                            <Timeline.Item>
                                                <Timeline.Point icon={HiChat} />
                                                <Timeline.Content>
                                                    <Timeline.Title>Reason:</Timeline.Title>
                                                    <Timeline.Body>
                                                        {data.actionReason ? reasonLabel(data.actionReason) : '--'}
                                                    </Timeline.Body>
                                                    {data.status === 43 && (
                                                        <>
                                                            <Timeline.Title>Fixed call date: </Timeline.Title>
                                                            <Timeline.Body>
                                                                <p>
                                                                    {data?.scheduledCallAt
                                                                        ? moment(data?.scheduledCallAt).format(
                                                                              'MM/DD/YYYY HH:mm:ss',
                                                                          )
                                                                        : '--'}
                                                                </p>
                                                            </Timeline.Body>
                                                        </>
                                                    )}
                                                    <Timeline.Title>Feedback: </Timeline.Title>
                                                    <Timeline.Body>
                                                        {data?.callCenterFeedback &&
                                                            data?.callCenterFeedback[
                                                                data?.callCenterFeedback?.length - 1
                                                            ]}
                                                    </Timeline.Body>
                                                </Timeline.Content>
                                            </Timeline.Item>
                                        </Timeline>
                                    </div>
                                </div>
                                <div>
                                    <div id="selectStatus">
                                        <div className="mb-2 block">
                                            <Label htmlFor="status" value="Update Order Status" />
                                        </div>
                                        <Select
                                            id="status"
                                            required
                                            {...formik.getFieldProps('status')}
                                            // onChange={(event) => {
                                            //     formik.setFieldValue('status', event.target.value);
                                            // }}
                                            name="status"
                                        >
                                            <option value="" /* onClick={() => { updateOrderStatus(option) }} */>
                                                Order status
                                            </option>
                                            {options.map((option) => (
                                                <option
                                                    value={statusCode(
                                                        option,
                                                    )} /* onClick={() => { updateOrderStatus(option) }} */
                                                >
                                                    {option}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                    {(formik.values.status === '3' && (
                                        <div id="selectReason">
                                            <div className="mb-2 block">
                                                <Label htmlFor="reason" value="Reason" />
                                            </div>
                                            <Select
                                                id="reason"
                                                required
                                                {...formik.getFieldProps('actionReason')}
                                                name="actionReason"
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="actionReason" component="div" />
                                                    </React.Fragment>
                                                }
                                            >
                                                <option value="">Choose a reason</option>
                                                {unreachableReason.map((option) => (
                                                    <option value={option.value}>{option.label}</option>
                                                ))}
                                            </Select>
                                        </div>
                                    )) ||
                                        (formik.values.status === '2' && (
                                            <div id="selectReason">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="reason" value="Reason" />
                                                </div>
                                                <Select
                                                    id="reason"
                                                    required
                                                    {...formik.getFieldProps('actionReason')}
                                                    name="actionReason"
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="actionReason" component="div" />
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <option value="">Choose a reason</option>
                                                    {canceledReason.map((option) => (
                                                        <option value={option.value}>{option.label}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                        )) ||
                                        (formik.values.status === '43' && (
                                            <div id="selectReason">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="reason" value="Reason" />
                                                </div>
                                                <Select
                                                    id="reason"
                                                    required
                                                    {...formik.getFieldProps('actionReason')}
                                                    name="actionReason"
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="actionReason" component="div" />
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <option value="">Choose a reason</option>
                                                    {scheduledCallReason.map((option) => (
                                                        <option value={option.value}>{option.label}</option>
                                                    ))}
                                                </Select>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="schedualCallDate" value="Fixed call date" />
                                                    <Datepicker
                                                        inputId="schedualCallDate"
                                                        containerClassName="relative z-100"
                                                        // inputClassName="absolute"
                                                        // toggleClassName="absolute
                                                        useRange={false}
                                                        minDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24)}
                                                        asSingle
                                                        {...formik.getFieldProps('schedualCallDate')}
                                                        value={value}
                                                        onChange={(opt) => {
                                                            handleValueChange(opt);
                                                            formik.setFieldValue('schedualCallDate', opt?.startDate);
                                                        }}
                                                        readOnly
                                                    />
                                                    <CustomError name="schedualCallDate" component="div" />
                                                </div>
                                                <div className="flex gap-1  ">
                                                    <div>
                                                        <Select
                                                            id="callHours"
                                                            required
                                                            {...formik.getFieldProps('callHours')}
                                                            name="callHours"
                                                            helperText={
                                                                <React.Fragment>
                                                                    <CustomError name="callHours" component="div" />
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <option value="">Hours</option>
                                                            {hours.map((option) => (
                                                                <option value={option}>{option}</option>
                                                            ))}
                                                        </Select>
                                                    </div>

                                                    <Select
                                                        id="callMinutes"
                                                        required
                                                        {...formik.getFieldProps('callMinutes')}
                                                        name="callMinutes"
                                                        helperText={
                                                            <React.Fragment>
                                                                <CustomError name="callMinutes" component="div" />
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <option value="">Minutes</option>
                                                        {minutes.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                    <div>
                                                        <AutoComplete
                                                            key="id"
                                                            {...formik.getFieldProps('timeZone')}
                                                            name="timeZone"
                                                            placeholder="Timezone"
                                                            options={moment.tz.names() as []}
                                                            getOptionLabel={(option: any) => `${option}`}
                                                            defaultValue="Egypt"
                                                            // eslint-disable-next-line no-underscore-dangle
                                                            onChange={(option) => {
                                                                formik.setFieldValue('timeZone', option);
                                                            }}
                                                        />
                                                        <div className="mt-2">
                                                            <CustomError name="timeZone" component="div" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    {formik.values.status === '4' && (
                                        <div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="deliveryDate" value="Fixed delivery date" />
                                                    <Datepicker
                                                        inputId="deliveryDate"
                                                        value={value}
                                                        containerClassName="relative z-100"
                                                        // inputClassName="absolute"
                                                        // toggleClassName="absolute
                                                        useRange={false}
                                                        minDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24)}
                                                        asSingle
                                                        onChange={handleValueChange}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="base" value="Total price" />
                                                </div>
                                                <TextInput
                                                    id="company"
                                                    type="number"
                                                    sizing="md"
                                                    {...formik.getFieldProps('totalPrice')}
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="totalPrice" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {formik.values.status === '41' ? (
                                        <div>
                                            <div id="textarea">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="content" value="Provide your question" />
                                                </div>
                                                <Textarea
                                                    id="feedback"
                                                    placeholder="Write your message ..."
                                                    rows={4}
                                                    {...formik.getFieldProps('content')}
                                                    name="content"
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="content" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div id="textarea">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="feedback" value="Feedback" />
                                                </div>
                                                <Textarea
                                                    id="feedback"
                                                    placeholder="Write your message ..."
                                                    rows={4}
                                                    {...formik.getFieldProps('callCenterFeedback')}
                                                    name="callCenterFeedback"
                                                    helperText={
                                                        <React.Fragment>
                                                            <CustomError name="callCenterFeedback" component="div" />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                            <CustomError
                                                name={data.status === 2 ? 'cancelReason' : 'callCenterFeedback'}
                                                component="div"
                                            />
                                        </>
                                    )}

                                    <div className="flex justify-end">
                                        <CustomBtn
                                            variant="primary"
                                            type="submit"
                                            className="w-full"
                                            disabled={
                                                !formik.isValid ||
                                                !formik.dirty ||
                                                formik.isSubmitting ||
                                                !cityVerification ||
                                                callCenterFeedbackLoader
                                            }
                                            isProcessing={callCenterFeedbackLoader}
                                        >
                                            Save
                                        </CustomBtn>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {answerHistory && answerHistory?.length > 0 && (
                <div className="bg-white p-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-2">
                    <h5 className="mb-2">Previous requests :</h5>
                    {answerHistory?.map((inquiry) => {
                        const user = inquiry.sentBy as Pick<
                            IUser,
                            'firstName' | 'lastName' | 'profileImage' | 'role' | '_id'
                        >;
                        return (
                            <div className=" flex p-2 mb-2 ">
                                <div className="flex-shrink-0">
                                    <Avatar
                                        img={user.profileImage}
                                        rounded
                                        alt={`${user.firstName} ${user.lastName}`}
                                    />
                                </div>
                                <div className="flex-1 min-w-0 ms-4  dark:bg-[#374152] bg-[#F1F2F5] rounded-lg py-2 px-3">
                                    <div className="flex gap-2">
                                        <p className=" text-sm font-medium text-gray-900  dark:text-white flex gap-1">
                                            {user.firstName} {user.lastName}
                                        </p>
                                        <Badge color="indigo" className="w-fit" size="xs">
                                            {user.role}
                                        </Badge>
                                    </div>
                                    <div>
                                        <p className=" text-xs font-light text-gray-500  dark:text-gray-400">
                                            {moment(inquiry.sentAt).format('MM/DD/YYYY HH:mm:SS')}
                                        </p>
                                    </div>
                                    <p className=" text-base text-gray-900   dark:text-white pt-1">{inquiry.content}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default OrderDetails;
