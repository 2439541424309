import * as Yup from 'yup';

interface CustomTestContext extends Yup.TestContext {
    originalValue?: string;
}
const decimalValidation = () => {
    return Yup.number()
        .nullable()
        .min(0, 'Minimum value is 0')
        .typeError('Field must be a number')
        .test('is-decimal', 'Field must be a decimal with this format X.xx', (_, context: CustomTestContext) => {
            const { originalValue } = context;
            if (!originalValue) return true;
            return /^\d+(\.\d+)?$/.test((originalValue.toString() || '')?.trim());
        });
};
export const addResellerGeneralInfoSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    chineseName: Yup.string().required('Required'),
    weight: decimalValidation().required('Required'),
    width: decimalValidation().required('Required'),
    height: decimalValidation().required('Required'),
    length: decimalValidation().required('Required'),
    quantity: Yup.number()
        .integer('Must be an integer')
        .min(1, 'Minimum value is 1')
        .typeError('Field must be a number')
        .required('Required'),
    declarationAmount: Yup.number()
        .moreThan(0, 'The value must be greater than 0')
        .typeError('Field must be a number')
        .required('Required'),
    // adCopies: Yup.array().of(Yup.string().required('Required')).min(1, 'Add at least one copy').required('Required'),
    resellerQuotation: Yup.array()

        .of(
            Yup.object().shape({
                country: Yup.string().typeError('Field must be a number').required('Required'),
                quantity: Yup.number()
                    .integer('Must be an integer')
                    .min(0, 'Minimum value is 0')
                    .typeError('Field must be a number')
                    .required('Required'),
                unitPurchasePrice: Yup.number()
                    .min(0, 'Minimum value is 0')
                    .typeError('Field must be a number')
                    .required('Required'),

                totalShippingFee: Yup.number()
                    .min(0, 'Minimum value is 0')
                    .typeError('Field must be a number')
                    .required('Required'),
            }),
        )
        .test({
            message: `Total quantity in diffrent warehouses should be equal to initial quantity`,
            test: (rows, context) => {
                const total =
                    rows?.reduce((accumulator, currentValue) => accumulator + (currentValue?.quantity || 0), 0) || 0;

                return total === Number(context?.parent?.quantity);
            },
        })
        .required('Required'),
});
export const addResellerSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    chineseName: Yup.string().required('Required'),
    weight: decimalValidation().required('Required'),
    width: decimalValidation().required('Required'),
    height: decimalValidation().required('Required'),
    length: decimalValidation().required('Required'),
    quantity: Yup.number()
        .integer('Must be an integer')
        .min(1, 'Minimum value is 1')
        .typeError('Field must be a number')
        .required('Required'),
    declarationAmount: Yup.number()
        .min(0, 'Minimum value is 0')
        .typeError('Field must be a number')
        .required('Required'),
    // adCopies: Yup.array().of(Yup.string().required('Required')).min(1, 'Add at least one copy').required('Required'),
    resellerQuotation: Yup.array()

        .of(
            Yup.object().shape({
                country: Yup.string().typeError('Field must be a number').required('Required'),
                quantity: Yup.number()
                    .integer('Must be an integer')
                    .min(1, 'Minimum value is 1')
                    .typeError('Field must be a number')
                    .required('Required'),
                unitPurchasePrice: Yup.number()
                    .min(0, 'Minimum value is 0')
                    .typeError('Field must be a number')
                    .required('Required'),
                sellingPrice: Yup.number()
                    // .min(0.01, 'Minimum value is 0.01')
                    .typeError('Field must be a number')
                    .required('Required'),
                totalShippingFee: Yup.number()
                    .min(0, 'Minimum value is 0')
                    .typeError('Field must be a number')
                    .required('Required'),
                recommendedPrice: Yup.number()
                    // .min(0.01, 'Minimum value is 0.01')
                    .optional()
                    .typeError('Field must be a number'),
            }),
        )

        .test({
            message: `Total quantity in diffrent warehouses should be equal to initial quantity`,
            test: (rows, context) => {
                const total =
                    rows?.reduce((accumulator, currentValue) => accumulator + (currentValue?.quantity || 0), 0) || 0;

                return total === Number(context?.parent?.quantity);
            },
        })
        .required('Required'),
    categories: Yup.array().of(Yup.string()).min(1, 'Add at least one category').required('Required'),
    quantityLimit: Yup.number()
        .integer('Must be an integer')
        .min(1, 'Minimum value is 1')
        .typeError('Field must be a number')
        .required('Required'),
});
export const draftResellerSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    weight: decimalValidation(),
    width: decimalValidation(),
    height: decimalValidation(),
    length: decimalValidation(),
    resellerQuotation: Yup.array().of(
        Yup.object().shape({
            country: Yup.string().typeError('Field must be a number').required('Required'),
            quantity: Yup.number()
                .integer('Must be an integer')
                .min(1, 'Minimum value is 1')
                .typeError('Field must be a number')
                .required('Required'),
            unitPurchasePrice: decimalValidation(),
            totalShippingFee: decimalValidation(),
        }),
    ),
    declarationAmount: decimalValidation(),
});
export const updateResellerSchema = Yup.object().shape({
    name: Yup.string().required('You need to choose a reseller product'),
    categories: Yup.array()
        .of(Yup.string().required('Category is required'))
        .min(1, 'At least one category is required')
        .required('Required'),
    resellerQuotation: Yup.array().of(
        Yup.object().shape({
            recommendedPrice: decimalValidation(),
            sellingPrice: decimalValidation(),
            quantityLimit: Yup.number()
                .integer('Must be an integer')
                .typeError('Field must be a number')
                .min(0, 'Minimum value is 0'),
        }),
    ),
    declarationAmount: decimalValidation(),
});
