/* eslint-disable react/style-prop-object */
import React from 'react';
import ExcelJS from 'exceljs';
import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Avatar } from 'flowbite-react';
import { ICredit } from 'services/accountManager/credits';
import { ICustomer } from 'pages/customers/customer.types';
import Image from 'assets/no-image.png';
import { StatusBadge } from 'utils/helpers/statusBadge';
import { format } from 'date-fns';
import { IUser } from 'services/auth';
import profileImg from 'assets/avatar.png';
import { IProduct } from 'utils/Interfaces/products.interface';
import { CREDIT_TYPES } from './credits.types';

export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: <StatusBadge name="Pending" style="blueTag" />,
        statusLabel: 'Pending',
        statusClass: 'blueTag',
    },
    2: {
        statusIcon: <StatusBadge name="Confirmed" style="yellowTag" />,
        statusLabel: 'Confirmed',
        statusClass: 'yellowTag',
    },
    3: {
        statusIcon: <StatusBadge name="Paid" style="greenTag" />,
        statusLabel: 'Paid',
        statusClass: 'greenTag',
    },
    4: {
        statusIcon: <StatusBadge name="Rejected" style="redTag" />,
        statusLabel: 'Rejected',
        statusClass: 'Rejected',
    },
};

export const cogsColumns: TableColDef[] = [
    {
        field: 'createAt',
        headerName: 'Creation date',
        fieldType: 'render',
        renderCell: (params: ICredit) => {
            return params?.createdAt && params.updatedAt ? (
                <div>
                    <p>
                        {format(new Date(params?.status === 2 ? params?.updatedAt : params?.createdAt), 'dd/MM/yyyy')}
                    </p>
                </div>
            ) : (
                <p>-</p>
            );
        },
    },
    {
        field: 'customer',
        headerName: 'Seller',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
        fieldType: 'render',
        renderCell: (params: ICredit) => (
            <>
                <p
                    title={`${(params.customer as ICustomer)?.firstName} ${(params.customer as ICustomer)?.lastName}`}
                    className="max-w-[140px] overflow-hidden whitespace-nowrap text-ellipsis font-medium text-gray-900 dark:text-white"
                >
                    {(params.customer as ICustomer)?.firstName
                        ? `${(params.customer as ICustomer)?.firstName} ${(params.customer as ICustomer)?.lastName}`
                        : (params.customer as ICustomer)?.email}
                </p>
            </>
        ),
    },

    {
        field: 'product',
        headerName: 'product Name',
        fieldType: 'render',
        renderCell: (params: ICredit) => {
            const isThere = (params.product?.design?.length ? params.product.design : [])?.filter(
                (prodImg: any) =>
                    prodImg.endsWith('.jpg') ||
                    prodImg.endsWith('.png') ||
                    prodImg.endsWith('.jpeg') ||
                    prodImg.endsWith('.webp'),
            );
            return (
                <>
                    <td className="px-2 py-4 flex gap-1">
                        <div>
                            {isThere && isThere.length > 0 ? <Avatar img={isThere[0]} /> : <Avatar img={Image} />}
                        </div>
                        <div className="flex flex-col">
                            <div
                                title={params?.product?.name}
                                className="max-w-[130px] overflow-hidden whitespace-nowrap text-ellipsis font-medium text-gray-900 dark:text-white capitalize my-auto"
                            >
                                <span>{params?.product?.name}</span>
                            </div>
                            <div>
                                <span className="max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis my-auto whitespace-nowrap">
                                    {params?.product?.globalSKU}
                                </span>
                            </div>
                        </div>
                    </td>
                </>
            );
        },
    },

    {
        field: 'creditAmout',
        headerName: 'Credit amount ($)',
        fieldType: 'render',
        renderCell: (params: ICredit) => <> {(params?.creditAmount).toFixed(2)}</>,
    },

    {
        field: 'cogsUnitPrice',
        headerName: 'Cogs unit price ($)',
        fieldType: 'render',
        renderCell: (params: ICredit) => <> {(params?.cogsUnitPrice || 0).toFixed(2)}</>,
    },

    {
        field: 'payedCredit',
        headerName: 'Payed amount ($)',
        fieldType: 'render',
        renderCell: (params: ICredit) => <> {(params?.payedCredit || 0).toFixed(2)}</>,
    },

    {
        field: 'managedBy',
        headerName: 'Managed by',
        fieldType: 'render',
        renderCell: (params: ICredit) => {
            const manager = params?.doneBy as IUser;

            return manager ? (
                <div className="flex gap-1">
                    {manager?.profileImage ? (
                        <img src={manager?.profileImage} alt="profileImage" className="w-8 h-8 !rounded-full" />
                    ) : (
                        <img src={profileImg} alt="profileImage" className="w-8 h-8 !rounded-full" />
                    )}

                    <span
                        title={`${manager.firstName || ''} ${manager.lastName || ''}`}
                        className="max-w-[90px] overflow-hidden whitespace-nowrap text-ellipsis font-medium text-gray-900 dark:text-white capitalize my-auto"
                    >
                        {`${manager.firstName || ''} ${manager.lastName || ''}`}
                    </span>
                </div>
            ) : (
                <p>-</p>
            );
        },
    },

    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },

    {
        field: 'Action',
        actionType: 'CreditActions',
        headerName: '',
        classField: 'w-[10px] min-w-[10px]',
        classHeader: 'w-[10px] min-w-[10px] ',
    },
];

export const adsColumns: TableColDef[] = [
    {
        field: 'createAt',
        headerName: 'Creation date',
        fieldType: 'render',
        renderCell: (params: ICredit) => {
            return params?.createdAt && params.updatedAt ? (
                <div>
                    <p>
                        {format(new Date(params?.status === 2 ? params?.updatedAt : params?.createdAt), 'dd/MM/yyyy')}
                    </p>
                </div>
            ) : (
                <p>-</p>
            );
        },
    },
    {
        field: 'customer',
        headerName: 'Seller',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
        fieldType: 'render',
        renderCell: (params: ICredit) => (
            <>
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {(params.customer as ICustomer)?.firstName
                        ? `${(params.customer as ICustomer)?.firstName} ${(params.customer as ICustomer)?.lastName}`
                        : (params.customer as ICustomer)?.email}
                </p>
            </>
        ),
    },

    {
        field: 'creditAmout',
        headerName: 'Credit amount ($)',
        fieldType: 'render',
        renderCell: (params: ICredit) => <> {(params?.creditAmount || 0).toFixed(2)}</>,
    },

    {
        field: 'adsPercent',
        headerName: 'Ads percent (%)',
        fieldType: 'render',
        renderCell: (params: ICredit) => <> {params?.adsPercent}</>,
    },

    {
        field: 'payedCredit',
        headerName: 'Payed amount ($)',
        fieldType: 'render',
        renderCell: (params: ICredit) => <> {(params?.payedCredit || 0).toFixed(2)}</>,
    },

    {
        field: 'managedBy',
        headerName: 'Managed by',
        fieldType: 'render',
        renderCell: (params: ICredit) => {
            const manager = params?.doneBy as IUser;

            return manager ? (
                <div className="flex gap-2">
                    {manager?.profileImage ? (
                        <img src={manager?.profileImage} alt="profileImage" className="w-10 h-10 !rounded-full" />
                    ) : (
                        <img src={profileImg} alt="profileImage" className="w-10 h-10 !rounded-full" />
                    )}

                    <span className="whitespace-nowrap font-small text-gray-900 dark:text-white capitalize my-auto">
                        {`${manager.firstName || ''} ${manager.lastName || ''}`}
                    </span>
                </div>
            ) : (
                <p>-</p>
            );
        },
    },

    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },

    {
        field: 'Action',
        actionType: 'CreditActions',
        headerName: '',
        classField: 'w-[10px] min-w-[10px]',
        classHeader: 'w-[10px] min-w-[10px] ',
    },
];

export const handleExportCredit = (selectedCredit: ICredit[], creditType: CREDIT_TYPES) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(`${creditType} credits`);
    sheet.getRow(1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };

    sheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'lightDown',
        fgColor: { argb: 'c6b1e6' },
        bgColor: { argb: 'c6b1e6' },
    };

    sheet.getRow(1).font = {
        name: 'Poppins Black',
        size: 10,
        bold: true,
    };
    sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

    sheet.columns =
        creditType === 'Cogs'
            ? [
                  {
                      header: 'Created at',
                      key: 'createdAt',
                      width: 20,
                  },
                  {
                      header: 'Seller name',
                      key: 'seller',
                      width: 25,
                  },
                  {
                      header: 'Product name',
                      key: 'product',
                      width: 25,
                  },
                  {
                      header: 'Product sku',
                      key: 'sku',
                      width: 25,
                  },
                  {
                      header: 'Credit amount ($)',
                      key: 'creditAmount',
                      width: 20,
                  },

                  {
                      header: 'Cogs unit price ($)',
                      key: 'cogsUnitPrice',
                      width: 20,
                  },

                  {
                      header: 'Payed amount ($)',
                      key: 'payedCredit',
                      width: 20,
                  },

                  {
                      header: 'Managed by',
                      key: 'doneBy',
                      width: 25,
                  },

                  {
                      header: 'Status',
                      key: 'status',
                      width: 20,
                  },
              ]
            : [
                  {
                      header: 'Created at',
                      key: 'createdAt',
                      width: 20,
                  },
                  {
                      header: 'Seller name',
                      key: 'seller',
                      width: 25,
                  },

                  {
                      header: 'Credit amount ($)',
                      key: 'creditAmount',
                      width: 20,
                  },

                  {
                      header: 'Ads percent (%)',
                      key: 'adsPercent',
                      width: 20,
                  },

                  {
                      header: 'Payed amount ($)',
                      key: 'payedCredit',
                      width: 20,
                  },

                  {
                      header: 'Managed by',
                      key: 'doneBy',
                      width: 25,
                  },

                  {
                      header: 'Status',
                      key: 'status',
                      width: 20,
                  },
              ];

    const promise = Promise.all(
        selectedCredit?.map(async (credit: ICredit) => {
            const rows = {};
            if (creditType === 'Cogs') {
                Object.assign(rows, {
                    createdAt: `${format(
                        new Date(credit?.status === 2 ? credit?.updatedAt || '' : credit?.createdAt || ''),
                        'dd/MM/yyyy',
                    )}`,

                    seller: `${(credit?.customer as ICustomer)?.firstName} ${(credit?.customer as ICustomer)
                        ?.lastName}`,
                    product: `${(credit?.product as IProduct)?.name}`,
                    sku: `${(credit?.product as IProduct)?.globalSKU}`,
                    creditAmount: credit.creditAmount,
                    cogsUnitPrice: credit.cogsUnitPrice,
                    payedCredit: credit.payedCredit,
                    doneBy: credit.doneBy
                        ? `${(credit?.doneBy as IUser)?.firstName} ${(credit?.doneBy as IUser)?.lastName}`
                        : `-`,
                    status: `${statusDisplay[credit.status].statusLabel}`,
                });
            } else {
                Object.assign(rows, {
                    createdAt: `${format(
                        new Date(credit?.status === 2 ? credit?.updatedAt || '' : credit?.createdAt || ''),
                        'dd/MM/yyyy',
                    )}`,

                    seller: `${(credit?.customer as ICustomer)?.firstName} ${(credit?.customer as ICustomer)
                        ?.lastName}`,
                    creditAmount: credit.creditAmount,
                    adsPercent: credit.adsPercent,
                    payedCredit: credit.payedCredit,
                    doneBy: credit.doneBy
                        ? `${(credit?.doneBy as IUser)?.firstName} ${(credit?.doneBy as IUser)?.lastName}`
                        : `-`,
                    status: `${statusDisplay[credit.status].statusLabel}`,
                });
            }

            sheet.addRow(rows);
        }),
    );

    promise.then(() => {
        const rowsLength = sheet.rowCount;
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < rowsLength; i++) {
            sheet.getRow(i + 1).height = 30;
            sheet.getRow(i + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getRow(i + 1).font = {
                name: 'Poppins Black',
                family: 4,
                size: 10,
            };
        }

        workbook.xlsx.writeBuffer().then((dataBlob) => {
            const blob = new Blob([dataBlob], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `${creditType} Credit.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    });
};
