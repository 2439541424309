import { IProduct } from 'utils/Interfaces/products.interface';
import { getBearerToken } from 'utils/helpers/auth';
import axios from 'utils/axios';
import { IPaginationData } from 'utils/Interfaces/types';
import { IUser } from 'services/auth';
import { IStock } from './stock';
import { ResellerStockShipment } from './resellerStock';

export interface IStockShipment {
    _id: string;
    product: IProduct;
    parentProduct: IProduct;
    stock: string | IStock;
    quantity: number;
    country: string;
    warehouse: string;
    trackingCode?: string;
    lgstTrackingCode?: string;
    status: number;
    boxes?: {
        boxNumber: number;
        quantity: number;
    }[];
    createdAt: string;
    updatedAt: string;
}

export interface IStockShipmentFiler {
    warehouse?: string;
    status?: number;
    skuOrTrackingCode?: string;
}

export interface IUpdateData {
    newStatus: number;
    id: string;
}

export interface IShipmentTrace {
    _id: string;
    time: Date;
    doneBy?: IUser;
    logistioStatus: string;
    description?: string;
}
export interface IShipment {
    _id: string;
    product: IProduct;
    parentProduct: IProduct;
    stock: string | IStock;
    quantity: number;
    country: string;
    warehouse: string;
    trackingCode?: string;
    lgstTrackingCode?: string;
    status: number;
    boxes?: {
        boxNumber: number;
        quantity: number;
    }[];
    createdAt?: string;
    updatedAt?: string;
}

export type IReadyForPickUp = {
    boxes: {
        id: number | string;
        weight: number;
        width: number;
        length: number;
        height: number;
        products: {
            _id: string;
            name: string;
            qty: number;
            shipmentId: string;
        }[];
    }[];
    productAttributes: {
        [productId: string]: {
            name: string;
            battery: boolean;
            branding: boolean;
            solely: boolean;
        };
    };
    transportation: string;
    platform: '其他' | '独立站' | 'NOON FLEX' | '亚马逊FLEX' | '速卖通' | '';
    documents?: string;
    country: string;
    status: number;
};

export const getStockShipments = async (data: IStockShipmentFiler) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginationData<IStockShipment | ResellerStockShipment>>(
        `/stocks/get-shipments`,
        data,
        config,
    );
    return response;
};

export const updateStockShipment = async (data: IUpdateData) => {
    const config = getBearerToken();
    const response = await axios.put(`/stocks/update-shipment/${data.id}`, data, config);
    return response;
};

export const getShipmentTrace = async (shipmentId: string) => {
    const config = getBearerToken();
    const response = await axios.get(`/stocks/shipment-trace/${shipmentId}`, config);
    return response;
};

export const getStockShipmentsByStockId = async (stockId: string) => {
    const config = getBearerToken();
    const response = await axios.get<IShipment[]>(`/stocks/get-stock-shipments/${stockId}`, config);
    return response;
};

export const setShipmentReadyForPickUp = async (dataForPickUp: IReadyForPickUp) => {
    const config = getBearerToken();
    const response = await axios.put(`/stocks/shipments/set-ready-for-pickup`, dataForPickUp, config);
    return response;
};

export const getShipmentHistory = async (shipmentId: string) => {
    const config = getBearerToken();
    const response = await axios.get(`/stocks/shipments/get-shipment-history/${shipmentId}`, config);
    return response;
};
