import React, { useState } from 'react';
import { PiTrashLight } from 'react-icons/pi';
import { IoCheckmark, IoEyeOutline } from 'react-icons/io5';
import { MdOutlineEdit, MdOutlinePaid } from 'react-icons/md';
import { CREDIT_TYPES, ICreditActions } from './credits.types';
import CreditHistory from './creditHistory';
import ConfirmationModal from './confirmationModal';
import CreateCredit from './createCredit';

const CreditActions = (props: ICreditActions) => {
    const { credit, setRows, currentPage, currentFilter, setStats } = props;
    const [openCreditHistory, setOpenCreditHistory] = useState<boolean>(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
    const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const editCredit = (status: number) => {
        switch (status) {
            case 4:
                setAction('reject');
                setOpenConfirmationModal(true);
                break;
            case 2:
                setAction('confirm');
                setOpenConfirmationModal(true);
                break;
            case 3:
                setAction('paid');
                setOpenConfirmationModal(true);
                break;

            case 0:
                setAction('edit');
                setOpenEditDrawer(true);
                break;

            default:
                break;
        }
    };

    return (
        <>
            <div className={`flex ${credit.status === 1 ? 'items-center' : 'justify-end'} gap-2`}>
                {credit.status === 1 && (
                    <div>
                        <PiTrashLight
                            title="Reject credit"
                            onClick={() => editCredit(4)}
                            className="w-5 h-5 hover:cursor-pointer dark:text-gray-400 text-gray-700"
                        />
                    </div>
                )}
                {credit.status === 1 && (
                    <div>
                        <IoCheckmark
                            title="Confirm credit"
                            onClick={() => editCredit(2)}
                            className="w-5 h-5 hover:cursor-pointer dark:text-gray-400 text-gray-700"
                        />
                    </div>
                )}

                {credit.status === 2 && (
                    <div>
                        <MdOutlinePaid
                            title="Confirm payment"
                            onClick={() => editCredit(3)}
                            className="w-5 h-5 hover:cursor-pointer dark:text-gray-400 text-gray-700"
                        />
                    </div>
                )}

                {credit.status === 1 && (
                    <div>
                        <MdOutlineEdit
                            title="Edit credit information"
                            onClick={() => editCredit(0)}
                            className="w-5 h-5 hover:cursor-pointer dark:text-gray-400 text-gray-700"
                        />
                    </div>
                )}

                {credit.status !== 1 && (
                    <div>
                        <IoEyeOutline
                            title="Payment history"
                            className="w-5 h-5 hover:cursor-pointer dark:text-gray-400 text-gray-700"
                            onClick={() => {
                                setOpenCreditHistory(true);
                            }}
                        />
                    </div>
                )}
            </div>
            {openCreditHistory && (
                <CreditHistory
                    data={credit}
                    openCreditHistory={openCreditHistory}
                    setOpenCreditHistory={setOpenCreditHistory}
                />
            )}

            {openConfirmationModal && (
                <ConfirmationModal
                    action={action}
                    credit={credit}
                    currentFilter={currentFilter}
                    currentPage={currentPage}
                    open={openConfirmationModal}
                    setAction={setAction}
                    setCredits={setRows}
                    setOpen={setOpenConfirmationModal}
                    setStats={setStats}
                    key="confirmationModal"
                />
            )}

            {openEditDrawer && (
                <CreateCredit
                    creditType={credit.creditType as CREDIT_TYPES}
                    openCreateCredit={openEditDrawer}
                    setOpenCreateCredit={setOpenEditDrawer}
                    setCreditList={setRows}
                    setCreditStats={setStats}
                    edit
                    creditData={credit}
                    key="editCredit"
                />
            )}
        </>
    );
};

export default CreditActions;
