import axios from 'utils/axios';
import { IPaginationData } from 'utils/Interfaces/types';
import { ICampaign } from 'pages/callCenterCampaignList/campaign.types';
import { getBearerToken } from '../../utils/helpers/auth';

interface IOptions {
    filter?: Partial<ICampaign>;
    total?: number;
    page?: number;
    limit?: number;
}

interface IPaginatedCampaigns extends IPaginationData<ICampaign> {}

export const getCampaigns = async (options: IOptions) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginatedCampaigns>(`/ziwo/filter`, options, config);
    return response;
};

export const updateLGSTCountry = async (data: { campaignId: string; name: string; country: string }) => {
    const orderUpdate = await axios.put(`/ziwo/updateLgstCampaign`, data, getBearerToken());
    return orderUpdate;
};

export const startCampaign = async (data: { campaignId: string }) => {
    const orderUpdate = await axios.post(`/ziwo/start`, data, getBearerToken());
    return orderUpdate;
};

export const pauseCampaign = async (data: { campaignId: string }) => {
    const orderUpdate = await axios.post(`/ziwo/pause`, data, getBearerToken());
    return orderUpdate;
};

export const deleteCustomerFromCampaign = async (phoneNumber: string) => {
    const orderUpdate = await axios.post(`/ziwo/deleteCustomer`, { phoneNumber }, getBearerToken());
    return orderUpdate;
};
