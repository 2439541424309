/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { MdOutlineCancel } from 'react-icons/md';
import { IAutoComplete } from './autoComplete.types';

/**
 * 
 * @param : this autocomplete take an array of objects as options for now
 * @example : 
 *   const areiba = [
    { id: 0, name: 'jka' },
    { id: 1, name: 'jazeka' },
];
 * 
 */

const autoComplete: React.FC<IAutoComplete> = ({
    onChange,
    options,
    getOptionLabel,
    placeholder,
    key,
    name,
    styles,
    defaultValue,
    onFocus,
    resetValue,
    disabled,
}) => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>(defaultValue);
    const [showOptions, setShowOptions] = useState(false);
    const [cursor, setCursor] = useState(-1);
    const [cancel, setCancel] = useState(false);

    const ref = useRef<any>();

    const select = (selectedValue: string) => {
        setSelectedOption(selectedValue);
        setShowOptions(false);
    };

    const handleChange = (text: string) => {
        setSelectedOption(text);
        if (text.trim().length !== 0) {
            setCancel(true);
        } else {
            setCancel(false);
            onChange({});
        }

        setCursor(-1);
        if (!showOptions) {
            setShowOptions(true);
        }
    };

    const filteredOptions = options.filter((option) =>
        getOptionLabel(option)
            .toLocaleLowerCase()
            .includes(selectedOption?.toLowerCase() || ''),
    );

    // const moveCursorDown = () => {
    //     if (cursor < filteredOptions.length - 1) {
    //         setCursor((c) => c + 1);
    //     }
    // };

    // const moveCursorUp = () => {
    //     if (cursor > 0) {
    //         setCursor((c) => c - 1);
    //     }
    // };

    // const handleNav = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //     // eslint-disable-next-line default-case
    //     switch (e.key) {
    //         case 'ArrowUp':
    //             moveCursorUp();
    //             break;
    //         case 'ArrowDown':
    //             moveCursorDown();
    //             break;
    //         case 'Enter':
    //             if (cursor >= 0 && cursor < filteredOptions.length) {
    //
    //                 // select(filteredOptions[cursor]);
    //             }
    //             break;
    //     }
    // };

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShowOptions(false);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);

    const getClassname = (i: number, arr: any[]) => {
        let className = 'px-4 hover:bg-gray-100 hover:dark:bg-[#333a47] ';

        if (i === 0 && arr.length === 1) {
            className += 'py-2 rounded-lg ';
        } else if (i === arr.length - 1) {
            className += 'pt-1 pb-2 rounded-b-lg ';
        } else if (i === 0) {
            className += ' pt-2 pb-1 rounded-t-lg ';
        } else {
            className += 'py-1';
        }

        if (cursor === i) {
            className += ' bg-gray-100 dark:bg-[#333a47]';
        }

        return className;
    };

    useEffect(() => {
        setSelectedOption('');
        setShowOptions(false);
        setCancel(false);
        setCursor(-1);
    }, []);

    useEffect(() => {
        if (defaultValue) setSelectedOption(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (resetValue) handleChange('');
    }, [resetValue]);

    useEffect(() => {
        if (!options?.length) setSelectedOption('');
    }, [options]);

    return (
        <>
            <div className="relative">
                <div className="relative">
                    <input
                        disabled={disabled}
                        style={styles}
                        name={name}
                        ref={ref}
                        value={selectedOption}
                        onChange={(e) => {
                            handleChange(e.target.value);
                        }}
                        onFocus={(e) => {
                            // eslint-disable-next-line no-unused-expressions
                            onFocus && onFocus({});
                            setShowOptions(false);
                            if (e.target.value.trim().length > 0) {
                                setCancel(true);
                            }
                        }}
                        autoComplete="off"
                        type="text"
                        id="autoComplete"
                        className="block w-full p-2.5 pl-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder={defaultValue || placeholder}
                        onClick={() => {
                            setShowOptions(!showOptions);
                        }}
                    />
                    {cancel && (
                        <button
                            type="submit"
                            onClick={() => {
                                setSelectedOption('');
                                setCancel(false);
                                onChange({});
                            }}
                            className="text-white absolute right-8 inset-y-2 px-1 hover:bg-tertiaryBgBtn rounded-3xl text-sm dark:focus:ring-blue-800"
                        >
                            <MdOutlineCancel className="h-5 w-5 text-black dark:text-white" />
                        </button>
                    )}

                    <div className="absolute inset-y-0 right-3 flex items-center pl-3 pointer-events-none">
                        {showOptions ? <AiFillCaretUp className="h-4 w-4" /> : <AiFillCaretDown className="h-4 w-4" />}
                    </div>

                    {/* Autocomplete dropdown */}
                </div>
                <ul
                    className={`absolute  w-full rounded-lg shadow-lg bg-background-primary max-h-52 overflow-auto z-[9999] ${
                        !showOptions && 'hidden'
                    } select-none`}
                >
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, i: number, arr: any) => {
                            return (
                                <li
                                    className={getClassname(i, arr)}
                                    key={option[key] || option?._id || i}
                                    onClick={() => {
                                        onChange(option);
                                        select(getOptionLabel(option));
                                    }}
                                >
                                    {getOptionLabel(option)}
                                </li>
                            );
                        })
                    ) : (
                        <li className="px-4 py-2 text-gray-500">No results</li>
                    )}
                </ul>
            </div>
        </>
    );
};

export default autoComplete;
