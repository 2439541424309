import React, { useEffect } from 'react';
import { TfiStatsUp } from 'react-icons/tfi';
import { GoBlocked } from 'react-icons/go';
import { getCreditStats } from 'services/accountManager/credits';
import toast from 'react-hot-toast';
import { FaBox } from 'react-icons/fa6';
import { ICountCreditStats, Ifilter } from './credits.types';

export const CreditStatistic: React.FC<{
    creditStats: ICountCreditStats | null;
    loadingStats: boolean;
    setCreditStats: any;
    setLoadingStats: any;
    filter: Ifilter;
}> = ({ loadingStats, creditStats, setCreditStats, filter, setLoadingStats }) => {
    useEffect(() => {
        if (filter) {
            setLoadingStats(true);
            getCreditStats(filter)
                .then((res) => {
                    setCreditStats(res.data);
                    setLoadingStats(false);
                })
                .catch((err) => {
                    toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {});
                    setLoadingStats(false);
                });
        }
    }, [filter]);

    return creditStats ? (
        <div className="flex center justify-between w-full gap-4 my-4">
            <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                    <GoBlocked className="w-6 h-6" />
                </div>
                <div>
                    <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">Total credit granted</p>
                    <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                        {loadingStats ? (
                            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                        ) : (
                            `${Math.abs(creditStats?.totalCreditGranted || 0).toFixed(2)} $`
                        )}
                    </p>
                </div>
            </div>

            <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                    <TfiStatsUp className="w-6 h-6" />
                </div>
                <div>
                    <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">Total amount reimbursed</p>
                    <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                        {loadingStats ? (
                            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                        ) : (
                            `${Math.abs(creditStats?.totalCreditReimbursed || 0).toFixed(2)} $`
                        )}
                    </p>
                </div>
            </div>

            <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                    <GoBlocked className="w-6 h-6" />
                </div>
                <div>
                    <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">Total credit balance</p>
                    <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                        {loadingStats ? (
                            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                        ) : (
                            `${Math.abs(creditStats?.totalRemainingAmount || 0).toFixed(2)} $`
                        )}
                    </p>
                </div>
            </div>
            <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                    <FaBox className="w-6 h-6" />
                </div>
                <div>
                    <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">In paymnet process</p>
                    <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                        {loadingStats ? (
                            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                        ) : (
                            creditStats?.inPaymentProcess || 0
                        )}
                    </p>
                </div>
            </div>
        </div>
    ) : null;
};
export default CreditStatistic;
