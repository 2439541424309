/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-fragments */
import CustomBtn from 'components/customButton/customBtn';
import CustomError from 'components/customError/CustomError';
import { Accordion, Card, Label, Modal, Select, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { countries } from 'utils/countryList';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { UpdatePricingPrepaidNoCC } from 'utils/validations/pricingConfiguration';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { accordContent, accordTitle } from 'pages/configurationAdmin/configuration.styles';
import { IPrepaidNoCCPricing, updateLogistioBulkFees } from 'services/admin/logistioBulkFees';
import { EditConfigLogistioBulkFee } from '../pricingConfiguration.types';

const CountriesAccordion: React.FC<EditConfigLogistioBulkFee> = ({ pricing, refetchGetFees, configId, setValues }) => {
    const [open, setOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(0);

    const updatePricing = (values: IPrepaidNoCCPricing, id: string, indexItem: number) => {
        const newValues = pricing;
        newValues.prepaidNoCC[indexItem] = values;
        setValues(newValues);
        toast.success(`${newValues.codWithCC[indexItem].country} prepaid values saved`);
    };

    const deletePricing = (index: number) => {
        const newValues = pricing;
        newValues?.prepaidNoCC.splice(index, 1);
        updateLogistioBulkFees(configId, { pricing: newValues })
            .then((res) => {
                toast.success(res.data.message);
                refetchGetFees();
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
            });
        setOpen(false);
    };

    return (
        <div className="p-2">
            {pricing.prepaidNoCC && (
                <>
                    <Modal show={open} size="md" className="z-[1400]" popup onClose={() => setOpen(false)}>
                        <Modal.Header />
                        <Modal.Body>
                            <div className="text-center">
                                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                <p className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this country pricing ?
                                    {pricing?.prepaidNoCC[deleteIndex]?.country}
                                </p>
                                <div className="flex justify-center gap-4">
                                    <CustomBtn variant="tertiary" onClick={() => setOpen(false)}>
                                        No, cancel
                                    </CustomBtn>
                                    <CustomBtn
                                        variant="failure"
                                        onClick={() => {
                                            deletePricing(deleteIndex);
                                        }}
                                    >
                                        Yes, am sure
                                    </CustomBtn>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Card
                        theme={{
                            root: {
                                base: 'flex rounded-lg border border-gray-200 bg-white  dark:border-gray-700 dark:bg-transparent',
                                children: 'flex h-full flex-col justify-center gap-4 p-2',
                            },
                        }}
                    >
                        <Accordion collapseAll alwaysOpen flush>
                            {pricing?.prepaidNoCC.map((accord) => (
                                <Accordion.Panel
                                    key={accord._id}
                                    className="!ring-0 focus:!ring-0 focus:!ring-transparent"
                                >
                                    <Accordion.Title as="h4" theme={accordTitle}>
                                        {accord.country}
                                    </Accordion.Title>
                                    <Accordion.Content theme={accordContent}>
                                        <Formik
                                            initialValues={{
                                                _id: accord._id,
                                                country: accord.country,
                                                shippedLeadPrice: accord.shippedLeadPrice,
                                                handleFees: accord.handleFees,
                                            }}
                                            onSubmit={(values, onSubmitProps) => {
                                                updatePricing(values, configId, pricing?.prepaidNoCC.indexOf(accord));
                                                onSubmitProps.setSubmitting(false);
                                            }}
                                            validationSchema={UpdatePricingPrepaidNoCC}
                                        >
                                            {(formik) => (
                                                <Form onSubmit={formik.handleSubmit} placeholder="">
                                                    <div>
                                                        <div>
                                                            <Card
                                                                theme={{
                                                                    root: {
                                                                        base: 'flex rounded-lg border border-gray-200 bg-white  dark:border-gray-700 dark:bg-transparent p-6  gap-x-4',
                                                                        children:
                                                                            'flex h-full flex-col justify-center gap-4 p-2',
                                                                    },
                                                                }}
                                                            >
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label htmlFor="country" value="Country" />
                                                                    </div>
                                                                    <Select
                                                                        id="countries"
                                                                        required
                                                                        {...formik.getFieldProps('country')}
                                                                        name="country"
                                                                        helperText={
                                                                            <React.Fragment>
                                                                                <CustomError
                                                                                    name="country"
                                                                                    component="div"
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        {countries.map((country) => (
                                                                            <option
                                                                                key={`${country.code}`}
                                                                                value={country.label}
                                                                            >
                                                                                {country.label}
                                                                            </option>
                                                                        ))}
                                                                    </Select>
                                                                </div>
                                                                <div className="grid md:grid-cols-2 gap-2">
                                                                    <div>
                                                                        <div className="mb-2 block">
                                                                            <Label
                                                                                htmlFor="price4"
                                                                                className="dark:text-white"
                                                                                color={
                                                                                    formik.errors.shippedLeadPrice &&
                                                                                    formik.touched.shippedLeadPrice
                                                                                        ? 'failure'
                                                                                        : ''
                                                                                }
                                                                                value="Shipping Fees"
                                                                            />
                                                                        </div>
                                                                        <TextInput
                                                                            {...formik.getFieldProps(
                                                                                'shippedLeadPrice',
                                                                            )}
                                                                            name="shippedLeadPrice"
                                                                            type="text"
                                                                            color={
                                                                                formik.errors.shippedLeadPrice &&
                                                                                formik.touched.shippedLeadPrice
                                                                                    ? 'failure'
                                                                                    : 'gray'
                                                                            }
                                                                            helperText={
                                                                                <React.Fragment>
                                                                                    <CustomError
                                                                                        name="shippedLeadPrice"
                                                                                        component="div"
                                                                                    />
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div className="mb-2 block">
                                                                            <Label
                                                                                htmlFor="handleFees"
                                                                                className="dark:text-white"
                                                                                color={
                                                                                    formik.touched.handleFees &&
                                                                                    formik.errors.handleFees
                                                                                        ? 'failure'
                                                                                        : ''
                                                                                }
                                                                                value="Handle Fees"
                                                                            />
                                                                        </div>
                                                                        <TextInput
                                                                            {...formik.getFieldProps('handleFees')}
                                                                            name="handleFees"
                                                                            // placeholder="jhondoe@email.com"
                                                                            color={
                                                                                formik.touched.handleFees &&
                                                                                formik.errors.handleFees
                                                                                    ? 'failure'
                                                                                    : 'gray'
                                                                            }
                                                                            type="text"
                                                                            helperText={
                                                                                <React.Fragment>
                                                                                    <CustomError
                                                                                        name="handleFees"
                                                                                        component="div"
                                                                                    />
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="flex justify-end gap-2">
                                                                    <CustomBtn
                                                                        variant="failure"
                                                                        pill
                                                                        onClick={() => {
                                                                            setOpen(true);
                                                                            setDeleteIndex(
                                                                                pricing?.prepaidNoCC.indexOf(accord),
                                                                            );
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <AiFillDelete className="mr-2 h-5 w-5" />
                                                                        Delete
                                                                    </CustomBtn>
                                                                    <CustomBtn
                                                                        variant="secondary"
                                                                        type="submit"
                                                                        pill
                                                                        disabled={
                                                                            !formik.isValid ||
                                                                            !formik.dirty ||
                                                                            formik.isSubmitting
                                                                        }
                                                                    >
                                                                        <AiFillEdit className="mr-2 h-5 w-5" /> Save
                                                                    </CustomBtn>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            ))}
                        </Accordion>
                    </Card>
                </>
            )}
        </div>
    );
};

export default CountriesAccordion;
