/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Tabs } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { getProduct } from 'services/admin/resellerProduct';
import { ResellerProduct } from '../reseller.types';
import ConfigurationForm from './helpers/configurationForm';
import QuotationForm from './helpers/quotationForm';
import { IAddResellerProduct } from './helpers/types';
import { intialResellerProduct } from './productsElements';

const ResellerProductDetails: React.FC = () => {
    const formRef = useRef<FormikProps<IAddResellerProduct> | null>(null);
    const [resellerProduct, setResellerProduct] = useState<ResellerProduct>();
    const location = useLocation();

    useEffect(() => {
        getProduct((location.state as { productId: string }).productId).then((result) => {
            setResellerProduct(result.data);
            const {
                name,
                adCopies,
                chineseName,
                variant,
                quantity,
                weight,
                width,
                height,
                length,
                declarationAmount,
                quotation,
                openForSale,
                canBeOrdered,
                notificationType,
                categories,
                landingPages,
                description,
            } = result.data;
            const resellerQuotation = (quotation || []).map((el) => {
                return {
                    ...el,
                    trackingCode: '',
                };
            });
            const data: IAddResellerProduct = {
                name,
                variant: variant || '',
                adCopies: adCopies || [''],
                chineseName,
                quantity,
                weight,
                width,
                height,
                length,
                declarationAmount,
                openForSale,
                canBeOrdered,
                notificationType: notificationType || [],
                categories,
                landingPages: landingPages || [],
                description: description || '',
                resellerQuotation,
            };
            formRef.current?.setValues(data);
        });
    }, []);

    return (
        <div className="bg-white h-[90vh] dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden w-full p-6">
            <div className="flex mb-5">
                <span className="font-medium text-2xl">Product Details</span>
            </div>
            {resellerProduct && (
                <>
                    <div className="flex mb-1">
                        <span className="font-medium text-xl pr-2">{resellerProduct?.name}</span>
                        {resellerProduct?.variant && (
                            <span className="font-medium text-xl"> - {resellerProduct.variant}</span>
                        )}
                        <span className="font-medium text-lg ml-2	">
                            <span className="flex gap-1 greenTag !text-green-400 h-[30px] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full  !dark:text-green-300 items-center">
                                <FaCircle size="8px" />
                                purchased
                            </span>
                        </span>
                    </div>
                    <div className="flex mb-5">
                        <span className="font-light text-sm text-gray-400">
                            Created at:{moment(resellerProduct.createdAt).format('DD/MM/YYYY')}
                        </span>
                    </div>
                </>
            )}
            <div>
                <Formik initialValues={intialResellerProduct} onSubmit={() => {}} enableReinitialize innerRef={formRef}>
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit} placeholder="">
                            <div>
                                <Tabs
                                    aria-label="Tabs with icons"
                                    // eslint-disable-next-line react/style-prop-object
                                    theme={{
                                        tablist: {
                                            tabitem: {
                                                base: 'flex items-center text-blue-500 justify-center px-4 h-14 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                                styles: {
                                                    default: {
                                                        base: 'flex items-center text-blue-500 justify-center px-4 h-14  text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                                        active: {
                                                            on: 'text-main border-main border-b-2',
                                                            off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <Tabs.Item
                                        title={<div className="h-full py-4">Quotation</div>}
                                        key="Quotation"
                                        style={{ height: '64px !important', padding: '16px 24px' }}
                                        className="!py-0 xs:!h-14"
                                    >
                                        <QuotationForm formik={formik} />
                                    </Tabs.Item>
                                    <Tabs.Item
                                        title={<div className="h-full py-4">Configuration</div>}
                                        key="Configuration"
                                        style={{ height: '64px !important', padding: '16px 24px' }}
                                        className="!py-0 xs:!h-14"
                                    >
                                        <ConfigurationForm formik={formik} />
                                    </Tabs.Item>
                                </Tabs>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
export default ResellerProductDetails;
