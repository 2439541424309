/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { TextInput } from 'flowbite-react';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import FormError from 'components/customError/formError';
import { IAddResellerProduct, QuotationFormProps } from './types';
import ErrorMsg from './formError';

const ConfigurationForm: React.FC<QuotationFormProps> = (props) => {
    const { formik, edit, isRefill, error } = props;

    const {
        values: { resellerQuotation },
        setFieldValue,
    } = useFormikContext<IAddResellerProduct>();

    useEffect(() => {
        const totalQty = (resellerQuotation || []).reduce((acc, cur) => {
            return acc + (!Number(cur?.quantity) ? 0 : Number(cur?.quantity));
        }, 0);
        setFieldValue('quantity', totalQty);
    }, [resellerQuotation]);

    return (
        <div className="m-4">
            <div className=" leading-7 text-lg font-medium h-8 mb-4">Configuration</div>
            <div className="mb-4">
                {' '}
                <div className={` grid  grid-cols-8 bg-gray-100 dark:bg-gray-600 items-center p-2 mb-1`}>
                    <div className="col-span-2">
                        Chinese name <span className="text-red-600 "> *</span>
                    </div>
                    <div>Total Qty</div>
                    <div>
                        Unit.Weight(kg)<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Unit.Height(CM)<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Unit.Width (CM)<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Unit lengh (CM)<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Unit.Declaration amount ($)<span className="text-red-600 "> *</span>
                    </div>
                </div>
                <div className={` grid  grid-cols-8 items-center pt-2 h-16`}>
                    <div className="col-span-2 h-12	pr-2">
                        <TextInput
                            {...formik.getFieldProps(`chineseName`)}
                            name="chineseName"
                            type="text"
                            sizing="m"
                            placeholder="Write the name"
                            disabled={!edit || isRefill}
                        />{' '}
                        <span className="pl-2">
                            <FormError name="chineseName" component="div" />
                        </span>
                    </div>
                    <div className=" h-12	pr-2">
                        <TextInput
                            {...formik.getFieldProps(`quantity`)}
                            name="quantity"
                            type="text"
                            sizing="m"
                            disabled
                            theme={{
                                field: {
                                    input: {
                                        base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-80 disabled:decoration-solid !bg-gray-200 dark:!bg-gray-700 ',
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className=" h-12	pr-2">
                        <TextInput
                            {...formik.getFieldProps(`weight`)}
                            name="weight"
                            type="text"
                            sizing="m"
                            disabled={!edit || isRefill}
                        />
                        <span className="pl-2">
                            <ErrorMsg name="weight" formik={formik} error={error} />{' '}
                        </span>
                    </div>
                    <div className=" h-12	pr-2">
                        <TextInput
                            {...formik.getFieldProps(`height`)}
                            name="height"
                            type="text"
                            sizing="m"
                            disabled={!edit || isRefill}
                        />
                        <span className="pl-2">
                            <ErrorMsg name="height" formik={formik} error={error} />{' '}
                        </span>
                    </div>
                    <div className=" h-12	pr-2">
                        <TextInput
                            {...formik.getFieldProps(`width`)}
                            name="width"
                            type="text"
                            sizing="m"
                            disabled={!edit || isRefill}
                        />
                        <span className="pl-2">
                            <ErrorMsg name="width" formik={formik} error={error} />{' '}
                        </span>
                    </div>
                    <div className=" h-12	pr-2">
                        <TextInput
                            {...formik.getFieldProps(`length`)}
                            name="length"
                            type="text"
                            sizing="m"
                            disabled={!edit || isRefill}
                        />
                        <span className="pl-2">
                            <ErrorMsg name="length" formik={formik} error={error} />{' '}
                        </span>
                    </div>
                    <div className=" h-12	pr-2">
                        <TextInput
                            {...formik.getFieldProps(`declarationAmount`)}
                            name="declarationAmount"
                            type="text"
                            sizing="m"
                            disabled={!edit}
                        />
                        <span className="pl-2">
                            <ErrorMsg name="declarationAmount" formik={formik} error={error} />{' '}
                        </span>
                    </div>
                </div>
            </div>{' '}
        </div>
    );
};
export default ConfigurationForm;
