/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import CallAgentSideBar from 'components/sideBar/callAgentSideBar';
import CustomNavbar from 'components/navbar/customNavbar';
import Ziwo from 'components/ziwo/ziwo';
import useZiwoEvents from 'utils/hooks/useZiwoEvents';
import { useActiveCallOrders } from 'utils/zustand/ziwo';
import { getOrder, IOrder } from 'services/admin/order';
import toast from 'react-hot-toast';
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import OrderDetails from 'pages/CallAgentsPages/orders/orderDetails';
import { IoCloseSharp } from 'react-icons/io5';
import ScheduledCallNotification from 'components/scheduledCallNotification/scheduledCallNotification';
import CustomBtn from 'components/customButton/customBtn';

const CallAgentLayout: React.FC = () => {
    const activeOrdersCalls = useActiveCallOrders();
    const [collapsed, setCollapsed] = useState(false);
    const [inCallOrders, setInCallOrders] = useState<IOrder[] | null>(null);
    const [activeOrder, setActiveOrder] = useState<IOrder | null>(null);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);

    const navigate = useNavigate();

    useZiwoEvents();
    useEffect(() => {
        if (getCurrentUser().role !== 'callAgent') {
            navigate(`/${getCurrentUser().role}`);
        }
    }, []);

    const fetchIncallOrders = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const calls = Array.from(activeOrdersCalls).map(([_callID, call]) => call);
        const call = calls.find((c) => c.user === getCurrentUser()._id);
        if (call && call.orders) {
            const orders: IOrder[] = [];
            const promises = (call.orders as string[]).map((orderID) => getOrder(orderID));
            Promise.allSettled(promises)
                .then((results) => {
                    results.forEach((res) => {
                        if (res.status === 'fulfilled') {
                            orders.push(res.value.data);
                        }
                    });
                })
                .finally(() => {
                    if (orders.length) {
                        setInCallOrders(orders);
                        setActiveOrder(orders[0]);
                        setOpenDetailsModal(true);
                    } else {
                        toast.error('Unable to open order details');
                    }
                });
        }
    }, [activeOrdersCalls]);

    useEffect(() => {
        fetchIncallOrders();
    }, [activeOrdersCalls]);

    return (
        <div>
            <div className="absolute top-0 bottom-0 left-0 w-56 ">
                <div className="h-screen fixed left-0 top-0 dark:bg-[#1f2937] ">
                    <CallAgentSideBar collapsed={collapsed} />
                </div>
            </div>

            <div
                className={`absolute top-0 bottom-0 right-0 ${
                    collapsed ? 'left-16' : 'left-56'
                } bg-background-secondary`}
            >
                <div>
                    <CustomNavbar collapsed={collapsed} setCollapsed={setCollapsed} />
                </div>

                <div className=" bg-background-secondary  px-2.5 pt-2.5">
                    <Outlet context={collapsed} />
                    <GeneralDrawer
                        anchor="right"
                        open={openDetailsModal}
                        onClose={setOpenDetailsModal}
                        PaperProps={{
                            sx: { width: '75%' },
                        }}
                    >
                        <div className="flex items-start">
                            {/* navigations */}
                            <div className="min-w-fit flex flex-col gap-3 p-3">
                                <p className="text-lg font-medium">Navigation</p>
                                {(inCallOrders || []).length > 1 ? (
                                    <div className="min-w-fit flex flex-col gap-3 p-3">
                                        <p className="text-lg font-medium">Navigation</p>
                                        {inCallOrders &&
                                            inCallOrders.map((order, idx) => (
                                                <CustomBtn
                                                    key={order._id}
                                                    variant="primary"
                                                    className={`w-full ${
                                                        activeOrder && activeOrder._id === order._id
                                                            ? '!bg-green-600'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        setActiveOrder(order);
                                                    }}
                                                >
                                                    {order.orderRef || `order ${idx}`}
                                                </CustomBtn>
                                            ))}
                                    </div>
                                ) : null}
                            </div>
                            {/* selected order details */}
                            <div className="p-2 flex-1">
                                <div className="flex justify-between items-center">
                                    <div>
                                        <p className="text-lg font-medium">Order Configuration</p>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => setOpenDetailsModal(false)}
                                        className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                                    >
                                        <IoCloseSharp className="w-5 h-5" />
                                    </button>
                                </div>
                                {activeOrder && <OrderDetails data={activeOrder} />}
                            </div>
                        </div>
                    </GeneralDrawer>
                </div>
            </div>
            <div className="fixed bottom-5 left-5 z-[1500]">
                <Ziwo />
            </div>
            <ScheduledCallNotification />
        </div>
    );
};

export default CallAgentLayout;
