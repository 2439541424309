/* eslint-disable no-underscore-dangle */
import DataTable from 'components/tables/tables';
import { TableColDef } from 'components/tables/tables.types';
import { format } from 'date-fns';
import React, { useState } from 'react';

import { IoEye } from 'react-icons/io5';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { Button } from 'flowbite-react';
import {
    getLogistioBulkFeesList,
    ICodNoCCPricing,
    ICodPricingItem,
    ILogistioBulkFees,
    IPrepaidNoCCPricing,
} from 'services/admin/logistioBulkFees';
import LogistioBulkFeesModal from './logistioBulkFeesModal';
import CustomersList from './customersList';
import PricingConfiguration from '../pricingConfiguration/PricingConfiguaration';

interface LogistioBulkFeesProps {
    logistioBulkFees: ILogistioBulkFees[];
    setLogistioBulkFees: any;
    isLoading: boolean;
}
export interface ILogisioBulkModals {
    modal: boolean;
    userDetails: boolean;
    feeDetails: boolean;
}

export interface IPricingConfigurationBulk {
    codWithCC: Array<ICodPricingItem>;
    codNoCC: Array<ICodNoCCPricing>;
    prepaidNoCC: Array<IPrepaidNoCCPricing>;
}

interface IConfigPricing {
    pricing: IPricingConfigurationBulk;
}

type ActionType = 'ADD' | 'UPDATE' | 'DELETE' | 'ASSIGN' | 'ASSIGN_UPDATE' | '';

const LogistioBulkFees = ({ logistioBulkFees, setLogistioBulkFees, isLoading }: LogistioBulkFeesProps) => {
    const [open, setOpen] = useState<ILogisioBulkModals>({
        modal: false,
        userDetails: false,
        feeDetails: false,
    });
    const [action, setAction] = useState<ActionType>('');
    const [selectedFee, setSelectedFee] = useState<ILogistioBulkFees | any>({});
    const [configPricing, setConfigPricing] = useState<IConfigPricing['pricing']>({
        codNoCC: [],
        codWithCC: [],
        prepaidNoCC: [],
    });

    const handleDuplicateFee = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setConfigPricing(fee.pricing);
        setAction('ADD');
        setOpen({ modal: false, userDetails: false, feeDetails: true });
    };
    const handleFeeDetails = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setConfigPricing(fee.pricing);
        setAction('UPDATE');
        setOpen({ modal: false, userDetails: false, feeDetails: true });
    };

    const handleAssignCustumers = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setAction('ASSIGN');
        setOpen({ modal: true, userDetails: false, feeDetails: false });
    };

    const refetchGetFees = () => {
        getLogistioBulkFeesList().then((res) => {
            setLogistioBulkFees(res?.data || []);
            if (action === 'ADD') {
                setOpen({ modal: true, userDetails: false, feeDetails: false });
                setAction('ASSIGN_UPDATE');
            }
        });
        setAction(action === 'ASSIGN_UPDATE' ? 'UPDATE' : action);
    };

    const handleDeleteFee = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setAction('DELETE');
        setOpen({ modal: true, userDetails: false, feeDetails: false });
    };

    const handleGetListOfCustomers = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setOpen({ modal: false, userDetails: true, feeDetails: false });
    };

    const logistioBulkFeesColumn: TableColDef<ILogistioBulkFees>[] = [
        {
            field: 'name',
            headerName: 'Name',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: ILogistioBulkFees) => (
                <div className="flex items-center gap-4">
                    <p>{`${params?.name || ''} `}</p>
                    {params.isDefault && (
                        <Button size="s" color="blue" disabled>
                            <p className="text-xs">Default</p>
                        </Button>
                    )}
                </div>
            ),
        },

        {
            field: 'updatedAt',
            headerName: 'Updated At',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: ILogistioBulkFees) => (
                <p>{`${params?.updatedAt ? format(new Date(params?.updatedAt), 'dd/MM/yyyy') : '-'} `}</p>
            ),
        },

        {
            field: 'customersCount',
            headerName: 'Sellers',
            fieldType: 'render',
            renderCell: (params: ILogistioBulkFees) => {
                return (
                    <div className="flex items-center gap-2">
                        <Button
                            title={
                                params.customersCount
                                    ? 'List of sellers'
                                    : 'This offer has not yet been assigned to a seller.'
                            }
                            disabled={!params?.customersCount}
                            onClick={() => handleGetListOfCustomers(params)}
                            color="light"
                        >
                            <p>{`${params?.customersCount || 0}`}</p>
                        </Button>
                    </div>
                );
            },
        },

        {
            field: 'Action',
            headerName: 'Actions',
            actionType: 'LogistioBulkFeesActions',
            fieldType: 'render',
            classField: 'w-[10px] min-w-[10px]',
            classHeader: 'w-[10px] min-w-[10px] ',
            renderCell: (params: ILogistioBulkFees) => (
                <div className="flex gap-0.5 pr-2">
                    <Button title="Assign new sellers" onClick={() => handleAssignCustumers(params)} color="white">
                        <FaPlus className="w-3 h-3" />
                    </Button>
                    {params.isDefault && (
                        <div>
                            <Button
                                title="Create new fee from this version"
                                color="white"
                                onClick={() => handleDuplicateFee(params)}
                            >
                                <HiOutlineDuplicate className="w-5 h-5 " />
                            </Button>
                        </div>
                    )}
                    {!params.isDefault && (
                        <div>
                            <Button title="Delete fee" color="white" onClick={() => handleDeleteFee(params)}>
                                <FaTrash className="w-4 h-4 text-red-500" />
                            </Button>
                        </div>
                    )}
                    <div>
                        <Button title="Display fee details" color="white" onClick={() => handleFeeDetails(params)}>
                            <IoEye className="w-5 h-5 " />
                        </Button>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <DataTable
                rows={logistioBulkFees}
                setRows={setLogistioBulkFees}
                columns={logistioBulkFeesColumn}
                pageState={{
                    isLoading,
                    total: 0,
                    pageSize: 0,
                    page: 0,
                    count: 0,
                }}
                setPageState={() => {}}
            />
            {open.modal && (
                <LogistioBulkFeesModal
                    key="modal"
                    fee={selectedFee}
                    action={action}
                    setAction={setAction}
                    open={open}
                    setOpen={setOpen}
                    refetchGetFees={refetchGetFees}
                />
            )}
            {open.userDetails && (
                <CustomersList
                    handleAssignCustumers={handleAssignCustumers}
                    key="customersList"
                    fee={selectedFee}
                    openDetails={open.userDetails}
                    setOpenDetails={setOpen}
                />
            )}

            {open.feeDetails && (
                <PricingConfiguration
                    key={selectedFee._id}
                    open={open.feeDetails}
                    fee={selectedFee}
                    setOpen={setOpen}
                    pricing={configPricing}
                    configId={selectedFee._id}
                    refetchGetFees={refetchGetFees}
                    action={action}
                    setConfigId={setSelectedFee}
                    setAction={setAction}
                />
            )}
        </>
    );
};

export default LogistioBulkFees;
