/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useZiwo } from 'utils/zustand/ziwo';
import { isAuthenticated } from './auth';

const ProtectedRoute = () => {
    const { pathname } = useLocation();
    const isAuth = isAuthenticated();
    const z = useZiwo();
    if (!isAuth) {
        if (z) {
            z.session.logout();
        }
        localStorage.removeItem('ZIWO_LOGIN_DETAILS');
        localStorage.removeItem('ZIWO_SELECTED_STATUS');
        localStorage.removeItem('ZIWO_SESSION_DETAILS');
    }

    return isAuth ? <Outlet /> : <Navigate to="/auth/login" state={{ from: pathname }} replace />;
};

export default ProtectedRoute;
