/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Badge } from 'flowbite-react';
import moment from 'moment';
import { ICallAgent } from 'pages/callAgentList/callAgent.types';
import React from 'react';
import { handleCopyTC } from 'utils/helpers/copyTC';
import { useActiveCallOrders } from 'utils/zustand/ziwo';
import { IOrder } from '../../services/admin/order';
import { orderStatus } from './orders.styles';
import CallCenterComment from './ordersDetails/callCenterComment';
// import ClientInfos from './ordersDetails/clientInfos';
import ProductInfo from './ordersDetails/productInfo';

export const columns: TableColDef<IOrder>[] = [
    {
        field: 'orderRef',
        headerName: 'Order Ref',
        fieldType: 'render',
        renderCell: (params) => <p>{`${params.orderRef ? params.orderRef : '-'}`}</p>,
    },
    // {
    //     field: 'ClientInfo',
    //     headerName: 'Client Info',
    //     fieldType: 'comboFields',
    //     child: ['firstName', 'lastName', 'phone', 'address1', 'address2'],
    // },
    {
        field: 'SellerName',
        headerName: 'Seller Name',
        fieldType: 'render',
        renderCell: (params: IOrder) => {
            let initials = '';
            let fullName = '';

            if (typeof params.customer !== 'string' && params.customer) {
                const firstNameInitial = params.customer.firstName
                    ? params.customer.firstName.charAt(0).toUpperCase()
                    : '';
                const lastNameInitial = params.customer.lastName
                    ? params.customer.lastName.charAt(0).toUpperCase()
                    : '';
                initials = `${firstNameInitial}${lastNameInitial}`;
                fullName = `${params.customer.firstName} ${params.customer.lastName}`;
            }

            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        backgroundColor: '#f0f2f5',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4B5563',
                        cursor: 'pointer',
                    }}
                    title={`Copy "${fullName}" to clipboard`}
                    onClick={() => handleCopyTC(fullName, 'Customer Name')}
                >
                    {initials}
                </div>
            );
        },
    },

    {
        field: 'ProductInfo',
        headerName: 'Product Info',
        fieldType: 'render',
        renderCell: (params: IOrder) => (
            <>
                <ProductInfo data={params} />
            </>
        ),
    },

    {
        field: 'country',
        headerName: 'Country',
    },

    {
        field: 'totalPrice',
        headerName: 'Total Price',
        fieldType: 'render',
        renderCell: (params: IOrder) => {
            if (params.prePaid === false && params.cod === true) {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        {params.currency ? params.currency : ''} {params.totalPrice}
                    </div>
                );
            }
            if (params.prePaid === true && params.cod === false) {
                return (
                    <div style={{ width: 'fit-content' }}>
                        <Badge color="success">Prepaid</Badge>
                    </div>
                );
            }
            return (
                <div style={{ width: 'fit-content' }}>
                    <p>-</p>
                </div>
            );
        },
    },
    {
        field: 'callCenter',
        headerName: 'Call Center',
        fieldType: 'render',
        renderCell: (params) => <p>{params.callCenter ? params.callCenter.name : '-'}</p>,
    },
    {
        field: 'trackingCode',
        headerName: 'Tracking Code',
        fieldType: 'render',
        renderCell: (params: IOrder) => <p>{`${params.trackingCode ? params.trackingCode : '-'}`}</p>,
    },

    {
        field: 'lgstTrackingCode',
        headerName: 'Logistio Code',
        fieldType: 'render',
        renderCell: (params: IOrder) => <p>{`${params.lgstTrackingCode ? params.lgstTrackingCode : '-'}`}</p>,
    },

    {
        field: 'callAgent',
        headerName: 'Call agent',
        fieldType: 'render',
        renderCell: (params) => {
            const callAgent = params.callAgent as ICallAgent;
            const { withCC } = params;
            if (withCC !== false) {
                return (
                    <p
                        title={
                            callAgent && (callAgent?.firstName || callAgent?.lastName)
                                ? `${callAgent?.firstName || '...'} ${callAgent?.lastName || '...'}`
                                : 'No call agent assigned yet!'
                        }
                    >{`${
                        callAgent && (callAgent?.firstName || callAgent?.lastName)
                            ? `${callAgent?.firstName || '...'} ${callAgent?.lastName || '...'}`
                            : '-'
                    }`}</p>
                );
            }
            return (
                <div style={{ width: 'fit-content' }}>
                    <Badge color="gray" title="Call center is disabled for the store">
                        disabled
                    </Badge>
                </div>
            );
        },
    },
    {
        field: 'callCenterAttempt',
        headerName: 'Call center attempts',
        fieldType: 'render',
        renderCell: (params: IOrder) => {
            if (params.withCC !== false) {
                return (
                    <>
                        <CallCenterComment data={params} />
                    </>
                );
            }
            return (
                <div style={{ width: 'fit-content' }}>
                    <Badge color="gray" title="Call center is disabled for the store">
                        disabled
                    </Badge>
                </div>
            );
        },
    },

    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'render',
        renderCell: (params) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{ width: 'fit-content' }}>
                    <div className="flex gap-2 items-center">
                        <Badge color={orderStatus(params.status).color} className="w-max max-w-[150px]">
                            {orderStatus(params.status).title}
                        </Badge>
                        {params.status === 41 && <span className="flex w-2 h-2 me-3 bg-red-600 rounded-full" />}
                    </div>
                </div>
                {params.statusDescription && (
                    <p>
                        {params.statusDescription.substring(0, 20) +
                            (params.statusDescription.substring(20).length > 0 ? '...' : '')}
                    </p>
                )}
            </div>
        ),
    },

    {
        field: 'date',
        headerName: 'DATE',
        // fieldType: 'date',
        fieldType: 'render',
        renderCell: (params) => {
            return (
                <div className="flex-col gap-1 text-xs w-max">
                    <p>
                        <span className="opacity-70">created at: </span>
                        {moment(
                            new Date(params.createdAt).toLocaleString('en-US', {
                                timeZone: 'Asia/Shanghai',
                            }),
                        ).format('MMMM Do YYYY, HH:mm')}
                    </p>
                    <p>
                        <span className="opacity-70">updated at: </span>
                        {moment(
                            new Date(params.updatedAt).toLocaleString('en-US', {
                                timeZone: 'Asia/Shanghai',
                            }),
                        ).format('MMMM Do YYYY, HH:mm')}
                    </p>
                </div>
            );
        },
    },

    {
        field: 'Action',
        headerName: 'Actions',
        actionType: 'OrdersForAdminAndAccountManager',
    },
];

export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="info" size="sm">
                New
            </Badge>
        ),
        statusLabel: 'New',
    },
    2: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Canceled
            </Badge>
        ),
        statusLabel: 'Canceled',
    },
    21: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Wrong number
            </Badge>
        ),
        statusLabel: 'Wrong number',
    },
    3: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Unreachable
            </Badge>
        ),
        statusLabel: 'Unreachable',
    },
    4: {
        statusIcon: (
            <Badge color="success" size="sm">
                Confirmed
            </Badge>
        ),
        statusLabel: 'Confirmed',
    },
    41: {
        statusIcon: (
            <Badge color="info" size="sm">
                Pending answer
            </Badge>
        ),
        statusLabel: 'Pending answer',
    },
    42: {
        statusIcon: (
            <Badge color="success" size="sm">
                Question answered
            </Badge>
        ),
        statusLabel: 'Question answered',
    },
    43: {
        statusIcon: (
            <Badge color="info" size="sm">
                Scheduled call
            </Badge>
        ),
        statusLabel: 'Scheduled call',
    },
    5: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Waiting for shipment
            </Badge>
        ),
        statusLabel: 'Waiting for shipment',
    },
    6: {
        statusIcon: (
            <Badge color="info" size="sm">
                Out for shipment
            </Badge>
        ),
        statusLabel: 'Out for shipment',
    },
    7: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Shipped
            </Badge>
        ),
        statusLabel: 'Shipped',
    },
    8: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pick and pack
            </Badge>
        ),
        statusLabel: 'Pick and pack',
    },
    81: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order received by carrier
            </Badge>
        ),
        statusLabel: 'Order received by carrier',
    },
    82: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Arrive to headquarters warehouse
            </Badge>
        ),
        statusLabel: 'Arrive to headquarters warehouse',
    },
    83: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order transferred
            </Badge>
        ),
        statusLabel: 'Order transferred',
    },
    84: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Arrival at Hub
            </Badge>
        ),
        statusLabel: 'Arrival at Hub',
    },
    9: {
        statusIcon: (
            <Badge color="info" size="sm">
                Out for delivery
            </Badge>
        ),
        statusLabel: 'Out for delivery',
    },
    10: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Delivery attempted
            </Badge>
        ),
        statusLabel: 'Delivery attempted',
    },
    101: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order returned to Hub
            </Badge>
        ),
        statusLabel: 'Order returned to Hub',
    },
    102: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order returned to Warehouse
            </Badge>
        ),
        statusLabel: 'Order returned to Warehouse',
    },
    103: {
        statusIcon: (
            <Badge color="warning" size="sm">
                New delivery request
            </Badge>
        ),
        statusLabel: 'New delivery request',
    },
    104: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Wait to WMS
            </Badge>
        ),
        statusLabel: 'Wait to WMS',
    },
    105: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Delivery scheduled
            </Badge>
        ),
        statusLabel: 'Wait to WMS',
    },
    11: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Delivery failed
            </Badge>
        ),
        statusLabel: 'Delivery failed',
    },
    111: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Return to origin - Out
            </Badge>
        ),
        statusLabel: 'Return to origin - Out',
    },
    112: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Return to origin - In
            </Badge>
        ),
        statusLabel: 'Return to origin - In',
    },
    113: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Transport management system to Warehouse
            </Badge>
        ),
        statusLabel: 'Transport management system to Warehouse',
    },
    114: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Back to stock
            </Badge>
        ),
        statusLabel: 'Back to stock',
    },
    12: {
        statusIcon: (
            <Badge color="success" size="sm">
                Delivered
            </Badge>
        ),
        statusLabel: 'Delivered',
    },
    13: {
        statusIcon: (
            <Badge color="pink" size="sm">
                Expired
            </Badge>
        ),
        statusLabel: 'Expired',
    },
    14: {
        statusIcon: (
            <Badge color="red" size="sm">
                Invalid
            </Badge>
        ),
        statusLabel: 'Expired',
    },
    15: {
        statusIcon: (
            <Badge color="red" size="sm">
                Pending payment
            </Badge>
        ),
        statusLabel: 'Pending payment',
    },
    99: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Check required
            </Badge>
        ),
        statusLabel: 'Check required',
    },
    100: {
        statusIcon: (
            <Badge color="red" size="sm">
                Hold - No stock available
            </Badge>
        ),
        statusLabel: 'Hold - No stock available',
    },
    115: {
        statusIcon: (
            <Badge color="red" size="sm">
                Duplicated
            </Badge>
        ),
        statusLabel: 'Duplicated',
    },
};
