/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Dropdown } from 'flowbite-react';
import { IInvoiceActions } from 'pages/invoices/invoice.types';
import React from 'react';
import toast from 'react-hot-toast';
import { HiDotsVertical, HiPencil } from 'react-icons/hi';
import { countInvoicesStats, getInvoiceList, updateInvoice } from 'services/admin/invoice';
import PrintInvoice from './invoicePrint';
import VisualizeInvoice from './visualizeInvoice';

const InvoiceActions = (props: IInvoiceActions) => {
    const { invoice, setRows, currentPage, currentFilter, setStats } = props;
    const updateInvoiceStatus = (status: Number) => {
        updateInvoice(invoice._id, { status })
            .then(() => {
                toast.success('Updated successfuly');
            })
            .then(() => {
                getInvoiceList({ page: currentPage, ...currentFilter })
                    .then((res) => {
                        setRows(res.data.docs);
                    })
                    .catch(() => {
                        toast.error(`Something went wrong, please try again`);
                    });

                countInvoicesStats(currentFilter)
                    .then((res) => {
                        setStats(res.data);
                    })
                    .catch((err) => {
                        toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {});
                    });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    };
    const optionsList = [
        {
            label: 'Set to Paid',
            action: () => updateInvoiceStatus(2),
            iconAcion: HiPencil,
        },
    ];
    return (
        <div className="flex items-center gap-4">
            <div>
                <VisualizeInvoice invoice={invoice} />
            </div>
            <div>
                <PrintInvoice invoice={invoice} />
            </div>

            {invoice.status === 1 && (
                <Dropdown
                    inline
                    arrowIcon={false}
                    label={<HiDotsVertical />}
                    theme={{
                        arrowIcon: 'ml-2 h-4 w-4',
                        content: 'py-1 focus:outline-none',
                        floating: {
                            animation: 'transition-opacity',
                            arrow: {
                                base: 'absolute z-10 h-2 w-2 rotate-45',
                                style: {
                                    dark: 'bg-gray-900 dark:bg-gray-700',
                                    light: 'bg-white',
                                    auto: 'bg-white dark:bg-gray-700',
                                },
                                placement: '-4px',
                            },
                            base: 'z-10 w-fit divide-y divide-gray-100 rounded shadow focus:outline-none',
                            content: 'py-1 text-sm text-gray-700 dark:text-gray-200',
                            divider: 'my-1 h-px bg-gray-100 dark:bg-gray-600',
                            header: 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200',
                            hidden: 'invisible opacity-0',
                            item: {
                                container: '',
                                base: 'flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white disabled:cursor-not-allowed',
                                icon: 'mr-2 h-4 w-4',
                            },
                            style: {
                                dark: 'bg-gray-900 text-white dark:bg-gray-700',
                                light: 'border border-gray-200 bg-white text-gray-900',
                                auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
                            },
                            target: 'w-fit',
                        },
                        inlineWrapper: 'flex items-center',
                    }}
                >
                    {optionsList.map((action) => {
                        return (
                            <Dropdown.Item
                                icon={action.iconAcion}
                                onClick={action.action}
                                key={action.label}
                                disabled={invoice.status !== 1}
                            >
                                {`${action.label}`}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown>
            )}
        </div>
    );
};

export default InvoiceActions;
