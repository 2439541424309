import { Button } from 'flowbite-react';
import React from 'react';
import { IOrder } from 'services/admin/order';
import { IResellerStock } from 'services/admin/resellerStock';
import OrdersList from './outsideOrders';

export interface IOrderActions {
    stock: IResellerStock;
}
const OrdersResellerStockActions = ({ stock }: IOrderActions) => {
    const [open, setOpen] = React.useState(false);

    if (!stock) return <></>;

    return (
        <>
            {open && (
                <OrdersList
                    orders={(stock as any).relatedOrders as IOrder[]}
                    open={open}
                    setOpen={setOpen}
                    key="customersList"
                />
            )}
            <Button
                title={stock?.outsideQuantity ? 'List of Orders' : 'Outside Quantity still 0.'}
                disabled={!stock?.outsideQuantity}
                onClick={() => setOpen(true)}
                color="light"
            >
                <p>{stock?.outsideQuantity}</p>
            </Button>
        </>
    );
};
export default OrdersResellerStockActions;
