export interface IOrderStatus {
    id: number;
    label: string;
    type: string;
}

export const orderStatusListForCA: IOrderStatus[] = [
    { id: 1, label: 'New', type: 'Processing' },
    { id: 3, label: 'Unreachable', type: 'Processing' },
    { id: 43, label: 'Scheduled call', type: 'Processing' },
    { id: 41, label: 'Pending answer', type: 'Processing' },
    { id: 42, label: 'Question answered', type: 'Processing' },
    { id: 8, label: 'Pick and pack', type: 'Shipped' },
    { id: 81, label: 'Order received by carrier', type: 'Shipped' },
    { id: 82, label: 'Arrive to headquarters warehouse', type: 'Shipped' },
    { id: 83, label: 'Order transferred', type: 'Shipped' },
    { id: 84, label: 'Arrival at Hub', type: 'Shipped' },
    { id: 9, label: 'Out for delivery', type: 'Shipped' },
    { id: 10, label: 'Delivery attempted', type: 'Shipped' },
    { id: 105, label: 'Delivery scheduled', type: 'Shipped' },
    { id: 103, label: 'New delivery request', type: 'Shipped' },
    { id: 11, label: 'Delivery failed', type: 'Shipped' },
    { id: 101, label: 'Order returned to Hub', type: 'Shipped' },
    { id: 102, label: 'Order returned to Warehouse', type: 'Shipped' },
    { id: 104, label: 'Wait to WMS', type: 'Shipped' },
    { id: 111, label: 'Return to origin - Out', type: 'Shipped' },
    { id: 112, label: 'Return to origin - In', type: 'Shipped' },
    { id: 113, label: 'Transport management system to Warehouse', type: 'Shipped' },
    { id: 114, label: 'Back to stock', type: 'Shipped' },
    { id: 12, label: 'Delivered', type: 'Shipped' },
    { id: 115, label: 'Duplicated', type: 'Processing' },
];
