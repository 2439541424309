/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Button, Tabs, TextInput } from 'flowbite-react';
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import toast from 'react-hot-toast';
import {
    createLogistioBulkFees,
    IPricingLogistioBulkFees,
    updateLogistioBulkFees,
} from 'services/admin/logistioBulkFees';
import CustomBtn from 'components/customButton/customBtn';
import PricingCodNoCC from './codNoCC/countriesPricing';
import PricingPrepaidNoCC from './prepaidNoCC/countriesPricing';
import PricingCodWithCC from './codWithCC/countriesPricing';
import { PricingConfigurationProps } from '../currencyRate/configuration.types';

const PricingConfiguration: React.FC<PricingConfigurationProps> = (pricingProps) => {
    const { pricing, configId, open, setOpen, fee, refetchGetFees, action, setConfigId } = pricingProps;
    const [feeName, setFeeName] = useState<string>('');
    const [updateLoader, setUpdateLoader] = useState<boolean>(false);
    const [values, setValues] = useState<IPricingLogistioBulkFees>(pricing);

    const handleAddName = (e: any) => {
        setFeeName(e.target.value);
    };
    const handleOnClose = () => {
        setOpen(open, { feeDetails: false });
    };

    useEffect(() => {
        if (['ASSIGN_UPDATE', 'UPDATE'].includes(action)) {
            setFeeName(fee.name);
        } else if (action === 'ADD') {
            setFeeName(`${fee.name} -Duplication`);
        }
    }, [action]);

    const actions = (id: string, pricingValues: any) => {
        if (['ASSIGN_UPDATE', 'UPDATE'].includes(action)) {
            updateLogistioBulkFees(id, { pricing: pricingValues, name: feeName })
                .then((res) => {
                    toast.success(res.data.message);
                    setFeeName(res.data.logistioBulkFees.name);
                    setConfigId(res.data?.logistioBulkFees);
                    refetchGetFees();
                })
                .catch((err) => {
                    toast.error(err.response.data.errors.message);
                    setUpdateLoader(false);
                })
                .finally(() => {
                    setUpdateLoader(false);
                });
        } else {
            createLogistioBulkFees({ pricing: pricingValues, name: feeName })
                .then((res) => {
                    toast.success(res.data.message);
                    setFeeName(res.data.logistioBulkFees.name);
                    setConfigId(res.data?.logistioBulkFees);
                    refetchGetFees();
                })
                .catch((err) => {
                    toast.error(err.response.data.errors.message);
                    setUpdateLoader(false);
                })
                .finally(() => {
                    setUpdateLoader(false);
                });
        }
    };

    const handleConfirm = () => {
        setUpdateLoader(true);
        actions(configId, values);
    };

    return (
        <GeneralDrawer
            key={configId}
            anchor="right"
            open={open}
            onClose={handleOnClose}
            PaperProps={{
                sx: { width: '60%', padding: '10px' },
            }}
        >
            <>
                <div className="flex justify-center items-center">
                    {action === 'UPDATE' ? <h4>{fee.name} details</h4> : <h4>Create New Logistio Bulk Fee</h4>}
                </div>

                <div className="pt-4 px-2">
                    <TextInput
                        required
                        onChange={handleAddName}
                        value={feeName}
                        name="name"
                        placeholder="Logistio bulk fee name"
                        type="text"
                        color={!feeName && !action ? 'failure' : 'gray'}
                        helperText={
                            <>
                                {!feeName && !action && (
                                    <span className="font-s">Logistio Bulk fee name is required</span>
                                )}
                            </>
                        }
                    />
                </div>
                <Tabs
                    aria-label="Pills"
                    // eslint-disable-next-line react/style-prop-object
                    style="pills"
                    theme={{
                        base: 'flex flex-col gap-2 pt-2',
                        tablist: {
                            styles: {
                                pills: 'flex-wrap font-medium text-sm text-gray-500 dark:text-gray-400 space-x-3 p-2',
                            },
                            tabitem: {
                                base: 'flex items-center justify-center px-4 py-2 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                styles: {
                                    pills: {
                                        base: '',
                                        active: {
                                            on: 'rounded-3xl bg-[#6874F5] text-white',
                                            off: 'rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white border rounded-3xl dark:border-gray-700',
                                        },
                                    },
                                },
                            },
                        },
                    }}
                >
                    <Tabs.Item title="COD with Call center">
                        <PricingCodWithCC
                            action={action}
                            pricing={pricing}
                            refetchGetFees={refetchGetFees}
                            configId={configId}
                            setValues={setValues}
                        />
                    </Tabs.Item>
                    <Tabs.Item title="COD Without Call center">
                        <PricingCodNoCC
                            action={action}
                            pricing={pricing}
                            refetchGetFees={refetchGetFees}
                            configId={configId}
                            setValues={setValues}
                        />
                    </Tabs.Item>
                    <Tabs.Item title="Prepaid">
                        <PricingPrepaidNoCC
                            action={action}
                            pricing={pricing}
                            refetchGetFees={refetchGetFees}
                            configId={configId}
                            setValues={setValues}
                        />
                    </Tabs.Item>
                </Tabs>

                <div className="flex items-center justify-end w-full gap-2">
                    <Button color="light" onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <CustomBtn
                        onClick={handleConfirm}
                        variant="secondary"
                        outline
                        disabled={updateLoader || !feeName}
                        isProcessing={updateLoader}
                        type="button"
                    >
                        {['UPDATE', 'ASSIGN_UPDATE'].includes(action) ? 'Confirm update' : 'Confirm'}
                    </CustomBtn>
                </div>
            </>
        </GeneralDrawer>
    );
};

export default PricingConfiguration;
