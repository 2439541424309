/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { TextInput } from 'flowbite-react';
import toast from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdOutlineShoppingCart } from 'react-icons/md';
import AutoComplete from 'components/autocomplete/autoComplete';
import DataTable from 'components/tables/tables';
import { IResellerStock, getStocks } from 'services/admin/resellerStock';
import { getProducts } from 'services/admin/resellerProduct';
import { vnlinWarehouses } from 'utils/helpers/warehouses';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import { useLocation } from 'react-router-dom';
import { Imeta, Ipagination, ResellerProduct } from '../reseller.types';
import { columns } from './resellerStockElements';

const ResellerStocks: React.FC = () => {
    const [stocks, setStocks] = useState<IResellerStock[]>([]);
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const location: { state: any } = useLocation();

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [resellerProducts, setResellerProducts] = useState<ResellerProduct[]>([]);
    const [filter, setFilter] = useState<{
        customer?: string;
        product?: string;
        warehouse?: string;
        sku?: string;
        available?: number;
    }>({
        sku: location?.state?.sku,
    });

    const handlePaginationChange = (value: Partial<Ipagination>) => {
        const data: Partial<Ipagination> = { ...value };

        if (value.limit) {
            data.page = 1;
        }
        setPagination((prev) => ({ ...prev, ...data }));
    };

    function handleFilterChange(name: string, value: string) {
        setFilter((prev) => ({
            ...prev,
            [name]: value || undefined,
        }));
        setPagination((prev) => ({ ...prev, page: 1 }));
    }

    useEffect(() => {
        getProducts({ limit: 1000, status: 1 }).then((res) => {
            setResellerProducts(res?.data?.resellerProducts?.docs);
        });
    }, []);

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);

    useEffect(() => {
        const toastId = toast.loading('Loading stocks data...');
        setIsLoading(true);

        getStocks({ ...debouncedPagination, ...debouncedFilter })
            .then((result) => {
                toast.dismiss(toastId);
                setStocks(result?.data?.docs);
                setMeta({
                    hasNextPage: result.data?.hasNextPage,
                    hasPrevPage: result.data?.hasPrevPage,
                    limit: result.data?.limit,
                    nextPage: result.data?.nextPage,
                    page: result.data?.page,
                    pagingCounter: result.data?.pagingCounter,
                    prevPage: result.data?.prevPage,
                    totalDocs: result.data?.totalDocs,
                    totalPages: result.data?.totalPages,
                });
            })
            .catch((err) => {
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', { id: toastId });
            })
            .finally(() => setIsLoading(false));
    }, [debouncedPagination.page, debouncedPagination.limit, debouncedFilter]);

    return (
        <Box className="bg-white dark:bg-gray-900 min-h-[calc(100vh-80px)] px-8 p-5">
            <div className="flex items-center gap-2 mb-4">
                <MdOutlineShoppingCart size={32} />
                <span className="font-medium text-2xl">Reseller Stock</span>
            </div>

            <section className="dark:bg-gray-900 flex items-center my-2">
                <div className="relative w-full flex p-3 justify-between">
                    <div className="flex justify-start gap-3">
                        <AutoComplete
                            key="products"
                            styles={{ height: '42px', maxWidth: 250 }}
                            placeholder="Reseller Products"
                            options={[{ _id: undefined, name: 'All' }, ...resellerProducts]}
                            name="resellerProduct"
                            onChange={(newValue: any) => {
                                handleFilterChange('resellerProduct', newValue._id);
                            }}
                            getOptionLabel={(option: any) => option.name}
                        />

                        <AutoComplete
                            key="warehouses"
                            styles={{ height: '42px', maxWidth: 250 }}
                            placeholder="Warehouses"
                            options={[{ id: undefined, name: 'All' }, ...Object.values(vnlinWarehouses)]}
                            getOptionLabel={(option: any) => option.name}
                            name="warehouse"
                            onChange={(option: any) => {
                                handleFilterChange('warehouse', option.id);
                            }}
                        />
                        <AutoComplete
                            key="Available"
                            styles={{ height: '42px', maxWidth: 250 }}
                            placeholder="All"
                            options={[
                                { id: undefined, name: 'All' },
                                { id: 1, name: 'Available' },
                                { id: 2, name: 'Not Available' },
                            ]}
                            getOptionLabel={(option: any) => option.name}
                            name="available"
                            onChange={(option: any) => {
                                handleFilterChange('available', option.id);
                            }}
                        />
                    </div>

                    <TextInput
                        icon={AiOutlineSearch}
                        id="skuSearch"
                        placeholder="Search by SKU"
                        name="sku"
                        value={filter.sku || ''}
                        onChange={(e) => {
                            handleFilterChange('sku', e.target.value.trim());
                        }}
                    />
                </div>
            </section>

            <div className="p-3">
                <DataTable
                    rows={stocks}
                    columns={columns}
                    setRows={setStocks}
                    pageState={{
                        isLoading,
                        total: meta.totalDocs,
                        pageSize: pagination.limit,
                        page: pagination.page,
                        count: meta.totalPages,
                    }}
                    setPageState={handlePaginationChange}
                    filterState={filter || {}}
                />
            </div>
        </Box>
    );
};

export default ResellerStocks;
