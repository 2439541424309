import { IFees } from 'pages/invoices/invoice.types';
import { IStore } from 'services/admin/store';

// this function allow to group reseller product in invoices by store
export const groupResellerProductsByStore = (fees: IFees[]) => {
    const groupedData: { [productName: string]: { [store: string]: any } } = {};

    fees.forEach((storeFee) => {
        const resellerDetails = Object.values(storeFee?.resellerFeesDetails || {});

        resellerDetails.forEach((reseller) => {
            const { productName } = reseller;
            const { store } = storeFee;

            if (!groupedData[productName]) {
                groupedData[productName] = {};
            }

            if (reseller.totalItems || reseller.totalFees) {
                groupedData[productName][(store as IStore)?.storeName as string] = {
                    totalItems: reseller.totalItems || 0,
                    totalFees: reseller.totalFees || 0,
                };
            }
        });
    });

    return groupedData;
};

// this function allow to group credit product in invoices by store
export const groupCreditProductByStore = (fees: IFees[]) => {
    const groupedData: { [product: string]: { [store: string]: any } } = {};

    fees.forEach((storeFee) => {
        const creditDetails = Object.values(storeFee?.creditForCogsDetails || {});

        creditDetails.forEach((credit) => {
            const { product } = credit;
            const { store } = storeFee;

            if (!groupedData[product]) {
                groupedData[product] = {};
            }

            if (credit.total) {
                groupedData[product][(store as IStore)?.storeName as string] = {
                    total: credit.total || 0,
                    nbrOfSales: credit.nbrOfSales,
                };
            }
        });
    });

    return groupedData;
};
