import { Modal } from 'flowbite-react';
import { ResellerProduct } from 'pages/reseller/reseller.types';
import { Button } from '@mui/material';

import React from 'react';
import toast from 'react-hot-toast';
import { BiCopy } from 'react-icons/bi';

const ProductSKUModal: React.FC<{
    product: ResellerProduct;
    open: boolean;
    setOpen: (el: boolean) => void;
}> = ({ product, open, setOpen }) => {
    const copySKUToClipboard = (sku: string) => {
        navigator.clipboard.writeText(sku);
        toast.success(`SKU: ${sku} copied to clipboard`);
    };
    return (
        <>
            <Modal
                dismissible
                show={open}
                popup
                onClose={() => setOpen(false)}
                theme={{
                    content: {
                        base: 'w-fit',
                        inner: 'relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh] w-fit',
                    },
                }}
            >
                {' '}
                <Modal.Header className="p-4 pb-0">Product Skus - {product?.name || '--'}</Modal.Header>
                <Modal.Body className=" flex !p-4 ">
                    <div className="overflow-x-auto min-w-[500px] overflow-y-auto">
                        <div className="flex items-center  justify-center flex-col">
                            {product && (
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative rounded-xl">
                                    <thead className="text-xs text-gray-700 uppercase bg-[#EAEDF7] rounded-xl dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3  ">
                                                Product
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                SKU{' '}
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr className=" border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-6 py-4 ">{product.name}</td>
                                            <td className="px-6 py-4 flex justify-between items-center">
                                                {product.sku}
                                                <Button
                                                    title="Copy sku to clipboard"
                                                    onClick={() => {
                                                        copySKUToClipboard(product?.sku ?? '--');
                                                    }}
                                                >
                                                    <BiCopy fontSize="20px" />
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ProductSKUModal;
