/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import { IoMdCloseCircle } from 'react-icons/io';
import { getShipmentHistory, IShipmentTrace, IStockShipment } from 'services/admin/stockShipment';
import { TableColDef } from 'components/tables/tables.types';
import { format } from 'date-fns';
import { Button } from 'flowbite-react';
import DataTable from 'components/tables/tables';
import { VscHistory } from 'react-icons/vsc';
import { getStockStatus } from 'utils/helpers/status';
import toast from 'react-hot-toast';
import { StatusBadge } from 'utils/helpers/statusBadge';
import { Typography } from '@mui/material';
import { ResellerStockShipment } from 'services/admin/resellerStock';
import { stockShipmentsStatus } from './utils';

const ShipmentHistory: React.FC<{ data: IStockShipment | ResellerStockShipment }> = ({ data }) => {
    const [shippingHistory, setShippingHistory] = useState<IShipmentTrace[]>([]);
    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [loadingDetails, setLoadingDetails] = useState<boolean>(false);

    useEffect(() => {
        if (data?._id && openDetails) {
            const toastId = toast.loading('Loading stock shipments history data...');
            setLoadingDetails(true);
            getShipmentHistory(data?._id)
                .then((res) => {
                    toast.dismiss(toastId);
                    setShippingHistory(res.data);

                    setLoadingDetails(false);
                })
                .catch(() => {
                    setLoadingDetails(false);
                    toast.error('Something went wrong', { id: toastId });
                });
        }
    }, [data._id, openDetails]);
    const columns: TableColDef[] = [
        {
            field: 'time',
            headerName: 'Date',
            fieldType: 'render',
            renderCell: (params: IShipmentTrace) =>
                params?.time ? <p>{format(new Date(params?.time), 'dd/MM/yyyy')}</p> : <p>-</p>,
        },

        {
            field: 'time',
            headerName: 'Time',
            fieldType: 'render',
            renderCell: (params: IShipmentTrace) =>
                params?.time ? <p>{format(new Date(params?.time), 'hh:mm:ss')}</p> : <p>-</p>,
        },

        {
            field: 'doneBy',
            headerName: 'Updated by',
            fieldType: 'render',
            renderCell: (params: IShipmentTrace) =>
                params?.doneBy?.firstName && params?.doneBy?.lastName ? (
                    <p>
                        {params?.doneBy?.firstName} {params?.doneBy?.lastName}
                    </p>
                ) : (
                    <p>Automated action</p>
                ),
        },

        {
            field: 'status',
            headerName: 'Status',
            fieldType: 'render',
            renderCell: (params: IShipmentTrace) => {
                return (
                    <>
                        <StatusBadge
                            color={
                                stockShipmentsStatus.find((elt) => elt.value === params?.logistioStatus?.toString())
                                    ?.color
                            }
                            style={`${stockShipmentsStatus.find(
                                (elt) => elt.value === params?.logistioStatus?.toString(),
                            )?.color} w-fit`}
                            name={getStockStatus(params?.logistioStatus as unknown as number)}
                        />
                        {params.description && <Typography>{params.description}</Typography>}
                    </>
                );
            },
        },
    ];
    return (
        <>
            <Button color="white" onClick={() => setOpenDetails(true)}>
                <VscHistory size="2em" />
            </Button>
            <GeneralDrawer
                anchor="right"
                open={openDetails}
                onClose={setOpenDetails}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">Shipment details</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpenDetails(false)}
                            className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                        >
                            <IoMdCloseCircle className="w-8 h-8" color="grey" />
                        </button>
                    </div>

                    <div className="flex items-center w-full my-7">
                        <p className="text-xl font-medium">
                            {(data as IStockShipment)?.product?.itemKind === 'variant'
                                ? (data as IStockShipment)?.parentProduct?.restockFor?.name ||
                                  (data as IStockShipment)?.parentProduct?.name
                                : (data as IStockShipment)?.product?.name ||
                                  (data as ResellerStockShipment)?.resellerProduct?.name}
                        </p>
                        {(data as IStockShipment)?.product?.itemKind === 'variant' && (
                            <p className="text-l font-medium pt-2 pl-2">
                                {(data as IStockShipment)?.product?.name ||
                                    (data as ResellerStockShipment)?.resellerProduct?.name ||
                                    ''}
                            </p>
                        )}
                    </div>

                    <DataTable
                        rows={shippingHistory}
                        setRows={setShippingHistory}
                        columns={columns}
                        pageState={{
                            isLoading: loadingDetails,
                            total: 0,
                            pageSize: 0,
                            page: 0,
                            count: 0,
                        }}
                        setPageState={() => {}}
                    />
                </div>
            </GeneralDrawer>
        </>
    );
};
export default ShipmentHistory;
