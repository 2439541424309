/* eslint-disable no-underscore-dangle */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Tabs } from 'flowbite-react';
import { HiUserCircle } from 'react-icons/hi';
import { RiBankFill, RiCustomerService2Fill, RiSettings3Fill } from 'react-icons/ri';
import { IConfig, IWalletCurrencyConfig, getConfiguration } from 'services/admin/configuration';
import { ImPriceTags } from 'react-icons/im';
import { MdCurrencyExchange } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { getLogistioBulkFeesList, ILogistioBulkFees } from 'services/admin/logistioBulkFees';
import BankConfiguration from './bankConfiguration/bankConfiguration';
import WalletCurrencyConfiguration from './walletCurrencyConfiguration/walletCurrencyConfiguration';

import CallCenter from './automationConfiguration/callCenter';
import LogistioBulkConfiguration from './logistioBulk/logistioBulk';
import LogistioBulkFees from './logistioBulkFees/logistioBulkFees';
import CurrencyRate from './currencyRate/currencyRate';

interface ILocation {
    state?: any;
}
const Configuration = () => {
    const { state }: ILocation = useLocation();
    const [configPricing, setConfigPricing] = useState<IConfig['pricing']>({
        currencyRate: [],
    });

    const [logistioBulkFees, setLogistioBulkFees] = useState<ILogistioBulkFees[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [bankConfig, setBankConfig] = useState<IConfig['bankInformations']>({
        simpleBank: [],
        financeService: [],
    });
    const [logistioBulkConfig, setLogistioBulkConfig] = useState<IConfig['logistioBulkConfiguration']>({
        quotedExpiration: 7,
        quotedExpirationType: 'days',
        paymentExpiration: 7,
        paymentExpirationType: 'days',
        review: 48,
        reviewType: 'hours',
    });
    const [walletCurrencyConfig, setWalletCurrencyConfig] = useState<IWalletCurrencyConfig[]>([]);

    const [configId, setConfigId] = useState<string>('');

    useEffect(() => {
        setIsLoading(true);
        getLogistioBulkFeesList().then((res) => {
            setLogistioBulkFees(res?.data || []);
            setIsLoading(false);
        });
        getConfiguration().then((res) => {
            setConfigPricing(res?.data?.pricing || []);
            setBankConfig(res?.data?.bankInformations || []);
            setWalletCurrencyConfig(res?.data?.walletCurrency || []);
            setConfigId(res?.data?._id);
            setLogistioBulkConfig(res?.data?.logistioBulkConfiguration);
        });
    }, []);

    return (
        <>
            <div className="h-full">
                <Tabs
                    className="h-full"
                    aria-label="Tabs with icons"
                    style="underline"
                    theme={{
                        tablist: {
                            tabitem: {
                                base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                            },
                        },
                    }}
                >
                    <Tabs.Item title="Logistio Bulk" icon={RiSettings3Fill}>
                        <LogistioBulkConfiguration
                            logistioBulkConfig={logistioBulkConfig}
                            configId={configId}
                            setLogistioBulkConfig={setLogistioBulkConfig}
                        />
                    </Tabs.Item>
                    <Tabs.Item active={state?.isPricing} title="Pricing" icon={ImPriceTags} className="h-full bg-white">
                        <div className="bg-white dark:bg-gray-900 rounded-lg shadow-md w-full">
                            <LogistioBulkFees
                                isLoading={isLoading}
                                setLogistioBulkFees={setLogistioBulkFees}
                                logistioBulkFees={logistioBulkFees}
                            />
                        </div>
                    </Tabs.Item>

                    <Tabs.Item title="Currency rate" icon={MdCurrencyExchange} className="h-full bg-white">
                        <CurrencyRate
                            pricing={configPricing}
                            configId={configId}
                            configLength={configPricing?.currencyRate?.length || 0}
                            setConfigPricing={setConfigPricing}
                        />
                    </Tabs.Item>
                    <Tabs.Item title="Bank information" icon={RiBankFill}>
                        <BankConfiguration bankConfig={bankConfig} configId={configId} setBankConfig={setBankConfig} />
                    </Tabs.Item>
                    <Tabs.Item title="Wallet currencies" icon={HiUserCircle}>
                        <WalletCurrencyConfiguration
                            walletCurrencyConfig={walletCurrencyConfig}
                            configId={configId}
                            setWalletCurrencyConfig={setWalletCurrencyConfig}
                        />
                    </Tabs.Item>
                    <Tabs.Item title="Configurations" icon={RiCustomerService2Fill}>
                        <CallCenter />
                    </Tabs.Item>
                </Tabs>
            </div>
        </>
    );
};

export default Configuration;
