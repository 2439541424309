/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';
import { getStatus } from 'utils/helpers/status';
import { formatDistance } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPaginationData } from 'utils/Interfaces/types';
import CustomBtn from 'components/customButton/customBtn';
import { IoIosNotifications } from 'react-icons/io';
import { Dropdown } from 'flowbite-react';

interface INotification {
    // 🏷️ `_justReceived` is used only to detect new notifications received
    // after the initial load of the component and
    // ⚠️ IT DOES NOT EXIST ON THE BACKEND BUT WE ARE ASSIGNING IT TO
    // THE NOTIFICATION OBJECT HERE!!!
    _justReceived?: boolean;
    _id: string;
    aboutModel: 'Message' | 'ProductHistory';
    content: any;
    createdAt: string;
    updatedAt: string;
    doneBy: { _id: string; email: string; firstName?: string; lastName?: string };
    onModel: 'User' | 'Customer';
    forCustomer: string;
    subject: number;
    seenBy: string[];
}

const getSubjectText = (subject: number, content: INotification['content'], doneBy: INotification['doneBy']) => {
    switch (subject) {
        case 11:
            // TODO: passing `0` to type in getStatus
            // double check what does the type arg is for!
            return `Product ${content?.product?.name} status changed from ${getStatus(
                content.oldStatus,
                0,
            )} to ${getStatus(content?.newStatus, 0)}`;

        case 12:
            if (doneBy && doneBy?.firstName && doneBy?.lastName) {
                return `${doneBy?.firstName} ${doneBy?.lastName} added 
                ${content && content?.name ? `product: ${content?.name}` : 'a new product'}`;
            }
            return `${content && content?.name ? `product ${content?.name}` : 'a new product'} is added`;

        case 21:
            return `New message about product: ${content.chat?.product?.name}`;

        //        // case 22:
        //            // return `New order message about order of external ID: ${content.chat.order?.externalOrderId}`;
        case 22:
            return `New order message about order of external ID: ${content.chat?.order?.externalOrderId}`;

        // case 31:
        //    return `Order of external order id ${content.order.externalOrderId} status changed form ${ordersStatus(
        //        content.oldStatus,
        //    )} to ${ordersStatus(content.newStatus)}`;
        case 41:
            return `New wallet operation from ${content?.accountName}`;
        case 51:
            return `New payment request`;
        default:
            return '';
    }
};

function Notifications() {
    const navigate = useNavigate();
    const location = useLocation();
    const currentLayout = location.pathname.split('/').filter((subpath: string) => !!subpath)[0];
    const [pagination, setPagination] = useState({
        hasNextPage: false,
        page: 2,
    });
    const [notificationsList, setNotificationsList] = useState<INotification[]>([]);
    const [newNotificationsCounter, setNewNotificationsCounter] = useState(0);

    const handleClose = () => {
        let foundNewNotifications = false;

        const seenList = notificationsList.map((notification) => {
            const oldData = notification;
            // eslint-disable-next-line no-prototype-builtins
            if (oldData.hasOwnProperty('_justReceived')) {
                foundNewNotifications = true;
                delete oldData._justReceived;
            }
            return oldData;
        });
        if (foundNewNotifications) {
            socket.emit('markNotificationsAsSeen', { user: getCurrentUser()._id });
        }
        setNotificationsList(seenList);
    };

    const getNotifications = () => {
        socket.emit('paginateNotifications', { user: getCurrentUser()._id, page: pagination.page });
        setPagination((prevPageNumber) => {
            return { ...prevPageNumber, page: prevPageNumber.page + 1 };
        });
    };

    useEffect(() => {
        const userId = getCurrentUser()._id;
        socket.emit('joinNotifications', { user: userId });
        socket.on('notifications-history', (data: IPaginationData<INotification>) => {
            setPagination((prevPagination) => ({ ...prevPagination, hasNextPage: data.hasNextPage }));
            const justReceived = data.docs.map((el) => {
                if (!el.seenBy.includes(userId)) {
                    return { ...el, _justReceived: true };
                }
                return { ...el };
            });
            setNotificationsList(justReceived);
            setNewNotificationsCounter(notificationsList.length);
        });
        socket.on('new-notification', (data: INotification) => {
            if (data.content.from !== getCurrentUser()._id) {
                setNotificationsList((oldNotifications) => [{ ...data, _justReceived: true }, ...oldNotifications]);
                setNewNotificationsCounter((counter) => {
                    return counter + 1;
                });
            } else {
                socket.emit('markNotificationsAsSeen', { user: getCurrentUser()._id });
            }
        });
        socket.on('paginate-notifications-history', (data: IPaginationData<INotification>) => {
            setPagination((prevPagination) => ({ ...prevPagination, hasNextPage: data.hasNextPage }));
            setNotificationsList((existingNotifications) => {
                return [...existingNotifications, ...data.docs];
            });
        });
        socket.on('paginate-notifications-history', (data: IPaginationData<INotification>) => {
            setPagination((prevPagination) => ({ ...prevPagination, hasNextPage: data.hasNextPage }));
            setNotificationsList((existingNotifications) => {
                return [...existingNotifications, ...data.docs];
            });
        });
        return () => {
            socket.off('notifications-history');
            socket.off('new-notification');
            socket.off('paginate-notifications-history');
        };
    }, []);

    // 🏷️ Get the unseen notifications count
    useEffect(() => {
        let unseenNotificaitons = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < notificationsList.length; i++) {
            const notification = notificationsList[i];
            if (notification._justReceived) {
                unseenNotificaitons += 1;
            }
        }
        setNewNotificationsCounter(unseenNotificaitons);
    }, [notificationsList]);

    return (
        <div>
            <Dropdown
                inline
                arrowIcon={false}
                // label={<IoIosNotifications className="w-6 h-6" />}
                label=""
                dismissOnClick={false}
                renderTrigger={() => (
                    <span className="cursor-pointer">
                        <div className="relative">
                            <IoIosNotifications className="w-6 h-6" />
                            <div>
                                {newNotificationsCounter > 0 && (
                                    <p
                                        className="absolute -top-2 -right-1"
                                        style={{
                                            zIndex: 2,
                                            height: 17,
                                            width: 17,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '100%',
                                            background: 'red',
                                            color: '#fff',
                                            fontSize: 12,
                                        }}
                                    >
                                        {newNotificationsCounter}
                                    </p>
                                )}
                            </div>
                        </div>
                    </span>
                )}
            >
                <Dropdown.Header>
                    <p className=" font-semibold text-center text-highlight">Notifications</p>
                </Dropdown.Header>
                {notificationsList && notificationsList.length > 0 ? (
                    <div className="h-[70vh] overflow-y-auto">
                        {notificationsList.map((notification) => {
                            const description = getSubjectText(
                                notification.subject,
                                notification.content,
                                notification.doneBy,
                            );
                            return (
                                <Dropdown.Item
                                    onClick={() => {
                                        handleClose();
                                        if (notification.subject === 11) {
                                            navigate('/admin/products');
                                        } else if ([21, 22, 23].includes(notification.subject)) {
                                            // navigate to chat page
                                            const navigationState: {
                                                chatId: string;
                                                type?: 'product' | 'order' | 'support';
                                            } = { chatId: notification.content?.chat._id };
                                            switch (notification.subject) {
                                                case 21:
                                                    Object.assign(navigationState, { type: 'product' });
                                                    break;
                                                case 22:
                                                    Object.assign(navigationState, { type: 'order' });
                                                    break;
                                                case 23:
                                                    Object.assign(navigationState, { type: 'support' });
                                                    break;
                                                default:
                                                    break;
                                            }
                                            navigate(`/${currentLayout}/conversations`, {
                                                state: navigationState,
                                            });
                                        }
                                        if (notification.subject === 31) {
                                            navigate('/admin/orders');
                                        } else if (notification.subject === 41) {
                                            // navigate(`/admin/customer/${customer}`);
                                        }
                                    }}
                                    className=""
                                    key={notification._id}
                                >
                                    {/* 🏷️ Product status update */}
                                    {notification.subject === 11 && (
                                        <div className="flex items-center gap-1">
                                            {notification._justReceived && (
                                                <div
                                                    style={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: '100%',
                                                        backgroundColor: 'Highlight',
                                                    }}
                                                />
                                            )}
                                            <div className="block">
                                                <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>
                                                    Product status updated
                                                </p>
                                                <p style={{ fontWeight: 600, margin: '4px 0px' }}>{description}</p>
                                                <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>
                                                    {formatDistance(new Date(notification.updatedAt), new Date(), {
                                                        includeSeconds: true,
                                                        addSuffix: true,
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {/* 🏷️ New product sourced */}
                                    {notification.subject === 12 && (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            {notification._justReceived && (
                                                <div
                                                    style={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: '100%',
                                                        backgroundColor: 'Highlight',
                                                    }}
                                                />
                                            )}
                                            <div
                                                style={{
                                                    display: 'block',
                                                }}
                                            >
                                                <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>
                                                    New product sourced
                                                </p>
                                                <p style={{ fontWeight: 600, margin: '4px 0px' }}>{description}</p>
                                                <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>
                                                    {formatDistance(new Date(notification.updatedAt), new Date(), {
                                                        includeSeconds: true,
                                                        addSuffix: true,
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {/* 🏷️ Order status update */}
                                    {notification.content?.order && (
                                        <p style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            {notification._justReceived && (
                                                <p
                                                    style={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: '100%',
                                                        backgroundColor: 'Highlight',
                                                    }}
                                                />
                                            )}
                                            <p
                                                style={{
                                                    display: 'block',
                                                }}
                                            >
                                                <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>
                                                    Order status updated
                                                </p>
                                                <p style={{ fontWeight: 600, margin: '4px 0px' }}>{description}</p>
                                                <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>
                                                    {formatDistance(new Date(notification.updatedAt), new Date(), {
                                                        includeSeconds: true,
                                                        addSuffix: true,
                                                    })}
                                                </p>
                                            </p>
                                        </p>
                                    )}
                                    {/* 🏷️ New wallet operation */}
                                    {notification.content?.chat && (
                                        <p className="">
                                            {notification._justReceived && (
                                                <div className="w-[10px] h-[10px] rounded-full bg-red-600" />
                                            )}
                                            <p className="flex flex-col justify-start">
                                                <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>New message</p>
                                                <p style={{ fontWeight: 600, margin: '4px 0px' }}>{description}</p>
                                                <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>
                                                    {formatDistance(new Date(notification.updatedAt), new Date(), {
                                                        includeSeconds: true,
                                                        addSuffix: true,
                                                    })}
                                                </p>
                                            </p>
                                        </p>
                                    )}
                                    {notification.content?.wallet && (
                                        <div className="flex items-center gap-1">
                                            {notification._justReceived && (
                                                <div
                                                    className="w-[10px] h-[10px] rounded-full bg-red-300"
                                                    // sx={{
                                                    //     width: 10,
                                                    //     height: 10,
                                                    //     borderRadius: '100%',
                                                    //     backgroundColor: 'Highlight',
                                                    // }}
                                                />
                                            )}
                                            <div
                                                style={{
                                                    display: 'block',
                                                }}
                                            >
                                                <p className="text-xs font-normal m-0">New wallet Operation</p>
                                                <p className="font-semibold my-4 mx-0">{description}</p>
                                                <p className="text-xs font-normal m-0">
                                                    {formatDistance(new Date(notification.updatedAt), new Date(), {
                                                        includeSeconds: true,
                                                        addSuffix: true,
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </Dropdown.Item>
                            );
                        })}
                        {pagination.hasNextPage ? (
                            <CustomBtn
                                onClick={getNotifications}
                                variant="secondary"
                                size="sm"
                                style={{ margin: 5, width: 'calc( 100% - 10px )' }}
                            >
                                Load more
                            </CustomBtn>
                        ) : (
                            <p style={{ padding: 5, textAlign: 'center' }}>All caught up!</p>
                        )}
                    </div>
                ) : (
                    <p style={{ fontSize: 14, fontWeight: 500, opacity: 0.7, padding: '10px 20px' }}>
                        No unseen notifications
                    </p>
                )}
            </Dropdown>
        </div>
    );
}

export default Notifications;
