import React from 'react';
import { Badge } from 'flowbite-react';
import { IStausInterface } from '../../../components/tables/tables.types';

export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Treating
            </Badge>
        ),
        statusLabel: 'Treating',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Paid
            </Badge>
        ),
        statusLabel: 'Paid',
    },
};
