import React, { useState } from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

import { RiExchangeFill } from 'react-icons/ri';
import { FaSave } from 'react-icons/fa';

import { Modal, Table, TextInput } from 'flowbite-react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { MdCancel } from 'react-icons/md';
import { ICurrencyRate, getConfiguration, updateConfigItem } from 'services/admin/configuration';
import CustomBtn from 'components/customButton/customBtn';
import { UpdatePricingCurrencyRate } from 'utils/validations/pricingConfiguration';
import CustomError from 'components/customError/CustomError';
import { countries } from 'utils/countryList';
import { EditConfig } from './configuration.types';

const CurrencyRateList: React.FC<EditConfig> = ({ pricing, configLength, configId, setConfigPricing }) => {
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const initAray = (configLength && new Array(configLength)?.fill(false)) || [];
    const [openEdit, setOpenEdit] = useState<boolean[]>(() => [...initAray]);
    const [deleteIndex, setDeleteIndex] = useState(0);

    const setEditState = (idx: number) => {
        const updatedCheckedState = openEdit.map((item, index) => (index === idx ? !item : item));
        setOpenEdit(updatedCheckedState);
    };

    const [updateLoader, setUpdateLoader] = useState(false);
    const updatePricing = (values: ICurrencyRate, id: string, indexItem: number) => {
        setUpdateLoader(true);
        const UpdateToast = toast.loading('Loading ..');
        const newValues = pricing;
        newValues.currencyRate[indexItem] = values;
        updateConfigItem(id, { pricing: newValues })
            .then((res) => {
                getConfiguration().then((result) => {
                    setEditState(indexItem);
                    toast.success(res.data.message, { id: UpdateToast });
                    setUpdateLoader(false);

                    setConfigPricing(result.data?.pricing);
                });
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message, { id: UpdateToast });
                setUpdateLoader(false);
            });
    };
    const deletePricing = (index: number) => {
        const toastLoader = toast.loading('Loading...');
        const newValues = pricing;
        newValues?.currencyRate.splice(index, 1);
        updateConfigItem(configId, { pricing: newValues })
            .then(() => {
                toast.success('Country configuration deleted', { id: toastLoader });
                getConfiguration().then((result) => {
                    setConfigPricing(result.data.pricing);
                });
            })
            .catch((err) => toast.error(err?.response?.data?.errors?.message, { id: toastLoader }));
        setOpenDelete(false);
    };
    return (
        <>
            <Modal show={openDelete} size="md" popup onClose={() => setOpenDelete(false)}>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <p className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this country pricing ?
                            {pricing?.currencyRate && pricing?.currencyRate[deleteIndex]?.country}
                        </p>
                        <div className="flex justify-center gap-4">
                            <CustomBtn variant="tertiary" onClick={() => setOpenDelete(false)}>
                                No, cancel
                            </CustomBtn>
                            <CustomBtn
                                variant="failure"
                                onClick={() => {
                                    deletePricing(deleteIndex);
                                }}
                            >
                                Yes, am sure
                            </CustomBtn>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div>
                <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700">
                    <div className="overflow-x-auto">
                        <Table>
                            <Table.Body className="divide-y-2">
                                {pricing?.currencyRate &&
                                    pricing?.currencyRate.length > 0 &&
                                    pricing?.currencyRate?.map((currency: any, idx: number) => (
                                        <>
                                            {openEdit && openEdit[idx] ? (
                                                <Formik
                                                    initialValues={{
                                                        country: currency.country,
                                                        currencyValue: currency.currencyValue,
                                                    }}
                                                    onSubmit={(values, onSubmitProps) => {
                                                        updatePricing(
                                                            values,
                                                            configId,
                                                            pricing?.currencyRate.indexOf(currency),
                                                        );
                                                        onSubmitProps.setSubmitting(false);
                                                    }}
                                                    validationSchema={UpdatePricingCurrencyRate}
                                                >
                                                    {(formik) => (
                                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                            <Table.Cell
                                                                scope="col"
                                                                className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                                            >
                                                                1
                                                            </Table.Cell>
                                                            <Table.Cell
                                                                scope="col"
                                                                className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                                            >
                                                                United States Dollar
                                                            </Table.Cell>
                                                            <Table.Cell
                                                                scope="col"
                                                                className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                                            >
                                                                <span>
                                                                    <RiExchangeFill className="h-7 w-7" color="green" />
                                                                </span>
                                                            </Table.Cell>
                                                            <Table.Cell
                                                                scope="col"
                                                                className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                                            >
                                                                <Form
                                                                    onSubmit={formik.handleSubmit}
                                                                    className="flex-col gap-1"
                                                                    id="editCurrencyForm"
                                                                    placeholder=""
                                                                >
                                                                    <TextInput
                                                                        sizing="sm"
                                                                        {...formik.getFieldProps('currencyValue')}
                                                                        name="currencyValue"
                                                                        color={
                                                                            formik.touched.currencyValue &&
                                                                            formik.errors.currencyValue
                                                                                ? 'failure'
                                                                                : 'gray'
                                                                        }
                                                                        type="text"
                                                                        helperText={
                                                                            <>
                                                                                <CustomError
                                                                                    name="currencyValue"
                                                                                    component="div"
                                                                                />
                                                                            </>
                                                                        }
                                                                    />
                                                                </Form>
                                                            </Table.Cell>
                                                            <Table.Cell
                                                                scope="col"
                                                                className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                                            >
                                                                {
                                                                    countries.find(
                                                                        (country) => country.label === currency.country,
                                                                    )?.currencyLabel
                                                                }
                                                            </Table.Cell>
                                                            <Table.Cell
                                                                scope="col"
                                                                className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                                            >
                                                                <div className="flex gap-1">
                                                                    <CustomBtn
                                                                        variant="success"
                                                                        type="submit"
                                                                        size="sm"
                                                                        pill
                                                                        form="editCurrencyForm"
                                                                        disabled={
                                                                            !formik.isValid ||
                                                                            !formik.dirty ||
                                                                            formik.isSubmitting ||
                                                                            updateLoader
                                                                        }
                                                                        isProcessing={updateLoader}
                                                                    >
                                                                        <FaSave className="h-6" />
                                                                    </CustomBtn>
                                                                    <CustomBtn
                                                                        variant="failure"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setEditState(idx);
                                                                        }}
                                                                        pill
                                                                    >
                                                                        <MdCancel className="h-6" />
                                                                    </CustomBtn>
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                </Formik>
                                            ) : (
                                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                        1
                                                    </Table.Cell>
                                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                        United States Dollar
                                                    </Table.Cell>
                                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                        <RiExchangeFill className="h-7 w-7" color="green" />
                                                    </Table.Cell>
                                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                        {currency.currencyValue}
                                                    </Table.Cell>
                                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                        {
                                                            countries.find(
                                                                (country) => country.label === currency.country,
                                                            )?.currencyLabel
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                        <div className="flex gap-1">
                                                            <CustomBtn
                                                                variant="primary"
                                                                size="sm"
                                                                onClick={() => {
                                                                    setEditState(idx);
                                                                }}
                                                                pill
                                                            >
                                                                <AiFillEdit className="h-6" />
                                                            </CustomBtn>
                                                            <CustomBtn
                                                                variant="failure"
                                                                pill
                                                                size="sm"
                                                                onClick={() => {
                                                                    setOpenDelete(true);
                                                                    setDeleteIndex(idx);
                                                                }}
                                                            >
                                                                <AiFillDelete className="h-6" />
                                                            </CustomBtn>
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </>
                                    ))}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CurrencyRateList;
