/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import DataTable from 'components/tables/tables';
import { ICustomer } from 'pages/customers/customer.types';
import { Ipagination } from 'pages/invoices/invoice.types';
import { Imeta } from 'pages/reseller/reseller.types';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import toast from 'react-hot-toast';
import { getCustomers } from 'services/admin/customer';
import { TableColDef } from 'components/tables/tables.types';
import { format } from 'date-fns';
import { Button, TextInput } from 'flowbite-react';
import { AiOutlineSearch } from 'react-icons/ai';
import { ILogistioBulkFees } from 'services/admin/logistioBulkFees';

export interface Ifilter {
    fullName?: string;
    logistioBulkFees: string;
}
const CustomersList: React.FC<{
    fee: ILogistioBulkFees;
    setOpenDetails: any;
    openDetails: boolean;
    handleAssignCustumers: any;
}> = ({ fee, openDetails, setOpenDetails, handleAssignCustumers }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [costumers, setCustomers] = useState<Array<ICustomer>>([]);
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [filter, setFilter] = useState<Ifilter>({ logistioBulkFees: fee._id });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    function handleChange(event: any) {
        setIsLoading(true);

        if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined } });
        }
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    }

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);
    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        setIsLoading(true);
        const queryOptions = { ...debouncedPagination, ...debouncedFilter };
        getCustomers(queryOptions)
            .then((result) => {
                toast.dismiss(feedbackToast);
                if (filter.fullName && !result.data.docs.length) {
                    toast.error('The name you are searching for is not assigned to this offer');
                }
                setCustomers(result.data.docs);
                setIsLoading(false);
                setMeta({
                    hasNextPage: result.data.hasNextPage,
                    hasPrevPage: result.data.hasPrevPage,
                    limit: result.data.limit,
                    nextPage: result.data.nextPage,
                    page: result.data.page,
                    pagingCounter: result.data.pagingCounter,
                    prevPage: result.data.prevPage,
                    totalDocs: result.data.totalDocs,
                    totalPages: result.data.totalPages,
                });
            })
            .catch((err) => {
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
                setIsLoading(false);
            });
    }, [debouncedFilter, debouncedPagination]);

    const columns: TableColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: ICustomer) => (
                <p>{`${
                    params?.firstName && params.lastName ? `${params?.firstName} ${params.lastName}` : params.email
                } `}</p>
            ),
        },

        {
            field: 'createdAt',
            headerName: 'Created at',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: ICustomer) => (
                <p>{`${params?.createdAt ? format(new Date(params?.createdAt), 'dd/MM/yyyy') : '-'} `}</p>
            ),
        },
    ];
    return (
        <>
            <GeneralDrawer
                anchor="right"
                open={openDetails}
                onClose={() => setOpenDetails(openDetails, { userDetails: false })}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <h4>List of sellers assigned to {fee.name}</h4>
                    <section className=" flex items-center my-2">
                        <div className="w-full p-2 flex items-center justify-between">
                            <TextInput
                                icon={AiOutlineSearch}
                                id="fullName"
                                placeholder="Enter name to search"
                                name="fullName"
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                            />

                            <Button onClick={() => handleAssignCustumers(fee)} size="m" className="text-xs p-2">
                                Add a seller
                            </Button>
                        </div>
                    </section>
                    <DataTable
                        rows={costumers}
                        columns={columns}
                        setRows={setCustomers}
                        pageState={{
                            isLoading,
                            total: meta.totalDocs,
                            pageSize: pagination.limit,
                            page: pagination.page,
                            count: meta.totalPages,
                        }}
                        setPageState={handlePaginationChange}
                        filterState={filter}
                    />
                </div>
            </GeneralDrawer>
        </>
    );
};
export default CustomersList;
