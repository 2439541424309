/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Button } from 'flowbite-react';
import { ICustomer } from 'pages/customers/customer.types';
import React from 'react';
import { ResellerProduct } from '../reseller.types';
import CustomersList from './helpers/customersList';

export interface IProductActions {
    product: ResellerProduct;
}
const CustomerResellerProductActions = ({ product }: IProductActions) => {
    const [open, setOpen] = React.useState(false);

    if (!product.name) return <></>;

    return (
        <>
            {open && (
                <CustomersList
                    customers={product?.customers as ICustomer[]}
                    open={open}
                    setOpen={setOpen}
                    key="customersList"
                />
            )}
            <Button
                title={
                    product?.customers?.length ? 'List of sellers' : 'This product has not yet been added by a seller.'
                }
                disabled={!product?.customers?.length}
                onClick={() => setOpen(true)}
                color="light"
            >
                <p>{product?.customers?.length || 0}</p>
            </Button>
        </>
    );
};
export default CustomerResellerProductActions;
