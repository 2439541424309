/* eslint-disable no-underscore-dangle */
import toast from 'react-hot-toast';
import React, { useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import ImageProofVerification from 'components/ImagePoofVerification/ImageproodVerification';
import { getpayments, updateBankPayment } from 'services/admin/payments';

import { Button, Table } from 'flowbite-react';
import { IStore } from 'services/admin/store';
import { format } from 'date-fns';
import { IProduct } from 'utils/Interfaces/products.interface';
import { currencyConverter } from 'utils/currencyConverter';
import { IPaymentProps } from './payments.types';
import { paymentType, statusDisplay } from './paymentsTableActions/paymentsElements';

const BankPayment: React.FC<IPaymentProps> = ({ paymentData, setRows, currentPage, currentFilter }) => {
    const [isLoading, setIsLoading] = useState(false);

    const getPaymentAfterUpdate = () => {
        getpayments({ page: currentPage, ...currentFilter }).then((res) => {
            const data = res.data.response;
            setRows(data.docs);
        });
    };
    /**
     * @param status
     * [
     * 2: accepted
     * 3: refused
     * ]
     * */
    const decideOnPayment = (status: 2 | 3) => {
        const feedbackToast = toast.loading('Updating payment...');
        setIsLoading(true);
        updateBankPayment(paymentData._id, {
            status,
        })
            .then(() => {
                setIsLoading(false);
                getPaymentAfterUpdate();
                if (status === 3) {
                    toast.success(
                        `Amount of  $${paymentData.amount} is rejected for account ${paymentData.accountName} `,
                        {
                            id: feedbackToast,
                        },
                    );
                } else if (status === 2) {
                    toast.success(`Amount of $${paymentData.amount} accepted for account ${paymentData.accountName} `, {
                        id: feedbackToast,
                    });
                }
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong', { id: feedbackToast });
            });
    };

    const totalHandleFees = paymentData?.ordersDetails?.reduce((sum, order) => sum + (order?.handleFees || 0), 0);
    const totalShippingFees = paymentData?.ordersDetails?.reduce((sum, order) => sum + (order?.shippingFees || 0), 0);

    const getRenderedElementByType = (type: string) => {
        switch (type) {
            case 'product':
            case 'restock':
            case 'resellerProduct':
                return (
                    <div className="p-4 grid grid-cols-2 gap-6">
                        <div className="flex items-center gap-4">
                            <div>Date:</div>
                            <div>
                                {paymentData?.createdAt && format(new Date(paymentData?.createdAt), 'dd/MM/yyyy')}
                            </div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div>Type:</div>
                            <div>{paymentType?.[type]?.statusIcon}</div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div>Status:</div>
                            <div>{statusDisplay?.[paymentData.status]?.statusIcon}</div>
                        </div>

                        {(paymentData?.product as IProduct)?.name ? (
                            <div className="flex items-center gap-4">
                                <div>Product:</div>
                                <div
                                    title={(paymentData?.product as IProduct)?.name}
                                    className="max-w-[220px] overflow-hidden whitespace-nowrap text-ellipsis"
                                >
                                    {(paymentData?.product as IProduct)?.name}
                                </div>
                            </div>
                        ) : null}

                        {['walletBank', 'bank'].includes(paymentData.source) && (
                            <>
                                {paymentData?.bankAmount && (
                                    <div className="flex items-center gap-4">
                                        <div>Bank Amount:</div>
                                        <div
                                            className={`${
                                                ['walletBank', 'bank'].includes(paymentData.source) ? 'font-bold' : ''
                                            }`}
                                        >
                                            {currencyConverter(
                                                paymentData.bankAmount,
                                                paymentData?.operationCurrency || 'USD',
                                            )}
                                        </div>
                                    </div>
                                )}
                                {paymentData?.walletAmount && (
                                    <div className="flex items-center gap-4">
                                        <div>Wallet Amount:</div>
                                        <div>{currencyConverter(paymentData.walletAmount, 'USD')}</div>
                                    </div>
                                )}
                            </>
                        )}

                        <div className={`${paymentData.source === 'bank' ? '' : 'col-span-2'} flex items-center gap-4`}>
                            <div>Total Amount:</div>
                            <div
                                className={`${['walletBank', 'bank'].includes(paymentData.source) ? '' : 'font-bold'}`}
                            >
                                {currencyConverter(paymentData.amount, 'USD')}
                            </div>
                        </div>

                        {['walletBank', 'bank'].includes(paymentData.source) ? (
                            <div className="col-span-2 flex items-center gap-4">
                                <div>Account Name:</div>
                                <div>{paymentData?.accountName}</div>
                            </div>
                        ) : null}

                        {paymentData.status === 1 &&
                            (paymentData.source === 'bank' || paymentData.source === 'walletBank') && (
                                <div className="col-span-2 flex justify-center mb-1">
                                    <div className="flex gap-3 justify-center">
                                        <Button
                                            disabled={isLoading}
                                            onClick={() => {
                                                decideOnPayment(3);
                                            }}
                                            color="light"
                                        >
                                            <AiOutlineClose className="ml-1 w-5 h-5" /> Reject
                                        </Button>
                                        <Button
                                            disabled={isLoading}
                                            onClick={() => {
                                                decideOnPayment(2);
                                            }}
                                            color="blue"
                                        >
                                            <AiOutlineCheck className="ml-1 w-5 h-5" /> Confirm
                                        </Button>
                                    </div>
                                </div>
                            )}

                        {paymentData?.verificationImage && (
                            <div className="col-span-2 flex justify-center">
                                <ImageProofVerification verifImage={paymentData} />
                            </div>
                        )}
                    </div>
                );

            case 'order':
                return (
                    <div className="p-4 grid grid-cols-2 gap-4">
                        <div className="flex items-center gap-4">
                            <div>Date</div>
                            <div>
                                {paymentData?.createdAt && format(new Date(paymentData?.createdAt), 'dd/MM/yyyy')}
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            <div>Type</div>
                            <div>{paymentType?.order?.statusIcon}</div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div>Status</div>
                            <div>{statusDisplay?.[paymentData.status]?.statusIcon}</div>
                        </div>
                        <div className="flex items-center gap-4">
                            <div>Total amount</div>
                            <div className="font-bold">{currencyConverter(paymentData.amount, 'USD')}</div>
                        </div>

                        <div className="my-1 col-span-2">
                            <div className="text-black justify-center w-full max-h-48">
                                <div className="overflow-x-auto overflow-y-auto max-h-48">
                                    <Table>
                                        <Table.Head>
                                            <Table.HeadCell>Order Ref</Table.HeadCell>
                                            <Table.HeadCell>Store</Table.HeadCell>
                                            <Table.HeadCell>Type</Table.HeadCell>
                                            <Table.HeadCell>Amount</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            {totalHandleFees ? (
                                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                    <Table.Cell>
                                                        {paymentData.ordersDetails
                                                            .map((order) => order.orederRef)
                                                            .join('; ')}
                                                    </Table.Cell>
                                                    <Table.Cell>{(paymentData?.store as IStore)?.storeName}</Table.Cell>{' '}
                                                    <Table.Cell>Handle</Table.Cell>
                                                    <Table.Cell>${totalHandleFees.toFixed(2)} </Table.Cell>
                                                </Table.Row>
                                            ) : null}

                                            {totalShippingFees ? (
                                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                    <Table.Cell>
                                                        {paymentData.ordersDetails
                                                            .map((order) => order.orederRef)
                                                            .join('; ')}
                                                    </Table.Cell>
                                                    <Table.Cell>{(paymentData?.store as IStore)?.storeName}</Table.Cell>{' '}
                                                    <Table.Cell>Shipping</Table.Cell>
                                                    <Table.Cell>${totalShippingFees.toFixed(2)} </Table.Cell>
                                                </Table.Row>
                                            ) : null}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case 'credit':
                return (
                    <div className="p-4 grid grid-cols-2 gap-4">
                        <div className="flex items-center gap-4">
                            <div>Date</div>
                            <div>
                                {paymentData?.createdAt && format(new Date(paymentData?.createdAt), 'dd/MM/yyyy')}
                            </div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div>Type</div>
                            <div>{paymentType?.credit?.statusIcon}</div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div>Status</div>
                            <div>{statusDisplay?.[paymentData.status]?.statusIcon}</div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div>Total amount</div>
                            <div className="font-bold">{currencyConverter(paymentData.amount, 'USD')}</div>
                        </div>

                        <div className="my-1 col-span-2">
                            <div className="text-black justify-center w-full max-h-64">
                                <div className="overflow-x-auto overflow-y-auto max-h-64">
                                    <Table>
                                        <Table.Head>
                                            <Table.HeadCell>Order Ref</Table.HeadCell>
                                            <Table.HeadCell>Product</Table.HeadCell>
                                            <Table.HeadCell>Store</Table.HeadCell>
                                            <Table.HeadCell>Type</Table.HeadCell>
                                            <Table.HeadCell>Amount</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            {paymentData?.adsCredits?.amount ? (
                                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                    <Table.Cell>{paymentData?.adsCredits?.orderRef || '-'}</Table.Cell>
                                                    <Table.Cell />
                                                    <Table.Cell>{(paymentData?.store as IStore)?.storeName}</Table.Cell>
                                                    <Table.Cell>Ads</Table.Cell>
                                                    <Table.Cell>
                                                        ${(paymentData?.adsCredits?.amount || 0)?.toFixed(2)}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ) : null}

                                            {paymentData?.cogsCredits?.length
                                                ? paymentData?.cogsCredits.map((cogs) => {
                                                      return (
                                                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                              <Table.Cell>{cogs?.orderRef || '-'}</Table.Cell>
                                                              <Table.Cell>{cogs?.productName || '-'}</Table.Cell>
                                                              <Table.Cell>
                                                                  {(paymentData?.store as IStore)?.storeName}
                                                              </Table.Cell>{' '}
                                                              <Table.Cell>Cogs</Table.Cell>
                                                              <Table.Cell>${cogs.payedAmount.toFixed(2)} </Table.Cell>
                                                          </Table.Row>
                                                      );
                                                  })
                                                : null}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    return <>{getRenderedElementByType(paymentData.type)}</>;
};

export default BankPayment;
