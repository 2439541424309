/* eslint-disable no-underscore-dangle */
import React, { useCallback, useState } from 'react';
import { getCustomerWallet, getWalletAmount, updateCustomerWalletAmount } from 'services/admin/wallet';
import { getCurrentUser } from 'utils/helpers/auth';
import { useLocation } from 'react-router-dom';
import { socket } from 'utils/socketIO';
import CustomizedCard from 'components/customCard/customizedCard';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';

import toast from 'react-hot-toast';
import { renderOpSatatusColor } from './wallet.styles';
import { getStatus, WalletVerifProps } from './wallet.types';

import 'react-medium-image-zoom/dist/styles.css';

const WalletHistoryCard: React.FC<WalletVerifProps> = ({
    walletCustomerHistory,
    operationId,
    setwalletCustomerHistory,
    noFilter,
    pagination,
    setCustomerWallet,
}) => {
    const location = useLocation().state as { customerName: string; customerId: string };
    const { customerName, customerId } = location;

    const confirmAmount = () => {
        updateCustomerWalletAmount(operationId, {
            status: 2,
        })
            .then(() => {
                getCustomerWallet({ ...pagination, ...noFilter }).then((res) => {
                    setwalletCustomerHistory(res.data.response.docs);
                });

                getWalletAmount(customerId).then((res) => {
                    setCustomerWallet(res.data);
                });

                socket.emit('newWalletOperation', {
                    user: getCurrentUser()._id,
                    about: 41,
                    walletOperation: operationId,
                    customerId,
                });
            })
            .then(() => {
                toast.success(`Amount of  $${walletCustomerHistory.amount} ACCEPTED for Customer ${customerName} `);
            });
    };
    const declineAmount = () => {
        updateCustomerWalletAmount(operationId, {
            status: 3,
        })
            .then(() => {
                getCustomerWallet({ ...pagination, ...noFilter }).then((res) => {
                    setwalletCustomerHistory(res.data.response.docs);
                });
                socket.emit('newWalletOperation', {
                    user: getCurrentUser()._id,
                    about: 41,
                    walletOperation: operationId,
                    customerId,
                });
            })
            .then(() => {
                toast.error(`Amount of  $${walletCustomerHistory.amount} REFUSED for Customer ${customerName} `);
            });
    };

    const [isZoomed, setIsZoomed] = useState(false);
    const handleZoomChange = useCallback((shouldZoom: any) => {
        setIsZoomed(shouldZoom);
    }, []);

    return (
        <>
            <CustomizedCard>
                <div className="grid grid-cols-5 gap-1 items-center">
                    <div>
                        <p>{walletCustomerHistory.accountName ? walletCustomerHistory.accountName : '-'}</p>
                    </div>
                    <div className=" text-center">
                        <p>{walletCustomerHistory.operationType}</p>
                    </div>
                    <div>
                        <p className={`${renderOpSatatusColor(walletCustomerHistory.status)} text-center`}>
                            {getStatus(walletCustomerHistory.status)}
                        </p>
                    </div>
                    <div>
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex">
                                <p>{walletCustomerHistory.createdAt.slice(0, 10)}</p>
                                <p>::</p>
                                <p>{walletCustomerHistory.createdAt.slice(11, 16)}</p>
                            </div>
                            <p>${walletCustomerHistory.amount}</p>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-center items-center gap-2">
                            {walletCustomerHistory.operationType === 'IN' && (
                                <>
                                    <div>
                                        {walletCustomerHistory.status === 1 && (
                                            <div className="flex justify-center items-center gap-2">
                                                <AiOutlineClose
                                                    className="h-6 w-6 text-red-600 cursor-pointer"
                                                    onClick={() => declineAmount()}
                                                />
                                                <AiOutlineCheck
                                                    className="h-6 w-6 text-green-600 cursor-pointer"
                                                    onClick={() => confirmAmount()}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
                                        <img
                                            src={walletCustomerHistory.verificationImage}
                                            alt=""
                                            style={{
                                                maxWidth: '50px',
                                                maxHeight: '50px',
                                                borderRadius: '8px',
                                            }}
                                        />
                                    </ControlledZoom>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </CustomizedCard>
        </>
    );
};

export default WalletHistoryCard;
