/* eslint-disable no-underscore-dangle */
import { Button, Label, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { IConfig } from 'services/admin/configuration';
import { updateCallCenterConfig } from 'services/callCenter/callCenterService';
import moment from 'moment-timezone';

const getMinutesFromCronExpression = (cronExpression?: string) => {
    if (!cronExpression) {
        return 15;
    }
    // cronExpression schema is "*/minutes * * * *"
    const cronIndexes = cronExpression.split(' ');
    // minutesIndex schema is "*/minutes"
    const minutesIndex = cronIndexes[0];

    const minutesStepValue = minutesIndex.substring(2);
    const isValidMinutes = !(
        Number(minutesStepValue) < 1 ||
        Number(minutesStepValue) > 59 ||
        Number.isNaN(Number(minutesStepValue)) ||
        `${minutesStepValue}`.includes(',') ||
        `${minutesStepValue}`.includes('.') ||
        `${minutesStepValue}`.length < 1
    );
    if (!isValidMinutes) {
        toast.error(`Invalid minutes value found! Defaulting to 15`);
        return 15;
    }
    return Number(minutesStepValue);
};

const JobsConfiguration: React.FC<{
    jobsConfig: IConfig['jobs'];
    callCenterId: IConfig['_id'];
}> = ({ jobsConfig, callCenterId }) => {
    const [orderAssignSchedule, setOrderAssignSchedule] = useState(
        getMinutesFromCronExpression(jobsConfig?.orderAssignSchedule || ''),
    );
    const [maxOngoingOrdersByAgent, setMaxOngoingOrdersByAgent] = useState(jobsConfig?.maxOngoingOrdersByAgent || 0);
    const [errors, setErrors] = useState<[string, string]>(['', '']);
    const [jobsConfigLoader, setJobsConfigLoader] = useState(false);

    const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'minutesStep') {
            const minutes = e.target.value;
            // validate minutes value
            if (Number.isNaN(Number(minutes)) || `${minutes}`.includes(',') || `${minutes}`.includes('.')) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[0] = `value must be a valid integer`;
                    return [draft[0], draft[1]];
                });
            } else if (Number(minutes) < 1 || Number(minutes) > 59) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[0] = `value must be betwin 0 and 59`;
                    return [draft[0], draft[1]];
                });
                setOrderAssignSchedule(Number(e.target.value));
            } else {
                if (errors[0] !== '') {
                    setErrors((prev) => {
                        const draft = [...prev];
                        draft[0] = '';
                        return [draft[0], draft[1]];
                    });
                }
                setOrderAssignSchedule(Number(e.target.value));
            }
        }
        if (e.target.name === 'maxOngoingOrdersByAgent') {
            const minutes = e.target.value;
            // validate minutes value
            if (Number.isNaN(Number(minutes)) || `${minutes}`.includes(',') || `${minutes}`.includes('.')) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[1] = `value must be a valid integer`;
                    return [draft[0], draft[1]];
                });
            } else if (Number(minutes) < 1) {
                setErrors((prev) => {
                    const draft = [...prev];
                    draft[1] = `Min value is 1`;
                    return [draft[0], draft[1]];
                });
                setMaxOngoingOrdersByAgent(Number(e.target.value));
            } else {
                if (errors[1] !== '') {
                    setErrors((prev) => {
                        const draft = [...prev];
                        draft[1] = '';
                        return [draft[0], draft[1]];
                    });
                }
                setMaxOngoingOrdersByAgent(Number(e.target.value));
            }
        }
    };

    const getNextRun = (minutes: number): string => {
        const now = new Date();
        const currentMinutes = now.getMinutes();
        const remainder = currentMinutes % minutes;
        const minutesToAdd = remainder === 0 ? minutes : minutes - remainder;
        const nextRunInUnix = now.setSeconds(0) + minutesToAdd * 60 * 1000;
        const shanghaiTime = moment.tz(nextRunInUnix, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
        return new Date(shanghaiTime).toLocaleString();
    };

    const handleCancel = () => {
        setOrderAssignSchedule(getMinutesFromCronExpression(jobsConfig.orderAssignSchedule));
        setMaxOngoingOrdersByAgent(jobsConfig?.maxOngoingOrdersByAgent || 12);
    };

    const handleSave = () => {
        setJobsConfigLoader(true);
        const toastId = toast.loading('Updating orders assignment configuration...');
        updateCallCenterConfig(callCenterId, {
            jobsConfig: {
                orderAssignSchedule: Number(orderAssignSchedule),
                maxOngoingOrdersByAgent: Number(maxOngoingOrdersByAgent),
            },
        })
            .then(() => {
                toast.success('Configuration updated successfully', { id: toastId });
                setJobsConfigLoader(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Something went wrong', { id: toastId });
                setJobsConfigLoader(false);
            });
    };
    return (
        <div className=" p-4 mb-2 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <h1 className="mb-5 text-lg font-bold leading-none text-gray-900 dark:text-white">
                Automated orders assignment
            </h1>
            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="minutesStep" value="Auto assign orders" />
                </div>
                <div className="flex items-center dark:bg-gray-700 bg-gray-100 w-fit rounded-md">
                    <p className="px-2 font-bold">run every</p>
                    <TextInput
                        name="minutesStep"
                        type="text"
                        sizing="md"
                        value={orderAssignSchedule}
                        className="max-w-[50px]"
                        onChange={handleValuesChange}
                    />
                    <p className="px-2 font-bold">minutes</p>
                </div>
                <p className="opacity-50 text-xs p-2">
                    Next run: <span className="font-bold">{getNextRun(orderAssignSchedule)}</span>
                </p>
                <p className="opacity-50 text-xs p-2">
                    *Update the <span className="font-bold">Automated orders assignment cycle</span> to automatically
                    run on every specified period
                </p>
                {errors[0] !== '' && <p className="text-red-700">{errors[0]}</p>}
            </div>

            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="maxOngoingOrdersByAgent" value="Max ongoing orders by agent" />
                </div>
                <div className="flex items-center dark:bg-gray-700 bg-gray-100 w-fit rounded-md">
                    <TextInput
                        name="maxOngoingOrdersByAgent"
                        type="text"
                        sizing="md"
                        value={maxOngoingOrdersByAgent}
                        className="max-w-[50px]"
                        onChange={handleValuesChange}
                    />
                    <p className="px-2 font-bold">orders</p>
                </div>
                <p className="opacity-50 text-xs p-2">
                    *Maximum <span className="font-bold">auto assigned</span> orders{' '}
                    <span className="font-bold">per agent</span>
                </p>
                {errors[1] !== '' && <p className="text-red-700">{errors[1]}</p>}
            </div>

            {(Number(getMinutesFromCronExpression(jobsConfig?.orderAssignSchedule)) !== Number(orderAssignSchedule) ||
                jobsConfig?.maxOngoingOrdersByAgent !== Number(maxOngoingOrdersByAgent)) &&
            errors.every((error) => error === '') ? (
                <div className="flex justify-end gap-2">
                    <Button
                        color="failure"
                        onClick={() => {
                            handleCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={jobsConfigLoader}
                        isProcessing={jobsConfigLoader}
                    >
                        Save
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default JobsConfiguration;
