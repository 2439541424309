import { Box } from '@mui/material';
import React from 'react';

import { ICustomer } from 'pages/customers/customer.types';
import { ChatStyleHeader } from './chat.styles';

const SupportChatHeader = ({ customer }: { customer: ICustomer }) => {
    return (
        <>
            <Box sx={ChatStyleHeader}>
                <div className="flex justify-center gap-3 w-full">
                    <div className="w-full">
                        <div className="flex justify-between items-center gap-3 w-full flex-wrap ">
                            <div>
                                <div className="flex items-center gap-4">
                                    <p className="truncate max-w-96 font-bold text-base font-popin ">
                                        Seller:{' '}
                                        {customer?.firstName
                                            ? `${customer.firstName} ${customer.lastName}`
                                            : customer.email}
                                    </p>
                                </div>
                            </div>
                            <div />
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
};

export default SupportChatHeader;
