import React from 'react';
import { Badge } from 'flowbite-react';
import { FaRegCalendarCheck, FaRegCheckCircle } from 'react-icons/fa';
import { TiPinOutline } from 'react-icons/ti';

import { IoCloseCircleOutline } from 'react-icons/io5';
import { BsTelephoneX } from 'react-icons/bs';
import { TbPhoneOff, TbTruckDelivery } from 'react-icons/tb';
import { PiHourglassLowLight, PiPackage, PiPhoneBold, PiPhoneSlashLight } from 'react-icons/pi';
import { CardInfo } from './dashboard.types';
import { TableColDef, IStausInterface } from '../../../components/tables/tables.types';

export const columns: TableColDef[] = [
    {
        field: 'customer',
        headerName: 'Seller',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
        fieldType: 'combo',
        child: ['firstName', 'lastName'],
    },
    {
        field: 'store',
        headerName: 'Store',
        fieldType: 'combo',
        child: ['storeName'],
    },
    {
        field: 'createdAt',
        headerName: 'Date',
        fieldType: 'date',
    },
    {
        field: 'generationTiming',
        headerName: 'Timing',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },

    {
        field: 'Action',
        actionType: 'Invoice',
        headerName: 'Update',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Treating
            </Badge>
        ),
        statusLabel: 'Treating',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Paid
            </Badge>
        ),
        statusLabel: 'Paid',
    },
};

export const reportcards: CardInfo[] = [
    {
        key: 'confirmedCount',
        title: 'Confirmed',
        color: 'green',
        Icon: FaRegCheckCircle,
    },
    {
        key: 'canceledCount',
        title: 'Canceled',
        color: 'red',
        Icon: IoCloseCircleOutline,
    },
    {
        key: 'wrongNumberCount',
        title: 'Wrong Number',
        color: 'orange',
        bgColor: 'yellow',
        Icon: TbPhoneOff,
    },
    {
        key: 'expiredCount',
        title: 'Expired',
        color: 'orange',
        bgColor: 'yellow',
        Icon: PiHourglassLowLight,
    },
    {
        key: 'otherCount',
        title: 'Other',
        color: 'orange',
        bgColor: 'yellow',
        Icon: BsTelephoneX,
    },
    {
        key: 'deliveryCount',
        title: 'Delivered',
        color: 'green',
        Icon: TbTruckDelivery,
    },
];
export const activitycards: CardInfo[] = [
    {
        key: 'confirmedCount', // 'confirmedCount',
        title: 'Confirmed',
        color: 'green',
        Icon: FaRegCheckCircle,
    },
    {
        key: 'canceledCount',
        title: 'Canceled',
        color: 'red',
        Icon: IoCloseCircleOutline,
    },
    {
        key: 'wrongNumberCount',
        title: 'Wrong Number',
        color: 'orange',
        bgColor: 'yellow',
        Icon: PiPhoneSlashLight,
    },
    {
        key: 'expiredCount',
        title: 'Expired',
        color: 'orange',
        bgColor: 'yellow',
        Icon: PiHourglassLowLight,
    },
    {
        key: 'unreachableCount',
        title: 'Unreachable',
        color: 'orange',
        bgColor: 'yellow',
        Icon: BsTelephoneX,
    },
    {
        key: 'scheduledCallCount',
        title: 'Scheduled',
        color: 'blue',
        Icon: FaRegCalendarCheck,
    },
    {
        key: 'pendingAnswersCount',
        title: 'Pending Answers',
        color: 'blue',
        Icon: TiPinOutline,
    },
    // {
    //     key: 'answeredCount',
    //     title: 'Confirmed',
    //     color: 'yellow',
    //     Icon: BsTelephoneX,
    //
    // },
    // {
    //     key: 'ordersAssignedCount',
    //     title: 'Confirmed',
    //     color: 'yellow',
    //     Icon: BsTelephoneX,
    //
    // },
];
export const reportGeneralInfo: CardInfo[] = [
    {
        key: 'ordersAssignedCount',
        title: 'Total Orders',
        color: 'purple',
        Icon: PiPackage,
    },
    {
        key: 'confirmedCount',
        title: 'Confirmation rate',
        color: 'purple',
        Icon: PiPhoneBold,
        perc: true,
    },
    {
        key: 'deliveryCount',
        title: 'Delivery rate',
        color: 'purple',
        Icon: TbTruckDelivery,
        perc: true,
    },
];

export const activityGeneralInfo: CardInfo[] = [
    {
        key: 'newCount',
        title: 'New Leads',
        color: 'purple',
        Icon: PiPackage,
    },
    {
        key: 'orderCalledCount',
        title: 'Called Leads',
        color: 'purple',
        Icon: PiPhoneBold,
    },
];
