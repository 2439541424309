import { Button, Dropdown, Modal, Select } from 'flowbite-react';
import React, { useState } from 'react';
import { HiDotsVertical, HiGlobe, HiPause, HiPlay } from 'react-icons/hi';
import { countries } from 'utils/countryList';
import { getCampaigns, pauseCampaign, startCampaign, updateLGSTCountry } from 'services/admin/campaign';
import toast from 'react-hot-toast';
import { ICampaignActions } from './campaign.types';

const CampaignActions: React.FC<ICampaignActions> = (props: ICampaignActions) => {
    const { campaign, setRows, currentPage, currentFilter } = props;
    const [open, setOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');

    const Start = () => {
        startCampaign({ campaignId: campaign.id })
            .then(() => {
                toast.success('Campaign successfully');
                setOpen(false);
                getCampaigns({
                    page: currentPage,
                    limit: 20,
                    filter: { ...currentFilter },
                }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch(() => {
                toast.error('Unable to update campaign');
            });
    };

    const Pause = () => {
        pauseCampaign({ campaignId: campaign.id })
            .then(() => {
                toast.success('Campaign successfully');
                setOpen(false);
                getCampaigns({
                    page: currentPage,
                    limit: 20,
                    filter: { ...currentFilter },
                }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch(() => {
                toast.error('Unable to update campaign');
            });
    };

    const Update = () => {
        updateLGSTCountry({ campaignId: campaign.id, name: campaign.name, country: selectedCountry })
            .then(() => {
                toast.success('Campaign successfully');
                setOpen(false);
                getCampaigns({
                    page: currentPage,
                    limit: 20,
                    filter: { ...currentFilter },
                }).then((res) => {
                    setRows(res.data.docs);
                });
            })
            .catch(() => {
                toast.error('Unable to update campaign');
            });
    };

    const actionsListAdmin = [
        {
            label: 'Start',
            action: Start,
            iconAcion: HiPlay,
            actionStatus: true,
        },
        {
            label: 'Pause',
            action: Pause,
            iconAcion: HiPause,
            actionStatus: true,
        },
        {
            label: 'Sync Country',
            action: () => setOpen(true),
            iconAcion: HiGlobe,
            actionStatus: true,
        },
    ];

    return (
        <>
            <Dropdown inline arrowIcon={false} label={<HiDotsVertical />}>
                {actionsListAdmin.map((action) => {
                    if (action.actionStatus) {
                        return (
                            <Dropdown.Item icon={action.iconAcion} onClick={action.action} key={action.label}>
                                {action.label}
                            </Dropdown.Item>
                        );
                    }
                    return null;
                })}
            </Dropdown>

            <Modal show={open} onClose={() => setOpen(false)} dismissible title="Available Countries">
                <div className="p-4">
                    <h4 className="my-5">
                        To launch the campaign, please select the predefined country associated with it.
                    </h4>
                    <Select
                        value={selectedCountry}
                        onChange={(e) => setSelectedCountry(e.target.value)}
                        className="w-full"
                    >
                        <option value="">Select a country</option>
                        {countries.map((country) => (
                            <option key={country.label} value={country.label}>
                                {country.label}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="flex justify-center gap-4 mb-5">
                    <Button
                        color="blue"
                        onClick={() => {
                            Update();
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        color="gray"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    );
};
export default CampaignActions;
