import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const getCustomers = async (filter: any) => {
    const customers = await axios.post(`/admin/customers/filter`, filter, getBearerToken());
    return customers;
};

export const addCustomer = async (data: any) => {
    const newCustomer = await axios.post(`/admin/customers`, data, getBearerToken());
    return newCustomer;
};

export const updateCustomer = async (id: string | undefined, data: any) => {
    const customerUpdated = await axios.put(`/admin/customers/${id}`, data, getBearerToken());
    return customerUpdated;
};

export const getCustomer = async (id: string | undefined) => {
    const customer = await axios.get(`/admin/customers/${id}`, getBearerToken());
    return customer;
};

export interface IassignCustomersInput {
    customers: string[];
    logistioBulkFees: string;
}

export const assignCustomersToLogitioBulkFees = async (data: IassignCustomersInput) => {
    const customer = await axios.post(`/admin/customers/assignCustomersToLogitioBulkFees`, data, getBearerToken());
    return customer;
};
