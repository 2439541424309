/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { TextInput } from 'flowbite-react';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import TextError from 'components/customError/customErrorText';
import FormError from 'components/customError/formError';
import { IAddResellerProduct, QuotationFormProps } from './types';
import ErrorMsg from './formError';

interface IProps {
    name: string;
    index: number;
}
const TotalCost = (props: IProps) => {
    const { name, index } = props;
    const {
        values: { resellerQuotation },
    } = useFormikContext<IAddResellerProduct>();
    const totalCost = useMemo(() => {
        const total =
            (Number(resellerQuotation[index].sellingPrice) || 0) - Number(resellerQuotation[index].productCost);
        return total % 1 ? total.toFixed(2) : total;
    }, [resellerQuotation[index].sellingPrice]);

    return (
        <div key={name} className="pr-2 h-12">
            <TextInput
                disabled
                value={totalCost}
                name={name}
                helperText={
                    <span>
                        <TextError name={name} component="div" />
                    </span>
                }
                type="text"
                sizing="m"
                theme={{
                    base: 'flex',
                    addon: 'inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400',
                    field: {
                        base: 'relative w-full',
                        icon: {
                            base: 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        rightIcon: {
                            base: 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        input: {
                            base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-80 disabled:decoration-solid !bg-gray-200 dark:!bg-gray-700',
                        },
                    },
                }}
            />
        </div>
    );
};
const QuotationDetailsForm: React.FC<QuotationFormProps> = (props) => {
    const { formik, error } = props;
    return (
        <div className="m-4">
            <div className=" leading-7 text-lg font-medium h-8 mb-4">Quotation</div>
            <div className="mb-4">
                {' '}
                <div className={` grid  grid-cols-7 bg-gray-100 dark:bg-gray-600 items-center p-2`}>
                    <div>Country</div>
                    <div>Products cost ($)</div>
                    <div>
                        Unit.Selling price($)<span className="text-red-600 "> *</span>
                    </div>
                    <div>Unit.Profit($)</div>
                    <div>Recommended price</div>
                    <div>
                        Quantity Limit<span className="text-red-600 "> *</span>
                    </div>
                </div>
            </div>
            <div>
                {formik.values.resellerQuotation.map((_, index) => {
                    return (
                        <div className="grid  grid-cols-7 h-16 mb-3">
                            <div key="quantity" className="pr-2 h-12">
                                <TextInput
                                    {...formik.getFieldProps(`resellerQuotation.${index}.country`)}
                                    name={`resellerQuotation[${index}].country`}
                                    type="text"
                                    sizing="m"
                                    theme={{
                                        field: {
                                            input: {
                                                base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-80 disabled:decoration-solid !bg-gray-200 dark:!bg-gray-700 ',
                                            },
                                        },
                                    }}
                                    disabled
                                />
                            </div>
                            <div key="productCost" className="pr-2 h-12">
                                <TextInput
                                    {...formik.getFieldProps(`resellerQuotation.${index}.productCost`)}
                                    name={`resellerQuotation[${index}].productCost`}
                                    type="text"
                                    sizing="m"
                                    theme={{
                                        field: {
                                            input: {
                                                base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-80 disabled:decoration-solid !bg-gray-200 dark:!bg-gray-700 ',
                                            },
                                        },
                                    }}
                                    disabled
                                />
                            </div>
                            <div key="unitPurchasePrice" className="pr-2 h-12">
                                <TextInput
                                    {...formik.getFieldProps(`resellerQuotation.${index}.sellingPrice`)}
                                    name={`resellerQuotation[${index}].sellingPrice`}
                                    type="text"
                                    sizing="m"
                                />
                                <span className="pl-2">
                                    <ErrorMsg
                                        name={`resellerQuotation[${index}].sellingPrice`}
                                        formik={formik}
                                        error={error}
                                    />{' '}
                                </span>
                            </div>
                            <TotalCost index={index} name={`resellerQuotation[${index}].unitProfit`} />
                            <div key="recommendedPrice" className="pr-2 h-12">
                                <TextInput
                                    {...formik.getFieldProps(`resellerQuotation.${index}.recommendedPrice`)}
                                    name={`resellerQuotation[${index}].recommendedPrice`}
                                    type="text"
                                    sizing="m"
                                />
                                <span className="pl-2">
                                    <FormError name={`resellerQuotation[${index}].recommendedPrice`} component="div" />
                                </span>
                            </div>
                            <div key="quantityLimit" className="pr-2 h-12">
                                <TextInput
                                    {...formik.getFieldProps(`resellerQuotation.${index}.quantityLimit`)}
                                    name={`resellerQuotation[${index}].quantityLimit`}
                                    type="text"
                                    sizing="m"
                                />
                                <span className="pl-2">
                                    <FormError name={`resellerQuotation[${index}].quantityLimit`} component="div" />
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
            <>
                <ErrorMessage
                    name="resellerQuotation"
                    render={(msg) => (
                        <>
                            {typeof msg === 'string' ? (
                                <span>
                                    <span className="text-xs text-red-600 font-semibold">*{msg} </span>
                                </span>
                            ) : null}
                        </>
                    )}
                />
            </>
        </div>
    );
};
export default QuotationDetailsForm;
