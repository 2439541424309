/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Select, TextInput } from 'flowbite-react';
import { ErrorMessage, FieldArray, useField, useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';

import { IoMdAddCircleOutline } from 'react-icons/io';

import { countries } from 'utils/countryList';

import TextError from 'components/customError/customErrorText';
import FormError from 'components/customError/formError';
import { IResellerQuotation } from 'pages/reseller/reseller.types';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IAddResellerProduct, QuotationFormProps } from './types';
import ErrorMsg from './formError';

interface IProps {
    name: string;
    index: number;
}
const ProductCost = (props: IProps) => {
    const { name, index } = props;
    const {
        values: { resellerQuotation },
        setFieldValue,
    } = useFormikContext<IAddResellerProduct>();
    const [field] = useField(name);

    useEffect(() => {
        // set the value of textC, based on textA and textB
        let unitShippingFee = 0;
        if (Number(resellerQuotation[index].totalShippingFee) && Number(resellerQuotation[index].quantity)) {
            unitShippingFee =
                Number(resellerQuotation[index].totalShippingFee) / Number(resellerQuotation[index].quantity);
        }
        const productCost = unitShippingFee + (Number(resellerQuotation[index].unitPurchasePrice) || 0);
        setFieldValue(name, productCost % 1 ? productCost.toFixed(2) : productCost);
    }, [
        resellerQuotation[index].quantity,
        resellerQuotation[index].totalShippingFee,
        resellerQuotation[index].unitPurchasePrice,
    ]);

    return (
        <div key="productCost" className="pr-2 h-12">
            <TextInput
                disabled
                {...field}
                name={name}
                helperText={
                    <span>
                        <TextError name={name} component="div" />
                    </span>
                }
                type="text"
                sizing="m"
                theme={{
                    base: 'flex',
                    addon: 'inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400',
                    field: {
                        base: 'relative w-full',
                        icon: {
                            base: 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        rightIcon: {
                            base: 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        input: {
                            base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-80 disabled:decoration-solid !bg-gray-200 dark:!bg-gray-700',
                        },
                    },
                }}
            />
        </div>
    );
};
const TotalCost = (props: IProps) => {
    const { name, index } = props;
    const {
        values: { resellerQuotation },
    } = useFormikContext<IAddResellerProduct>();
    const totalCost = useMemo(() => {
        // set the value of textC, based on textA and textB
        const total = (Number(resellerQuotation[index].quantity) || 0) * Number(resellerQuotation[index].productCost);
        return total % 1 ? total.toFixed(2) : total;
    }, [resellerQuotation[index].quantity, resellerQuotation[index].productCost]);

    return (
        <div key={name} className="pr-2 h-12">
            <TextInput
                disabled
                value={totalCost}
                name={name}
                helperText={
                    <span>
                        <TextError name={name} component="div" />
                    </span>
                }
                type="text"
                sizing="m"
                theme={{
                    base: 'flex',
                    addon: 'inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400',
                    field: {
                        base: 'relative w-full',
                        icon: {
                            base: 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        rightIcon: {
                            base: 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3',
                            svg: 'h-5 w-5 text-gray-500 dark:text-gray-400',
                        },
                        input: {
                            base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-80 disabled:decoration-solid !bg-gray-200 dark:!bg-gray-700',
                        },
                    },
                }}
            />
        </div>
    );
};
const QuotationForm: React.FC<QuotationFormProps> = (props) => {
    const { formik, edit, error } = props;
    const initQuotation = {
        country: '',
        packaging: '',
        type: '',
        trackingCode: undefined,
        productCost: 0,
        unitProfit: 0,
        accepted: true,
        isHidden: false,
        // to check wich field can be updated
        editable: true,
    };
    const checkCountrySelected = (country: string, quotations: IResellerQuotation[]): boolean => {
        let isContained = false;
        // eslint-disable-next-line no-unreachable-loop
        for (const quotation of quotations) {
            if (quotation.country === country) {
                isContained = true;

                break;
            }
        }
        return isContained;
    };

    const tt = formik.getFieldProps(`resellerQuotation`).value;
    return (
        <div className="m-4">
            <div className=" leading-7 text-lg font-medium h-8 mb-4">Quotation</div>
            <div className="mb-4">
                {' '}
                <div className={` grid  grid-cols-${edit ? '8' : '7'} bg-gray-100 dark:bg-gray-600 items-center p-2`}>
                    <div>
                        Country<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Quantity<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Unit P.Price ($)<span className="text-red-600 "> *</span>
                    </div>
                    <div>
                        Shipping fee ($)<span className="text-red-600 "> *</span>
                    </div>
                    <div>Unit.cost ($)</div>
                    <div>Total cost ($)</div>
                    <div>Tracking code</div>
                    {edit && <div className="text-end ">Action</div>}
                </div>
            </div>
            <FieldArray
                {...formik.getFieldProps('resellerQuotation')}
                render={(arrayHelpers) => (
                    <div>
                        {formik.values.resellerQuotation.map((quotation, index) => {
                            return (
                                <div
                                    className={`grid  grid-cols-${
                                        edit ? '8' : '7'
                                    } h-16 border-b-2 border-gray-100 mb-3`}
                                >
                                    {edit ? (
                                        <div key="country" className="pr-2 h-12">
                                            <Select
                                                id="countriesList"
                                                {...formik.getFieldProps(`resellerQuotation[${index}].country`)}
                                                name={`resellerQuotation[${index}].country`}
                                                sizing="m"
                                                disabled={!edit}
                                            >
                                                <option key="null val" value="">
                                                    Choose country
                                                </option>
                                                {countries.map((country) => {
                                                    const disabled = checkCountrySelected(
                                                        country.label,
                                                        formik.values.resellerQuotation,
                                                    );
                                                    return (
                                                        <option
                                                            key={country.code}
                                                            value={country.label}
                                                            disabled={disabled}
                                                        >
                                                            {country.label}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                            <span className="pl-2">
                                                <FormError
                                                    name={`resellerQuotation[${index}].country`}
                                                    component="div"
                                                />
                                            </span>
                                        </div>
                                    ) : (
                                        <div key="country" className="pr-2 h-12">
                                            <TextInput
                                                {...formik.getFieldProps(`resellerQuotation.${index}.country`)}
                                                name={`resellerQuotation[${index}].country`}
                                                type="text"
                                                sizing="m"
                                                disabled={!edit}
                                            />
                                        </div>
                                    )}
                                    <div key="quantity" className="pr-2 h-12">
                                        <TextInput
                                            id={`resellerQuotation[${index}].quantity`}
                                            {...formik.getFieldProps(`resellerQuotation.${index}.quantity`)}
                                            value={tt[index].quantity}
                                            defaultValue={quotation.quantity}
                                            name={`resellerQuotation[${index}].quantity`}
                                            type="text"
                                            sizing="m"
                                            disabled={!edit}
                                        />

                                        <span className="pl-2">
                                            <ErrorMsg
                                                name={`resellerQuotation[${index}].quantity`}
                                                formik={formik}
                                                error={error}
                                            />{' '}
                                        </span>
                                    </div>
                                    <div key="unitPurchasePrice" className="pr-2 h-12">
                                        <TextInput
                                            {...formik.getFieldProps(`resellerQuotation.${index}.unitPurchasePrice`)}
                                            name={`resellerQuotation[${index}].unitPurchasePrice`}
                                            type="text"
                                            sizing="m"
                                            disabled={!edit}
                                        />
                                        <span className="pl-2">
                                            <ErrorMsg
                                                name={`resellerQuotation.${index}.unitPurchasePrice`}
                                                formik={formik}
                                                error={error}
                                            />{' '}
                                        </span>
                                    </div>
                                    <div key="totalShippingFee" className="pr-2 h-12">
                                        <TextInput
                                            {...formik.getFieldProps(`resellerQuotation.${index}.totalShippingFee`)}
                                            name={`resellerQuotation[${index}].totalShippingFee`}
                                            type="text"
                                            sizing="m"
                                            disabled={!edit}
                                        />
                                        <span className="pl-2">
                                            <span className="pl-2">
                                                <ErrorMsg
                                                    name={`resellerQuotation[${index}].totalShippingFee`}
                                                    formik={formik}
                                                    error={error}
                                                />{' '}
                                            </span>
                                        </span>
                                    </div>
                                    <ProductCost index={index} name={`resellerQuotation[${index}].productCost`} />
                                    <TotalCost index={index} name={`resellerQuotation[${index}].totalCost`} />

                                    {/* tracking code is useful if the stock related to the warehouse is alredy shipped and delivered */}
                                    <div key="trackingCode" className="pr-2 h-12">
                                        <TextInput
                                            {...formik.getFieldProps(`resellerQuotation.${index}.trackingCode`)}
                                            name={`resellerQuotation[${index}].trackingCode`}
                                            type="text"
                                            helperText={<span />}
                                            sizing="m"
                                            disabled={!edit}
                                        />
                                        <span className="pl-2">
                                            <FormError
                                                name={`resellerQuotation[${index}].trackingCode`}
                                                component="div"
                                            />
                                        </span>
                                    </div>
                                    {edit && formik.values.resellerQuotation.length > 1 && (
                                        <div className="text-end pt-2 pr-4">
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                                disabled={!edit}
                                            >
                                                <RiDeleteBin6Line className="h-6 w-6" color="red" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        {edit && (
                            <div className="flex w-full justify-end pr-2">
                                <button onClick={() => arrayHelpers.push(initQuotation)} type="button" className="p-2">
                                    <IoMdAddCircleOutline className="w-6 h-6" />
                                </button>
                            </div>
                        )}
                    </div>
                )}
            />
            <>
                <ErrorMessage
                    name="resellerQuotation"
                    render={(msg) => (
                        <>
                            {typeof msg === 'string' ? (
                                <span>
                                    <span className="text-xs text-red-600 font-semibold">*{msg} </span>
                                </span>
                            ) : null}
                        </>
                    )}
                />
            </>
        </div>
    );
};
export default QuotationForm;
