/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Dropdown, Modal } from 'flowbite-react';
import { HiPencil, HiDotsVertical } from 'react-icons/hi';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { MdOutlineReplayCircleFilled } from 'react-icons/md';
import { IStockActions } from './stockElements';
import StockPacking from './stockPacking';
import StockRefill from './refillStock';

const ResellerStockActions = (props: IStockActions) => {
    const [modalUpdateStock, setModalUpdateStock] = useState(false);
    const [modalRefillStock, setModalRefillStock] = useState(false);
    const { stock, setRows, currentPage, currentFilter } = props;

    const actionsList = [
        {
            label: 'Ready for pickup',
            action: () => {
                // eslint-disable-next-line no-unused-expressions
                stock.status === 1
                    ? setModalUpdateStock(true)
                    : toast.error('Cannot set stock to Ready for pickup in this stage');
            },
            iconAcion: HiPencil,
            disabled: false,
        },
        {
            label: 'Refill stock',
            action: () => {
                setModalRefillStock(true);
            },
            iconAcion: MdOutlineReplayCircleFilled,
            disabled: false,
        },
    ];

    return (
        <>
            <Modal
                dismissible
                show={modalUpdateStock}
                size="3xl"
                popup
                onClose={() => {
                    setModalUpdateStock(false);
                }}
            >
                <Modal.Header>Update Reseller Stock</Modal.Header>
                <Modal.Body>
                    <StockPacking
                        stock={stock}
                        setRows={setRows}
                        currentPage={currentPage}
                        currentFilter={currentFilter}
                        setModal={setModalUpdateStock}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                dismissible
                show={modalRefillStock}
                size="3xl"
                popup
                onClose={() => {
                    setModalRefillStock(false);
                }}
            >
                <Modal.Header>Refill Reseller Stock</Modal.Header>
                <Modal.Body>
                    <StockRefill
                        stock={stock}
                        setRows={setRows}
                        currentPage={currentPage}
                        currentFilter={currentFilter}
                        setModal={setModalRefillStock}
                    />
                </Modal.Body>
            </Modal>
            <Dropdown inline arrowIcon={false} label={<HiDotsVertical />}>
                {actionsList.map((action) => {
                    return (
                        <Dropdown.Item
                            icon={action.iconAcion}
                            onClick={action.action}
                            key={action.label}
                            disabled={action.disabled}
                            hidden={action.disabled}
                        >
                            {action.label}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </>
    );
};
export default ResellerStockActions;
