/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import MessageFile from 'components/messageFile/MessageFile';
import MessageInput from 'components/messageInput/MessageInput';
import MessageText from 'components/messageText/MessageText';
import React, { forwardRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import { socket } from 'utils/socketIO';
import ModalLoader from 'utils/helpers/themeStyle/modalLoader';

import { LoadPreviousMessages, LoadPreviousMessagesWrapper, MessageTexts, MessageTextsContainer } from './chat.styles';
import SupportChatHeader from './supportChatHeader';

const SupportChat = forwardRef(() => {
    const location: any = useLocation();
    const [currentRef, setCurrentRef] = useState('last');
    const [convMessages, setConvMessages] = useState<any>([]);
    const myRef = React.useRef<any>();
    const [limit, setLimit] = useState(0);
    const [supportChatInfo, setSupportChatInfo] = useState<any>({});
    const [totalMessages, setTotalMessages] = useState(0);

    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [convMessages]);

    useEffect(() => {
        // Creates a WebSocket connection

        socket.emit(
            'joinSupportChat',
            JSON.stringify({
                user: getCurrentUser()._id,
                recieverId: location.state?.customer?._id,
            }),
        );

        socket.on('paginate-support-messages', (data: any) => {
            if (data.totalDocs) setTotalMessages(data.totalDocs);
            if (data.limit) setLimit(data.limit);
            if (data.docs) {
                setConvMessages(data.docs);
            }
            setCurrentRef('last');
        });

        socket.on('new-support-message', (data: any) => {
            if (data.docs) {
                if (data.docs.length === 1) {
                    setConvMessages((conv: any) => [...data.docs, ...conv]);
                } else setConvMessages(data.docs);
                if (getCurrentUser()._id !== data.docs[0].to) {
                    socket.emit('newChatMessage', {
                        user: getCurrentUser()._id,
                        about: 23, // new support message
                        customerId: location.state?.customer?._id,
                        messageId: data.docs[0]._id,
                    });
                }
            }

            setCurrentRef('last');
        });

        return () => {
            socket.off('paginate-support-messages');
            socket.off('new-support-message');
        };
    }, []);

    useEffect(() => {
        if (location.state?.data) setSupportChatInfo(location.state.data);
    }, [location.state?.data]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_progressCount, setProgressCount] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_fileName, setFileName] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_fileSize, setFileSize] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_displayuploadingFile, setDisplayUploadingFile] = useState(false);

    if (!supportChatInfo) {
        return <ModalLoader />;
    }

    return (
        <div className="h-[90vh] bg-background-secondary relative">
            <div className="h-[calc(90vh-(104px-50px))] bg-background-secondary  overflow-hidden hover:overflow-y-auto">
                {location.state?.customer && <SupportChatHeader customer={location.state?.customer} />}

                <Divider />
                {limit < totalMessages && (
                    <Box sx={LoadPreviousMessagesWrapper}>
                        <Box
                            onClick={() => {
                                socket.emit(
                                    'paginateSupportMessages',
                                    JSON.stringify({
                                        limit: limit + 20,
                                        user: getCurrentUser()._id,
                                        order: location.state.data._id,
                                    }),
                                );
                                setLimit((lmt) => lmt + 20);
                                setCurrentRef('first');
                            }}
                            sx={LoadPreviousMessages}
                        >
                            <ExpandLessIcon /> Click to load previous messages
                        </Box>
                    </Box>
                )}

                <Box sx={MessageTextsContainer}>
                    {currentRef === 'first' && <Box ref={myRef} />}
                    {convMessages &&
                        convMessages
                            .slice(0)
                            .reverse()
                            .map((message: any) => (
                                <Box sx={MessageTexts}>
                                    {message.message ? (
                                        <MessageText
                                            entity={message.from}
                                            message={message.message}
                                            received={message.onModelFrom === 'Customer'}
                                            timestamp={message.createdAt}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={message.id}
                                        />
                                    ) : message.file ? (
                                        <MessageFile
                                            file={message.file}
                                            received={message.onModelFrom === 'Customer'}
                                            timestamp={message.createdAt}
                                            fileName={message.fileName}
                                            fileSize={message.fileSize}
                                        />
                                    ) : null}
                                </Box>
                            ))}
                    {currentRef === 'last' && <Box ref={myRef} />}
                </Box>
            </div>
            <Divider />
            <MessageInput
                socketRef={socket}
                action="newSupportMessage"
                setProgressCount={setProgressCount}
                setFileName={setFileName}
                setFileSize={setFileSize}
                setDisplayUploadingFile={setDisplayUploadingFile}
                customerId={location.state?.customer?._id}
            />
        </div>
    );
});

export default SupportChat;
