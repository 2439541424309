/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser, getUser } from 'utils/helpers/auth';
import { Avatar, Breadcrumb, Dropdown, Navbar } from 'flowbite-react';
import { FaBars } from 'react-icons/fa';
import { HiHome } from 'react-icons/hi';
import { CgProfile, CgLogOut } from 'react-icons/cg';
import Notifications from 'components/notifications/Notifications';
import ThemeSwitch from 'components/Switch/themeSwitch';
import { v4 as uuidv4 } from 'uuid';
import { useZiwo } from 'utils/zustand/ziwo';

interface bredCInterface {
    element: string;
    gobackUrl?: string;
    key: string;
}
interface ILocation {
    pathname: string;
    state?: any;
}
const CustomNavbar: React.FC<{
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setCollapsed, collapsed }) => {
    const navigate = useNavigate();
    const z = useZiwo();
    const { pathname, state }: ILocation = useLocation();
    const pathnameSplit = pathname.split('/');
    const breadCrumbsConfiguration = () => {
        const list = pathnameSplit
            .slice(2)
            .map((el) => ({ element: el, gobackUrl: '', key: uuidv4() }) as bredCInterface);
        if (list?.length === 1 && list[0].element === 'products') {
            list.splice(0, 1, { element: 'products list', key: uuidv4() });
        }
        if (list?.length === 1 && list[0].element === 'product-settings' && state?.productName) {
            list.splice(
                0,
                1,
                {
                    element: 'products list',
                    gobackUrl: `/${pathnameSplit[1]}/${state.isRestock ? 'restock' : 'products'}`,
                    key: uuidv4(),
                },
                { element: state.productName, key: uuidv4() },
            );
        }

        if (
            list?.length === 1 &&
            ['resellerProducts-content-details', 'resellerProducts-details', 'new-reseller-Product'].includes(
                list[0].element,
            )
        ) {
            list.splice(
                0,
                1,
                {
                    element: 'reseller Products list',
                    gobackUrl: `/${pathnameSplit[1]}/${
                        !list[0]?.element?.includes('content') ? 'resellerProducts' : 'resellerProducts-content'
                    }`,
                    key: uuidv4(),
                },
                { element: state?.productName, key: uuidv4() },
            );
        }
        return list;
    };
    const bCrumbs = breadCrumbsConfiguration();

    // const breadcrumbs = useBreadcrumbs(routes);

    const getName = `${getUser().firstName === undefined ? '' : getUser().firstName} ${
        getUser().lastName === undefined ? '' : getUser().lastName
    }`;
    const name = getName.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        if (z) {
            z.session.logout();
        }
        localStorage.removeItem('ZIWO_LOGIN_DETAILS');
        localStorage.removeItem('ZIWO_SELECTED_STATUS');
        localStorage.removeItem('ZIWO_SESSION_DETAILS');
        navigate('/auth/login');
    };
    const getInitials = () => {
        const firstInt = name?.charAt(0);
        const secondInt = name?.indexOf(' ') !== -1 && name?.substring(name.indexOf(' ') + 1, name.length).charAt(0);
        return `${firstInt} ${secondInt}`;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_value, setValue] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const getNavBarTitle = (label: string) => {
        switch (label) {
            case 'products list':
                return 'Product list';

            case 'stock-shipments':
                return 'Stock shipments';

            case 'resellerProducts':
                return 'Reseller products';

            case 'resellerStocks':
                return 'Reseller stocks';

            case 'walletOperations':
                return 'Wallet pperations';

            case 'prepaid-invoices':
                return 'Prepaid invoices';

            case 'adminList':
                return 'Admin list';

            case 'accountManagerList':
                return 'Account manager list';

            case 'productProviderList':
                return 'Sourcing agent list';

            case 'callCenterList':
                return 'Call center list';

            case 'callCenterAdminList':
                return 'Call center admin list';

            case 'callAgentList':
                return 'Call agent list';
            case 'resellerProducts-details':
                return 'Reseller products details';
            case 'resellerProducts-content-details':
                return 'Reseller Products Content';
            case 'resellerProducts-content':
                return 'Reseller Products list';

            default:
                return label;
        }
    };
    return (
        <>
            <Navbar fluid>
                <Navbar.Brand>
                    <FaBars
                        className="w-6 h-6 cursor-pointer text-gray-400 mr-2"
                        onClick={() => setCollapsed(!collapsed)}
                    />
                    <Breadcrumb aria-label="Default breadcrumb example">
                        <Link to={`/${getCurrentUser().role}`}>
                            <Breadcrumb.Item icon={HiHome} />
                        </Link>
                        {bCrumbs.map((bredC) => {
                            const title = getNavBarTitle(bredC.element);
                            return (
                                <Breadcrumb.Item key={bredC.key}>
                                    {bredC?.gobackUrl ? (
                                        <Link to={bredC?.gobackUrl || ''} className="inline-flex items-center ">
                                            <p
                                                className="text-lg font-light text-gray-700  dark:text-gray-400 capitalize truncate max-w-52  hover:text-[#5C64FC]"
                                                title={title}
                                            >
                                                {title}
                                            </p>
                                        </Link>
                                    ) : (
                                        <p
                                            className="text-lg font-light text-gray-700  dark:text-gray-400 capitalize truncate max-w-52 "
                                            title={title}
                                        >
                                            {title}
                                        </p>
                                    )}
                                </Breadcrumb.Item>
                            );
                        })}
                    </Breadcrumb>
                </Navbar.Brand>

                <div className="flex gap-2 items-center ">
                    <div>
                        <div className="py-2 px-4 shadow-md rounded-full bg-gray-100 text-black font-sans font-semibold text-sm border-blue active:shadow-none mr-2 dark:bg-blue-900 dark:text-white">
                            {new Intl.DateTimeFormat('en-GB', {
                                dateStyle: 'full',
                                timeStyle: 'short',
                                timeZone: 'Asia/Shanghai',
                            }).format(new Date())}
                        </div>
                    </div>
                    <Notifications />
                    <ThemeSwitch />
                    <Dropdown
                        theme={{
                            floating: {
                                base: 'z-20 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none',
                            },
                        }}
                        arrowIcon={false}
                        inline
                        label={
                            <Avatar
                                alt={name}
                                placeholderInitials={getInitials()}
                                img={getUser().profileImage}
                                rounded
                            />
                        }
                    >
                        <Dropdown.Header>
                            <span className="block text-sm">{name}</span>
                            <span className="block truncate text-sm font-medium">{getUser().email}</span>
                        </Dropdown.Header>
                        <Dropdown.Item onClick={() => navigate(`/${getCurrentUser().role}/profile`)}>
                            {' '}
                            <CgProfile className="mr-1 h-4 w-4" /> Profile
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => logout()}>
                            <CgLogOut className="mr-1 h-4 w-4" />
                            Sign out
                        </Dropdown.Item>
                    </Dropdown>
                    <Navbar.Toggle />
                </div>
            </Navbar>
        </>
    );
};

export default CustomNavbar;
