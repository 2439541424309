/* eslint-disable no-nested-ternary */
import ExcelJS from 'exceljs';
import moment from 'moment';
import { ICustomer } from 'pages/customers/customer.types';
import { statusDisplay } from 'pages/orders/orderElements';
import { IOrder } from 'services/admin/order';
import { IStore } from 'services/admin/store';
import { IProduct } from 'utils/Interfaces/products.interface';

export const exportOrdersExcel = (data: IOrder[]) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('My Sheet');
    sheet.getRow(1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };

    sheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'lightDown',
        fgColor: { argb: 'c6b1e6' },
        bgColor: { argb: 'c6b1e6' },
    };

    sheet.getRow(1).font = {
        name: 'Poppins Black',
        size: 10,
        bold: true,
    };
    // sheet.getRow(1).height = 25;
    sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
    sheet.columns = [
        {
            header: 'Reference',
            key: 'orderRef',
            width: 20,
        },
        {
            header: 'Created at',
            key: 'createdAt',
            width: 20,
        },
        {
            header: 'Seller name',
            key: 'seller',
            width: 20,
        },
        {
            header: 'Customer name',
            key: 'customer',
            width: 20,
        },
        {
            header: 'Customer phone',
            key: 'phone',
            width: 20,
        },
        {
            header: 'Customer adress 1',
            key: 'adress1',
            width: 20,
        },
        {
            header: 'Customer adress 2',
            key: 'adress2',
            width: 20,
        },
        {
            header: 'Customer city',
            key: 'city',
            width: 20,
        },
        { header: 'Product', key: 'product', width: 32 },
        // { header: 'Variant', key: 'variant', width: 32 },
        { header: 'SKU', key: 'sku', width: 32 },
        {
            header: 'Quantity',
            key: 'quantity',
            width: 15,
        },

        { header: 'Store', key: 'store', width: 20 },
        {
            header: 'Type',
            key: 'type',
            width: 32,
        },
        {
            header: 'Country',
            key: 'country',
            width: 20,
        },
        {
            header: 'Call Center',
            key: 'callCenterName',
            width: 32,
        },
        {
            header: 'Status',
            key: 'status',
            width: 20,
        },
        {
            header: 'Updated at',
            key: 'updatedAt',
            width: 20,
        },
        {
            header: 'Total price',
            key: 'totalPrice',
            width: 15,
        },
        {
            header: 'Currency',
            key: 'currency',
            width: 15,
        },
        {
            header: 'Tracking code',
            key: 'trackingCode',
            width: 15,
        },
        {
            header: 'Logistio code',
            key: 'lgstTrackingCode',
            width: 15,
        },
        {
            header: 'Call attempts',
            key: 'callCenter',
            width: 15,
        },
    ];

    let startRow = 1;
    const promise = Promise.all(
        data?.map(async (order) => {
            const rows = {};
            Object.assign(rows, {
                seller: `${(order?.customer as ICustomer)?.firstName} ${
                    (order?.customer as ICustomer)?.lastName || '--'
                }`,

                customer: `${order.firstName || '--'} ${order.lastName || '--'}`,
                phone: `${order?.phone || '--'} 
                 `,
                adress1: `${order?.address1 || '--'} 
                 `,
                adress2: `${order?.address2 || '--'} 
                 `,
                city: `${order?.city || '--'} 
                 `,
                store: `${(order.store as IStore)?.storeName || '--'}`,
                createdAt: `${moment(
                    new Date(order?.createdAt).toLocaleString('en-US', {
                        timeZone: 'Asia/Shanghai',
                    }),
                ).format('DD/MM/YYYY HH:mm')}`,
                updatedAt: `${moment(
                    new Date(order?.updatedAt).toLocaleString('en-US', {
                        timeZone: 'Asia/Shanghai',
                    }),
                ).format('DD/MM/YYYY HH:mm')}`,
                type: `${
                    !order?.cod ? 'Prepaid' : order?.withCC ? 'COD with call center' : 'COD without call center '
                }`,
                callCenterName: order?.callCenter?.name || '--',
                status: statusDisplay[order?.status].statusLabel,
                orderRef: `${order?.orderRef}`,
                country: `${order?.country}`,
                totalPrice: Number(order?.totalPrice) || '--',
                currency: `${order?.currency ? order?.currency : '--'}`,
                trackingCode: `${order?.trackingCode || '--'}`,
                lgstTrackingCode: `${order?.lgstTrackingCode || '--'}`,
                callCenter: `${order?.callCenterAttempt || '--'}`,
            });
            if (order?.items?.length > 0) {
                order.items.forEach((item) => {
                    const product = item?.product as IProduct;
                    // const variant = item?.product as IProduct;
                    Object.assign(rows, {
                        product: `${product?.parentProduct?.name || ''} ${product?.name || '--'}`,
                        // variant: `${variant?.name || '--'}`,
                        sku: `${product?.globalSKU || '--'}`,
                        quantity: (item?.quantity || 0) + (item?.upsellQuantity || 0),
                    });
                    sheet.addRow(rows);
                });
                sheet.mergeCells(startRow + 1, 1, startRow + order?.items.length, 1);
                sheet.mergeCells(startRow + 1, 2, startRow + order?.items.length, 2);
                sheet.mergeCells(startRow + 1, 3, startRow + order?.items.length, 3);
                sheet.mergeCells(startRow + 1, 4, startRow + order?.items.length, 4);
                sheet.mergeCells(startRow + 1, 5, startRow + order?.items.length, 5);
                sheet.mergeCells(startRow + 1, 6, startRow + order?.items.length, 6);
                sheet.mergeCells(startRow + 1, 7, startRow + order?.items.length, 7);
                sheet.mergeCells(startRow + 1, 8, startRow + order?.items.length, 8);
                sheet.mergeCells(startRow + 1, 12, startRow + order?.items.length, 12);
                sheet.mergeCells(startRow + 1, 13, startRow + order?.items.length, 13);
                sheet.mergeCells(startRow + 1, 14, startRow + order?.items.length, 14);
                sheet.mergeCells(startRow + 1, 15, startRow + order?.items.length, 15);
                sheet.mergeCells(startRow + 1, 16, startRow + order?.items.length, 16);
                sheet.mergeCells(startRow + 1, 17, startRow + order?.items.length, 17);
                sheet.mergeCells(startRow + 1, 18, startRow + order?.items.length, 18);
                sheet.mergeCells(startRow + 1, 19, startRow + order?.items.length, 19);
                sheet.mergeCells(startRow + 1, 20, startRow + order?.items.length, 20);
                sheet.mergeCells(startRow + 1, 21, startRow + order?.items.length, 21);
                sheet.mergeCells(startRow + 1, 22, startRow + order?.items.length, 22);

                startRow += order?.items.length;
            } else {
                startRow += 1;
                Object.assign(rows, {
                    product: `${(order?.product as IProduct)?.name || '--'}`,
                    quantity: (order?.product as IProduct)?.quantity || '--',
                    sku: `${(order?.product as IProduct)?.globalSKU || '--'}`,
                });
                sheet.addRow(rows);
            }
        }),
    );

    promise.then(() => {
        const priceCol = sheet.getColumn(5);
        const rowsLength = sheet.rowCount;
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < rowsLength; i++) {
            sheet.getRow(i + 1).height = 30;
            sheet.getRow(i + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getRow(i + 1).font = {
                name: 'Poppins Black',
                family: 4,
                size: 10,
            };
        }
        // iterate over all current cells in this column
        priceCol.eachCell((cell) => {
            const cellValue = Number(sheet.getCell(cell?.address).value);
            if (cellValue > 50 && cellValue < 1000) {
                sheet.getCell(cell?.address).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF0000' },
                };
            }
        });

        workbook.xlsx.writeBuffer().then((dataBlob) => {
            const blob = new Blob([dataBlob], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'Logistio-Orders.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    });
};
