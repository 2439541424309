/* eslint-disable no-underscore-dangle */
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import DataTable from 'components/tables/tables';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IoMdCloseCircle, IoMdInformationCircle } from 'react-icons/io';

import { getHistory, ICredit } from 'services/accountManager/credits';

import { TableColDef } from 'components/tables/tables.types';
import { ICreditHistory } from './credits.types';
import { statusDisplay } from './creditElements';

const CreditHistory: React.FC<{ data: ICredit; setOpenCreditHistory: any; openCreditHistory: boolean }> = ({
    data,
    setOpenCreditHistory,
    openCreditHistory,
}) => {
    const [creditHistory, setCreditHistory] = useState<ICreditHistory[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);

    const handleDisplayTooltip = () => {
        setDisplayTooltip(!displayTooltip);
    };

    useEffect(() => {
        if (data?._id && openCreditHistory) {
            const toastId = toast.loading('Loading credit operation history data...');
            setIsLoading(true);
            getHistory(data?._id)
                .then((res) => {
                    toast.dismiss(toastId);
                    setCreditHistory(res.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.error('Something went wrong', { id: toastId });
                });
        }
    }, [data?._id, openCreditHistory]);

    const historyColumns: TableColDef[] = [
        {
            field: 'createAt',
            headerName: 'Date',
            fieldType: 'render',
            renderCell: (params: ICreditHistory) => {
                return params?.createdAt ? (
                    <div className="flex flex-col ">
                        <div>
                            <p className="font-bold">{format(new Date(params?.createdAt), 'dd/MM/yyyy')}</p>
                        </div>
                        <div>
                            <p className="text-xs">{format(new Date(params?.createdAt), 'HH:mm:ss')}</p>
                        </div>
                    </div>
                ) : (
                    <p>-</p>
                );
            },
        },

        {
            field: 'amount',
            headerName: 'Amount paid ($)',
            fieldType: 'render',
            renderCell: (params: ICreditHistory) => <> {(params?.amount || 0).toFixed(2)}</>,
        },

        {
            field: 'nbrOfSales',
            headerName: 'Number of sales',
            fieldType: 'render',
            renderCell: (params: ICreditHistory) => <> {params.nbrOfSales || '-'}</>,
        },

        {
            field: 'invoiceRef',
            headerName: 'Invoice reference',
            fieldType: 'render',
            renderCell: (params: ICreditHistory) => <> {params.invoiceRef || '-'}</>,
        },
    ];

    return (
        <>
            <GeneralDrawer
                anchor="right"
                open={openCreditHistory}
                onClose={setOpenCreditHistory}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">History</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpenCreditHistory(false)}
                            className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                        >
                            <IoMdCloseCircle className="w-8 h-8" color="grey" />
                        </button>
                    </div>

                    <div className="flex items-center w-full my-7">
                        <p className="text-xl font-medium">
                            {data?.customer?.firstName} {data.customer?.lastName}{' '}
                            {data?.creditType === 'Cogs' && `/ ${data?.product?.name || ''}`}
                        </p>
                    </div>

                    <div className="my-2 shadow-sm sm:rounded-lg border dark:border-gray-700 transition duration-9000 ease-in-out">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-4 py-3">
                                        Date
                                    </th>
                                    <th scope="col" className="px-4 py-3">
                                        {data?.creditType === 'Cogs' ? 'Cogs unit price ($)' : 'Ads percent (%)'}
                                    </th>
                                    <th scope="col" className="px-4 py-3">
                                        Credit amount ($)
                                    </th>
                                    <th scope="col" className="px-4 py-3">
                                        Payed amount ($)
                                    </th>
                                    <th scope="col" className="px-4 py-3">
                                        Status
                                    </th>
                                </tr>
                            </thead>

                            {isLoading ? (
                                <tbody>
                                    <tr className="border-b bg-white dark:bg-gray-800 dark:border-gray-700 animate-pulse">
                                        <th
                                            scope="row"
                                            className="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </th>
                                        <td className="p-4 flex gap-2">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="p-4">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="p-4 flex gap-2">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                        <td className="p-4">
                                            <div className="h-3.5 bg-gray-400 rounded-full dark:bg-gray-700 w-28" />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr
                                        className="border-b bg-white dark:bg-gray-800 dark:border-gray-700"
                                        key={data?._id}
                                    >
                                        <td className="p-4">
                                            {data?.status === 2
                                                ? format(new Date(data?.updatedAt || ''), 'dd/MM/yyyy')
                                                : format(new Date(data?.createdAt || ''), 'dd/MM/yyyy')}
                                        </td>

                                        <td className="p-4">
                                            {data?.creditType === 'Cogs'
                                                ? (data?.cogsUnitPrice).toFixed(2)
                                                : data?.adsPercent}
                                        </td>

                                        <td className="p-4">{(data?.creditAmount).toFixed(2)}</td>

                                        <td className="p-4">
                                            <div className="flex items-center gap-2 z-999">
                                                {(data?.payedCredit).toFixed(2)}
                                                {data.reason && data.status === 3 && (
                                                    <div className="relative group">
                                                        <IoMdInformationCircle
                                                            onMouseEnter={handleDisplayTooltip}
                                                            onMouseLeave={handleDisplayTooltip}
                                                            size={22}
                                                            className="cursor-pointer"
                                                        />
                                                        <div
                                                            className={`absolute right-0 mb-1 w-52 p-2 normal-case text-white bg-gray-800 dark:bg-gray-600 rounded-lg text-center transition-opacity duration-200 bottom-full ${
                                                                displayTooltip ? 'opacity-100' : 'opacity-0'
                                                            }`}
                                                        >
                                                            Reason: {data.reason}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className="p-4">
                                            <p className="w-fit">{statusDisplay[data.status].statusIcon}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                    <div className="py-2">
                        <DataTable
                            rows={creditHistory}
                            setRows={setCreditHistory}
                            columns={historyColumns.filter((col) =>
                                data?.creditType === 'Ads' ? col.field !== 'nbrOfSales' : col,
                            )}
                            pageState={{
                                isLoading,
                                total: 0,
                                pageSize: 0,
                                page: 0,
                                count: 0,
                            }}
                            setPageState={() => {}}
                        />
                    </div>
                </div>
            </GeneralDrawer>
        </>
    );
};
export default CreditHistory;
