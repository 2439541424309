import axios from 'utils/axios';
import { getBearerToken } from '../../utils/helpers/auth';

export interface ICurrencyRate {
    _id?: string;
    country: string;
    currencyValue: number;
}
export interface IPricingConfiguration {
    currencyRate: Array<ICurrencyRate>;
}

export interface ISimpleBank {
    _id?: string;
    accountCurrency?: string;
    paymentType?: string;
    accountHolder?: string;
    accountType?: string;
    routingNumber?: string;
    accountNumber?: string;
    iban?: string;
    swiftBic?: string;
    description?: string;
}

export interface IFinanceService {
    _id?: string;
    email: string;
    serviceType: string;
}

export interface IBankInformations {
    simpleBank: Array<ISimpleBank>;
    financeService: Array<IFinanceService>;
}

export interface IWalletCurrencyConfig {
    currency: string;
    currencyValue: number;
}

export interface ILogistioBulkConfiguration {
    quotedExpiration: number;
    quotedExpirationType: 'days' | 'hours';
    paymentExpiration: number;
    paymentExpirationType: 'days' | 'hours';
    review: number;
    reviewType: 'days' | 'hours';
}

export interface IConfig {
    pricing: IPricingConfiguration;
    bankInformations: IBankInformations;
    walletCurrency?: IWalletCurrencyConfig[];
    logistioBulkConfiguration?: ILogistioBulkConfiguration;
    message: string;
    order: {
        assignUnreachableAfterHours: number;
        orderMaxUnreachable: number;
    };
    jobs: {
        orderAssignSchedule: string;
        maxOngoingOrdersByAgent: number;
    };
    _id: string;
}

export interface IConfigByCallCenterAdmin {
    message: string;
    order: IConfig['order'];
    jobs: IConfig['jobs'];
    _id: string;
}

export interface ILogistioBulkPricing {
    pricing: IPricingConfiguration;
}

export const addCountryConfiguration = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.post(`/admin/configuration`, data, config);
    return response;
};

export const getConfiguration = async () => {
    const config = getBearerToken();
    const response = await axios.get<IConfig>(`/admin/configuration`, config);
    return response;
};

export const updateConfigItem = async (id: string, data: any) => {
    const config = getBearerToken();
    const response = await axios.put(`/admin/configuration/${id}`, data, config);
    return response;
};
export const deleteConfig = async () => {
    const config = getBearerToken();
    const response = await axios.post(`/admin/configuration/delete`, {}, config);
    return response;
};

// CALL CENTER ADMIN AREA

export const getConfigurationbyCallCenterAdmin = async () => {
    const config = getBearerToken();
    const response = await axios.get<IConfigByCallCenterAdmin>(`/admin/configuration/callAgentAdmin`, config);
    return response;
};

export const updateConfigItemByCallCenterAdmin = async (id: string, data: any) => {
    const config = getBearerToken();
    const response = await axios.put(`/admin/configuration/callAgentAdmin/${id}`, data, config);
    return response;
};
