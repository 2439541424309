/* eslint-disable react/style-prop-object */
import Avatar from 'assets/avatar.png';
import { IStausInterface, TableColDef } from 'components/tables/tables.types';
import { Tooltip } from 'flowbite-react';
import React from 'react';
import { FiPackage } from 'react-icons/fi';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { PiArrowUUpLeft } from 'react-icons/pi';
import { StatusBadge } from 'utils/helpers/statusBadge';
import { urlValidation } from 'utils/helpers/imageFinder';
import Package2 from 'assets/no-image.png';
import { IResellerQuotation, ResellerProduct } from '../reseller.types';
import { IAddResellerProduct } from './helpers/types';

export interface ICard {
    title: 'draft' | 'purchased' | 'spending';
    label: string;
    Icon: any;
    color: string;
}
export const columns: TableColDef[] = [
    {
        field: 'createdAt',
        headerName: 'Created at',
        fieldType: 'date',
        dateFormat: 'DD/MM/YYYY',
    },
    {
        field: 'name',
        headerName: 'product name',
        fieldType: 'render',
        classField: 'w-[100px]',
        renderCell: (params: ResellerProduct) => {
            const { name, chineseName } = params;
            return (
                <>
                    <div className="font-medium text-gray-900 dark:text-white">{name}</div>
                    <div className=" font-poppin   text-base text-[#A3AED0]">{chineseName}</div>
                </>
            );
        },
    },
    {
        field: 'variant',
        headerName: 'Variant',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'quotation',
        headerName: 'country',
        fieldType: 'render',
        renderCell: (params) => {
            const countries =
                (params?.quotation || [])
                    .filter((el: IResellerQuotation) => el.accepted && !el.isHidden)
                    .map((el: IResellerQuotation) => el.country) || [];
            if (!countries.length) return <></>;
            return (
                <>
                    <div className="flex items-center gap-1">
                        <>
                            {countries.length > 2 ? (
                                <>
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                        {countries[0]}
                                    </span>
                                    <Tooltip content={countries.slice(1).join(' - ')}>
                                        <span className="bg-gray-100 cursor-pointer text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                            +{countries.length - 1}
                                        </span>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    {countries.map((el: string) => (
                                        <span
                                            key={el}
                                            className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500"
                                        >
                                            {el}
                                        </span>
                                    ))}
                                </>
                            )}
                        </>
                    </div>
                </>
            );
        },
    },

    {
        field: 'quantity',
        headerName: 'Total Qty',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'declarationAmount',
        headerName: 'Total amount ($)',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white ',
        fieldType: 'render',
        renderCell: (params: ResellerProduct) => {
            const TotalAmount = (params.quotation || []).reduce((acc, cur) => {
                return acc + cur.productCost * (cur.quantity || 0);
            }, 0);
            return (
                <>
                    <div className="whitespace-nowrap font-medium text-gray-900 dark:text-white ">
                        {TotalAmount % 1 ? TotalAmount.toFixed(3) : TotalAmount}
                    </div>
                </>
            );
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
    {
        field: 'Action',
        headerName: '',
        actionType: 'ResellerProduct',
    },
];

export const statusList = [
    { id: 1, label: 'Purchased' }, // the id will be changed to 1
    { id: 11, label: 'Draft' },
];
export const statusLists = [
    { id: 1, label: 'Publish' },
    { id: 2, label: 'Unpublish' },
    { id: 3, label: 'Draft' },
];
export const cardList: ICard[] = [
    { title: 'purchased', label: 'Purchased products', Icon: FiPackage, color: 'purple' },
    { title: 'draft', label: 'Draft', Icon: IoIosCheckmarkCircleOutline, color: 'green' },
    { title: 'spending', label: 'Spending($)', Icon: PiArrowUUpLeft, color: 'red' },
];
export const statusDisplayColor: { [keys: string]: IStausInterface } = {
    '1': {
        // will be changed to
        statusIcon: <StatusBadge name="Purchased" style="greenTag" />,
        statusLabel: 'purchased',
        statusClass: 'greenTag',
    },
    '2': {
        // will be changed to
        statusIcon: <StatusBadge name="Purchased" style="greenTag" />,
        statusLabel: 'purchased',
        statusClass: 'greenTag',
    },
    '11': {
        statusIcon: <StatusBadge name="Draft" style="yellowTag" />,
        statusLabel: 'Draft',
        statusClass: 'yellowTag',
    },
};

export const managerCol = {
    field: 'managedBy',
    headerName: 'Managed by',
    fieldType: 'render',
    renderCell: (params: ResellerProduct) => {
        const manager = params.managedBy;
        if (!manager) return <></>;

        return (
            <div className="flex gap-2">
                {urlValidation(manager?.profileImage) ? (
                    <img src={manager?.profileImage} className="w-10 h-10 !rounded-full" alt="profileImage" />
                ) : (
                    <img src={Avatar} className="w-10 h-10 !rounded-full" alt="avatar" />
                )}
                <div className="flex items-center gap-1">{`${manager.firstName || ''} ${manager.lastName || ''}`}</div>
            </div>
        );
    },
};

export const displayBadge = (params: ResellerProduct | IAddResellerProduct | undefined) => {
    if (!params) return <></>;
    switch (true) {
        case !params.name:
            return <></>;
        case params.isContentDraft:
            return <StatusBadge name="Draft" style="blueTag" />;
        case params.openForSale:
            return <StatusBadge name="Publish" style="greenTag" />;
        default:
            return <StatusBadge name="Unpublish" style="yellowTag" />;
    }
};
export const ContentColumns: TableColDef[] = [
    {
        field: 'num',
        headerName: 'N°',
    },
    {
        field: 'name',
        headerName: 'product name',
        fieldType: 'render',
        classField: 'w-[100px]',
        renderCell: (params: ResellerProduct) => {
            const { name, chineseName, thumbnail } = params;
            return (
                <>
                    {name && (
                        <div className="flex">
                            {thumbnail ? (
                                <img src={thumbnail || ''} className="w-10 h-10 !rounded-full" alt="thumbnail" />
                            ) : (
                                <img src={Package2} alt="Package2" />
                            )}
                            <div className="ml-2">
                                <div className="font-medium text-gray-900 dark:text-white">{name}</div>
                                <div className=" font-poppin   text-base text-[#A3AED0]">{chineseName}</div>
                            </div>
                        </div>
                    )}
                </>
            );
        },
    },
    {
        field: 'variant',
        headerName: 'Variant',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
    },
    {
        field: 'quotation',
        headerName: 'country',
        fieldType: 'render',
        renderCell: (params) => {
            const countries =
                (params?.quotation || [])
                    .filter((el: IResellerQuotation) => el.accepted && !el.isHidden)
                    .map((el: IResellerQuotation) => el.country) || [];
            if (!countries.length) return <></>;
            return (
                <>
                    <div className="flex items-center gap-1">
                        <>
                            {countries.length > 2 ? (
                                <>
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                        {countries[0]}
                                    </span>
                                    <Tooltip content={countries.slice(1).join(' - ')}>
                                        <span className="bg-gray-100 cursor-pointer text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                            +{countries.length - 1}
                                        </span>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    {countries.map((el: string) => (
                                        <span
                                            key={el}
                                            className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-500"
                                        >
                                            {el}
                                        </span>
                                    ))}
                                </>
                            )}
                        </>
                    </div>
                </>
            );
        },
    },

    {
        field: 'quantity',
        headerName: 'Available Qty',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
        fieldType: 'render',
        renderCell: (params) => {
            return (
                <>
                    {!params.quotation?.length || params.hasChilds
                        ? ''
                        : (params.quotation?.length && params.quotation[0]?.availableQuantity) || 0}
                </>
            );
        },
    },
    {
        field: '',
        headerName: 'Unit selling price ($)',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
        fieldType: 'render',
        renderCell: (params) => {
            return (
                <>
                    {!params.quotation?.length || params.hasChilds
                        ? ''
                        : (params.quotation?.length && params.quotation[0]?.sellingPrice) || 0}
                </>
            );
        },
    },
    {
        field: 'daily',
        headerName: 'Average/Daily',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white ',
        fieldType: 'render',
        renderCell: (params) => {
            return <>{params.hasChilds ? '' : (params.quotation?.length && params.quotation[0]?.daily) || 0}</>;
        },
    },
    {
        field: 'weekly',
        headerName: 'Average /Weekly',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white ',
        fieldType: 'render',
        renderCell: (params) => {
            return <>{params.hasChilds ? '' : (params.quotation?.length && params.quotation[0]?.weekly) || 0}</>;
        },
    },
    {
        field: 'Action',
        headerName: 'customer',
        actionType: 'CustomerResellerProduct',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'render',
        renderCell: (params) => displayBadge(params),
    },
    {
        field: 'Action',
        headerName: '',
        actionType: 'ContentResellerProduct',
    },
];

export const intialResellerProduct: IAddResellerProduct = {
    name: '',
    variant: '',
    adCopies: [''],
    chineseName: '',
    resellerQuotation: [
        {
            country: '',
            packaging: '',
            type: '',
            trackingCode: '',
            productCost: 0,
            unitProfit: 0,
            accepted: true,
            isHidden: false,
            editable: true,
            shippingDuration: '',
        },
    ],
    openForSale: undefined,
    canBeOrdered: false,
    notificationType: [],
    categories: [],
    landingPages: [''],
    description: '',
};
