/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */

import DataTable from 'components/tables/tables';
import { Select, TextInput } from 'flowbite-react';
import moment from 'moment';
import { Ipagination } from 'pages/invoices/invoice.types';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { getProducts, IResellerProductData } from 'services/admin/resellerProduct';
import { getCurrentUser } from 'utils/helpers/auth';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import { Imeta, ResellerProduct } from '../reseller.types';
import { cardList, columns, managerCol, statusDisplayColor, statusList } from './productsElements';

interface Ifilter {
    status?: number;
    shippingTo?: string;
    sku?: string;
    email?: string;
    category?: string;
}

interface IDateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}
interface IStats {
    purchased: number;
    draft: number;
    spending: number;
}

const ResellerProducts: React.FC = () => {
    const navigate = useNavigate();
    const [Rangevalue, setRangeValue] = useState<IDateRange | null>(null);
    const [stats, setStats] = useState<IStats | null>({
        purchased: 0,
        draft: 0,
        spending: 0,
    });
    const [isLoading, setIsLoading] = useState(false);

    const [resellerProducts, setResellerProducts] = useState<Array<ResellerProduct>>([]);

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });

    const [filter, setFilter] = useState<Ifilter>({});
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);

    function handleChangeFilter(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined } });
        }
        setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        setMeta({ ...meta, page: -1 });
    }
    const handlePaginationChange = (value: { [key: string]: number }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleProducts = (values: IResellerProductData) => {
        const data = values?.resellerProducts || values;
        if (!data) return;
        setResellerProducts(data.docs || []);
        let spending = 0;
        if (values?.spendAmount) {
            spending = values?.spendAmount % 1 ? Number(values?.spendAmount.toFixed(3)) : values?.spendAmount;
        }

        setStats({
            purchased: values?.purchasedCount || 0,
            draft: values?.draftCount || 0,
            spending,
        });
        setMeta({
            hasNextPage: data.hasNextPage,
            hasPrevPage: data.hasPrevPage,
            limit: data.limit,
            nextPage: data.nextPage,
            page: data.page,
            pagingCounter: data.pagingCounter,
            prevPage: data.prevPage,
            totalDocs: data.totalDocs,
            totalPages: data.totalPages,
        });
    };

    const handleRangeFilterChange = (newValue?: DateValueType) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setRangeValue(newValue || null);
        setFilter({ ...filter, ...{ dateRange: newRange } });
        setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        setMeta({ ...meta, page: -1 });
    };

    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        const queryOptions = { ...debouncedFilter, ...debouncedPagination };
        setIsLoading(true);
        getProducts({ ...queryOptions })
            .then((result) => {
                toast.dismiss(feedbackToast);
                handleProducts(result?.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            })
            .finally(() => setIsLoading(false));
    }, [debouncedFilter, debouncedPagination]);

    const user = useMemo(() => getCurrentUser(), []);
    const cols = [...columns];
    if (user?.role === 'admin') {
        cols.splice(7, 0, { ...managerCol });
    }

    return (
        <>
            <section className="bg-gray-50 dark:bg-gray-900 h-dvh">
                <div className="w-full h-full">
                    <div className="bg-white h-full dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden w-full">
                        <div className="items-center  mx-4 mt-4 ">
                            <div className="flex  justify-between mb-1">
                                <div className="flex ">
                                    <MdOutlineShoppingCart size={32} />{' '}
                                    <span className="font-medium text-2xl">Products sourcing</span>
                                </div>
                                <div className="">
                                    <div className=" lg:flex lg:flex-1 lg:justify-end">
                                        <button
                                            type="button"
                                            className=" flex items-center justify-center rounded-3xl border border-transparent bg-indigo-600 px-8 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                let navigation = 'admin';
                                                if (user.role === 'accountManager') {
                                                    navigation = 'accountManager';
                                                }
                                                navigate(`/${navigation}/new-reseller-Product`);
                                            }}
                                        >
                                            Sourcing product
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className=" grid gap-4 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-3 py-2 mb-1">
                                {cardList.map(({ Icon, color, label, title }) => (
                                    <div className=" bg-white  rounded-2xl  dark:bg-gray-800 border ">
                                        <div className="w-full flex gap-4 items-center m-4">
                                            <div
                                                className={`w-[60px] h-[60px] bg-${color}-100 flex justify-center items-center rounded-xl`}
                                            >
                                                {Icon && <Icon className="w-10 h-10" color={color} />}
                                            </div>
                                            <div className="flex-1 grow w-full">
                                                <div className=" font-poppin font-medium  text-base text-[#A3AED0]">
                                                    {label}
                                                </div>

                                                <div className="flex justify-between items-center w-full">
                                                    <div className="w-36 h-8 ext-[#2B3674] dark:text-[#6874F5] text-2xl font-semibold font-poppin">
                                                        {(stats && stats[title]) || 0}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className=" relative flex w-full items-center py-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4 justify-between mb-1">
                                <div className="flex items-center p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                                    <div id="selectStatus">
                                        <Select
                                            id="productStatus"
                                            required
                                            value={filter.status || ''}
                                            onChange={handleChangeFilter}
                                            name="status"
                                        >
                                            {/* <div className="h-8 overflow-x-auto"> */}
                                            <option value="">All Status</option>
                                            {statusList.map((status) => {
                                                return (
                                                    <option key={status.id} value={`${status.id}`}>
                                                        {status.label}
                                                    </option>
                                                );
                                            })}
                                            {/* </div> */}
                                        </Select>
                                    </div>
                                    <div className="mb-2 block">
                                        <Datepicker
                                            inputId="deliveryDate"
                                            value={Rangevalue}
                                            containerClassName="relative z-100"
                                            useRange
                                            onChange={handleRangeFilterChange}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <TextInput
                                        icon={AiOutlineSearch}
                                        id="name"
                                        placeholder="Search"
                                        name="name"
                                        onChange={handleChangeFilter}
                                        className="!w-[290px]"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mx-4">
                            <DataTable
                                rows={resellerProducts}
                                columns={cols}
                                icons={statusDisplayColor}
                                setRows={handleProducts}
                                pageState={{
                                    isLoading,
                                    total: meta.totalDocs,
                                    pageSize: pagination.limit,
                                    page: pagination.page,
                                    count: meta.totalPages,
                                }}
                                setPageState={handlePaginationChange}
                                filterState={filter}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default ResellerProducts;
