/* eslint-disable react/jsx-fragments */
import CustomError from 'components/customError/CustomError';
import { Label, Modal, Select, TextInput } from 'flowbite-react';
import { Form, Formik } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import { countries } from 'utils/countryList';
import { AddPricingCodNoCC } from 'utils/validations/pricingConfiguration';
import CustomBtn from 'components/customButton/customBtn';
import { ICodNoCCPricing, updateLogistioBulkFees } from 'services/admin/logistioBulkFees';
import { AddConfigLogstioBulkFee } from '../pricingConfiguration.types';

const AddConfiguration: React.FC<AddConfigLogstioBulkFee> = ({
    open,
    configPricing,
    setOpen,
    configId,
    refetchGetFees,
}) => {
    const AddCountryConfig = (values: ICodNoCCPricing) => {
        const toastLoader = toast.loading('Loading...');
        let newValues;
        const newPricing = configPricing;
        if (configPricing.codNoCC) {
            newValues = [...configPricing.codNoCC];
            newValues.push(values);
        } else {
            newValues = [values];
        }
        newPricing.codNoCC = newValues;
        updateLogistioBulkFees(configId, { pricing: newPricing })
            .then((res) => {
                toast.success(res.data.message, { id: toastLoader });
                refetchGetFees();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message, { id: toastLoader });
            });
    };
    const checkCountryPricing = (country: string) => {
        return configPricing.codNoCC && configPricing.codNoCC.find((el) => el.country === country);
    };
    return (
        <>
            <Modal dismissible show={open} className="z-[1400]" popup onClose={() => setOpen(false)}>
                <Modal.Header>New Pricing</Modal.Header>
                <Modal.Body className=" !p-0">
                    <Formik
                        initialValues={{
                            country: '',
                            deliveredLeadPrice: '',
                            shippedLeadPrice: '',
                            percentFromSellPrice: '',
                            handleFees: '',
                        }}
                        onSubmit={(values, onSubmitProps) => {
                            AddCountryConfig({
                                country: values.country,
                                deliveredLeadPrice: Number(values.deliveredLeadPrice),
                                shippedLeadPrice: Number(values.shippedLeadPrice),
                                percentFromSellPrice: Number(values.percentFromSellPrice),
                                handleFees: Number(values.handleFees),
                            });
                            setOpen(false);
                            onSubmitProps.setSubmitting(false);
                            onSubmitProps.resetForm();
                        }}
                        validationSchema={AddPricingCodNoCC}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <div className="py-1 px-6 flex flex-col gap-2 overflow-auto max-h-[37rem]">
                                    <div>
                                        <div className="mb-2 block" key="zer">
                                            <Label htmlFor="country" value="Select country" />
                                        </div>
                                        <Select
                                            id="countries"
                                            required
                                            {...formik.getFieldProps('country')}
                                            name="country"
                                            helperText={
                                                <React.Fragment>
                                                    <CustomError name="country" component="div" />
                                                </React.Fragment>
                                            }
                                        >
                                            <option key="chooseCountryKey" value="" selected>
                                                Select Country
                                            </option>
                                            {countries.map((country) => {
                                                if (!checkCountryPricing(country.label)) {
                                                    return (
                                                        <option key={`${country.code}add`} value={country.label}>
                                                            {country.label}
                                                        </option>
                                                    );
                                                }
                                                return (
                                                    <option key={country.code} value={country.label} disabled>
                                                        {country.label}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <div>
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="handleFees"
                                                    className="dark:text-white"
                                                    color={
                                                        formik.touched.handleFees && formik.errors.handleFees
                                                            ? 'failure'
                                                            : ''
                                                    }
                                                    value="Handle Fees"
                                                />
                                            </div>
                                            <TextInput
                                                {...formik.getFieldProps('handleFees')}
                                                name="handleFees"
                                                color={
                                                    formik.touched.handleFees && formik.errors.handleFees
                                                        ? 'failure'
                                                        : 'gray'
                                                }
                                                type="text"
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="handleFees" component="div" />
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>

                                        <div>
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="price4"
                                                    className="dark:text-white"
                                                    color={
                                                        formik.errors.shippedLeadPrice &&
                                                        formik.touched.shippedLeadPrice
                                                            ? 'failure'
                                                            : ''
                                                    }
                                                    value="Shipped & Returned"
                                                />
                                            </div>
                                            <TextInput
                                                {...formik.getFieldProps('shippedLeadPrice')}
                                                name="shippedLeadPrice"
                                                type="text"
                                                color={
                                                    formik.errors.shippedLeadPrice && formik.touched.shippedLeadPrice
                                                        ? 'failure'
                                                        : 'gray'
                                                }
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="shippedLeadPrice" component="div" />
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                        <div>
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="price3"
                                                    className="dark:text-white"
                                                    color={
                                                        formik.touched.deliveredLeadPrice &&
                                                        formik.errors.deliveredLeadPrice
                                                            ? 'failure'
                                                            : ''
                                                    }
                                                    value="Shipped & Delivered"
                                                />
                                            </div>
                                            <TextInput
                                                {...formik.getFieldProps('deliveredLeadPrice')}
                                                name="deliveredLeadPrice"
                                                color={
                                                    formik.touched.deliveredLeadPrice &&
                                                    formik.errors.deliveredLeadPrice
                                                        ? 'failure'
                                                        : 'gray'
                                                }
                                                type="text"
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="deliveredLeadPrice" component="div" />
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                        <div>
                                            <div className="mb-2 block">
                                                <Label
                                                    htmlFor="price5"
                                                    className="dark:text-white"
                                                    color={
                                                        formik.errors.shippedLeadPrice &&
                                                        formik.touched.shippedLeadPrice
                                                            ? 'failure'
                                                            : ''
                                                    }
                                                    value="COD %"
                                                />
                                            </div>
                                            <TextInput
                                                {...formik.getFieldProps('percentFromSellPrice')}
                                                name="percentFromSellPrice"
                                                type="text"
                                                color={
                                                    formik.errors.shippedLeadPrice && formik.touched.shippedLeadPrice
                                                        ? 'failure'
                                                        : 'gray'
                                                }
                                                helperText={
                                                    <React.Fragment>
                                                        <CustomError name="percentFromSellPrice" component="div" />
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-2 justify-between py-2 px-2 items-center rounded-b-lg  bg-background-secondary">
                                        <CustomBtn
                                            variant="failure"
                                            outline
                                            pill
                                            type="reset"
                                            onClick={() => {
                                                setOpen(false);
                                                formik.resetForm();
                                            }}
                                        >
                                            Cancel
                                        </CustomBtn>
                                        <CustomBtn
                                            pill
                                            variant="primary"
                                            type="submit"
                                            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                        >
                                            Add
                                        </CustomBtn>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddConfiguration;
