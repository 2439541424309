/* eslint-disable no-unused-vars */
import React from 'react';
import { TableColDef } from 'components/tables/tables.types';

export const columns: TableColDef[] = [
    {
        field: 'name',
        headerName: 'Name',
    },
    {
        field: 'status',
        headerName: 'Status',
    },
    {
        field: 'type',
        headerName: 'Type',
    },
    {
        field: 'country',
        headerName: 'Campaign Country',
        fieldType: 'render',
        renderCell: (params) => <div>{params.country.name}</div>,
    },
    {
        field: 'lgstCountry',
        headerName: 'Logistio Country',
        fieldType: 'render',
        renderCell: (params) => <div>{params.lgstCountry ? params.lgstCountry : '-'}</div>,
    },
    {
        field: 'Action',
        actionType: 'Campaign',
        headerName: 'Options',
    },
];
