import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { chargeWallet, getCustomerWallet, getWalletAmount, IAmount, Ifilter, Ipagination } from 'services/admin/wallet';
import Datepicker from 'react-tailwindcss-datepicker';
import { Button, Modal, Select, TextInput } from 'flowbite-react';
import CustomizedCard from 'components/customCard/customizedCard';
import DateRangeComp from 'components/dateRangePicker/dateRangeComp';
import { RiRefund2Fill } from 'react-icons/ri';
import { BsFillSendFill } from 'react-icons/bs';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import moment from 'moment-timezone';
import WalletHistoryCard from './walletHistoryCard';

const Wallet = () => {
    const location = useLocation().state as { walletId: string; customerId: string };
    const { walletId } = location;
    const { customerId } = location;

    const [walletCustomerHistory, setwalletCustomerHistory] = useState<any>([]);
    const [customerWallet, setCustomerWallet] = useState<IAmount>();

    const [pagination] = useState<Ipagination>({
        page: 1,
        limit: 100,
    });

    const [Rangevalue, setRangeValue] = useState({
        // startDate: new Date().toDateString(),
        // endDate: new Date().toDateString(),
        startDate: null,
        endDate: null,
    });

    const [noFilter, setNoFilter] = useState<Ifilter>({
        wallet: walletId,
    });
    const [open, setOpen] = useState(false);

    const handleRangeFilterChange = (newValue: any) => {
        const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
        const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
        endD.set('hour', 23);
        endD.set('minute', 59);
        endD.set('second', 59);
        const newRange = {
            startDate: startD.format(),
            endDate: endD.format(),
        };
        const updatedValue = { ...newValue };
        setRangeValue(newValue);
        Object.keys(updatedValue).forEach((key) => {
            if (updatedValue[key] === null) {
                setNoFilter({ wallet: walletId });
            } else {
                setNoFilter({ ...noFilter, dateRange: newRange });
            }
        });
        // setNoFilter({ ...noFilter, dateRange: newValue });
    };

    const handleWalletChange = (newFilter: React.SetStateAction<Ifilter>) => {
        const updatedFilter: any = { ...noFilter, ...newFilter };

        Object.keys(updatedFilter).forEach((key) => {
            if (updatedFilter[key] === '' || updatedFilter[key] === 0) {
                delete updatedFilter[key];
            }
        });
        setNoFilter(updatedFilter);
    };

    useEffect(() => {
        getCustomerWallet({ ...pagination, ...noFilter }).then((res) => {
            setwalletCustomerHistory(res.data.response.docs);
        });
    }, [pagination, noFilter]);

    useEffect(() => {
        getWalletAmount(customerId).then((res) => {
            setCustomerWallet(res.data);
        });
    }, []);
    const refundWallet = (values: any) => {
        chargeWallet(values)
            .then(() => {
                toast.success(`Amount Refunded Successfully`);
                getWalletAmount(customerId).then((res) => {
                    setCustomerWallet(res.data);
                });
                getCustomerWallet({ ...pagination, ...noFilter }).then((res) => {
                    setwalletCustomerHistory(res.data.response.docs);
                });
            })
            .catch(() => {
                toast.error(`cannot apply this action`);
            });
    };
    return (
        <>
            <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                <Modal.Header>Refund to wallet</Modal.Header>
                <Modal.Body className="!p-4 overflow-y-auto h-[10rem]">
                    <Formik
                        initialValues={{
                            accountName: 'Logistio',
                            amount: '',
                            status: 2,
                            operationType: 'REFUND',
                        }}
                        onSubmit={(values, onSubmitProps) => {
                            refundWallet({ ...values, wallet: walletId });

                            onSubmitProps.setSubmitting(false);
                            onSubmitProps.resetForm();
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <div className="flex flex-wrap items-center gap-2 justify-center">
                                    <div className="w-72 p-4">
                                        <TextInput
                                            id="refundAmount"
                                            type="number"
                                            shadow
                                            placeholder="Refund Amount"
                                            {...formik.getFieldProps('amount')}
                                        />
                                    </div>
                                    <div>
                                        <Button pill type="submit">
                                            Send
                                            <BsFillSendFill className="ml-2 h-5 w-5" />
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <div>
                <div>History Transactions:</div>
                <div className="mb-1 mt-1">
                    <CustomizedCard className="flex justify-center items-center ">
                        <article className="format w-full dark:format-invert ">
                            <h2> Wallet Amount: {customerWallet?.amount} $ </h2>
                        </article>
                    </CustomizedCard>
                </div>

                <div className="flex items-center justify-start mb-1 gap-2">
                    <div className="w-72">
                        <Button
                            pill
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            <RiRefund2Fill className="mr-2 h-5 w-5" />
                            Refund
                        </Button>
                    </div>
                    <div id="select">
                        <Select
                            id="operationType"
                            required
                            value={noFilter.operationType?.toString()}
                            onChange={(e) => {
                                handleWalletChange({ operationType: e.target.value });
                            }}
                        >
                            <option value="">Show all Operations</option>
                            <option value="IN">In</option>
                            <option value="OUT">Out</option>
                        </Select>
                    </div>

                    <div id="select">
                        <Select
                            id="status"
                            required
                            value={noFilter.status?.toString()}
                            onChange={(e) => {
                                handleWalletChange({ status: Number(e.target.value) });
                            }}
                        >
                            <option value="">Show all Status</option>
                            <option value="1">Pending</option>
                            <option value="2">Confirmed</option>
                            <option value="3">Rejected</option>
                        </Select>
                    </div>
                    <DateRangeComp handleWalletChange={handleWalletChange} />
                    <div>
                        <Datepicker
                            inputId="deliveryDate"
                            value={Rangevalue}
                            containerClassName="relative z-100"
                            // inputClassName="absolute"
                            // toggleClassName="absolute
                            useRange
                            onChange={handleRangeFilterChange}
                        />
                    </div>
                </div>

                <div className="overflow-auto min-h-0 max-h-[72vh] flex flex-col gap-1 pb-1 ">
                    {walletCustomerHistory &&
                        walletCustomerHistory.map((wch: any) => (
                            <WalletHistoryCard
                                key={wch.id}
                                walletCustomerHistory={wch}
                                setwalletCustomerHistory={setwalletCustomerHistory}
                                operationId={wch.id}
                                pagination={pagination}
                                noFilter={noFilter}
                                setCustomerWallet={setCustomerWallet}
                            />
                        ))}
                </div>
            </div>
        </>
    );
};

export default Wallet;
