import { ResellerProduct } from 'pages/reseller/reseller.types';
import { IPaginationData } from 'utils/Interfaces/types';
import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';
import { IReadyForPickUp, IUpdateData } from './stockShipment';
import { LABEL_SIZES } from './stock';

export type CommunicationMethod = 'email' | 'whatsapp';
export interface IResellerStock {
    _id: string;
    resellerProduct: string | ResellerProduct;
    quantity: number;
    lgQuantity: number;
    availableQuantity: number;
    packagedQuantity?: number;
    outsideQuantity: number;
    deliveredQuantity: number;
    country: string;
    warehouse: string;
    trackingCode?: string;
    /* STOCK STATUS:
     ** 1 : Preparing stock : Stock created successfully on our database and we preparing it.
     ** 2 : Stock ready for pickup : The stock is packed from our side and ready for pickup.
     ** 3 : Stock being shipped : Vnlin took the stock and started shipping it.
     ** 4 : Stock ready : Vnlin shipped the stock to the required warehouse.
     */
    status: number;
    boxes?: {
        boxNumber: number;
        quantity: number;
    }[];
    quantityLimit?: number;
    notificationType?: [] | [CommunicationMethod] | [CommunicationMethod, CommunicationMethod];
}
export interface ResellerStockShipment {
    _id: string;
    resellerProduct: ResellerProduct;
    resellerStock: string | IResellerStock;
    quantity: number;
    country: string;
    warehouse: string;
    trackingCode: string;
    lgstTrackingCode?: string;
    /* STOCK STATUS:
     ** 1 : Preparing stock : Stock created successfully on our database and we preparing it.
     ** 2 : Stock ready for pickup : The stock is packed from our side and ready for pickup.
     ** 3 : Stock being shipped : Vnlin took the stock and started shipping it.
     ** 4 : Stock ready : Vnlin shipped the stock to the required warehouse.
     ** 5 : Out of stock : Available stock quantity is 0.
     */
    status: number;
    boxes?: {
        boxNumber: number;
        quantity: number;
    }[];
    createdAt: string;
}
export const getStocks = async (pagination: any) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginationData<IResellerStock>>(`/reseller/stocks/filter`, pagination, config);

    return response;
};
export const refillResellerStock = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.post(`/reseller/stocks/refill`, data, config);
    return response;
};
export const getStockShipments = async (pagination: any) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginationData<ResellerStockShipment>>(
        `/reseller/stocks/filter/shipments`,
        pagination,
        config,
    );

    return response;
};
export const getResellerStockShipmentsByStockId = async (stockId: string) => {
    const config = getBearerToken();
    const response = await axios.get<ResellerStockShipment[]>(
        `/reseller/stocks/get-reseller-stock-shipments/${stockId}`,
        config,
    );
    return response;
};

export const setResellerShipmentReadyForPickUp = async (dataForPickUp: IReadyForPickUp) => {
    const config = getBearerToken();
    const response = await axios.put(`/reseller/stocks/shipments/set-ready-for-pickup`, dataForPickUp, config);
    return response;
};

export const cancelResellerStockShipment = async (shipmentId: string) => {
    const config = getBearerToken();
    const response = await axios.post(`/reseller/stocks/cancelShipment/${shipmentId}`, config);
    return response;
};

export const updateResellerStockShipment = async (data: IUpdateData) => {
    const config = getBearerToken();
    const response = await axios.put(`/reseller/stocks/update-shipment/${data.id}`, data, config);
    return response;
};

export const getResellerLabels = async (data: { shipmentId: string; size: LABEL_SIZES }) => {
    const config = getBearerToken();
    const response = await axios.post<Buffer>(
        `/reseller/stocks/getLabels/${data.shipmentId}`,
        {
            size: data.size,
        },
        { ...config, responseType: 'arraybuffer' },
    );
    return response;
};
