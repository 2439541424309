/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */

import DataTable from 'components/tables/tables';
import { Select, TextInput } from 'flowbite-react';
import { Ipagination } from 'pages/invoices/invoice.types';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getProducts, IResellerProductData } from 'services/admin/resellerProduct';
import { getCurrentUser } from 'utils/helpers/auth';
import useDebouncedValue from 'utils/hooks/useDebouncedValue';
import { Imeta, IResellerQuotation, ResellerProduct } from '../reseller.types';
import { ContentColumns, managerCol, statusDisplayColor, statusLists } from './productsElements';

interface Ifilter {
    status?: number;
    shippingTo?: string;
    sku?: string;
    email?: string;
    category?: string;
    withAvg: boolean;
    isContentDraft?: boolean;
    openForSale?: boolean;
    onlyContent?: boolean;
    sort?: string;
}

const ResellerProductsContent: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [resellerProducts, setResellerProducts] = useState<Array<ResellerProduct>>([]);

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const navigate = useNavigate();

    const [filter, setFilter] = useState<Ifilter>({ onlyContent: true, withAvg: true, sort: 'updatedAt' });
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });

    const debouncedPagination = useDebouncedValue(pagination, 500);
    const debouncedFilter = useDebouncedValue(filter, 500);

    function handleChangeFilter(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        switch (true) {
            case event.target.name === 'selectStatus' && event.target.value === 'Publish':
                setFilter({ ...filter, isContentDraft: false, openForSale: true });
                break;
            case event.target.name === 'selectStatus' && event.target.value === 'Unpublish':
                setFilter({ ...filter, isContentDraft: false, openForSale: false });
                break;
            case event.target.name === 'selectStatus' && event.target.value === 'Draft':
                setFilter({ ...filter, isContentDraft: true, openForSale: undefined });
                break;
            case event.target.name === 'selectStatus':
                setFilter({ ...filter, isContentDraft: undefined, openForSale: undefined });
                break;
            case event.target.value !== '':
                setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
                break;
            default:
                setFilter({ ...filter, ...{ [event.target.name]: undefined } });
                break;
        }
        setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        setMeta({ ...meta, page: -1 });
    }
    const handlePaginationChange = (value: { [key: string]: number }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleProducts = (data: IResellerProductData) => {
        if (!data) return;
        const products = data?.resellerProducts;
        const list: (ResellerProduct & { num?: number; hasChilds?: boolean })[] = [];
        (products?.docs || []).forEach((el: ResellerProduct, index: number) => {
            const quotation: IResellerQuotation[] = el.quotation.filter((ele) => ele.accepted && !ele.isHidden);
            const num = pagination?.limit * pagination?.page - pagination?.limit + index + 1;
            if (quotation.length > 1) {
                const child = quotation.map((element, ind) => {
                    const country = el?.quotation[ind]?.country;
                    const quot = {
                        ...element,
                        daily: (data.avg && data.avg[el?._id][country]?.daily) || 0,
                        weekly: (data.avg && data.avg[el?._id][country]?.weekly) || 0,
                        income: (data.icomes && data.icomes[el?._id] && data.icomes[el?._id][country]) || 0,
                    };
                    return {
                        ...el,
                        _id: `${el._id}-${quot.country}`,
                        managedBy: undefined,
                        name: '',
                        variant: '',
                        quotation: [quot],
                    };
                });
                list.push({ num, ...el, hasChilds: true, quotation: [] }, ...child);
            } else {
                const country = el?.quotation[0]?.country;
                const quot = {
                    ...el.quotation[0],
                    daily: (data.avg && data.avg[el?._id][country]?.daily) || 0,
                    weekly: (data.avg && data.avg[el?._id][country]?.weekly) || 0,
                    income: (data.icomes && data.icomes[el?._id] && data.icomes[el?._id][country]) || 0,
                };
                list.push({ num, ...el, quotation: [quot] });
            }
        });
        setResellerProducts(list);

        setMeta({
            hasNextPage: products?.hasNextPage,
            hasPrevPage: products?.hasPrevPage,
            limit: products?.limit,
            nextPage: products?.nextPage,
            page: products?.page,
            pagingCounter: data.resellerProducts?.pagingCounter,
            prevPage: products?.prevPage,
            totalDocs: products?.totalDocs,
            totalPages: products?.totalPages,
        });
    };

    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        const queryOptions = { ...debouncedFilter, ...debouncedPagination };
        setIsLoading(true);
        getProducts({ ...queryOptions })
            .then((result) => {
                toast.dismiss(feedbackToast);
                handleProducts(result?.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            })
            .finally(() => setIsLoading(false));
    }, [debouncedFilter, debouncedPagination]);

    const user = useMemo(() => getCurrentUser(), []);
    const cols = [...ContentColumns];
    if (user?.role === 'admin') {
        cols.splice(8, 0, { ...managerCol });
    }

    return (
        <>
            <section className="bg-gray-50 dark:bg-gray-900 h-dvh">
                <div className="w-full h-full">
                    <div className="bg-white h-full dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden w-full">
                        <div className="items-center  mx-4 mt-4 ">
                            <div className="flex  justify-between mb-1">
                                <div className="flex ">
                                    <MdOutlineShoppingCart size={32} />{' '}
                                    <span className="font-medium text-2xl">List of products</span>
                                </div>
                                <div className="flex ">
                                    <button
                                        type="button"
                                        className="gap-1 flex items-center justify-center rounded-xl border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            let navigation = 'admin';
                                            if (user.role === 'accountManager') {
                                                navigation = 'accountManager';
                                            }
                                            navigate(`/${navigation}/resellerProducts-content-details`);
                                        }}
                                    >
                                        <FaPlus className="w-4 h-4" />
                                        Product content
                                    </button>
                                </div>
                            </div>

                            <div className=" relative flex w-full items-center py-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4 justify-between mb-1">
                                <div className="flex items-center p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                                    <div id="selectStatus">
                                        <Select
                                            id="productStatus"
                                            required
                                            onChange={(e) => {
                                                handleChangeFilter(e);
                                            }}
                                            name="selectStatus"
                                        >
                                            <option value="">All Status</option>
                                            {statusLists.map((status) => {
                                                return (
                                                    <option key={status.id} value={status.label}>
                                                        {status.label}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div>
                                    <TextInput
                                        icon={AiOutlineSearch}
                                        id="name"
                                        placeholder="Search"
                                        name="name"
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={handleChangeFilter}
                                        className="!w-[290px]"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mx-4">
                            <DataTable
                                rows={resellerProducts}
                                columns={cols}
                                icons={statusDisplayColor}
                                setRows={handleProducts}
                                pageState={{
                                    isLoading,
                                    total: meta.totalDocs,
                                    pageSize: pagination.limit,
                                    page: pagination.page,
                                    count: meta.totalPages,
                                }}
                                setPageState={handlePaginationChange}
                                filterState={filter}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default ResellerProductsContent;
