import { ICallAgent } from 'pages/callAgentList/callAgent.types';
import { ICallCenter } from 'pages/callCenterList/callCenter.types';
import { ICustomer } from 'pages/customers/customer.types';
import { IUser } from 'services/auth';
import axios from 'utils/axios';
import { IProduct } from 'utils/Interfaces/products.interface';
import { IPaginationData } from 'utils/Interfaces/types';
import { socket } from 'utils/socketIO';
import { getBearerToken, getCurrentUser } from '../../utils/helpers/auth';
import { IStore } from './store';

interface IOptions {
    dateRange?: { startDate?: string | number; endDate?: string | number };
    name?: string;
    status?: number | number[];
    zipCode?: number;
    customer?: ICustomer | string;
    country?: string;
    fulfillement?: string;
    goodsCost?: number;
    ShippingCost?: number;
    total?: number;
    page?: number;
    limit?: number;
    type?: number;
    callAgent?: string;
}

export interface IItems {
    product: string | IProduct;
    quantity?: number;
    upsellQuantity?: number;
}

export interface IOrder {
    _id: string;
    product: string | IProduct;
    logistioVariant: string;
    store: string | IStore;
    customer: string | ICustomer;
    externalOrderId?: string;
    address1?: string;
    address2?: string;
    city?: string;
    company?: string;
    country: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    phone?: string;
    province?: string;
    zip?: string;
    provinceCode?: string;
    countryCode?: string;
    actionReason?: number;
    updateType?: number;
    currency?: string;
    failReason?: string;
    totalPrice?: number;
    items: IItems[];
    shipmentLineStatus?: string;
    status: number;
    shipmentLineOrderId?: string;
    trackingCode?: string;
    lgstTrackingCode?: string;
    statusDescription?: string;
    callCenterFeedback?: string[];
    externalRefId?: string;
    callCenterAttempt?: number;
    fixedDeliveryDate?: string;
    cod?: boolean;
    callAgent: string | ICallAgent;
    orderRef?: string;
    unreachableCount: number;
    lastUnreachableTimestamp: number;
    deliveryDate: Date;
    lastTimeAssigned: Date;
    createdAt: string;
    updatedAt: string;
    withCC: boolean;
    prePaid?: boolean;
    inquiriesConversation?: {
        sentBy: string | IUser;
        content: string;
        sentAt: Date;
    }[];
    scheduledCallAt?: Date;
    returnUsed?: boolean;
    callCenter?: ICallCenter;
    duplicatedOrder?: {
        orderId: string;
        orderRef?: string;
    };
}
export interface IOrderHistory {
    _id?: string;
    order: string & IOrder;
    oldStatus: number;
    newStatus: number;
    onModel: string;
    doneBy: string & ICustomer & IUser;
    changeType: string;
    createdAt: string;
    description?: string;
}
interface IPaginatedOrders extends IPaginationData<IOrder> {}

export const getOrders = async (options: IOptions) => {
    const config = getBearerToken();
    const response = await axios.post<IPaginatedOrders>(`/orders/filter`, options, config);
    return response;
};

export const getOrder = async (id: string) => {
    const config = getBearerToken();
    const response = await axios.get<IOrder>(`/orders/${id}`, config);
    return response;
};

export const updateOrderItems = async (data: any) => {
    const orderUpdate = await axios.put(`/orders/items/${data.id}`, data, getBearerToken());
    if (orderUpdate.data.orderHistoryId) {
        socket.emit('orderStatusUpdate', {
            // eslint-disable-next-line no-underscore-dangle
            user: getCurrentUser()._id,
            orderHistoryId: orderUpdate.data.orderHistoryId,
        });
    }
    return orderUpdate;
};

export const updateOrder = async (data: any) => {
    const orderUpdate = await axios.put(`/orders/update/${data.id}`, data, getBearerToken());
    if (orderUpdate.data.orderHistoryId) {
        socket.emit('orderStatusUpdate', {
            // eslint-disable-next-line no-underscore-dangle
            user: getCurrentUser()._id,
            orderHistoryId: orderUpdate.data.orderHistoryId,
        });
    }
    return orderUpdate;
};
export const markAsOriginalPackageOrder = async (data: { id: string }) => {
    const originalPackageOrderUpdate = await axios.post(`/orders/originalpackage/`, data, getBearerToken());
    return originalPackageOrderUpdate;
};

export const assignCallAgent = async (data: any) => {
    const orderUpdate = await axios.put(`/orders/assignAgent`, data, getBearerToken());
    if (orderUpdate.data.orderHistoryId) {
        socket.emit('orderStatusUpdate', {
            // eslint-disable-next-line no-underscore-dangle
            user: getCurrentUser()._id,
            orderHistoryId: orderUpdate.data.orderHistoryId,
        });
    }
    return orderUpdate;
};

export const assignOrdersToCallCenter = async (data: { ordersIds: string[]; callCenterId: string }) => {
    const orderUpdate = await axios.put<{
        assignedOrdersIds: string[];
        failedOrders:
            | {}
            | {
                  [orderId: string]: string[];
              };
    }>('/orders/assignCallCenter', data, getBearerToken());
    return orderUpdate;
};

export const getHistory = async (OrderId: string) => {
    const orderHistory = await axios.get<IOrderHistory[]>(`/orders/history/${OrderId}`, getBearerToken());
    return orderHistory;
};
export const getHistoryOrdersByAdmin = async (data: any) => {
    const config = getBearerToken();
    const orderHistory = await axios.post(`/orders/HistoryByAdmin`, { ...data }, config);
    return orderHistory;
};

export const getPendingAnswerOrdersCount = async () => {
    const res = await axios.get<number>('/orders/info/countPendingAnswer', getBearerToken());
    return res;
};
