/* eslint-disable no-underscore-dangle */
import CustomSelect from 'components/customSelect/customSelect';
import { Button, Modal } from 'flowbite-react';
import { ICustomer } from 'pages/customers/customer.types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { assignCustomersToLogitioBulkFees, getCustomers } from 'services/admin/customer';
import { deleteLogistioBulkFees, ILogistioBulkFees } from 'services/admin/logistioBulkFees';
import { ILogisioBulkModals } from './logistioBulkFees';

export interface LogistioBulkFeesModalProps {
    open: ILogisioBulkModals;
    setOpen: any;
    fee: ILogistioBulkFees;
    refetchGetFees: any;
    action: string;
    setAction: any;
}
const LogistioBulkFeesModal: React.FC<LogistioBulkFeesModalProps> = ({
    open,
    setOpen,
    fee,
    refetchGetFees,
    action,
    setAction,
}) => {
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        getCustomers({ limit: 1000, status: 2, excludeFee: fee._id }).then((res) => {
            setCustomers(res?.data?.docs);
            setIsLoading(false);
        });
    }, []);

    const handleAddCustomers = (e: any) => {
        if (e?.target.value !== '') {
            if (selectedCustomers?.includes(e.target.value)) {
                setSelectedCustomers(
                    e.target.value === 'select_all'
                        ? []
                        : selectedCustomers?.filter((elt: string) => elt !== e.target.value && elt !== 'select_all'),
                );
            } else {
                setSelectedCustomers(
                    e.target.value === 'select_all'
                        ? ['select_all', ...(customers as [])?.map((elt: any) => elt?._id)]
                        : [...(selectedCustomers?.filter((elt: string) => elt !== 'select_all') || []), e.target.value],
                );
            }
        } else {
            setSelectedCustomers([]);
        }
    };

    const handleAssignCustomers = () => {
        if (selectedCustomers?.length && fee._id) {
            assignCustomersToLogitioBulkFees({
                customers: selectedCustomers?.filter((elt) => elt !== 'select_all'),
                logistioBulkFees: fee._id,
            })
                .then(() => {
                    toast.success('The seller (s) has been assigned to the logistio bulk fee.');
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong! Please try again.');
                })
                .finally(() => {
                    refetchGetFees();
                    setOpen((prevOpen: ILogisioBulkModals) => ({
                        ...prevOpen,
                        modal: false,
                    }));
                });
        }
    };

    const handleDeleteFee = () => {
        if (fee?._id) {
            deleteLogistioBulkFees(fee._id)
                .then(() => {
                    toast.success('Logistio bulk fees has been deleted');
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong! Please try again.');
                })
                .finally(() => {
                    refetchGetFees();
                    setOpen((prevOpen: ILogisioBulkModals) => ({
                        ...prevOpen,
                        modal: false,
                    }));
                });
        }
    };

    const handleCancel = () => {
        setAction(action === 'ASSIGN_UPDATE' ? 'UPDATE' : '');
        setOpen((prevOpen: ILogisioBulkModals) => ({
            ...prevOpen,
            modal: false,
        }));
    };
    const handleConfirm = () => {
        if (['ASSIGN_UPDATE', 'ASSIGN'].includes(action)) handleAssignCustomers();
        if (action === 'DELETE') handleDeleteFee();
        setAction('');
    };
    const handleModalTitle = () => {
        if (['ASSIGN_UPDATE', 'ASSIGN'].includes(action)) return `Assign sellers to ${fee.name}`;
        if (action === 'DELETE') return `Delete fee: ${fee.name}`;
        return null;
    };

    return (
        <>
            <Modal
                key="logistioBulkModel"
                dismissible
                show={open.modal}
                className="z-[1400] p-4"
                popup
                onClose={() =>
                    setOpen((prevOpen: ILogisioBulkModals) => ({
                        ...prevOpen,
                        modal: false,
                    }))
                }
            >
                <Modal.Header>{handleModalTitle()} </Modal.Header>

                <Modal.Body className="min-h-[350px] min-w-[120px]">
                    <div className="flex flex-col justify-between p-4 h-[340px]">
                        {!isLoading && ['ASSIGN_UPDATE', 'ASSIGN'].includes(action) ? (
                            <div className="flex  justify-center ">
                                {customers?.length ? (
                                    <CustomSelect
                                        optionList={customers}
                                        selectedOptions={selectedCustomers}
                                        onChange={handleAddCustomers}
                                        placeholder="Select the Seller(s)"
                                    />
                                ) : (
                                    <p>All the sellers are assigned to this logistio bulk fee</p>
                                )}
                            </div>
                        ) : (
                            <div />
                        )}

                        {action === 'DELETE' && (
                            <div className="flex items-center justify-center">
                                <p>If you remove this offer, sellers will be reassigned to the default offer.</p>
                            </div>
                        )}

                        <div className="flex items-center justify-end w-full gap-2">
                            <Button color="light" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button
                                color="blue"
                                disabled={!selectedCustomers?.length && action !== 'DELETE'}
                                onClick={handleConfirm}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LogistioBulkFeesModal;
