import { Badge } from 'flowbite-react';
import React, { ReactElement } from 'react';

export interface IStausInterface {
    statusIcon: ReactElement;
    statusLabel: string;
}
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="info" size="sm">
                New
            </Badge>
        ),
        statusLabel: 'New',
    },
    2: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Canceled
            </Badge>
        ),
        statusLabel: 'Canceled',
    },
    21: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Wrong number
            </Badge>
        ),
        statusLabel: 'Wrong number',
    },
    3: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Unreachable
            </Badge>
        ),
        statusLabel: 'Unreachable',
    },
    4: {
        statusIcon: (
            <Badge color="success" size="sm">
                Confirmed
            </Badge>
        ),
        statusLabel: 'Confirmed',
    },
    41: {
        statusIcon: (
            <Badge color="info" size="sm">
                Pending answer
            </Badge>
        ),
        statusLabel: 'Pending answer',
    },
    42: {
        statusIcon: (
            <Badge color="success" size="sm">
                Question answered
            </Badge>
        ),
        statusLabel: 'Question answered',
    },
    43: {
        statusIcon: (
            <Badge color="info" size="sm">
                Scheduled call
            </Badge>
        ),
        statusLabel: 'Scheduled call',
    },
    5: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Waiting for shipment
            </Badge>
        ),
        statusLabel: 'Waiting for shipment',
    },
    6: {
        statusIcon: (
            <Badge color="info" size="sm">
                Out for shipment
            </Badge>
        ),
        statusLabel: 'Out for shipment',
    },
    7: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Shipped
            </Badge>
        ),
        statusLabel: 'Shipped',
    },
    8: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pick and pack
            </Badge>
        ),
        statusLabel: 'Pick and pack',
    },
    81: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order received by carrier
            </Badge>
        ),
        statusLabel: 'Order received by carrier',
    },
    82: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Arrive to headquarters warehouse
            </Badge>
        ),
        statusLabel: 'Arrive to headquarters warehouse',
    },
    83: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order transferred
            </Badge>
        ),
        statusLabel: 'Order transferred',
    },
    84: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Arrival at Hub
            </Badge>
        ),
        statusLabel: 'Arrival at Hub',
    },
    9: {
        statusIcon: (
            <Badge color="info" size="sm">
                Out for delivery
            </Badge>
        ),
        statusLabel: 'Out for delivery',
    },
    10: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Delivery attempted
            </Badge>
        ),
        statusLabel: 'Delivery attempted',
    },
    101: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order returned to Hub
            </Badge>
        ),
        statusLabel: 'Order returned to Hub',
    },
    102: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Order returned to Warehouse
            </Badge>
        ),
        statusLabel: 'Order returned to Warehouse',
    },
    103: {
        statusIcon: (
            <Badge color="warning" size="sm">
                New delivery request
            </Badge>
        ),
        statusLabel: 'New delivery request',
    },
    104: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Wait to WMS
            </Badge>
        ),
        statusLabel: 'Wait to WMS',
    },
    105: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Delivery scheduled
            </Badge>
        ),
        statusLabel: 'Delivery scheduled',
    },
    11: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Delivery failed
            </Badge>
        ),
        statusLabel: 'Delivery failed',
    },
    111: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Return to origin - Out
            </Badge>
        ),
        statusLabel: 'Return to origin - Out',
    },
    112: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Return to origin - In
            </Badge>
        ),
        statusLabel: 'Return to origin - In',
    },
    113: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Transport management system to Warehouse
            </Badge>
        ),
        statusLabel: 'Transport management system to Warehouse',
    },
    114: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Back to stock
            </Badge>
        ),
        statusLabel: 'Back to stock',
    },
    12: {
        statusIcon: (
            <Badge color="success" size="sm">
                Delivered
            </Badge>
        ),
        statusLabel: 'Delivered',
    },
    13: {
        statusIcon: (
            <Badge color="pink" size="sm">
                Expired
            </Badge>
        ),
        statusLabel: 'Expired',
    },
    14: {
        statusIcon: (
            <Badge color="red" size="sm">
                Invalid
            </Badge>
        ),
        statusLabel: 'Invalid',
    },
    15: {
        statusIcon: (
            <Badge color="red" size="sm">
                Pending payment
            </Badge>
        ),
        statusLabel: 'Pending payment',
    },
    99: {
        statusIcon: (
            <Badge color="purple" size="sm">
                Check required
            </Badge>
        ),
        statusLabel: 'Check required',
    },
    100: {
        statusIcon: (
            <Badge color="red" size="sm">
                Hold - No stock available
            </Badge>
        ),
        statusLabel: 'Hold - No stock available',
    },
    115: {
        statusIcon: (
            <Badge color="red" size="sm">
                Duplicated
            </Badge>
        ),
        statusLabel: 'Duplicated',
    },
};
