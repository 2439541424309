import { Badge, Timeline } from 'flowbite-react';
import moment from 'moment';
import { ResellerProduct } from 'pages/reseller/reseller.types';
import React from 'react';
import { HiCalendar } from 'react-icons/hi';
import { ResellerProductPricingHistory } from './types';

export const StatusTimeline: React.FC<{
    history: ResellerProductPricingHistory[];
    product: ResellerProduct;
}> = ({ history, product }) => {
    return (
        <div className=" realative overflow-y-auto relative h-full w-full p-8 md:h-auto max-w-2xl">
            <div className="gap-2  mb-3 text-2xl font-bold">
                Reseller Product Name : {` ${product.name} / ${product.variant}`}
            </div>
            <Timeline>
                {history?.map((histo, idx) => {
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Timeline.Item key={idx}>
                            <Timeline.Point icon={HiCalendar} />
                            <Timeline.Content>
                                <Timeline.Time className="text-gray-700">
                                    {moment(
                                        new Date(histo.updatedAt || '').toLocaleString('en-US', {
                                            timeZone: 'Asia/Shanghai',
                                        }),
                                    ).format('DD MMMM YYYY , HH:mm:ss')}
                                </Timeline.Time>
                                <Timeline.Title>{histo.country}</Timeline.Title>
                                <Timeline.Body>
                                    <div className="flex flex-wrap gap-6 mt-2">
                                        <div className="flex gap-2">
                                            Old Price:
                                            {histo.oldPrice === undefined || histo.oldPrice === null ? (
                                                <span>--</span>
                                            ) : (
                                                <Badge color="yellow" size="sm">
                                                    {histo.oldPrice}
                                                </Badge>
                                            )}
                                        </div>
                                        <div className="flex gap-2">
                                            New Price:
                                            {histo.newPrice === undefined || histo.newPrice === null ? (
                                                <span>--</span>
                                            ) : (
                                                <Badge color="blue" size="sm">
                                                    {histo.newPrice}
                                                </Badge>
                                            )}
                                        </div>
                                    </div>
                                </Timeline.Body>{' '}
                            </Timeline.Content>
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        </div>
    );
};
export default StatusTimeline;
