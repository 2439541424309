/* eslint-disable no-underscore-dangle */
import { Button, Modal, Textarea } from 'flowbite-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { getCredits, getCreditStats, ICredit, updatecredit } from 'services/accountManager/credits';
import { ICountCreditStats } from './credits.types';

export interface ConfirmationModalProps {
    open: boolean;
    credit: ICredit;
    action: string;
    currentPage: number | undefined;
    currentFilter: any;
    setAction: React.Dispatch<React.SetStateAction<string>>;
    setStats: React.Dispatch<React.SetStateAction<ICountCreditStats>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setCredits: React.Dispatch<React.SetStateAction<ICredit[]>>;
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    open,
    setOpen,
    credit,
    setCredits,
    action,
    setAction,
    currentPage,
    setStats,
    currentFilter,
}) => {
    const [reason, setReason] = useState<string>('');
    const editCredit = (status: number) => {
        updatecredit(credit._id, {
            status,
            reason,
        }).then(() => {
            getCredits({ page: currentPage, ...currentFilter })
                .then((res) => {
                    setCredits(res.data.docs);
                })
                .catch(() => {
                    toast.error(`Something went wrong, please try again`);
                });

            getCreditStats(currentFilter)
                .then((res) => {
                    setStats(res.data);
                })
                .catch((err) => {
                    toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {});
                });
            toast.success(`Credit successfully updated`);
            setOpen(false);
        });
    };

    const handleCancel = () => {
        setAction('');
        setOpen(false);
    };
    const handleConfirm = () => {
        switch (action) {
            case 'reject':
                editCredit(4);
                break;

            case 'confirm':
                editCredit(2);
                break;

            case 'paid':
                editCredit(3);
                break;

            default:
                break;
        }
        setAction('');
    };
    const handleModalTitle = () => {
        switch (action) {
            case 'reject':
                return `Reject credit`;

            case 'confirm':
                return `Confirm credit`;

            case 'paid':
                return `Confirm payment`;
            default:
                return null;
        }
    };

    return (
        <>
            <Modal key="confirmCredit" dismissible show={open} className="p-4" popup onClose={() => setOpen(false)}>
                <Modal.Header>{handleModalTitle()} </Modal.Header>

                <Modal.Body className="min-h-[120px] min-w-[100px]">
                    <div className="flex flex-col justify-between items-center min-h-[120px] p-2">
                        {['confirm', 'reject'].includes(action) && (
                            <div className="flex items-center justify-center">
                                <p className="font-bold">Are you certain you want to {action} this credit?</p>
                            </div>
                        )}

                        {action === 'paid' && (
                            <div className="flex flex-col gap-2 pb-2">
                                <div>
                                    <p className="font-bold">
                                        Please note: confirming the payment for this credit will prevent any further
                                        status updates.
                                    </p>
                                </div>

                                <div>
                                    <Textarea
                                        id="notes"
                                        placeholder="Please enter a reason for marking this credit as paid..."
                                        rows={2}
                                        value={reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="flex items-center justify-end w-full gap-2">
                            <Button color="light" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button color="blue" onClick={handleConfirm}>
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ConfirmationModal;
