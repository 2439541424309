import { Badge, Button, Modal } from 'flowbite-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { deleteCustomerFromCampaign } from 'services/admin/campaign';
import { getOrder, IOrder, updateOrder } from 'services/admin/order';
import { addCountryPrefix } from 'utils/hooks/useZiwoEvents';
import {
    useScheduledCallNotification,
    useSetScheduledCallNotification,
    useZiwo,
    useZiwoVisibility,
} from 'utils/zustand/ziwo';

export default function ScheduledCallNotification() {
    const { show, data } = useScheduledCallNotification();
    const setScheduledCall = useSetScheduledCallNotification();
    const z = useZiwo();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setOpenZiwo] = useZiwoVisibility();

    const [order, setOrderData] = useState<IOrder>();
    useEffect(() => {
        if (data) {
            getOrder(data?.order)
                .then((res) => {
                    setOrderData(res.data);
                })
                .catch(() => {
                    toast.error('Unable to fetch order data for scheduled call notification');
                });
        }
    }, [data]);

    const clickToCall = () => {
        if (z && order?.country && data?.phoneNumber) {
            setOpenZiwo(true);
            setScheduledCall({ show: false });
            z.calls
                .startCall(addCountryPrefix(data.phoneNumber, order?.country))
                .catch((e: any) => toast.error(e?.message));
            deleteCustomerFromCampaign(data.phoneNumber).catch(() => {
                toast.error('Something went wrong when handling campign data');
            });
        } else {
            toast.error('Unable to start call');
        }
    };

    const rescheduleCall = () => {
        const unixTimeStamp = new Date().getTime() + 5 * 1000 * 60;
        // eslint-disable-next-line no-underscore-dangle
        updateOrder({ id: order?._id, status: 43, scheduledCallAt: new Date(unixTimeStamp) })
            .then(() => {
                toast.success('Call sheduled successfully');
                setScheduledCall({ show: false });
            })
            .catch(() => {
                toast.error('Unable to schedule call');
            });
    };

    return (
        <Modal show={show} popup onClose={() => setScheduledCall({ show: false })}>
            <Modal.Body>
                {data && order ? (
                    <div className="text-center py-3 flex flex-col items-center justify-center gap-4">
                        <h3>Scheduled call</h3>
                        <div className="text-center space-y-2">
                            <p>
                                You have a scheduled call at {moment(order.scheduledCallAt).format('MM/DD/YYYY HH:mm')}
                            </p>
                            <div className="flex items-center justify-center gap-2">
                                <span className="font-medium">Order Ref:</span>
                                <Badge className="px-2 py-1">{order.orderRef || '--'}</Badge>
                            </div>
                        </div>
                        <div className="flex justify-center gap-4">
                            <Button
                                color="blue"
                                onClick={() => {
                                    clickToCall();
                                }}
                            >
                                Start call
                            </Button>
                            <Button
                                color="gray"
                                onClick={() => {
                                    rescheduleCall();
                                }}
                            >
                                Reschedule + 5 min
                            </Button>
                        </div>
                    </div>
                ) : null}
            </Modal.Body>
        </Modal>
    );
}
