/* eslint-disable no-underscore-dangle */
import React from 'react';
import toast from 'react-hot-toast';
import Barcode from 'react-barcode';
import { getLabels, LABEL_SIZES } from 'services/admin/stock';
import { IStockShipment } from 'services/admin/stockShipment';
import generatePDF from 'react-to-pdf';
import { getResellerLabels, ResellerStockShipment } from 'services/admin/resellerStock';

export const copyToClickboard = (value: string, field: string) => {
    navigator.clipboard.writeText(value);
    toast.success(`${field}: ${value} copied to clipboard`);
};
export function BarCode({ barCode, elRef }: { barCode: string | undefined; elRef: any }) {
    return (
        <div className="flex flex-col justify-center items-center gap-2 py-2" ref={elRef}>
            <Barcode value={barCode || ''} />
            <p className="text-xs text-black">Made in china</p>
        </div>
    );
}

export const LABELSIZES: LABEL_SIZES[] = ['mini', 'a4'];

export const stockShipmentsStatus = [
    { label: 'Preparing stock', value: '1', color: 'yellow' },
    { label: 'Ready for pickup', value: '2', color: 'purple' },
    { label: 'Stock received by carrier', value: '30', color: 'pink' },
    { label: 'Sent to GuangZhou Airport', value: '31', color: 'pink' },
    { label: 'Instation Scan GZ', value: '32', color: 'pink' },
    { label: 'Arrived in loading warehouse', value: '33', color: 'pink' },
    { label: 'Sent To HongKong', value: '34', color: 'pink' },
    { label: 'Instation Scan HK', value: '35', color: 'pink' },
    { label: 'Departed on Flight', value: '36', color: 'pink' },
    { label: 'Arrived destination Airport', value: '37', color: 'pink' },
    { label: 'Customs clearance start', value: '38', color: 'pink' },
    { label: 'Customs Released_Import', value: '39', color: 'pink' },
    { label: 'Arrive to headquarters warehouse', value: '40', color: 'pink' },
    { label: 'Delivered', value: '4', color: 'greenTag' },
    { label: 'Canceled', value: '100', color: 'red' },
];

export const downloadLabels = async (size: LABEL_SIZES, shipmentData: IStockShipment | ResellerStockShipment) => {
    const downloadToast = toast.loading('Loading labels...');
    try {
        const response = (shipmentData as ResellerStockShipment).resellerProduct
            ? await getResellerLabels({
                  shipmentId: shipmentData._id,
                  size,
              })
            : await getLabels({
                  shipmentId: shipmentData._id,
                  size,
              });
        toast.loading('Generating your files...', { id: downloadToast });

        const blob = new Blob([response.data]);
        const reader = new FileReader();
        reader.onloadend = () => {
            const downloadLink = document.createElement('a');
            downloadLink.href = reader.result as string;
            downloadLink.download =
                response.headers['Content-Disposition'] || `shipment-labels-${shipmentData.trackingCode}-${size}.pdf`;
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            toast.success('Labels loaded successfully', { id: downloadToast });
        };
        reader.readAsDataURL(blob);
    } catch (e) {
        toast.error((e as any)?.response?.data?.errors?.message || 'Unable to load labels', { id: downloadToast });
    }
};

export const handleDownloadBarCode = (barCode: string | undefined, barcodePdfRef: any) => {
    const downloadToast = toast.loading('Loading barcode label...');
    if (!barCode) {
        toast.error('Unable to load barcode label', { id: downloadToast });
        return;
    }
    generatePDF(barcodePdfRef, {
        filename: `bc-label-${barCode}`,
    });
    toast.success('Labels loaded successfully', { id: downloadToast });
};
