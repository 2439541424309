/* eslint-disable no-underscore-dangle */
import React from 'react';
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import DataTable from 'components/tables/tables';
import { TableColDef } from 'components/tables/tables.types';
// import { useNavigate } from 'react-router-dom';
import { IOrder } from 'services/admin/order';
import { orderStatus } from 'pages/orders/orders.styles';
import { Badge, Button } from 'flowbite-react';
import { handleCopyTC } from 'utils/helpers/copyTC';
import { FaRegCopy } from 'react-icons/fa';

const OrdersList: React.FC<{
    orders: IOrder[];
    open: boolean;
    setOpen: (el: boolean) => void;
}> = ({ orders, open, setOpen }) => {
    // const navigate = useNavigate();

    const columns: TableColDef[] = [
        {
            field: 'orderRef',
            headerName: 'Order Ref',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: IOrder) => <p>{`${params?.orderRef} `}</p>,
        },
        {
            field: 'trackingCode',
            headerName: 'Tracking Code',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: any) => {
                return (
                    <>
                        <td className="py-4 block gap-2">
                            <div className="flex items-center gap-2">
                                <p className="font-normal ">{params?.lgstTrackingCode || '--'}</p>
                                {params?.lgstTrackingCode && (
                                    <Button
                                        size="l"
                                        color="white"
                                        title="Copy tracking code to clipboard"
                                        onClick={() => {
                                            handleCopyTC(params?.lgstTrackingCode || '--', 'Tracking Code');
                                        }}
                                    >
                                        <FaRegCopy className="w-4 h-4" />
                                    </Button>
                                )}
                            </div>
                        </td>{' '}
                    </>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <div style={{ width: 'fit-content' }}>
                        <div className="flex gap-2 items-center">
                            <Badge color={orderStatus(params.status).color} className="w-max max-w-[150px]">
                                {orderStatus(params.status).title}
                            </Badge>
                        </div>
                    </div>
                    {params.statusDescription && (
                        <p>
                            {params.statusDescription.substring(0, 20) +
                                (params.statusDescription.substring(20).length > 0 ? '...' : '')}
                        </p>
                    )}
                </div>
            ),
        },
    ];
    return (
        <>
            <GeneralDrawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    sx: { width: '60%' },
                }}
            >
                <div className="p-4">
                    <h4 className="py-2">List of Orders</h4>
                    <DataTable
                        rows={orders}
                        columns={columns}
                        setRows={() => {}}
                        pageState={{
                            isLoading: false,
                            total: 0,
                            pageSize: 0,
                            page: 0,
                            count: 0,
                        }}
                        setPageState={() => {}}
                    />
                </div>
            </GeneralDrawer>
        </>
    );
};
export default OrdersList;
