/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Button, Modal, Select } from 'flowbite-react';
import { AiFillEdit } from 'react-icons/ai';
import { assignCustomersToLogitioBulkFees, getCustomers } from 'services/admin/customer';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { getLogistioBulkFeesList, ILogistioBulkFees, IPricingLogistioBulkFees } from 'services/admin/logistioBulkFees';
import { ICustomer } from '../customer.types';

export interface CustomerFeeModalProps {
    open: boolean;
    setOpen: any;
    customer: ICustomer;
    setRows: any;
    currentPage: number;
}

type PricingFilterKeys = keyof IPricingLogistioBulkFees;

const CustomerFeeModal: React.FC<CustomerFeeModalProps> = ({ open, setOpen, customer, setRows, currentPage }) => {
    const [selectedFilter, setSelectedFilter] = useState<PricingFilterKeys>('codWithCC');
    const [edit, setEdit] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [logistioBulkFees, setLogistioBulkFees] = useState<ILogistioBulkFees[]>([]);
    const [selectedFee, setSelectedFee] = useState<string>('');

    useEffect(() => {
        setIsLoading(true);
        getLogistioBulkFeesList().then((res) => {
            setLogistioBulkFees(
                res?.data?.filter((elt: ILogistioBulkFees) => elt._id !== customer.logistioBulkFees?._id) || [],
            );
            setIsLoading(false);
        });
    }, []);

    const handleCancel = () => {
        setEdit(false);
    };
    const handleConfirm = () => {
        if (customer?._id && selectedFee) {
            assignCustomersToLogitioBulkFees({
                customers: [customer?._id],
                logistioBulkFees: selectedFee,
            })
                .then(() => {
                    toast.success('The seller has been assigned to the logistio bulk fee.');
                })
                .catch(() => {
                    toast.error('Sorry, something went wrong! Please try again.');
                })
                .finally(() => {
                    getCustomers({ page: currentPage }).then((res) => {
                        const updatedDocs = res.data.docs.map(
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            (customer: { accountManager: any[]; accountManagerNames: string }) => {
                                const acctManagers = customer.accountManager || [];
                                const managerNames = acctManagers
                                    .map((manager: { firstName: string; lastName?: string }) => {
                                        const lastNameInitial = manager.lastName ? manager.lastName.charAt(0) : '';
                                        return `${manager.firstName}${lastNameInitial}`;
                                    })
                                    .join(', ');
                                return { ...customer, accountManagerNames: managerNames };
                            },
                        );

                        getLogistioBulkFeesList().then((ress) => {
                            setLogistioBulkFees(
                                ress?.data?.filter(
                                    (elt: ILogistioBulkFees) =>
                                        elt._id !==
                                        res.data.docs.find((seller: any) => seller._id === customer._id)
                                            .logistioBulkFees?._id,
                                ) || [],
                            );
                        });

                        setRows(updatedDocs);
                    });

                    setEdit(false);
                });
        }
    };

    const handleEdit = () => {
        setEdit(true);
    };

    const priceTable = (pricing: any) => {
        const countries = Object.keys(pricing);
        switch (selectedFilter) {
            case 'codWithCC':
                return (
                    <div className="overflow-x-auto py-2">
                        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="py-2 px-2 text-left border border-gray-300" />
                                    {pricing.map((elt: any) => (
                                        <th key={elt.country} className="py-2 px-2 text-center border border-gray-300">
                                            {elt.country}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">Entered ($)</td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-newLeadPrice`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].newLeadPrice}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">Confirmed ($)</td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-confirmedLeadPrice`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].confirmedLeadPrice}
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">Delivered ($)</td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-deliveredPriceforCC`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].deliveredPriceforCC}
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">
                                        Shipped & delivered ($)
                                    </td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-deliveredLeadPrice`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].deliveredLeadPrice}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">
                                        Shipped & Returned ($)
                                    </td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-shippedLeadPrice`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].shippedLeadPrice}
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">COD (%)</td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-percentFromSellPrice`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].percentFromSellPrice}
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">
                                        Up Down Cross sell ($)
                                    </td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-upDownCross`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].upDownCross}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );

            case 'codNoCC':
                return (
                    <div className="overflow-x-auto py-2">
                        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="py-2 px-2 text-left border border-gray-300" />
                                    {pricing.map((elt: any) => (
                                        <th key={elt.country} className="py-2 px-2 text-center border border-gray-300">
                                            {elt.country}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">
                                        Shipped & delivered ($)
                                    </td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-deliveredLeadPrice`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].deliveredLeadPrice}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">
                                        Shipped & Returned ($)
                                    </td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-shippedLeadPrice`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].shippedLeadPrice}
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">COD (%)</td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-percentFromSellPrice`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].percentFromSellPrice}
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">Handle Fees ($)</td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-handleFees`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].handleFees}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );

            case 'prepaidNoCC':
                return (
                    <div className="overflow-x-auto py-2">
                        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="py-2 px-2 text-left border border-gray-300" />
                                    {pricing.map((elt: any) => (
                                        <th key={elt.country} className="py-2 px-2 text-center border border-gray-300">
                                            {elt.country}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">Shipping Fees ($)</td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-shippedLeadPrice`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].shippedLeadPrice}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="py-2 px-2 text-left border border-gray-300">Handle Fees ($)</td>
                                    {countries.map((country) => (
                                        <td
                                            key={`${country}-handleFees`}
                                            className="py-2 px-2 text-center border border-gray-300"
                                        >
                                            {pricing[country].handleFees}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Modal dismissible show={open} className="z-[1400] p-4 " popup onClose={() => setOpen(false)}>
                <Modal.Header>Logistio Bulk Fees: {customer.logistioBulkFees?.name}</Modal.Header>

                <Modal.Body className="min-h-[400px]  w-full ">
                    {!edit ? (
                        <div className="flex flex-col justify-between p-2 ">
                            <div className="flex items-center justify-between  gap-4 ">
                                <div className="flex items-center gap-4 py-2">
                                    <Button
                                        size="xs"
                                        className="text-xs"
                                        color={selectedFilter === 'codWithCC' ? 'purple' : 'light'}
                                        onClick={() => setSelectedFilter('codWithCC')}
                                    >
                                        COD with call center
                                    </Button>

                                    <Button
                                        size="xs"
                                        className="text-xs"
                                        color={selectedFilter === 'codNoCC' ? 'purple' : 'light'}
                                        onClick={() => setSelectedFilter('codNoCC')}
                                    >
                                        COD without call center
                                    </Button>

                                    <Button
                                        size="xs"
                                        className="text-xs"
                                        color={selectedFilter === 'prepaidNoCC' ? 'purple' : 'light'}
                                        onClick={() => setSelectedFilter('prepaidNoCC')}
                                    >
                                        Prepaid
                                    </Button>
                                </div>
                                <div>
                                    <Button size="xs" className="text-xs" onClick={() => handleEdit()}>
                                        <AiFillEdit className=" h-5 w-5" />
                                    </Button>
                                </div>
                            </div>

                            <div className="py-2">
                                {priceTable(customer?.logistioBulkFees?.pricing[selectedFilter])}
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col justify-between p-4 h-[350px]">
                            <div className="pt-4">
                                {!isLoading && logistioBulkFees?.length ? (
                                    <Select
                                        disabled={!logistioBulkFees?.length}
                                        id="fee"
                                        name="fee"
                                        onChange={(e) => {
                                            setSelectedFee(e.target.value);
                                        }}
                                    >
                                        <option value="" key="">
                                            Logistio Bulk Fees list
                                        </option>
                                        {logistioBulkFees?.map((fee: ILogistioBulkFees) => (
                                            <option key={fee._id} value={fee._id}>
                                                {fee.name}
                                            </option>
                                        ))}
                                    </Select>
                                ) : (
                                    <div />
                                )}
                            </div>

                            {!logistioBulkFees?.length && !isLoading && (
                                <div className="flex items-center justify-center">
                                    <Link to="/admin/configuration" state={{ isPricing: true }}>
                                        <p className="underline">
                                            You have only a default fee, click here to add a new fee .
                                        </p>
                                    </Link>
                                </div>
                            )}
                            <div className="flex items-center justify-end w-full gap-2">
                                <Button color="light" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button color="blue" disabled={!selectedFee} onClick={handleConfirm}>
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CustomerFeeModal;
