import React, { useState } from 'react';
import { getCustomers, updateCustomer } from 'services/admin/customer';
import { Formik, Form } from 'formik';
import { changeLimitSchema } from 'utils/validations/accountInformationValidation';
import CustomError from 'components/customError/CustomError';
import { HiCheckCircle } from 'react-icons/hi';
import { Button, Label, Select } from 'flowbite-react';
import CounterCustom from 'components/counter/customCounter';
import CustomBtn from 'components/customButton/customBtn';
import { BsFillCalendarPlusFill } from 'react-icons/bs';
import toast from 'react-hot-toast';
import { ICustomer } from '../customer.types';

const CustomerAccountManagment: React.FC<{
    status: number;
    id: string;
    productsLimit: number;
    invoiceTiming: string;
    setRows: React.Dispatch<React.SetStateAction<ICustomer[]>>;
    currentPage?: number;
    currentFilter?: Record<string, any>;
}> = ({ status, id, invoiceTiming, productsLimit, setRows, currentPage }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceTimingLoader, setInvoiceTimingLoader] = useState(false);
    const [addProdLimitLoader, setAddProdLimitLoader] = useState(false);

    const [limit, setLimit] = useState<number>(productsLimit);

    const desactivateAccount = (value: any) => {
        setIsLoading(true);
        updateCustomer(id, value)
            .then(() => {
                setRows(
                    (prevRows) =>
                        prevRows.map((row) =>
                            // eslint-disable-next-line no-underscore-dangle
                            row._id === id ? { ...row, status: value.status } : row,
                        ) as ICustomer[],
                );

                if (value.status === 3) {
                    toast.success('Account Activated');
                } else if (value.status === 1) {
                    toast.success('Customer Approved');
                } else {
                    toast.success('Account Deactivated');
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            })
            .finally(() => {
                setIsLoading(false);
                getCustomers({ page: currentPage }).then((res) => {
                    const updatedDocs = res.data.docs.map(
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        (customer: { accountManager: any[]; accountManagerNames: string }) => {
                            const acctManagers = customer.accountManager || [];
                            const managerNames = acctManagers
                                .map((manager: { firstName: string; lastName?: string }) => {
                                    const lastNameInitial = manager.lastName ? manager.lastName.charAt(0) : '';
                                    return `${manager.firstName}${lastNameInitial}`;
                                })
                                .join(', ');
                            return { ...customer, accountManagerNames: managerNames };
                        },
                    );
                    setRows(updatedDocs);
                });
            });
    };
    const updateProductLimit = (value: any) => {
        setAddProdLimitLoader(true);
        updateCustomer(id, value)
            .then(() => {
                getCustomers({}).then((res) => setRows(res.data.docs));
            })
            .then(() => {
                toast.success('Product Limit updated');
                setAddProdLimitLoader(false);
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setAddProdLimitLoader(false);
            });
    };

    const updateCustomerIvoiceTiming = (values: any) => {
        setInvoiceTimingLoader(true);
        updateCustomer(id, values)
            .then((res) => {
                toast.success(res?.data?.message);
                setInvoiceTimingLoader(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
                setInvoiceTimingLoader(false);
            });
    };
    return (
        <>
            <div className="flex items-center gap-3 p-2 justify-center">
                <Formik
                    initialValues={{
                        invoiceTiming,
                    }}
                    onSubmit={(values) => {
                        const formData = new FormData();
                        formData.append('invoiceGenTiming', String(values.invoiceTiming));
                        updateCustomerIvoiceTiming(formData);
                    }}
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit} placeholder="">
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="username4"
                                    className="dark:text-white"
                                    value="Invoice Generation Timing"
                                />
                            </div>
                            <div className="flex gap-1 items-center">
                                <div id="selectinvoiceTiming" className="w-62">
                                    <Select
                                        id="invoiceTiming"
                                        required
                                        {...formik.getFieldProps('invoiceTiming')}
                                        name="invoiceTiming"
                                        // value={invoiceGenTiming}
                                    >
                                        <option value="">Choose</option>
                                        <option value="Daily">Daily</option>
                                        <option value="TwiceAWeek">Twice a week(Monday and Thursday)</option>
                                        <option value="Weekly">Weekly</option>
                                    </Select>
                                </div>
                                <Button
                                    type="submit"
                                    color="info"
                                    pill
                                    isProcessing={invoiceTimingLoader}
                                    disabled={invoiceTimingLoader}
                                >
                                    <BsFillCalendarPlusFill className="mr-2 h-5 w-5" />
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="flex items-center gap-3 p-2 justify-center">
                <Formik
                    initialValues={{
                        productsLimit,
                    }}
                    onSubmit={() => {
                        const formData = new FormData();
                        formData.append('productsLimit', String(limit));
                        updateProductLimit(formData);
                    }}
                    validationSchema={changeLimitSchema}
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit} placeholder="">
                            <div className="flex gap-1 items-center">
                                <div className=" w-62">
                                    <CounterCustom count={limit} setCount={setLimit} />
                                    <CustomError name="productsLimit" />
                                </div>
                                <div>
                                    <CustomBtn
                                        variant="primary"
                                        type="submit"
                                        size="md"
                                        isProcessing={addProdLimitLoader}
                                        disabled={addProdLimitLoader}
                                    >
                                        Add product Limit
                                    </CustomBtn>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="flex items-center gap-3 p-2 justify-between">
                <div>
                    <CustomBtn
                        variant={status === 3 ? 'success' : 'failure'}
                        disabled={status === 1 || isLoading}
                        onClick={() => {
                            let newVal = status;
                            if (status === 2) {
                                newVal = 3;
                            } else {
                                newVal = 2;
                            }
                            desactivateAccount({ status: newVal });
                        }}
                        isProcessing={isLoading}
                    >
                        {status === 3 ? 'Activate account' : 'Disable Account'}
                    </CustomBtn>
                </div>
                <div>
                    <Formik
                        initialValues={{
                            status: 2,
                        }}
                        onSubmit={(value) => {
                            desactivateAccount(value);
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} placeholder="">
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={status === 2 || status === 3 || isLoading}
                                    isProcessing={isLoading}
                                >
                                    <HiCheckCircle className="mr-2 h-5 w-5" />
                                    {status === 1 ? 'Approve this Seller' : 'Seller Approved'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default CustomerAccountManagment;
