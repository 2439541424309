import FormError from 'components/customError/formError';
import { Label, TextInput } from 'flowbite-react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    addResellerProduct,
    confirmResellerProduct,
    getProduct,
    newDraftResellerProduct,
    updateDraftedResellerProduct,
} from 'services/admin/resellerProduct';
import { refillResellerStock } from 'services/admin/resellerStock';
import { getCurrentUser } from 'utils/helpers/auth';
import { addResellerGeneralInfoSchema, draftResellerSchema } from 'utils/validations/addResellerProduct';
import ConfigurationForm from './helpers/configurationForm';
import QuotationForm from './helpers/quotationForm';
import { FORM_SUBMIT, IAddResellerProduct } from './helpers/types';
import { intialResellerProduct } from './productsElements';

const AddResellerProduct: React.FC = () => {
    const formRef = useRef<FormikProps<IAddResellerProduct> | null>(null);
    const location = useLocation();
    const state = location?.state as { productId: string; isRefill: boolean };
    const navigate = useNavigate();
    const productId = state?.productId;
    const isRefill = state?.isRefill;
    const [buttonAction, setButtonAction] = useState<FORM_SUBMIT>('CONFIRM');
    const [formLoader, setFormLoader] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const initBeforeUnLoad = (showExitPrompt: boolean) => {
        // eslint-disable-next-line consistent-return
        window.onbeforeunload = () => {
            // Show prompt based on state
            // event.preventDefault();

            if (showExitPrompt) {
                if (formRef?.current?.values && Object.keys(formRef?.current?.values).length > 0) {
                    localStorage.setItem('resellerProductForm', JSON.stringify({ ...formRef?.current?.values }));
                }
            }
        };
    };

    const [intialFormAdd, setIntialFormAdd] = useState<IAddResellerProduct>(intialResellerProduct);

    useEffect(() => {
        initBeforeUnLoad(true);
        if (productId) {
            const dataToast = toast.loading('getting reseller product...');
            getProduct(productId)
                .then((res) => {
                    const {
                        name,
                        adCopies,
                        chineseName,
                        variant,
                        quantity,
                        weight,
                        width,
                        height,
                        length,
                        declarationAmount,
                        quotation,
                        openForSale,
                        canBeOrdered,
                        notificationType,
                        categories,
                        landingPages,
                        description,
                    } = res.data;
                    const resellerQuotation = (quotation || []).map((el) => {
                        return {
                            ...el,
                            trackingCode: '',
                        };
                    });
                    const data: IAddResellerProduct = {
                        name,
                        variant: variant || '',
                        adCopies: adCopies || [''],
                        chineseName,
                        quantity,
                        weight,
                        width,
                        height,
                        length,
                        declarationAmount,
                        openForSale,
                        canBeOrdered,
                        notificationType: notificationType || [],
                        categories,
                        landingPages: landingPages || [],
                        description: description || '',
                        resellerQuotation,
                    };
                    formRef.current?.setValues(data);
                    setIntialFormAdd(data);
                    toast.success('getting Reseller Product ', { id: dataToast });
                })
                .catch((err) => {
                    toast.error(`Sorry get reseller product failed! Error: ${err?.response?.data?.errors?.message}`, {
                        id: dataToast,
                    });
                });
        } else {
            const storedData = localStorage.getItem('resellerProductForm');
            if (storedData) {
                const savedForm: IAddResellerProduct = JSON.parse(storedData);
                if (Object.keys(savedForm).length > 0) {
                    setIntialFormAdd({ ...savedForm });
                }
            }
        }
    }, []);

    const handleFormikChanges = () => {
        setError(false);
        if (!productId) {
            localStorage.setItem('resellerProductForm', JSON.stringify({ ...formRef?.current?.values }));
        }
    };

    const HandleSubmit = async (values: IAddResellerProduct, onSubmitProps: FormikHelpers<IAddResellerProduct>) => {
        values.resellerQuotation.map((quot) => {
            const newval = quot;
            delete newval.editable;
            if (isRefill) {
                delete newval.recommendedPrice;
                delete newval.sellingPrice;
                delete newval.quantityLimit;
            }
            return newval;
        });
        const { resellerQuotation: quotation, ...vals } = values;
        const newProductData = {
            ...vals,
            quotation,
        };
        onSubmitProps.setSubmitting(false);
        const dataToast = toast.loading('Saving reseller product...');
        let navigation = 'admin';
        const user = getCurrentUser();

        if (user.role === 'accountManager') {
            navigation = 'accountManager';
        }
        if (user.role === 'productProvider') {
            navigation = 'productProvider';
        }
        switch (true) {
            case isRefill:
                refillResellerStock({ productId, ...newProductData })
                    .then(() => {
                        navigate(`/${user.role}/resellerProducts-content-details`, {
                            state: {
                                productId,
                                productName: newProductData?.name,
                            },
                        });
                        toast.success('Reseller Product refilled', { id: dataToast });
                    })
                    .catch((err) => {
                        toast.error(
                            `Sorry refill reseller product failed! Error: ${err?.response?.data?.errors?.message}`,
                            {
                                id: dataToast,
                            },
                        );
                    })
                    .finally(() => {
                        setFormLoader(false);
                    });
                break;
            // add new reseller product as purchased
            case !productId && buttonAction === 'CONFIRM':
                addResellerProduct(newProductData)
                    .then(() => {
                        localStorage.removeItem('resellerProductForm');
                        navigate(`/${navigation}/resellerProducts`);
                        toast.success('Reseller Product Added', { id: dataToast });
                    })
                    .catch((err) => {
                        toast.error(
                            `Sorry add reseller product failed! Error: ${err?.response?.data?.errors?.message}`,
                            {
                                id: dataToast,
                            },
                        );
                    })
                    .finally(() => {
                        setFormLoader(false);
                    });
                break;
            // add new reseller product as Draft
            case !productId && buttonAction === 'DRAFT':
                newDraftResellerProduct(newProductData)
                    .then(() => {
                        localStorage.removeItem('resellerProductForm');
                        navigate(`/${navigation}/resellerProducts`);
                        toast.success('Reseller Product Added', { id: dataToast });
                    })
                    .catch((err) => {
                        toast.error(
                            `Sorry add reseller product failed! Error: ${err?.response?.data?.errors?.message}`,
                            {
                                id: dataToast,
                            },
                        );
                    })
                    .finally(() => {
                        setFormLoader(false);
                    });
                break;

            // update reseller product (Draft) and put it as purchased
            case buttonAction === 'CONFIRM':
                confirmResellerProduct(newProductData, productId)
                    .then(() => {
                        localStorage.removeItem('resellerProductForm');
                        navigate(`/${navigation}/resellerProducts`);
                        toast.success('Reseller Product Added', { id: dataToast });
                    })
                    .catch((err) => {
                        toast.error(
                            `Sorry add reseller product failed! Error: ${err?.response?.data?.errors?.message}`,
                            {
                                id: dataToast,
                            },
                        );
                    })
                    .finally(() => {
                        setFormLoader(false);
                    });
                break;
            // update reseller product (Draft)  information
            case buttonAction === 'DRAFT':
                updateDraftedResellerProduct(newProductData, productId)
                    .then(() => {
                        localStorage.removeItem('resellerProductForm');
                        navigate(`/${navigation}/resellerProducts`);
                        toast.success('Reseller Product Added', { id: dataToast });
                    })
                    .catch((err) => {
                        toast.error(
                            `Sorry add reseller product failed! Error: ${err?.response?.data?.errors?.message}`,
                            {
                                id: dataToast,
                            },
                        );
                    })
                    .finally(() => {
                        setFormLoader(false);
                    });
                break;

            default:
                break;
        }
    };
    const handleReset = (formik: FormikProps<IAddResellerProduct>) => {
        formik.setTouched({});
        if (productId) {
            formik.setValues(intialFormAdd);
            return;
        }
        formik.setValues(intialResellerProduct);
        intialResellerProduct.resellerQuotation.forEach((_, index) => {
            formik.setFieldValue(`resellerQuotation.${index}.quantity`, '');
            formik.setFieldValue(`resellerQuotation.${index}.unitPurchasePrice`, '');
            formik.setFieldValue(`resellerQuotation.${index}.totalShippingFee`, '');
        });
        formik.setFieldValue(`declarationAmount`, '');
        formik.setFieldValue(`length`, '');
        formik.setFieldValue(`width`, '');
        formik.setFieldValue(`height`, '');
        formik.setFieldValue(`weight`, '');
    };

    return (
        <div className="bg-white h-full dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden w-full p-4">
            <div className="flex mb-5">
                <span className="font-medium text-2xl">Product sourcing</span>
            </div>
            <Formik
                initialValues={intialFormAdd}
                onSubmit={(values, onSubmitProps) => {
                    setFormLoader(true);
                    HandleSubmit(values, onSubmitProps);
                }}
                enableReinitialize
                innerRef={formRef}
                validationSchema={buttonAction === 'CONFIRM' ? addResellerGeneralInfoSchema : draftResellerSchema}
            >
                {(formik) => {
                    if (formik.isSubmitting && Object.keys(formik.errors).length) {
                        setError(true);
                    }
                    return (
                        <Form onSubmit={formik.handleSubmit} placeholder="" onBlur={handleFormikChanges}>
                            <div className="border border-neutral-80 p-4 rounded-2xl gap-4 m-4">
                                <div className=" leading-7 text-lg font-medium h-8 mb-4">General Informations</div>
                                <div className="mb-4">
                                    <div className="mb-2 mt-1 block flex">
                                        <Label
                                            htmlFor="productName"
                                            value="Product Name"
                                            className="block text-sm font-medium text-gray-900 dark:text-white"
                                        />
                                        <span className="text-red-600 "> *</span>

                                        <span>
                                            <FormError name="name" component="div" />
                                        </span>
                                    </div>
                                    <TextInput
                                        id="productName"
                                        type="text"
                                        {...formik.getFieldProps('name')}
                                        placeholder="Insert the name of the product"
                                        name="name"
                                        disabled={!!productId}
                                    />
                                </div>
                                <div className="mb-4">
                                    <div className="mb-2 mt-1 block flex">
                                        <Label
                                            htmlFor="Assign a variant"
                                            value="Assign a variant"
                                            className="block text-sm font-medium text-gray-900 dark:text-white"
                                        />

                                        <span>
                                            <FormError name="variant" component="div" />
                                        </span>
                                    </div>
                                    <TextInput
                                        id="variant"
                                        type="text"
                                        {...formik.getFieldProps('variant')}
                                        placeholder="Assign a characteristic"
                                        name="variant"
                                        disabled={!!isRefill}
                                    />
                                </div>
                            </div>

                            <QuotationForm formik={formik} edit isRefill={isRefill} error={error} />
                            <ConfigurationForm formik={formik} edit isRefill={isRefill} error={error} />

                            <div className="m-4 mt-6 flex justify-between ">
                                <div className=" py-2 px-2 items-center ">
                                    {(!productId || isRefill) && (
                                        <button
                                            type="button"
                                            onClick={() => handleReset(formik)}
                                            className="text-gray-400 border hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-[#F7BE38]/50 font-medium rounded-lg  text-sm px-5 py-2.5 text-center me-2 mb-2 dark:text-gray-300 dark:border-gray-300 dark:hover:bg-gray-600 dark:focus:ring-[#F7BE38]/50"
                                        >
                                            Reset
                                        </button>
                                    )}
                                </div>
                                <div className="flex gap-2 py-2 px-2 items-center  ">
                                    {!isRefill && (
                                        <button
                                            type="submit"
                                            disabled={formik.isSubmitting}
                                            onClick={() => {
                                                setButtonAction('DRAFT');
                                            }}
                                            className="text-gray-900 border hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-[#F7BE38]/50 font-medium rounded-lg  text-sm px-5 py-2.5 text-center me-2 mb-2 dark:text-gray-300 dark:border-gray-300 dark:hover:bg-gray-600 dark:focus:ring-[#F7BE38]/50"
                                        >
                                            {formLoader && buttonAction === 'DRAFT' && (
                                                <svg
                                                    aria-hidden="true"
                                                    role="status"
                                                    className="inline w-5 h-5 me-1 text-white animate-spin"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="#5e6070"
                                                    />
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            )}
                                            <span>Save as a Draft</span>
                                        </button>
                                    )}
                                    <button
                                        type="submit"
                                        onClick={() => {
                                            setButtonAction('CONFIRM');
                                        }}
                                        disabled={formik.isSubmitting}
                                        className="text-white bg-main hover:bg-main-hover focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-main-dark dark:hover:bg-main dark:focus:ring-blue-800"
                                    >
                                        {formLoader && buttonAction === 'CONFIRM' && (
                                            <svg
                                                aria-hidden="true"
                                                role="status"
                                                className="inline w-5 h-5 me-1 text-white animate-spin"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="#5e6070"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        )}
                                        <span>{isRefill ? 'Refill' : 'Purchase'}</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
export default AddResellerProduct;
