/* eslint-disable no-underscore-dangle */
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import { Alert, Badge, Button, Dropdown, Label, Modal, Textarea } from 'flowbite-react';
import React from 'react';
import toast from 'react-hot-toast';
import { FaHistory } from 'react-icons/fa';
import { HiChat, HiDotsVertical, HiInformationCircle, HiPencil } from 'react-icons/hi';
import { IoCloseSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { getHistory, getOrders, markAsOriginalPackageOrder, updateOrder } from 'services/admin/order';
import { getOrderTraces } from 'services/admin/orderTrace';
import { IProduct } from 'utils/Interfaces/products.interface';
import { getCurrentUser } from 'utils/helpers/auth';
import { cities } from 'utils/vnlinCities';
import OrderDetails from './orderDetailsAdmin';
import { statusDisplay } from './orderElements';
import StatusTimeline from './orderStatusTimeline';
import { IOrderActions, IStatusTimeline } from './orders.types';

const OrderActions = (props: IOrderActions) => {
    const [history, setHistory] = React.useState<IStatusTimeline>({ history: [], deliveryAttempts: [] });
    // const [selectedOrder, setSelectedOrder] = React.useState<IOrder | null>(null);
    const [open, setOpen] = React.useState(false);
    const [openDetails, setOpenDetails] = React.useState(false);
    const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false);
    const [feedbackErrorText, setFeedbackErrorText] = React.useState<null | string>(null);
    const [feedbackText, setFeedbackText] = React.useState<undefined | string>(undefined);
    const navigate = useNavigate();
    const { orderData, currentPage, setRows, currentFilter } = props;

    const updateOrderStatus = (status: Number, feedback?: string, updateType?: number) => {
        const feedbackToast = toast.loading('Updating order status...');
        if (status === 4) {
            const city = cities[orderData.country as keyof typeof cities].find((el) => el === orderData.city);
            if (city) {
                updateOrder({ id: orderData._id, status })
                    .then(() => {
                        toast.success('Status Updated Successfuly', { id: feedbackToast });
                        getOrders({ page: currentPage, ...(currentFilter || {}) }).then((res) => {
                            if (res.data.docs.length === 0) {
                                // if the current page does not have items to show, paginate to the 1st pag
                                getOrders({ page: 1, ...(currentFilter || {}) })
                                    .then((prevPageData) => {
                                        setRows(prevPageData.data.docs);
                                    })
                                    .catch(() => {
                                        toast.error(`Something went wrong, please try again`, { id: feedbackToast });
                                    });
                            } else {
                                setRows(res.data.docs);
                            }
                        });
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.errors?.message);
                    });
            } else {
                toast.error(`Please verify and update City field before this action`);
            }
        } else {
            const updateData = {
                status,
            };
            if (feedback) {
                Object.assign(updateData, { callCenterFeedback: [...(orderData.callCenterFeedback || []), feedback] });
            }
            if (updateType) {
                Object.assign(updateData, { updateType });
            }
            updateOrder({ id: orderData._id, ...updateData })
                .then(() => {
                    toast.success('Status Updated Successfuly', { id: feedbackToast });
                    if (feedback && feedbackText) {
                        setFeedbackText(undefined);
                    }
                    getOrders({ page: currentPage, ...(currentFilter || {}) }).then((res) => {
                        if (res.data.docs.length === 0) {
                            // if the current page does not have items to show, paginate to the 1st pag
                            getOrders({ page: 1, ...(currentFilter || {}) })
                                .then((prevPageData) => {
                                    setRows(prevPageData.data.docs);
                                })
                                .catch(() => {
                                    toast.error(`Something went wrong, please try again`, { id: feedbackToast });
                                });
                        } else {
                            setRows(res.data.docs);
                        }
                    });
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.errors?.message);
                });
        }
    };

    const markOriginalPackageOrder = (id: string) => {
        markAsOriginalPackageOrder({ id })
            .then(() => {
                toast.success('Order Updated Successfuly');

                getOrders({ page: currentPage, ...(currentFilter || {}) }).then((res) => {
                    if (res.data.docs.length === 0) {
                        // if the current page does not have items to show, paginate to the 1st pag
                        getOrders({ page: 1, ...(currentFilter || {}) })
                            .then((prevPageData) => {
                                setRows(prevPageData.data.docs);
                            })
                            .catch(() => {
                                toast.error(`Something went wrong, please try again`);
                            });
                    } else {
                        setRows(res.data.docs);
                    }
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message);
            });
    };
    // const navigateEditOrder = () => {
    //     const user = getCurrentUser();
    //     let navigation = 'admin';
    //     if (user.role === 'accountManager') {
    //         navigation = 'accountManager';
    //     }
    //     navigate(`/${navigation}/order-details`, {
    //         state: {
    //             data: orderData,
    //             tabId: 0,
    //             orderId: orderData._id,
    //             customerId: orderData.customer,
    //         },
    //     });
    // };
    const navigateChatOrder = () => {
        const user = getCurrentUser();
        let navigation = 'admin';
        if (user.role === 'accountManager') {
            navigation = 'accountManager';
        }
        navigate(`/${navigation}/chat`, {
            state: {
                data: orderData,
                orderId: orderData._id,
                customerId: orderData.customer,
            },
        });
    };
    const editOrder = () => {
        setOpenDetails(true);
    };
    const showHistory = () => {
        getHistory(orderData._id).then((data) => {
            getOrderTraces({ order: orderData._id, logistioStatus: 10 }).then((dataTraces) => {
                setHistory({ history: data?.data, deliveryAttempts: dataTraces?.data.docs });
                setOpen(true);
            });
        });
    };

    const navigationList = [
        {
            label: 'Edit',
            action: editOrder,
            iconAcion: HiPencil,
        },
        {
            label: 'Chat',
            action: navigateChatOrder,
            iconAcion: HiChat,
        },
        {
            label: 'History',
            action: showHistory,
            iconAcion: FaHistory,
        },
    ];
    const callCenterOptions = [
        {
            label: 'New',
            action: () => {
                if (orderData.status === 1) {
                    toast.error("Order status is already 'New'");
                } else {
                    updateOrderStatus(1);
                }
            },
            iconAcion: HiPencil,
            status: 1,
        },
        {
            label: 'Canceled',
            // action: () => updateOrderStatus(2),
            action: () => {
                if (orderData.status === 2) {
                    toast.error("Order status is already 'Canceled'");
                } else {
                    setOpenFeedbackModal(true);
                }
            },
            iconAcion: HiPencil,
            status: 2,
        },
        {
            label: 'Unreachable',
            action: () => {
                if (orderData.status === 3) {
                    toast.error("Order status is already 'Unreachable'");
                } else {
                    updateOrderStatus(3);
                }
            },
            iconAcion: HiPencil,
            status: 3,
        },
        {
            label: 'Confirmed',
            action: () => {
                if (orderData.status === 4) {
                    toast.error("Order status is already 'Confirmed'");
                } else {
                    updateOrderStatus(4);
                }
            },
            iconAcion: HiPencil,
            status: 4,
        },
        {
            label: 'Invalid',
            action: () => {
                if (orderData.status === 14) {
                    toast.error("Order status is already 'Invalid'");
                } else {
                    updateOrderStatus(14);
                }
            },
            iconAcion: HiPencil,
            status: 14,
        },
    ];
    const dropshippingOptions = [
        // Status for dropshipping only
        {
            label: 'Waiting for shipment',
            action: () => {
                if (orderData.status === 5) {
                    toast.error("Order status is already 'Waiting for shipment'");
                } else {
                    updateOrderStatus(5);
                }
            },
            iconAcion: HiPencil,
            status: 5,
        },
        {
            label: 'Out for shipment',
            action: () => {
                if (orderData.status === 6) {
                    toast.error("Order status is already 'Out for shipment'");
                } else {
                    updateOrderStatus(6);
                }
            },
            iconAcion: HiPencil,
            status: 6,
        },
        {
            label: 'Shipped',
            action: () => {
                if (orderData.status === 7) {
                    toast.error("Order status is already 'Shipped'");
                } else {
                    updateOrderStatus(7);
                }
            },
            iconAcion: HiPencil,
            status: 7,
        },
    ];
    const optionsList = [
        {
            label: 'Pick and pack',
            action: () => {
                if (orderData.status === 8) {
                    toast.error("Order status is already 'pick and pack'");
                } else {
                    updateOrderStatus(8);
                }
            },
            iconAcion: HiPencil,
            status: 8,
        },
        {
            label: 'Order received by carrier',
            action: () => {
                if (orderData.status === 81) {
                    toast.error("Order status is already 'Order received by carrier'");
                } else {
                    updateOrderStatus(81);
                }
            },
            iconAcion: HiPencil,
            status: 81,
        },
        {
            label: 'Arrive to headquarters warehouse',
            action: () => {
                if (orderData.status === 82) {
                    toast.error("Order status is already 'Arrive to headquarters warehouse'");
                } else {
                    updateOrderStatus(82);
                }
            },
            iconAcion: HiPencil,
            status: 82,
        },
        {
            label: 'Order transferred',
            action: () => {
                if (orderData.status === 83) {
                    toast.error("Order status is already 'Order transferred'");
                } else {
                    updateOrderStatus(83);
                }
            },
            iconAcion: HiPencil,
            status: 83,
        },
        {
            label: 'Arrival at Hub',
            action: () => {
                if (orderData.status === 84) {
                    toast.error("Order status is already 'Arrival at Hub'");
                } else {
                    updateOrderStatus(84);
                }
            },
            iconAcion: HiPencil,
            status: 84,
        },
        {
            label: 'Out for delivery',
            action: () => {
                if (orderData.status === 9) {
                    toast.error("Order status is already 'Out for delivery'");
                } else {
                    updateOrderStatus(9);
                }
            },
            iconAcion: HiPencil,
            status: 9,
        },
        {
            label: 'Delivery attempted',
            action: () => {
                if (orderData.status === 10) {
                    toast.error("Order status is already 'Delivery attempted'");
                } else {
                    updateOrderStatus(10);
                }
            },
            iconAcion: HiPencil,
            status: 10,
        },
        {
            label: 'Order returned to Hub',
            action: () => {
                if (orderData.status === 101) {
                    toast.error("Order status is already 'Order returned to Hub'");
                } else {
                    updateOrderStatus(101);
                }
            },
            iconAcion: HiPencil,
            status: 101,
        },
        {
            label: 'Order returned to Warehouse',
            action: () => {
                if (orderData.status === 102) {
                    toast.error("Order status is already 'Order returned to Warehouse'");
                } else {
                    updateOrderStatus(102);
                }
            },
            iconAcion: HiPencil,
            status: 102,
        },
        {
            label: 'New delivery request',
            action: () => {
                if (orderData.status === 103) {
                    toast.error("Order status is already 'New delivery request'");
                } else {
                    updateOrderStatus(103);
                }
            },
            iconAcion: HiPencil,
            status: 103,
        },
        {
            label: 'Wait to WMS',
            action: () => {
                if (orderData.status === 104) {
                    toast.error("Order status is already 'Wait to WMS'");
                } else {
                    updateOrderStatus(104);
                }
            },
            iconAcion: HiPencil,
            status: 104,
        },
        {
            label: 'Delivery failed',
            action: () => {
                if (orderData.status === 11) {
                    toast.error("Order status is already 'Delivery failed'");
                } else {
                    updateOrderStatus(11);
                }
            },
            iconAcion: HiPencil,
            status: 11,
        },
        {
            label: 'Return to origin - Out',
            action: () => {
                if (orderData.status === 111) {
                    toast.error("Order status is already 'Return to origin - Out'");
                } else {
                    updateOrderStatus(111);
                }
            },
            iconAcion: HiPencil,
            status: 111,
        },
        {
            label: 'Return to origin - In',
            action: () => {
                if (orderData.status === 112) {
                    toast.error("Order status is already 'Return to origin - In'");
                } else {
                    updateOrderStatus(112);
                }
            },
            iconAcion: HiPencil,
            status: 112,
        },
        {
            label: 'Transport management system to Warehouse',
            action: () => {
                if (orderData.status === 113) {
                    toast.error("Order status is already 'Transport management system to Warehouse'");
                } else {
                    updateOrderStatus(113);
                }
            },
            iconAcion: HiPencil,
            status: 113,
        },
        {
            label: 'Back to stock',
            action: () => {
                if (orderData.status === 114) {
                    toast.error("Order status is already 'Back to stock'");
                } else {
                    updateOrderStatus(114);
                }
            },
            iconAcion: HiPencil,
            status: 114,
        },
        {
            label: 'Delivered',
            action: () => {
                if (orderData.status === 12) {
                    toast.error("Order status is already 'Delivered'");
                } else {
                    updateOrderStatus(12);
                }
            },
            iconAcion: HiPencil,
            status: 12,
        },
        {
            label: 'Original packages order inventory',
            action: () => {
                if (orderData.returnUsed === undefined && orderData.status === 114) {
                    markOriginalPackageOrder(orderData._id);
                } else {
                    toast.error(
                        'To make this action, order should be with the status Back to stock or not marked already',
                    );
                }
            },
            iconAcion: HiPencil,
            status: 114,
        },
    ];
    // prepaid orders statuses start from 8
    const updateStatusOptions: typeof optionsList = [];
    if (
        ((orderData.items[0]?.product as IProduct)?.isReseller ||
            (orderData.items[0]?.product as IProduct)?.type === 3) &&
        orderData?.withCC
    ) {
        updateStatusOptions.push(...callCenterOptions);
    }
    if ((orderData.items[0]?.product as IProduct)?.type === 1) {
        updateStatusOptions.push(...dropshippingOptions);
    }
    updateStatusOptions.push(...optionsList);

    const submitCancelOrder = () => {
        if (!feedbackText) {
            setFeedbackErrorText('You need to specify a feedback to be able to cancel this order.');
            return;
        }
        const dataUpdate = {
            status: 2, // cancel
            callCenterFeedback: feedbackText,
            updateType: 1,
        };
        updateOrderStatus(dataUpdate.status, dataUpdate.callCenterFeedback, dataUpdate.updateType);
        setOpenFeedbackModal(false);
    };
    return (
        <>
            {/* <Modal dismissible show={openDetails} size="6xl" popup onClose={() => setOpenDetails(false)}>
                <Modal.Header>Order Configuration </Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    <OrderDetails
                        data={orderData}
                        currentPage={currentPage}
                        setRows={setRows}
                        currentFilter={currentFilter}
                    />
                </Modal.Body>
            </Modal> */}
            <GeneralDrawer
                anchor="right"
                open={openDetails}
                onClose={setOpenDetails}
                PaperProps={{
                    sx: { width: '75%' },
                }}
            >
                <div className="p-2">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-lg font-medium">Order Configuration</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpenDetails(false)}
                            className="p-1 hover:dark:bg-gray-700 hover:bg-gray-100 rounded-md"
                        >
                            <IoCloseSharp className="w-5 h-5" />
                        </button>
                    </div>
                    {orderData && (
                        <OrderDetails
                            data={orderData}
                            currentPage={currentPage}
                            setRows={setRows}
                            currentFilter={currentFilter}
                            setOpenDetails={setOpenDetails}
                        />
                    )}
                </div>
            </GeneralDrawer>
            <Modal dismissible show={open} popup onClose={() => setOpen(false)}>
                <Modal.Header>Order Status History</Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    {orderData && <StatusTimeline setOpen={setOpen} history={history} order={orderData} />}
                </Modal.Body>
            </Modal>
            <Modal dismissible show={openFeedbackModal} popup onClose={() => setOpenFeedbackModal(false)}>
                <Modal.Header>Cancel order</Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    <div className="p-4">
                        <p className="flex items-center gap-1">
                            Order ref:{' '}
                            <span className="w-fit">
                                <Badge>{orderData.orderRef || '--'}</Badge>
                            </span>
                        </p>
                        <p className="flex items-center gap-1">
                            Current Status:{' '}
                            <span className="w-fit">{statusDisplay[orderData.status]?.statusIcon || '--'}</span>
                        </p>
                        <p>
                            Country: <b>{orderData.country || '--'}</b>
                        </p>
                        <p>
                            Customer: <b>{orderData.fullName || '--'}</b>
                        </p>
                    </div>
                    <div id="textarea" className="p-4">
                        <div className="mb-2 block">
                            <Label htmlFor="feedback" value="Feedback" />
                        </div>
                        <Textarea
                            id="feedback"
                            placeholder="Write your feedback..."
                            rows={2}
                            value={feedbackText}
                            onChange={(e) => {
                                setFeedbackText(e.target.value);
                            }}
                        />
                    </div>
                    {feedbackErrorText && (
                        <div className="px-4">
                            <Alert color="failure" icon={HiInformationCircle}>
                                {feedbackErrorText}
                            </Alert>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        onClick={() => {
                            submitCancelOrder();
                        }}
                    >
                        submit
                    </Button>
                    <Button
                        color="gray"
                        type="button"
                        onClick={() => {
                            setFeedbackText(undefined);
                            setOpenFeedbackModal(false);
                        }}
                    >
                        cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Dropdown
                inline
                className=" overflow-auto"
                arrowIcon={false}
                label={<HiDotsVertical />}
                theme={{
                    arrowIcon: 'ml-2 h-4 w-4',
                    content: 'py-1 focus:outline-none',
                    floating: {
                        base: 'z-[99999] h-[200px] absolute w-fit rounded divide-y divide-gray-100 shadow focus:outline-none',
                    },
                }}
            >
                {navigationList.map((navigation) => {
                    return (
                        <Dropdown.Item icon={navigation.iconAcion} onClick={navigation.action} key={navigation.label}>
                            {navigation.label}
                        </Dropdown.Item>
                    );
                })}
                <Dropdown.Divider />
                {orderData.status !== 115 ? (
                    <>
                        {updateStatusOptions.map((action) => (
                            <Dropdown.Item
                                icon={action.iconAcion}
                                disabled={
                                    [100, 99].includes(orderData.status) ||
                                    (orderData.status === 1 && !orderData?.callAgent)
                                        ? false
                                        : action.status === 14
                                }
                                onClick={action.action}
                                key={action.label}
                                style={{
                                    cursor:
                                        action.status === 14 &&
                                        (![100, 1, 99].includes(orderData.status) ||
                                            (orderData.status === 1 && orderData?.callAgent))
                                            ? 'not-allowed'
                                            : 'default',
                                }}
                            >
                                Set to <span className="font-bold px-1">{action.label}</span>
                            </Dropdown.Item>
                        ))}
                    </>
                ) : null}
            </Dropdown>
        </>
    );
};
export default OrderActions;
