/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { AxiosResponse } from 'axios';
import TextError from 'components/customError/customErrorText';
import FormError from 'components/customError/formError';
import { Label, Progress, Textarea, ToggleSwitch } from 'flowbite-react';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { FaPlus } from 'react-icons/fa';
import { IoMdRemoveCircle } from 'react-icons/io';
import { MdAddPhotoAlternate } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProduct, getProducts, updateResellerProduct, uploadMedia } from 'services/admin/resellerProduct';
import { getCurrentUser } from 'utils/helpers/auth';
import { urlValidation } from 'utils/helpers/imageFinder';
import { updateResellerSchema } from 'utils/validations/addResellerProduct';
import Upload from '../../../assets/productDesign.svg';
import { ResellerProduct } from '../reseller.types';
import GeneralInformations from './helpers/generalInformations';
import MediaUploader from './helpers/mediaUploader';
import { IAddResellerProduct, MediaData } from './helpers/types';
import { displayBadge, intialResellerProduct } from './productsElements';

const resellerProductsContentDetails: React.FC = () => {
    const formRef = useRef<FormikProps<IAddResellerProduct> | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const productId = (location?.state as { productId: string })?.productId;
    const [allVideos, setAllVideos] = useState<MediaData[]>([]);
    const [progressCount, setProgressCount] = useState(0);
    const [allImages, setAllImages] = useState<MediaData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<boolean>(false);

    const [resellerVideos, setResellerVideos] = useState<string[]>([]);
    const [urlImage, setUrlImage] = useState(Upload);

    const [formLoader, setFormLoader] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<ResellerProduct>();
    const [resellerProducts, setResellerProducts] = useState<ResellerProduct[]>([]);
    const [thumbnail, setThumbnail] = useState<MediaData | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [intialFormAdd, setIntialFormAdd] = useState<IAddResellerProduct>(intialResellerProduct);
    const user = getCurrentUser();
    let navigation = 'admin';
    if (user.role === 'accountManager') {
        navigation = 'accountManager';
    }
    if (user.role === 'productProvider') {
        navigation = 'productProvider';
    }

    useEffect(() => {
        if (productId) {
            const dataToast = toast.loading('getting reseller product...');
            getProduct(productId)
                .then((res) => {
                    const {
                        name,
                        adCopies,
                        chineseName,
                        variant,
                        quantity,
                        weight,
                        width,
                        height,
                        length,
                        declarationAmount,
                        quotation,
                        openForSale,
                        notificationType,
                        categories,
                        landingPages,
                        description,
                        updatedAt,
                        isContentDraft,
                    } = res.data;
                    const data: IAddResellerProduct = {
                        name,
                        variant: variant || '',
                        adCopies: adCopies || [''],
                        chineseName,
                        quantity,
                        weight,
                        width,
                        height,
                        length,
                        declarationAmount,
                        openForSale,
                        canBeOrdered: res.data.canBeOrdered,
                        notificationType: notificationType || [],
                        categories,
                        landingPages: landingPages?.length ? landingPages : [''],
                        description: description || '',
                        resellerQuotation: quotation,
                        updatedAt,
                        isContentDraft,
                    };
                    formRef.current?.setValues(data);
                    toast.success('getting Reseller Product ', { id: dataToast });
                    setIntialFormAdd(data);
                    setAllImages([
                        ...res.data.images.map((url: string) => ({
                            name: url.substring(url.indexOf('.com/') + 5),
                            url,
                        })),
                    ]);
                    setUrlImage(res.data.thumbnail);
                    setResellerVideos([...res.data.videos.map((url: string) => url)]);
                    setAllVideos([
                        ...res.data.videos.map((url: string) => ({
                            name: url.substring(url.indexOf('.com/') + 5),
                            url,
                            type: 'video/',
                            date: new Date(),
                        })),
                    ]);
                })
                .catch((err) => {
                    toast.error(`Sorry get reseller product failed! Error: ${err?.response?.data?.errors?.message}`, {
                        id: dataToast,
                    });
                });
        } else {
            const feedbackToast = toast.loading('Loading data...');
            const queryOptions = { onlyContent: false, status: 1, limit: 200 };
            getProducts({ ...queryOptions })
                .then((result) => {
                    toast.dismiss(feedbackToast);
                    const list = (result?.data?.resellerProducts?.docs || []).sort((a, b) => {
                        return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
                    });
                    setResellerProducts(list || []);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.errors?.message || 'Unable to load data.', {
                        id: feedbackToast,
                    });
                });
            const storedData = localStorage.getItem('resellerProductContentForm');
            if (storedData) {
                const savedForm: IAddResellerProduct & { selectedProduct?: ResellerProduct } = JSON.parse(storedData);
                const selectedProd = savedForm.selectedProduct;
                setSelectedProduct(selectedProd);
                delete savedForm.selectedProduct;
                if (Object.keys(savedForm).length > 0) {
                    setIntialFormAdd({ ...savedForm });
                }
            }
        }
    }, [productId]);

    const handleUpdate = async (values: IAddResellerProduct) => {
        setIsLoading(true);
        const config = {
            onUploadProgress: (progressEvent: any) => {
                const progress = Math.round((progressEvent.loaded * 50) / progressEvent.total);
                setProgressCount(progress);
            },
            onDownloadProgress: (progressEvent: any) => {
                const progress = Math.round(50 + (progressEvent.loaded * 50) / progressEvent.total);
                setProgressCount(progress);
            },
        };
        values.resellerQuotation.map((quot) => {
            const newval = quot;
            delete newval.editable;
            return newval;
        });
        const newProductData = {
            name: values.name,
            chineseName: values.chineseName,
            adCopies: values.adCopies,
            quantity: values.quantity,
            weight: values.weight,
            width: values.width,
            height: values.height,
            length: values.length,
            quotation: values.resellerQuotation,
            declarationAmount: values.declarationAmount,
            notificationType: values.notificationType,
            categories: values.categories,
            landingPages: values.landingPages,
            canBeOrdered: values.canBeOrdered,
            description: values.description,
            ...(selectedProduct ? { isContentDraft: true } : {}),
        };
        let assetsFormData = new FormData();
        const existingVidsUrls: string[] = [];
        const existingImagesUrls: string[] = [];
        const existingFilesUrls: string[] = [];
        allVideos.forEach((vidData) => {
            if (vidData.file) {
                assetsFormData.append(vidData.name, vidData.file);
            } else {
                existingVidsUrls.push(vidData.url);
            }
        });
        allImages.forEach((imageData) => {
            if (imageData.file) {
                assetsFormData.append(imageData.name, imageData.file);
            } else {
                existingImagesUrls.push(imageData.url);
            }
        });

        if (allVideos.length > 0 || allImages.length > 0 || thumbnail?.file) {
            const toastId = toast.loading('Uploading product assets...', { duration: Infinity });
            let uploadRes: AxiosResponse<{
                uploderImages?: string[] | undefined;
                uplodervideos?: string[] | undefined;
                uploaderFiles?: string[] | undefined;
            }>;
            uploadRes = await uploadMedia(assetsFormData, config);
            if (uploadRes.data.uploderImages && uploadRes.data.uploderImages.length > 0) {
                Object.assign(newProductData, { images: [...existingImagesUrls, ...uploadRes.data.uploderImages] });
            }
            if (uploadRes.data.uploaderFiles && uploadRes.data.uploaderFiles.length > 0) {
                Object.assign(newProductData, { files: [...existingFilesUrls, ...uploadRes.data.uploaderFiles] });
            }
            if (uploadRes.data.uplodervideos && uploadRes.data.uplodervideos.length > 0) {
                Object.assign(newProductData, { videos: [...existingVidsUrls, ...uploadRes.data.uplodervideos] });
            }
            if (uploadRes?.data?.uplodervideos) {
                toast.success('Product assets uploaded', { id: toastId });
            } else {
                toast.error('failed to upload', { id: toastId });
            }
            toast.dismiss(toastId);
            if (thumbnail?.file) {
                toast.loading('Uploading product thumbnail...', { id: toastId, duration: Infinity });
                assetsFormData = new FormData();
                assetsFormData.append(thumbnail.name, thumbnail.file);
                uploadRes = await uploadMedia(assetsFormData, config);
                if (uploadRes.data.uploderImages && uploadRes.data.uploderImages.length > 0) {
                    Object.assign(newProductData, { thumbnail: uploadRes.data.uploderImages[0] });
                    toast.success('Product thumbnail uploaded', { id: toastId });
                }
            }
        }
        const dataToast = toast.loading('Saving new reseller product...');
        updateResellerProduct(newProductData, selectedProduct?._id || productId, config)
            .then(() => {
                toast.success('Reseller product updated', { id: dataToast });
                navigate(`/${navigation}/resellerProducts-content`);
                localStorage.removeItem('resellerProductContentForm');
            })
            .catch((err) => {
                toast.error(`Sorry update failed! Error: ${err?.response?.data?.errors?.message}`, { id: dataToast });
            })
            .finally(() => {
                setIsLoading(false);
                setProgressCount(0);
            });
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            formRef.current?.setFieldValue('verificationImage', file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            setUrlImage(URL.createObjectURL(file));
            reader.onload = () => {
                setThumbnail({
                    file,
                    name: file.name,
                    url: URL.createObjectURL(file),
                    base64: reader.result,
                });
            };
        }
    };

    const handleFormikChanges = () => {
        setError(false);
        if (!productId) {
            localStorage.setItem(
                'resellerProductContentForm',
                JSON.stringify({ ...formRef?.current?.values, selectedProduct }),
            );
        }
    };

    return (
        <div className="p-3">
            {' '}
            <Formik
                initialValues={intialFormAdd}
                onSubmit={(values) => {
                    setFormLoader(true);
                    handleUpdate(values);
                }}
                enableReinitialize
                innerRef={formRef}
                validationSchema={updateResellerSchema}
            >
                {(formik) => {
                    if (formik.isSubmitting && Object.keys(formik.errors).length) {
                        setError(true);
                    }
                    return (
                        <Form onSubmit={formik.handleSubmit} placeholder="" onBlur={handleFormikChanges}>
                            <div className="m-4 flex justify-between">
                                <div className="flex gap-5">
                                    <div>
                                        {formik.getFieldProps('name').value && (
                                            <div
                                                className="relative  w-16 h-16"
                                                onClick={() => fileInputRef.current?.click()}
                                            >
                                                {urlValidation(urlImage) ? (
                                                    <img
                                                        className="object-cover rounded-lg overflow-hidden border-2 border-gray-300 border-dashed rounded-lg  w-16 h-16"
                                                        src={urlValidation(urlImage) || Upload}
                                                        alt="thumbnail"
                                                    />
                                                ) : (
                                                    <MdAddPhotoAlternate className="w-16 h-16" />
                                                )}
                                            </div>
                                        )}
                                        <input
                                            id="dropzone-file-thumbnail"
                                            type="file"
                                            accept="image/png, image/jpg, image/jpeg,"
                                            className="hidden"
                                            ref={fileInputRef}
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    <div>
                                        <div className="flex mb-1 font-medium text-2xl pr-2">
                                            {formik.getFieldProps('name').value}
                                        </div>
                                        {formik.getFieldProps('name').value && (
                                            <div className="flex mb-5">
                                                <span className="font-light text-lg text-gray-400">
                                                    Last updated:
                                                    {moment(formik.getFieldProps('updatedAt').value).format(
                                                        'DD/MM/YYYY',
                                                    )}
                                                </span>
                                                <span className="font-medium text-lg ml-2	">
                                                    {!selectedProduct && displayBadge(intialFormAdd)}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex mb-1 items-center font-medium text-2xl pr-2">
                                    <button
                                        onClick={() => navigate(`/${navigation}/resellerProducts-content`)}
                                        disabled={formik.isSubmitting}
                                        type="button"
                                        className="text-gray-900 bg-white border hover:bg-gray-100 focus:outline-none  font-medium rounded-lg  text-sm px-5 py-2.5 text-center me-2 mb-2 dark:text-gray-300 dark:border-gray-300 dark:hover:bg-gray-600 dark:focus:ring-[#F7BE38]/50"
                                    >
                                        <span>Cancel</span>
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={formik.isSubmitting || !formik.getFieldProps('name').value}
                                        className="text-white bg-main hover:bg-main-hover focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-main-dark dark:hover:bg-main dark:focus:ring-blue-800"
                                    >
                                        {formLoader && (
                                            <svg
                                                aria-hidden="true"
                                                role="status"
                                                className="inline w-5 h-5 me-1 text-white animate-spin"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="#5e6070"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        )}
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>

                            <GeneralInformations
                                formik={formik}
                                setSelectedProduct={setSelectedProduct}
                                productId={productId}
                                resellerProducts={resellerProducts}
                                selectedProduct={selectedProduct}
                                error={error}
                            />

                            <div className="m-4 bg-white h-full dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden w-full p-4">
                                <div className="flex items-center gap-2 mb-4">
                                    <ToggleSwitch
                                        {...formik.getFieldProps(`canBeOrdered`)}
                                        checked={formik.getFieldProps(`canBeOrdered`).value}
                                        onChange={(e) => {
                                            formik.setFieldValue('canBeOrdered', e);
                                        }}
                                    />
                                    <div>
                                        <Label className="p-2">
                                            {formik.getFieldProps(`canBeOrdered`).value
                                                ? 'The “Sourcing” button is displayed'
                                                : 'Show the “Sourcing” button '}
                                        </Label>
                                        <div className="px-2">
                                            This enables the seller to request a specific quantity of this product for
                                            their stock.
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="flex mb-5">
                                        <span className="font-medium text-xl">Ad copies</span>
                                    </div>
                                    <FieldArray
                                        name="adCopies"
                                        render={(arrayHelpers) => (
                                            <div>
                                                {formik.values.adCopies.map((_, index) => {
                                                    return (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <div className="relative" key={index}>
                                                            <div className="absolute right-2 p-2">
                                                                {' '}
                                                                <IoMdRemoveCircle
                                                                    className="h-6 w-6 absolute right-2"
                                                                    color="red"
                                                                    role="button"
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                />
                                                            </div>
                                                            <Textarea
                                                                placeholder="..."
                                                                rows={4}
                                                                {...formik.getFieldProps(`adCopies[${index}]`)}
                                                                name={`adCopies[${index}]`}
                                                                helperText={
                                                                    <span>
                                                                        <TextError
                                                                            name={`adCopies[${index}]`}
                                                                            component="div"
                                                                        />
                                                                    </span>
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                                <button
                                                    onClick={() => arrayHelpers.push('')}
                                                    type="button"
                                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                >
                                                    <FaPlus className="w-2 h-2" />
                                                </button>
                                            </div>
                                        )}
                                    />
                                    <span>
                                        <FormError name="adCopies" component="div" />
                                    </span>
                                </div>
                                <div className="mb-5">
                                    <div className="flex mb-5">
                                        <span className="font-medium text-xl">Landing pages</span>
                                    </div>
                                    <FieldArray
                                        name="landingPages"
                                        render={(arrayHelpers) => (
                                            <div>
                                                {formik.values.landingPages.map((lp, index) => {
                                                    return (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <div className="relative pb-2" key={index}>
                                                            <div className="absolute right-2 p-2">
                                                                {' '}
                                                                <IoMdRemoveCircle
                                                                    className="h-6 w-6 absolute right-2"
                                                                    color="red"
                                                                    role="button"
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                />
                                                            </div>
                                                            <Textarea
                                                                placeholder="Enter your text..."
                                                                rows={2}
                                                                {...formik.getFieldProps(`landingPages[${index}]`)}
                                                                name={`landingPages[${index}]`}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                                <button
                                                    onClick={() => arrayHelpers.push('')}
                                                    type="button"
                                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                >
                                                    <FaPlus className="w-2 h-2" />
                                                </button>
                                            </div>
                                        )}
                                    />

                                    <span>
                                        <FormError name="adCopies" component="div" />
                                    </span>
                                </div>
                                {isLoading && <Progress progress={progressCount} size="sm" className="mb-2" />}
                                <div className="border border-neutral-80 p-4 rounded-2xl ">
                                    <div className="flex mb-5">
                                        <span className="font-medium text-xl">
                                            Images and videos <span className="text-red-600 "> *</span>
                                        </span>
                                    </div>

                                    <MediaUploader
                                        allImages={allImages}
                                        allVideos={allVideos}
                                        resellerVideos={resellerVideos}
                                        setAllImages={setAllImages}
                                        setAllVideos={setAllVideos}
                                        setResellerVideos={setResellerVideos}
                                    />
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
export default resellerProductsContentDetails;
