/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Dropdown, Modal } from 'flowbite-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaEye, FaEyeSlash, FaHistory } from 'react-icons/fa';
import { IoEyeOutline, IoOpenOutline } from 'react-icons/io5';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getProducts, getResellerProductHistory } from 'services/admin/resellerProduct';
import { getCurrentUser } from 'utils/helpers/auth';
import { ResellerProduct } from '../reseller.types';
import DeleteResellerProductContentModal from './helpers/deleteResellerProductContentModal';
import OpenVisibilityModal from './helpers/openVisibilityModal';
import { ResellerProductPricingHistory } from './helpers/types';
import StatusTimeline from './helpers/resellerProductStatusTimeline';

export interface IProductActions {
    product: ResellerProduct;
    setRows?: any;
    currentPage?: number;
    currentFilter?: Record<string, any>;
}
const ResellerProductContentActions = ({ product, setRows, currentFilter, currentPage }: IProductActions) => {
    const navigate = useNavigate();

    const [deletedProduct, setDeletedProduct] = useState<ResellerProduct>();
    const [history, setHistory] = useState<ResellerProductPricingHistory[]>([]);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [visible, setVisible] = useState<ResellerProduct | null>(null);

    const user = getCurrentUser();
    let role = 'admin';
    if (user.role === 'accountManager') {
        role = 'accountManager';
    }
    const refetchData = () => {
        const queryOptions = { ...currentFilter, currentPage };
        const feedbackToast = toast.loading('Loading data...');
        getProducts({ ...queryOptions })
            .then((result) => {
                setRows(result?.data);
            })
            .catch((err) => {
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.');
            })
            .finally(() => toast.dismiss(feedbackToast));
    };

    const showHistory = () => {
        const feedbackToast = toast.loading('Loading data...');

        getResellerProductHistory(product._id)
            .then((result) => {
                setHistory(result?.data || []);
                setOpenHistoryModal(true);
            })
            .catch((err) => {
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.');
            })
            .finally(() => toast.dismiss(feedbackToast));
    };

    const navigateEditProduct = () => {
        navigate(`/${role}/resellerProducts-content-details`, {
            state: {
                productId: product._id,
                productName: product.name,
            },
        });
    };

    const navigatetoStock = () => {
        navigate(`/${role}/resellerStocks`, {
            state: {
                sku: product.sku,
            },
        });
    };

    if (!product.name) return <></>;

    return (
        <>
            {' '}
            {deletedProduct && (
                <DeleteResellerProductContentModal
                    productDelete={deletedProduct}
                    refetchData={refetchData}
                    setProductDelete={setDeletedProduct}
                    key="deleteResellerModal"
                />
            )}
            {visible && (
                <OpenVisibilityModal
                    openVisibilityModal={!!visible}
                    productVisibility={visible}
                    refetchData={refetchData}
                    setOpenVisibilityModal={setVisible}
                    key="visibilityModal"
                />
            )}
            <Modal dismissible show={openHistoryModal} popup onClose={() => setOpenHistoryModal(false)}>
                <Modal.Header>Reseller Products History</Modal.Header>
                <Modal.Body className="!p-0 overflow-y-auto h-[34rem]">
                    {history && <StatusTimeline product={product} history={history} />}
                </Modal.Body>
            </Modal>
            <Dropdown
                label=""
                renderTrigger={() => (
                    <button
                        id="apple-iphone-14-dropdown-button"
                        data-dropdown-toggle="apple-iphone-14-dropdown"
                        className="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
                        type="button"
                    >
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                        </svg>
                    </button>
                )}
                dismissOnClick={false}
            >
                <Dropdown.Item icon={IoEyeOutline} onClick={navigateEditProduct}>
                    Product details
                </Dropdown.Item>
                <Dropdown.Item icon={FaHistory} onClick={showHistory}>
                    History
                </Dropdown.Item>

                {product.isContentDraft && (
                    <Dropdown.Item icon={MdDelete} onClick={() => setDeletedProduct(product)}>
                        Delete
                    </Dropdown.Item>
                )}

                {!product.isContentDraft && (
                    <Dropdown.Item icon={IoOpenOutline} onClick={navigatetoStock}>
                        Go to stock
                    </Dropdown.Item>
                )}

                <Dropdown.Item icon={product.openForSale ? FaEyeSlash : FaEye} onClick={() => setVisible(product)}>
                    {!product.openForSale || product.isContentDraft ? 'Publish' : 'Unpublish'}
                </Dropdown.Item>
            </Dropdown>
        </>
    );
};
export default ResellerProductContentActions;
