/* eslint-disable react/style-prop-object */
/* eslint-disable no-underscore-dangle */
import { Dropdown } from 'flowbite-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { HiPencil } from 'react-icons/hi';
import { IoIosBarcode } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import { IoEyeOutline, IoOpenOutline } from 'react-icons/io5';
import { confirmResellerProduct, getProducts } from 'services/admin/resellerProduct';
import { ResellerProduct } from '../reseller.types';
import DeleteResellerProductModal from './helpers/deleteResellerProductModal';
import ProductSKUModal from './helpers/productSKUModal';

export interface IProductActions {
    product: ResellerProduct;
    setRows?: any;
    currentPage?: number;
    currentFilter?: Record<string, any>;
}
const ResellerProductActions = ({ product, setRows, currentFilter, currentPage }: IProductActions) => {
    const navigate = useNavigate();

    const [deletedProduct, setDeletedProduct] = useState<string>('');
    const [openModalSkus, setOpenModalSkus] = React.useState(false);

    const user = getCurrentUser();
    let role = 'admin';
    if (user.role === 'accountManager') {
        role = 'accountManager';
    }
    const refetchData = () => {
        const queryOptions = { ...currentFilter, currentPage };

        getProducts({ ...queryOptions })
            .then((result) => {
                setRows(result?.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message || 'Unable to load data.');
            });
    };
    const navigateEditProduct = (isRefill?: boolean) => {
        const path = product.status === 11 || isRefill ? 'new-reseller-Product' : 'resellerProducts-details';
        navigate(`/${role}/${path}`, {
            state: {
                productId: product._id,
                productName: product.name,
                isRefill,
            },
        });
    };
    const navigatetoStock = () => {
        navigate(`/${role}/resellerStocks`, {
            state: {
                sku: product.sku,
            },
        });
    };
    const navigateToContent = () => {
        navigate(`/${role}/resellerProducts-content-details`, {
            state: {
                productId: product._id,
                productName: product.name,
            },
        });
    };
    const setToPurchase = () => {
        const dataToast = toast.loading('Saving reseller product...');

        confirmResellerProduct(product, product._id)
            .then(() => {
                localStorage.removeItem('resellerProductForm');
                navigate(`/${role}/resellerProducts`);
                toast.success(`Reseller Product ${product.name} / ${product.variant}`, { id: dataToast });
                refetchData();
            })
            .catch((err) => {
                toast.error(`Sorry add reseller product failed! Error: ${err?.response?.data?.errors?.message}`, {
                    id: dataToast,
                });
            });
    };
    // the 2 will be changed to
    const navigationList = [
        {
            label: 'Product details',
            action: navigateEditProduct,
            iconAcion: IoEyeOutline,
            status: [1, 2],
        },
        {
            label: 'Edit Product',
            action: navigateEditProduct,
            iconAcion: HiPencil,
            status: [11],
        },
        {
            label: 'Preview SKU',
            action: () => setOpenModalSkus(true),
            iconAcion: IoIosBarcode,
            status: [1, 2, 11],
        },
        {
            label: 'Product content',
            action: navigateToContent,
            iconAcion: IoOpenOutline,
            status: product?.categories?.length ? [1, 2] : [],
        },
        {
            label: 'Delete',
            action: () => setDeletedProduct(product?._id || ''),
            iconAcion: MdDelete,
            status: [11],
        },
        {
            label: 'Go to stock',
            action: navigatetoStock,
            iconAcion: IoOpenOutline,
            status: [1, 2],
        },
        {
            label: 'Set as purchased',
            action: setToPurchase,
            iconAcion: HiPencil,
            status: [11],
        },
        {
            label: 'Refill',
            action: () => navigateEditProduct(true),
            iconAcion: IoOpenOutline,
            status: [1, 2],
        },
    ];
    return (
        <>
            {deletedProduct && (
                <DeleteResellerProductModal
                    productDelete={deletedProduct}
                    refetchData={refetchData}
                    setProductDelete={setDeletedProduct}
                    key="deleteResellerModal"
                />
            )}

            {openModalSkus && (
                <ProductSKUModal product={product as ResellerProduct} open={openModalSkus} setOpen={setOpenModalSkus} />
            )}

            <Dropdown
                label=""
                renderTrigger={() => (
                    <button
                        id="apple-iphone-14-dropdown-button"
                        data-dropdown-toggle="apple-iphone-14-dropdown"
                        className="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
                        type="button"
                    >
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                        </svg>
                    </button>
                )}
                dismissOnClick={false}
            >
                {navigationList
                    .filter((el) => el.status.includes(product.status))
                    .map((navigation) => {
                        return (
                            <Dropdown.Item
                                icon={navigation.iconAcion}
                                onClick={() => navigation.action()}
                                key={navigation.label}
                            >
                                {navigation.label}
                            </Dropdown.Item>
                        );
                    })}
            </Dropdown>
        </>
    );
};
export default ResellerProductActions;
