/* eslint-disable no-underscore-dangle */
import AutoComplete from 'components/autocomplete/autoComplete';
import { Button, Modal, Tabs, TabsRef, TextInput } from 'flowbite-react';
import { ICustomer } from 'pages/customers/customer.types';
import React, { useEffect, useRef, useState } from 'react';
import { getCustomers } from 'services/admin/customer';
import {
    countInvoicesStats,
    downloadBatchPayment,
    getInvoiceList,
    ICountInvoicesStats,
    updateManyInvoices,
} from 'services/admin/invoice';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { AiOutlineSearch } from 'react-icons/ai';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment-timezone';
import toast from 'react-hot-toast';
import { primaryButton } from 'utils/helpers/themeStyle/buttons';
import { CiExport } from 'react-icons/ci';
import { TfiStatsUp } from 'react-icons/tfi';
import { GoBlocked } from 'react-icons/go';
import { LuPhoneOutgoing } from 'react-icons/lu';
import { FaBox } from 'react-icons/fa6';
import { TableColDef } from 'components/tables/tables.types';
import CustomerBankInformation from 'pages/customers/customerTableActions/customerBankInformation';
import StatusAccount from 'components/status/statusAccount';
import DataTable from '../../../components/tables/tables';
import { Ifilter, Iinvoice, Ipagination } from '../invoice.types';
import { statusDisplay, timingDisplay } from './invoiceListElements';

const invoiceList = () => {
    UseDocumentTitle('Invoices');
    const tabsRef = useRef<TabsRef | null>(null);
    const [markPaidLoader, setMarkPaidLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [invoices, setInvoices] = useState<Array<Iinvoice>>([]);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [invoicesStats, setInvoicesStats] = useState<ICountInvoicesStats | null>(null);
    const [openBankInfo, setOpenBankInfo] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
    const [loadingStats, setLoadingStats] = useState<boolean>(false);
    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });

    const [resetTable, setResetTable] = useState<Date>(new Date());
    const [filter, setFilter] = useState<Ifilter>({
        invoiceType: ['Cod'],
        status: 1,
    });

    const filterAuthorizedInvoices = (invoicesList: Iinvoice[]) => {
        // return invoice autorized to be paid pending  (1)
        return invoicesList?.filter((invoice) => invoice.status === 1);
    };

    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [invoicesSelected, setInvoicesSelected] = useState<Iinvoice[]>([]);

    interface Imeta {
        totalDocs: number;
        limit: number;
        totalPages: number;
        page: number;
        pagingCounter: number;
        hasPrevPage: boolean;
        hasNextPage: boolean;
        prevPage: number | null;
        nextPage: number | null;
    }

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setFilter({ ...filter, ...{ dateRange: newRange } });
        setPagination({ ...pagination, page: 1 });
        setMeta({ ...meta, page: -1 });
    };
    useEffect(() => {
        getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    }, []);
    const exportBatchPayment = () => {
        downloadBatchPayment(filterAuthorizedInvoices(invoicesSelected));
        setResetTable(new Date());
    };
    const updateInvoicesStatus = () => {
        setMarkPaidLoader(true);
        const feedbackToast = toast.loading('Updating invoices...');
        const invoicesIds = filterAuthorizedInvoices(invoicesSelected).map((invoice) => invoice?._id);
        updateManyInvoices({ invoices: invoicesIds, status: 2 })
            .then(() => {
                toast.success('Invoices updated successfully', { id: feedbackToast });
                setMarkPaidLoader(false);
                const queryOptions = { ...filter, ...pagination };
                getInvoiceList(queryOptions).then((result) => {
                    setResetTable(new Date());
                    setInvoices(result.data.docs);
                    setIsLoading(false);
                    setMeta({
                        hasNextPage: result.data.hasNextPage,
                        hasPrevPage: result.data.hasPrevPage,
                        limit: result.data.limit,
                        nextPage: result.data.nextPage,
                        page: result.data.page,
                        pagingCounter: result.data.pagingCounter,
                        prevPage: result.data.prevPage,
                        totalDocs: result.data.totalDocs,
                        totalPages: result.data.totalPages,
                    });
                });
                setLoadingStats(true);
                countInvoicesStats(filter)
                    .then((res) => {
                        setInvoicesStats(res.data);
                        setLoadingStats(false);
                    })
                    .catch((err) => {
                        toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {});
                        setLoadingStats(false);
                    });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message, { id: feedbackToast });
                setMarkPaidLoader(false);
            });
    };

    useEffect(() => {
        if (filter) {
            setLoadingStats(true);
            countInvoicesStats(filter)
                .then((res) => {
                    setInvoicesStats(res.data);
                    setLoadingStats(false);
                })
                .catch((err) => {
                    toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {});
                    setLoadingStats(false);
                });
        }
    }, [filter.customer, filter.dateRange, filter.invoiceRef]);

    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        setIsLoading(true);
        const queryOptions = { ...pagination, ...filter };

        getInvoiceList(queryOptions)
            .then((result) => {
                toast.dismiss(feedbackToast);
                setInvoices(result.data.docs?.filter((inv: Iinvoice) => inv.fees?.length));
                setIsLoading(false);
                setMeta({
                    hasNextPage: result.data.hasNextPage,
                    hasPrevPage: result.data.hasPrevPage,
                    limit: result.data.limit,
                    nextPage: result.data.nextPage,
                    page: result.data.page,
                    pagingCounter: result.data.pagingCounter,
                    prevPage: result.data.prevPage,
                    totalDocs: result.data.totalDocs,
                    totalPages: result.data.totalPages,
                });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            });
    }, [filter, pagination]);

    const getTabValue = (tab: number) => {
        switch (tab) {
            case 0:
                return 1;
            case 1:
                return 3;
            case 2:
                return 2;
            default:
                return 0;
        }
    };

    const handleOpenBankInfo = (customer: ICustomer | null) => {
        setOpenBankInfo(!openBankInfo);
        setSelectedCustomer(customer);
    };

    const columns: TableColDef[] = [
        {
            field: 'createdAt',
            headerName: 'Created At',
            fieldType: 'date',
        },

        {
            field: 'reference',
            headerName: 'Reference',
            fieldType: 'render',
            renderCell: (params: Iinvoice) => <>{params.reference}</>,
        },
        {
            field: 'customer',
            headerName: 'Seller',
            classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
            classHeader: 'px-8',
            fieldType: 'render',
            renderCell: (params: Iinvoice) => (
                <>
                    <Button
                        title="Seller bank information"
                        onClick={() => handleOpenBankInfo(params?.customer as ICustomer)}
                        color="light"
                        disabled={!params?.customer}
                        className=" border-0"
                    >
                        <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {(params.customer as ICustomer)?.firstName
                                ? `${(params.customer as ICustomer)?.firstName} ${(params.customer as ICustomer)
                                      ?.lastName}`
                                : (params.customer as ICustomer)?.email}
                        </p>
                    </Button>
                </>
            ),
        },

        {
            field: 'generationTiming',
            headerName: 'Timing',
            fieldType: 'render',
            renderCell: (params: Iinvoice) => <>{timingDisplay[params.generationTiming]}</>,
        },

        {
            field: 'totalAmoutToPay',
            headerName: 'Amount ($)',
            fieldType: 'render',
            renderCell: (params: Iinvoice) => <>{Math.abs(params.totalAmountToPay || 0).toFixed(2)}</>,
        },

        {
            field: 'status',
            headerName: 'Status',
            fieldType: 'status',
        },

        {
            field: 'Action',
            actionType: 'Invoice',
            headerName: '',
            classField: 'w-[10px] min-w-[10px]',
            classHeader: 'w-[10px] min-w-[10px] ',
        },
    ];

    return (
        <div className="bg-white dark:bg-gray-900 p-2">
            <section className="dark:bg-gray-900  items-center my-1">
                <div className="flex  my-4">
                    <div className="flex ">
                        <span className="font-medium text-2xl">COD Invoices</span>
                    </div>
                </div>
                {invoicesStats && (
                    <div className="flex center justify-between w-full gap-4 my-4">
                        <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                            <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                                <TfiStatsUp className="w-6 h-6" />
                            </div>
                            <div>
                                <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                    Sellers income
                                </p>
                                <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                    {loadingStats ? (
                                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                                    ) : (
                                        `${Math.abs(invoicesStats?.totalAmountToPay || 0).toFixed(2)} $`
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                            <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                                <GoBlocked className="w-6 h-6" />
                            </div>
                            <div>
                                <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                    Total unpaid fees
                                </p>
                                <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                    {loadingStats ? (
                                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                                    ) : (
                                        `${Math.abs(invoicesStats?.totalUnpaidBalance || 0).toFixed(2)} $`
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                            <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                                <LuPhoneOutgoing className="w-6 h-6" />
                            </div>
                            <div>
                                <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                    Call center income
                                </p>
                                <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                    {loadingStats ? (
                                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                                    ) : (
                                        `${Math.abs(invoicesStats?.callCenterIncome || 0).toFixed(2)} $`
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                            <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                                <FaBox className="w-6 h-6" />
                            </div>
                            <div>
                                <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                    Orders income
                                </p>
                                <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                    {loadingStats ? (
                                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                                    ) : (
                                        `${Math.abs(invoicesStats?.ordersIncome || 0).toFixed(2)} $`
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <div className=" relative flex w-full items-center justify-between">
                    <div className="flex items-center gap-4">
                        <div>
                            <Datepicker
                                inputId="deliveryDate"
                                value={filter.dateRange || null}
                                containerClassName="relative z-100"
                                useRange
                                onChange={handleRangeFilterChange}
                                showShortcuts
                            />
                        </div>
                        <div className="w-72">
                            <AutoComplete
                                key="id"
                                placeholder="Sellers"
                                options={customers as []}
                                getOptionLabel={(option: any) =>
                                    option?.lastName && option?.firstName
                                        ? `${option.firstName} ${option.lastName}`
                                        : option?.email
                                }
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    setFilter({ ...filter, ...{ customer: option._id as string } });
                                    setPagination({ ...pagination, page: 1 });
                                    setMeta({ ...meta, page: -1 });
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <TextInput
                            icon={AiOutlineSearch}
                            style={{ width: '300px' }}
                            id="skuSearch"
                            placeholder="Search by reference"
                            name="sku"
                            onChange={(e) => {
                                setFilter({
                                    ...filter,
                                    ...{ invoiceRef: e.target.value || '' },
                                });
                                setPagination({ ...pagination, page: 1 });
                                setMeta({ ...meta, page: -1 });
                            }}
                        />
                    </div>
                </div>
            </section>

            <Tabs
                aria-label="Tabs with icons"
                // eslint-disable-next-line react/style-prop-object
                theme={{
                    tablist: {
                        tabitem: {
                            base: 'flex items-center text-blue-500 justify-center px-4 h-14 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                            styles: {
                                default: {
                                    base: 'flex items-center text-blue-500 justify-center px-4 h-14  text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                    active: {
                                        on: 'text-main border-main border-b-2',
                                        off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                                    },
                                },
                            },
                        },
                    },
                }}
                ref={tabsRef}
                onActiveTabChange={(tab) => {
                    if (getTabValue(tab) !== filter.status) {
                        setFilter({ ...filter, ...{ status: getTabValue(tab) } });
                        setPagination({ ...pagination, page: 1 });
                        setMeta({ ...meta, page: -1 });
                    }
                }}
            >
                {[
                    { label: 'Pending', value: 1 },
                    { label: 'Postponed', value: 3 },
                    { label: 'Paid', value: 2 },
                ].map((item: any) => {
                    const cols = [...columns];
                    const newColumn = {
                        field: 'totalAmountToPay',
                        headerName: 'Unpaid Balance ($)',
                        fieldType: 'render',
                        renderCell: (params: Iinvoice) => (
                            <>
                                {params.totalAmountToPay > 0
                                    ? Math.abs(params.fees.reduce((acc, elt) => acc + elt.unpaidDebt, 0)).toFixed(2)
                                    : Math.abs(params.totalAmountToPay).toFixed(2)}
                            </>
                        ),
                    };
                    if (item.value === 3) cols.splice(3, 1, newColumn);
                    return (
                        <Tabs.Item
                            title={<div className="h-full py-4">{item.label}</div>}
                            key={item.value}
                            style={{ height: '64px !important', padding: '0px 24px' }}
                            className="!py-0 xs:!h-14"
                        >
                            <div className="flex w-full items-center pb-2  justify-between">
                                {filter.status === 1 && (
                                    <div className="flex items-center justify-end w-full gap-4">
                                        <div>
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    updateInvoicesStatus();
                                                }}
                                                disabled={
                                                    markPaidLoader ||
                                                    filterAuthorizedInvoices(invoicesSelected).length === 0
                                                }
                                                color="light"
                                            >
                                                Set to paid
                                                {invoicesSelected.length > 0 ? (
                                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-600 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                                                        {filterAuthorizedInvoices(invoicesSelected).length}
                                                    </div>
                                                ) : null}
                                            </Button>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className={primaryButton}
                                                onClick={() => {
                                                    exportBatchPayment();
                                                }}
                                                disabled={filterAuthorizedInvoices(invoicesSelected).length === 0}
                                            >
                                                <CiExport className="mr-2 h-5 w-5" />
                                                Export
                                                {invoicesSelected.length > 0 ? (
                                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-600 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                                                        {filterAuthorizedInvoices(invoicesSelected).length}
                                                    </div>
                                                ) : null}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <DataTable
                                rows={invoices}
                                columns={cols}
                                icons={statusDisplay}
                                setRows={setInvoices}
                                pageState={{
                                    isLoading,
                                    total: meta.totalDocs,
                                    pageSize: pagination.limit,
                                    page: pagination.page,
                                    count: meta.totalPages,
                                }}
                                setPageState={handlePaginationChange}
                                selectable={item.value === 1}
                                setRowsSelected={setInvoicesSelected}
                                filterState={filter}
                                resetTable={resetTable}
                                setStats={setInvoicesStats}
                            />
                        </Tabs.Item>
                    );
                })}
            </Tabs>
            {openBankInfo && (
                <Modal
                    key={(selectedCustomer as ICustomer)._id}
                    dismissible
                    show={openBankInfo}
                    size="3xl"
                    popup
                    onClose={() => handleOpenBankInfo(null)}
                >
                    <Modal.Header>
                        <StatusAccount type="customer" label={(selectedCustomer as ICustomer).status} />
                    </Modal.Header>
                    <Modal.Body className="min-h-[740px] min-w-[150px]">
                        <CustomerBankInformation
                            bankInformation={(selectedCustomer as ICustomer)?.bankAccountInformation ?? {}}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
};

export default invoiceList;
