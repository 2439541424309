/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import Image from 'assets/no-image.png';
import { TableColDef } from 'components/tables/tables.types';
import { format } from 'date-fns';
import { Avatar, Button } from 'flowbite-react';
import React from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { IShipmentTrace, IShipment } from 'services/admin/stockShipment';
import { vnlinWarehouses } from 'utils/helpers/warehouses';
import { IStock } from 'services/admin/stock';
import { StatusBadge } from 'utils/helpers/statusBadge';
import { handleCopyTC } from 'utils/helpers/copyTC';
import { Typography } from '@mui/material';
import ShippingHistory from './ShipmentHistory/ShipmentHistory';
import ShipmentDetails from './shipmentDetails/shipmentDetails';

export const columns: TableColDef[] = [
    {
        field: 'country',
        headerName: 'Country',
    },
    {
        field: 'product.name',
        headerName: 'product Name',
        fieldType: 'render',
        renderCell: (params: IStock) => {
            const isThere = (
                params.product?.design?.length ? params.product.design : params?.parentProduct?.design || []
            )?.filter(
                (prodImg: any) =>
                    prodImg.endsWith('.jpg') ||
                    prodImg.endsWith('.png') ||
                    prodImg.endsWith('.jpeg') ||
                    prodImg.endsWith('.webp'),
            );
            return (
                <>
                    <td className="px-4 py-4 flex gap-2">
                        {isThere && isThere.length > 0 ? <Avatar img={isThere[0]} /> : <Avatar img={Image} />}
                        <span className="capitalize my-auto">
                            {params.parentProduct?.restockFor?.name || params.parentProduct.name}
                        </span>
                    </td>{' '}
                </>
            );
        },
    },
    {
        field: 'product',
        headerName: 'Variant',
        fieldType: 'render',
        renderCell: (params: any) => {
            return (
                <>
                    <td className="py-4 block gap-2">
                        {params?.product?.itemKind === 'variant' && (
                            <div className="capitalize text-black dark:text-white">{params?.product?.name}</div>
                        )}
                        <div className="flex items-center gap-2">
                            <p className="font-normal ">{params?.product?.globalSKU}</p>
                            <Button
                                size="l"
                                color="white"
                                title="Copy tracking code to clipboard"
                                onClick={() => {
                                    handleCopyTC(params?.product?.globalSKU || '', 'SKU');
                                }}
                            >
                                <FaRegCopy className="w-4 h-4" />
                            </Button>
                        </div>
                    </td>{' '}
                </>
            );
        },
    },
    {
        field: 'warehouse',
        headerName: 'Warehouse',
        fieldType: 'render',
        renderCell: (params: any) => {
            return (
                <>
                    <td className="py-4 block gap-2">
                        <div className="capitalize my-auto">
                            {vnlinWarehouses[params.warehouse as '64' | '90' | '91' | '99'].name}
                        </div>
                    </td>{' '}
                </>
            );
        },
    },
    {
        field: 'customer',
        headerName: 'Seller name',
        fieldType: 'combo',
        child: ['firstName', 'lastName'],
    },
    {
        field: 'quantity',
        headerName: 'Total Qty',
        classHeader: 'text-center',
        classField: 'text-center items-center  justify-around',
    },
    {
        field: 'incomingQuantity',
        headerName: 'Incoming Qty',
        fieldType: 'render',
        classHeader: 'text-center',
        renderCell: (params: IStock) => (
            <p className="text-center  items-center  justify-around">{` ${params?.incomingQuantity || 0} `}</p>
        ),
    },
    {
        field: 'availableQuantity',
        headerName: 'Available Qty',
        classHeader: 'text-center',
        classField: 'text-center items-center  justify-around',
    },
    {
        field: 'outboundQuantity',
        headerName: 'Outside Qty',
        classHeader: 'text-center',
        classField: 'text-center items-center  justify-around',
    },
    {
        field: 'deliveredQuantity',
        headerName: 'Delivered Qty ',
        classHeader: 'text-center',
        classField: 'text-center items-center  justify-around',
    },
    {
        field: 'packagedQuantity',
        headerName: 'Original Package Returns',
        classHeader: 'text-center',
        classField: 'text-center items-center  justify-around',
    },
    {
        field: 'Action',
        headerName: '',
        actionType: 'render',
        renderCell: (params) => {
            return (
                <>
                    <ShipmentDetails data={params} />
                </>
            );
        },
    },
];

export const stockStatusMatch = {
    1: { label: 'Preparing stock', badgeColor: 'yellowTag' },
    2: { label: 'Ready for pickup', badgeColor: 'blueTag' },
    3: { label: 'Shipping', badgeColor: 'blueTag' },
    30: { label: 'Stock received by carrier ', badgeColor: 'blueTag' },
    31: { label: 'Sent to GuangZhou Airport ', badgeColor: 'blueTag' },
    32: { label: 'Instation Scan GZ ', badgeColor: 'blueTag' },
    33: { label: 'Arrived in loading warehouse ', badgeColor: 'blueTag' },
    34: { label: 'Sent To HongKong ', badgeColor: 'blueTag' },
    35: { label: 'Instation Scan HK ', badgeColor: '' },
    36: { label: 'Departed on Flight ', badgeColor: 'blueTag' },
    37: { label: 'Arrived destination Airport ', badgeColor: 'blueTag' },
    38: { label: 'Customs clearance start ', badgeColor: 'blueTag' },
    39: { label: 'Customs Released_Import ', badgeColor: 'blueTag' },
    40: { label: 'Arrive to headquarters warehouse ', badgeColor: 'blueTag' },
    4: { label: 'Delivered', badgeColor: 'greenTag' },
};

export const shipmentDetailsColumns: TableColDef[] = [
    {
        field: 'createdAt',
        headerName: 'Date',
        fieldType: 'render',
        renderCell: (params: IShipment) =>
            params?.createdAt ? <p>{format(new Date(params?.createdAt), 'dd/MM/yyyy')}</p> : <p>-</p>,
    },

    {
        field: 'quantity',
        headerName: 'Qty',
        fieldType: 'render',
        renderCell: (params: IShipment) => (params?.quantity ? <p>{params.quantity}</p> : <p>-</p>),
    },

    {
        field: 'trackingCode',
        headerName: 'Tracking Code',
        fieldType: 'render',
        renderCell: (params: IShipment) =>
            params?.trackingCode ? (
                <div className="flex items-center gap-2">
                    <p className="font-bold">{params?.trackingCode}</p>
                    <Button
                        size="l"
                        color="white"
                        title="Copy Logistio code to clipboard"
                        onClick={() => {
                            handleCopyTC(params?.trackingCode || '', 'Tracking code');
                        }}
                    >
                        <FaRegCopy className="w-4 h-4" />
                    </Button>
                </div>
            ) : (
                <p> -</p>
            ),
    },

    {
        field: 'lgstTrackingCode',
        headerName: 'Logistio Code',
        fieldType: 'render',
        renderCell: (params: IShipment) =>
            params?.lgstTrackingCode ? (
                <div className="flex items-center gap-2">
                    <p className="font-bold">{params?.lgstTrackingCode}</p>
                    <Button
                        size="l"
                        color="white"
                        title="Copy Logistio code to clipboard"
                        onClick={() => {
                            handleCopyTC(params?.lgstTrackingCode || '', 'Logistio code');
                        }}
                    >
                        <FaRegCopy className="w-4 h-4" />
                    </Button>
                </div>
            ) : (
                <p> -</p>
            ),
    },

    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'render',
        renderCell: (params: IShipment) => {
            const statusValue = params?.status?.toString() as '1' | '2' | '3' | '4';
            return (
                // <Badge color={stockStatusMatch[statusValue]} size="sm" style={{ width: 'fit-content' }}>
                //     {stockStatusMatch[statusValue]}
                // </Badge>
                <StatusBadge
                    name={stockStatusMatch[statusValue].label}
                    style={`${stockStatusMatch[statusValue].badgeColor} w-fit`}
                />
            );
        },
    },

    {
        field: 'Details',
        headerName: '',
        fieldType: 'render',
        renderCell: (params) => (
            <>
                <ShippingHistory data={params} />
            </>
        ),
    },
];
export const shipmentColumns: TableColDef[] = [
    {
        field: 'time',
        headerName: 'Date',
        fieldType: 'render',
        renderCell: (params: IShipmentTrace) =>
            params?.time ? <p>{format(new Date(params?.time), 'dd/MM/yyyy')}</p> : <p>-</p>,
    },

    {
        field: 'time',
        headerName: 'hour',
        fieldType: 'render',
        renderCell: (params: IShipmentTrace) =>
            params?.time ? <p>{format(new Date(params?.time), 'hh:mm:ss')}</p> : <p>-</p>,
    },

    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'render',
        renderCell: (params: IShipmentTrace) => {
            const statusValue = params?.logistioStatus?.toString() as '1' | '2' | '3' | '4';

            return (
                <>
                    <StatusBadge
                        name={stockStatusMatch?.[statusValue]?.label}
                        style={`${stockStatusMatch?.[statusValue]?.badgeColor} w-fit`}
                    />
                    {params.description && <Typography>{params.description}</Typography>}
                </>
            );
        },
    },
];
