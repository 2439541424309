import { ICall } from 'services/call/call';
import { create } from 'zustand';

export type ZiwoStoreValues = {
    iFrameVisible: boolean;
    ziwo: any;
    callWebSocketActive: boolean;
    activeCallOrders: Map<string, ICall>;
    status: string;
    scheduledCallNotification: {
        show: boolean;
        data: {
            user: string;
            order: string;
            phoneNumber: string;
        } | null;
    };
    order: string;
};

export type ZiwoStoreActions = {
    setIFrameVisibility: (visibility: boolean) => void;
    setZiwo: (value: any) => void;
    setZiwoStatus: (value: string) => void;
    setCallWebSocketStatus: (value: boolean) => void;
    setActiveCallOrders: ({ callID, callData }: { callID: string; callData: ICall }, type: 'add' | 'remove') => void;
    setScheduledCallNotification: (value: Partial<ZiwoStoreValues['scheduledCallNotification']>) => void;
    setOrder: (value: string) => void;
};

const ziwoStore = create<ZiwoStoreValues & ZiwoStoreActions>((set) => ({
    // Store values
    ziwo: null,
    iFrameVisible: false,
    callWebSocketActive: false,
    activeCallOrders: new Map<string, ICall>(),
    status: '',
    scheduledCallNotification: {
        show: false,
        data: null,
    },
    order: '',

    // Store actions
    setZiwo: (value) => set(() => ({ ziwo: value })),
    setZiwoStatus: (value) => set(() => ({ status: value })),
    setIFrameVisibility: (value) => set(() => ({ iFrameVisible: value })),
    setCallWebSocketStatus: (value) => set(() => ({ callWebSocketActive: value })),
    setActiveCallOrders: ({ callID, callData }, type) =>
        set((state) => {
            const draft = new Map<string, ICall>();
            state.activeCallOrders.forEach((v, k) => {
                draft.set(k, JSON.parse(JSON.stringify(v)));
            });
            switch (type) {
                case 'add':
                    draft.set(callID, callData);
                    break;
                case 'remove':
                    if (!state.activeCallOrders.has(callID)) {
                        return { activeCallOrders: state.activeCallOrders };
                    }
                    draft.delete(callID);
                    break;
                default:
                    break;
            }
            return { activeCallOrders: draft };
        }),
    setScheduledCallNotification: (value) =>
        set((state) => {
            const scheduledCallNotification = JSON.parse(
                JSON.stringify(state.scheduledCallNotification),
            ) as ZiwoStoreValues['scheduledCallNotification'];
            if (value.show !== undefined) {
                scheduledCallNotification.show = value.show;
            }
            if (value.data !== undefined) {
                scheduledCallNotification.data = value.data;
            }
            return { scheduledCallNotification };
        }),
    setOrder: (value) => set(() => ({ order: value })),
}));

export const useZiwo = () =>
    ziwoStore((state) => {
        return state.ziwo;
    });

export const useSetZiwo = () => ziwoStore((state) => state.setZiwo);

export const useZiwoStatus = () =>
    ziwoStore((state) => {
        return state.status;
    });

export const useSetZiwoStatus = () => ziwoStore((state) => state.setZiwoStatus);

export const useZiwoVisibility = () => {
    const iFrameVisible = ziwoStore((state) => state.iFrameVisible);
    const setIFrameVisibility = ziwoStore((state) => state.setIFrameVisibility);
    return [iFrameVisible, setIFrameVisibility] as const;
};

export const ziwoLogout = () => {
    const z = ziwoStore((state) => {
        return state.ziwo;
    });
    if (z) {
        z.session.logout();
    }
    localStorage.removeItem('ZIWO_LOGIN_DETAILS');
    localStorage.removeItem('ZIWO_SELECTED_STATUS');
    localStorage.removeItem('ZIWO_SESSION_DETAILS');
};

export const useCallWebSocketStatus = () => ziwoStore((state) => state.callWebSocketActive);

export const useSetCallWebSocketStatus = () => ziwoStore((state) => state.setCallWebSocketStatus);

export const useActiveCallOrders = () => ziwoStore((state) => state.activeCallOrders);

export const useSetActiveCallOrders = () => ziwoStore((state) => state.setActiveCallOrders);

export const useSetScheduledCallNotification = () => ziwoStore((state) => state.setScheduledCallNotification);

export const useScheduledCallNotification = () => ziwoStore((state) => state.scheduledCallNotification);

// export const useZiwoOrder = () => {
//     const order = ziwoStore((state) => {
//         return state.order;
//     });
//     const setOrder = ziwoStore((state) => state.setOrder);
//     return [order, setOrder] as const;
// };
export const useZiwoOrder = () =>
    ziwoStore((state) => {
        return state.order;
    });

export const useSetZiwoOrder = () => ziwoStore((state) => state.setOrder);
