/* eslint-disable react/jsx-fragments */
import CustomBtn from 'components/customButton/customBtn';
import React, { useState } from 'react';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import CountriesAccordion from './countriesAccordion';
import AddConfiguration from './addConfiguration';
import { IPricingLogistioBulkFeesProps } from '../pricingConfiguration.types';

const PricingPrepaidNoCC: React.FC<IPricingLogistioBulkFeesProps> = ({
    pricing,
    refetchGetFees,
    action,
    configId,
    setValues,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <AddConfiguration
                refetchGetFees={refetchGetFees}
                configId={configId}
                open={open}
                setOpen={setOpen}
                configPricing={pricing}
            />
            <div className="flex justify-end gap-2 p-2">
                {action === 'UPDATE' && (
                    <CustomBtn variant="primary" onClick={() => setOpen(true)} pill>
                        <AiOutlineAppstoreAdd className="mr-2 h-5 w-5" />
                        New
                    </CustomBtn>
                )}
            </div>

            <div>
                <CountriesAccordion
                    pricing={pricing}
                    configId={configId}
                    refetchGetFees={refetchGetFees}
                    setValues={setValues}
                />
            </div>
        </>
    );
};

export default PricingPrepaidNoCC;
