import React from 'react';
import { Badge } from 'flowbite-react';
import { TableColDef, IStausInterface } from '../../../components/tables/tables.types';
import { ICustomer } from '../customer.types';

export const columns: TableColDef<ICustomer>[] = [
    {
        field: 'firstName',
        headerName: 'Full name',
        classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
        fieldType: 'render',
        renderCell: (params: ICustomer) => (
            <p>{`${params?.lastName && params.firstName ? `${params.firstName} ${params.lastName}` : '-'} `}</p>
        ),
    },
    {
        field: 'email',
        headerName: 'E-Mail',
    },
    {
        field: 'accountManagerNames',
        headerName: 'Assigned Managers',
    },
    {
        field: 'status',
        headerName: 'Status',
        fieldType: 'status',
    },
    {
        field: 'productsSourced',
        headerName: 'Sourced products',
    },
    {
        field: 'Action',
        actionType: 'Customer',
        headerName: 'Options',
    },
];
export const statusDisplay: { [keys: string]: IStausInterface } = {
    1: {
        statusIcon: (
            <Badge color="warning" size="sm">
                Pending
            </Badge>
        ),
        statusLabel: 'Pending',
    },
    2: {
        statusIcon: (
            <Badge color="success" size="sm">
                Approved
            </Badge>
        ),
        statusLabel: 'Approved',
    },
    3: {
        statusIcon: (
            <Badge color="failure" size="sm">
                Disabled
            </Badge>
        ),
        statusLabel: 'Disabled',
    },
};
