/* eslint-disable no-underscore-dangle */
import AutoComplete from 'components/autocomplete/autoComplete';
import { ICustomer } from 'pages/customers/customer.types';
import React, { useEffect, useState } from 'react';
import { getCustomers } from 'services/admin/customer';
import { countInvoicesStats, getInvoiceList, ICountInvoicesStats } from 'services/admin/invoice';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import { TfiStatsUp } from 'react-icons/tfi';
import { FaBox } from 'react-icons/fa6';
import Datepicker from 'react-tailwindcss-datepicker';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';

import moment from 'moment-timezone';
import toast from 'react-hot-toast';

import { TableColDef } from 'components/tables/tables.types';
import DataTable from '../../../components/tables/tables';
import { Ifilter, Iinvoice, Ipagination } from '../invoice.types';
import { statusDisplay } from './invoiceListElements';

import { timingDisplay } from '../invoice/invoiceListElements';

const prepaidInvoiceList = () => {
    UseDocumentTitle('Prepaid Invoices');
    const [isLoading, setIsLoading] = useState(true);
    const [invoices, setInvoices] = useState<Array<Iinvoice>>([]);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [invoicesStats, setInvoicesStats] = useState<ICountInvoicesStats | null>(null);
    const [loadingStats, setLoadingStats] = useState<boolean>(false);

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });

    const [filter, setFilter] = useState<Ifilter>({ invoiceType: ['PrepaidNoCC'] });

    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [, setInvoicesSelected] = useState<any[]>([]);
    const [resetTable] = useState<Date>(new Date());
    interface Imeta {
        totalDocs: number;
        limit: number;
        totalPages: number;
        page: number;
        pagingCounter: number;
        hasPrevPage: boolean;
        hasNextPage: boolean;
        prevPage: number | null;
        nextPage: number | null;
    }

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setFilter({ ...filter, ...{ dateRange: newRange } });
        setMeta({ ...meta, page: -1 });
    };
    useEffect(() => {
        getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    }, []);

    useEffect(() => {
        if (filter) {
            setLoadingStats(true);
            countInvoicesStats(filter)
                .then((res) => {
                    setInvoicesStats(res.data);
                    setLoadingStats(false);
                })
                .catch((err) => {
                    toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {});
                    setLoadingStats(false);
                });
        }
    }, [filter.dateRange, filter.customer]);

    useEffect(() => {
        const feedbackToast = toast.loading('Loading data...');
        setIsLoading(true);
        // const queryOptions = { ...debouncedPagination, ...debouncedFilter };

        const queryOptions = { ...pagination, ...filter };

        getInvoiceList(queryOptions)
            .then((result) => {
                toast.dismiss(feedbackToast);
                setInvoices(result.data.docs?.filter((inv: Iinvoice) => inv.fees?.length));
                setIsLoading(false);
                setMeta({
                    hasNextPage: result.data.hasNextPage,
                    hasPrevPage: result.data.hasPrevPage,
                    limit: result.data.limit,
                    nextPage: result.data.nextPage,
                    page: result.data.page,
                    pagingCounter: result.data.pagingCounter,
                    prevPage: result.data.prevPage,
                    totalDocs: result.data.totalDocs,
                    totalPages: result.data.totalPages,
                });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error((err as any)?.response?.data?.errors?.message || 'Unable to load data.', {
                    id: feedbackToast,
                });
            });
    }, [filter, pagination]);

    const columns: TableColDef[] = [
        {
            field: 'createdAt',
            headerName: 'Created At',
            fieldType: 'date',
        },
        {
            field: 'reference',
            headerName: 'Reference',
            fieldType: 'render',
            renderCell: (params: Iinvoice) => <>{params.reference}</>,
        },

        {
            field: 'customer',
            headerName: 'Seller',
            classField: 'whitespace-nowrap font-medium text-gray-900 dark:text-white',
            fieldType: 'render',
            renderCell: (params: Iinvoice) => (
                <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {(params.customer as ICustomer)?.firstName
                        ? `${(params.customer as ICustomer)?.firstName} ${(params.customer as ICustomer)?.lastName}`
                        : (params.customer as ICustomer)?.email}
                </p>
            ),
        },

        {
            field: 'generationTiming',
            headerName: 'Timing',
            fieldType: 'render',
            renderCell: (params: Iinvoice) => <>{timingDisplay[params.generationTiming]}</>,
        },

        {
            field: 'totalAmoutToPay',
            headerName: 'Total fees ($)',
            fieldType: 'render',
            renderCell: (params: Iinvoice) => <>{Math.abs(params.totalFees || 0).toFixed(2)}</>,
        },

        {
            field: 'Action',
            actionType: 'Invoice',
            headerName: '',
            classField: 'w-[10px] min-w-[10px]',
            classHeader: 'w-[10px] min-w-[10px] ',
        },
    ];

    return (
        <div className="bg-white dark:bg-gray-900 p-2">
            <section className="dark:bg-gray-900  items-center my-1">
                <div className="flex  my-4">
                    <div className="flex ">
                        <span className="font-medium text-2xl">Prepaid Invoices</span>
                    </div>
                </div>
                {invoicesStats && (
                    <div className="flex center justify-between w-full gap-4 my-4">
                        <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                            <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                                <TfiStatsUp className="w-6 h-6" />
                            </div>
                            <div>
                                <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">Handle fees</p>
                                <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                    {loadingStats ? (
                                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                                    ) : (
                                        `${Math.abs(invoicesStats?.totalHandleFees || 0).toFixed(2)} $`
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                            <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                                <FaBox className="w-6 h-6" />
                            </div>
                            <div>
                                <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                    Shipping fees
                                </p>
                                <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                    {loadingStats ? (
                                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                                    ) : (
                                        `${Math.abs(invoicesStats?.totalShippingFees || 0).toFixed(2)} $`
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                            <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                                <ShoppingCartSharpIcon className="w-6 h-6" />
                            </div>
                            <div>
                                <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                    Reseller fees
                                </p>
                                <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                    {loadingStats ? (
                                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-700 dark:border-gray-500" />
                                    ) : (
                                        `${Math.abs(invoicesStats?.totalResellerFees || 0).toFixed(2)} $`
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <div className=" relative flex w-full items-center justify-between py-2">
                    <div className="flex items-center gap-4">
                        <div>
                            <Datepicker
                                inputId="deliveryDate"
                                value={filter.dateRange || null}
                                containerClassName="relative z-100"
                                useRange
                                onChange={handleRangeFilterChange}
                                showShortcuts
                            />
                        </div>
                        <div className="w-72">
                            <AutoComplete
                                key="id"
                                placeholder="Sellers"
                                options={customers as []}
                                getOptionLabel={(option: any) =>
                                    option?.lastName && option?.firstName
                                        ? `${option.firstName} ${option.lastName}`
                                        : option?.email
                                }
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => {
                                    setFilter({ ...filter, ...{ customer: option._id as string } });
                                    setPagination({ ...pagination, page: 1 });
                                    setMeta({ ...meta, page: -1 });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <DataTable
                rows={invoices}
                columns={columns}
                icons={statusDisplay}
                setRows={setInvoices}
                pageState={{
                    isLoading,
                    total: meta.totalDocs,
                    pageSize: pagination.limit,
                    page: pagination.page,
                    count: meta.totalPages,
                }}
                setPageState={handlePaginationChange}
                setRowsSelected={setInvoicesSelected}
                filterState={filter}
                resetTable={resetTable}
            />
        </div>
    );
};

export default prepaidInvoiceList;
